import { Pipe, PipeTransform } from '@angular/core';
import { BetCouponGroup } from 'clientside-coupon';

@Pipe({
  name: 'hasGroupings',
})
export class HasGroupingsPipe implements PipeTransform {
  transform(groups: BetCouponGroup[]): boolean {
    // check if atleast one grouping is checked
    const checkedGrouping = groups.filter(g => g.Selected);

    // if none are found, set current group to checked and exit
    if (checkedGrouping.length <= 1) {
      return false;
    }

    return true;
  }
}
