import { OnDestroy, Pipe, PipeTransform } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';

@Pipe({
  name: 'isOddSelected',
})
export class IsOddSelectedPipe implements PipeTransform, OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly couponQuery: CouponQuery) {}

  transform(oddId: number): Observable<boolean> {
    return this.couponQuery.couponSelectionIds$.pipe(
      map(csi => csi.includes(oddId)),
      takeUntil(this.destroy$)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
