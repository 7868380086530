import { Component, Input } from '@angular/core';

import { OddModel } from 'src/app/shared/models/coupon.model';
import { CorrectScoreOddsModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-correct-scores-market-odds',
  templateUrl: './correct-scores-market-odds.component.html',
  styleUrls: ['./correct-scores-market-odds.component.scss'],
})
export class CorrectScoresMarketOddsComponent {
  @Input() odd: CorrectScoreOddsModel;

  oddTrackBy(index: number, odd: OddModel): number {
    return odd.id;
  }
}
