import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { OddModel } from 'src/app/shared/models/coupon.model';

@Component({
  selector: 'sports-single-line-market-odds',
  templateUrl: './single-line-market-odds.component.html',
  styleUrls: ['./single-line-market-odds.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleLineMarketOddsComponent {
  @Input() odds: OddModel[];
  @Input() separator: boolean = true;

  oddTrackBy(index: number, odd: OddModel): number {
    return odd.id;
  }
}
