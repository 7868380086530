import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { VirtualsInfoSection, VirtualsInfoSubSection } from 'src/app/shared/models/virtuals.model';

@Component({
  selector: 'virtuals-info-section',
  templateUrl: './virtuals-info-section.component.html',
  styleUrls: ['./virtuals-info-section.component.scss'],
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None, // required in order to be able to style <a> elements in content
})
export class VirtualsInfoSectionComponent implements OnInit, OnDestroy {
  @Input() section: VirtualsInfoSection;

  private readonly expandedSubSection$ = new BehaviorSubject<VirtualsInfoSubSection>(undefined);
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  ngOnInit(): void {
    const subSectionToExpand = this.section?.subSections?.find(s => s.expanded);
    subSectionToExpand && this.toggleExpanded(subSectionToExpand);
  }

  toggleExpanded(subSection: VirtualsInfoSubSection): void {
    if (this.expandedSubSection$.value === subSection) {
      this.expandedSubSection$.next(undefined);
    } else {
      this.expandedSubSection$.next(subSection);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
