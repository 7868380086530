export const INSERT_COUPON_STATUS_CODES: any = {
  0: $localize`An error has occurred, please try again`,
  1: $localize`Bet was successful`,
  2: $localize`At this time you cannot place bets. Please contact customer support for more information`,
  3: $localize`One of the chosen events has expired`,
  4: $localize`One of the odds has changed.`,
  5: $localize`Wrong Password`,
  6: $localize`Username Equals Agent name`,
  7: $localize`Timeout For Coupon Cancellation`,
  8: $localize`Wrong User Network`,
  9: $localize`Wrong User Information`,
  10: $localize`Username Already Exists`,
  11: $localize`Account Insert Data Error`,
  12: $localize`Error Inserting Contact User`,
  13: $localize`Error Inserting Parameters User`,
  14: $localize`Error Updating User`,
  15: $localize`Wrong Currency`,
  16: $localize`Your account balance is low. Deposit now in order to proceed with your bet.`,
  17: $localize`Bet Not Paid By System`,
  18: $localize`Agent Bettor Mismatch`,
  19: $localize`Bet Already Paid`,
  20: $localize`Max Number Of Coupons Cancelled`,
  21: $localize`Bet Lost or Open`,
  22: $localize`Banker Not Allowed On Integrals`,
  23: $localize`Email Already Exist`,
  24: $localize`MinLimit By Player Reached`,
  25: $localize`MaxLimit By Player Reached`,
  26: $localize`Single Bet Max Limit By User`,
  27: $localize`Multiple Bet Max Limit By User`,
  28: $localize`Combination Bet Max Limit By User`,
  29: $localize`Concurrency Issue`,
  30: $localize`Team Already Exists`,
  31: $localize`Error Inserting User Risk Settings`,
  32: $localize`Results Already Exists`,
  33: $localize`Error Inserting User Limits`,
  34: $localize`Missing Mappings`,
  35: $localize`Stake Too High To Cancel`,
  36: $localize`Operation Already Done`,
  37: $localize`Cannot Cancel Live Bet`,
  38: $localize`Coupon Already Paid`,
  39: $localize`Max Coupon Loss Limit Exceeded`,
  40: $localize`Incorrect number of events`,
  41: $localize`Role Already Exists`,
  43: $localize`Operator Already Exists`,
  44: $localize`Country Mismatch`,
  45: $localize`Over Max Win Threshold`,
  46: $localize`Has To Change Password`,
  49: $localize`Maximum combinability exceeded`,
  50: $localize`Cannot Cancel Has Sub Events`,
  51: $localize`Cannot Cancel Has Markets`,
  52: $localize`Maximum stake allowed exceeded`,
  53: $localize`Maximum winning allowed exceeded`,
  54: $localize`Stake is lower than amount allowed`,
  55: $localize`Stake for each group is lower than amount allowed`,
  69: $localize`The maximum number of events allowed has been exceeded`,
  70: $localize`Incompatible events in the coupon`,
  200: $localize`Record Not Found`,
  201: $localize`Operation Not Allowed`,
  400: $localize`An error has occurred, please try again (400)`,
  401: $localize`Unauthorized, please try logging in again (401)`,
  404: $localize`An error has occurred, please try again (404)`,
  406: $localize`Free Bet is not available`,
  407: $localize`Free Bet has already been used`,
  412: $localize`Free Bet has expired`,
  415: $localize`Free Bet is not available`,
  500: $localize`An error has occurred, please try again (500)`,
  600: $localize`An error has occurred, please try again (600)`,
  601: $localize`Please add or remove 1 selection and try again`,
  602: $localize`An error has occurred, please try again (602)`,
  603: $localize`An error has occurred, please try again (603)`,
  604: $localize`An error has occurred, please try again (604)`,
  605: $localize`An error has occurred, please try again (605)`,
  606: $localize`Your account balance is low. Deposit now in order to proceed with your bet.`,
  607: $localize`An error has occurred, please try again (607)`,
  608: $localize`An error has occurred, please try again (608)`,
  609: $localize`An error has occurred, please try again (609)`,
  610: $localize`Unauthorized, please try logging in again (610)`,
  611: $localize`An error has occurred, please try again (611)`,
  612: $localize`An error has occurred, please try again (612)`,
  613: $localize`An error has occurred, please try again (613)`,
  614: $localize`An error has occurred, please try again (614)`,
  615: $localize`Stake exceeds limit allowed (615)`,
  616: $localize`An error has occurred, please try again (616)`,
  617: $localize`An error has occurred, please try again (617)`,
  618: $localize`An error has occurred, please try again (618)`,
  619: $localize`An error has occurred, please try again (619)`,
  620: $localize`An error has occurred, please try again (620)`,
  621: $localize`An error has occurred, please try again (621)`,
  622: $localize`An error has occurred, please try again (622)`,
  623: $localize`An error has occurred, please try again (623)`,
};

export const UPDATE_COUPON_STATUS_CODES: any = {
  0: $localize`Success`,
  1: $localize`Operation Not Allowed`,
  2: $localize`Selection has already been added`,
  3: $localize`OutrightError`,
  4: $localize`Cannot add a selection with a unique event`,
  5: $localize`Cannot add a unique event`,
  6: $localize`Max number of selections reached`,
  7: $localize`Cannot add more than 30 events`,
  8: $localize`Banker not allow in cross combination`,
  9: $localize`Cannot insert cross combination on live`,
  10: $localize`Banker not allowed on live`,
  11: $localize`Related events are incompatible`,
  12: $localize`Minimum combinability not reached`,
  13: $localize`Odd Does Not Exist`,
  14: $localize`Could not remove odd`,
  15: $localize`Could not remove grouping`,
  16: $localize`Wrong game play. Requires more events.`,
  17: $localize`One of the odds has changed`,
  18: $localize`One of the chosen events has expired`,
  19: $localize`Stake under minimum amount allowed`,
  20: $localize`Stake over maximum amount allowed`,
  21: $localize`Over maximum winning amount`,
  22: $localize`Group stake under minimum amount allowed`,
  23: $localize`Group stake over maximum amount allowed`,
  405: $localize`Booking code is no longer available. All events are expired`,
  416: $localize`Free Bet is not available on this market`,
  500: $localize`Flexicut is not available on this market`,
};

export const UPDATE_VIRTUALS_COUPON_STATUS_CODES: any = {
  0: $localize`Success`,
  1: $localize`Operation Not Allowed`,
  2: $localize`Selection has already been added`,
  3: $localize`OutrightError`,
  4: $localize`Cannot add a selection with a unique event`,
  5: $localize`Cannot add a unique event`,
  6: $localize`Max number of selections reached`,
  7: $localize`Cannot add more than 30 events`,
  8: $localize`Banker not allow in cross combination`,
  9: $localize`Cannot insert cross combination on live`,
  10: $localize`Banker not allowed on live`,
  11: $localize`Related events are incompatible`,
  12: $localize`Minimum combinability not reached`,
  13: $localize`Odd Does Not Exist`,
  14: $localize`Could not remove odd`,
  15: $localize`Could not remove grouping`,
  16: $localize`Wrong game play. Requires more events.`,
  17: $localize`One of the odds has changed`,
  18: $localize`One of the chosen events has expired`,
  19: $localize`Stake under minimum amount allowed`,
  20: $localize`Stake over maximum amount allowed`,
  21: $localize`Over maximum winning amount`,
  22: $localize`Minimum stake per group is`,
  23: $localize`Group stake over maximum amount allowed`,
  405: $localize`Booking code is no longer available. All events are expired`,
  416: $localize`Free Bet is not available on this market`,
};
