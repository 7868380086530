import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from 'src/app/core/services/application.service';
import { SportService } from 'src/app/core/services/sports/sport.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { SportQuery } from 'src/app/core/state/sport/sport.query';

@Component({
  selector: 'header-bk-logo',
  templateUrl: './bk-header-logo.component.html',
  styleUrls: ['./bk-header-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BKHeaderLogoComponent {
  readonly isAuth$ = this.accountQuery.isAuthenticated$;

  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly applicationService: ApplicationService,
    private readonly router: Router,
    private readonly sportQuery: SportQuery,
    private readonly sportService: SportService
  ) {}

  goHome(): void {
    if (this.router.url !== '/') {
      // Requirements state that the brand logo must always lead to the topmost landing page, irrelevant of current active product
      this.router.navigateByUrl('/');
      this.applicationService.closeAnySlideUps();
      if (this.sportQuery.selectedPrematch) {
        this.sportService.restoreAreaRegionsCache();
      }
    }
  }
}
