<app-info-modal i18n [show]="show" [hasActions]="false" (closeModal)="handleCloseModal()" data-testid="free-bets-list-modal">
  <div class="header" header>
    <div class="flexicut-logo"></div>
    <span class="header-info">Select how many matches you want to CUT from your bet and increase your chances of winning!</span>
  </div>

  <div class="body" body>
    <div class="cut-options" *ngIf="(couponQuery.flexicutOdds$ | async).length > 0">
      <ng-container *ngFor="let item of flexicutOptions">
        <coupon-flexicut-option
          id={{item.id}}
          [cutNumber]="(((tempOptionClick$ | async)?.optionId === item.id) && (tempOptionClick$ | async)?.cutNumber) ? (tempOptionClick$ | async).cutNumber : item.cutNumber"
          [selected]="(tempOptionClick$ | async)?.optionId === item.id"
          (optionClick)="optionClick($event)"
          [decreaseThreshold]="item.decreaseThreshold"
          [isCustomized]="item.isCustomized">
        </coupon-flexicut-option>
      </ng-container>
    </div>

    <div class="btn-container">
      <button
        class="btn cancel-flexicut"
        (click)="handleCloseModal()"
        data-testid="apply-flexicut-btn"
      >
        <span>Cancel</span>
      </button>
      <button
        class="btn apply-flexicut"
        [disabled]="((tempOptionClick$ | async) === undefined) || ((tempOptionClick$ | async)?.optionId === undefined)"
        (click)="onApplyFlexicut()"
        data-testid="apply-flexicut-btn"
      >
        <span>Apply Flexicut</span>
      </button>
    </div>
  </div>
</app-info-modal>
