import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AndroidSettingsState } from 'src/app/modules/native-app/models/android-settings.model';

export const initialAndroidSettingsObject = {
  latestVersion: {
    versionName: '0.0.1',
    versionCode: 1,
    fileName: 'app.apk',
    eventDateTime: new Date().toTimeString(),
    optionalUpdate: true,
  },
  appVersionCode: 1,
  appVersionName: '0.0.1',
  minVersionCode: 1,
  webViewMode: false,
  offlineMode: false,
};

const createInitialState = (): AndroidSettingsState => ({ ...initialAndroidSettingsObject });

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'androidSettings' })
export class AndroidSettingsStore extends Store<AndroidSettingsState> {
  constructor() {
    super(createInitialState());
  }

  changeAndroidSettings(AndroidSettings: AndroidSettingsState): void {
    this.update({ ...AndroidSettings });
  }

  switchToOfflineMode(): void {
    this.update({ offlineMode: true });
  }
}
