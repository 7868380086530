<div class="card" [ngClass]="{ 'border-radius': addBorderRadius, 'collapsible-card': collapsible, 'no-margin': overrideCardMargin, collapsed: collapsed }">
  <div class="title-header" *ngIf="titleHeaderText" [class.no-padding]="overrideHeaderPadding" [class.more-padding]="overridePadding" (click)="toggleWidget()">
    <div class="title-details" [class.with-icon]="rightIcon">
      <div *ngIf="leftSportIconId !== undefined" class="sport-icon icon-{{ leftSportIconId }} brand"></div>
      <div
        *ngIf="leftSvgIcon"
        class="svg-icon"
        [appDynamicSvgMask]="leftSvgIcon"
        [style.width]="leftSvgIconWidth"
        [style.height]="leftSvgIconHeight"
      ></div>
      <h1 *ngIf="titleHeaderText && !contentReference.childNodes.length" class="ellipsis">{{ titleHeaderText }}</h1>
      <div class="header-content-container" [class.has-content]="contentReference.childNodes.length" #contentReference>
        <ng-content select="[header]"></ng-content>
      </div>
    </div>
    <i
      class="fa {{ rightIcon }}"
      aria-hidden="true"
      *ngIf="rightIcon"
      [appDropdownArrowRotator]="!collapsed"
      [rotateDegrees]="rotateDegrees"
      [openAndCloseColors]="[variablesService.colorBrand]"
    ></i>
  </div>
  <div class="card-content" [class.add-overflow]="addOverflow" [@expandCollapse]="collapsed ? 'collapsed' : 'expanded'">
    <div class="content" *ngIf="!loading">
      <ng-content select="[body]"></ng-content>
    </div>
  </div>
  <app-loading-bar [show]="loading"></app-loading-bar>
  <div *ngIf="addHorizontalLine" class="horizontal-line"></div>
</div>
