<div class="groupings-container">
  <div class="header-row" *ngIf="{minGroupBet: this.instantCouponQuery.minGroupingBetStake$ | async} as values">
    <div class="header-column grouping" data-testid="coupon-groupings-header-grp" i18n>Group</div>
    <div class="header-column combinations" data-testid="coupon-groupings-header-com" i18n>Com</div>
    <div class="header-column stake" data-testid="coupon-groupings-header-stake">
      <span i18n>Stake</span>
      <span *ngIf="values.minGroupBet">{{ ' (Min ' + values.minGroupBet + ')'}}</span>
    </div>
    <div class="header-column winnings" data-testid="coupon-groupings-header-winnings" i18n>Winnings</div>
  </div>

  <!-- ng-container - set for loop here -->
  <ng-container *ngFor="let group of groups; let isLast = last; trackBy: indexTrackBy;">

    <div class="data-row" *ngIf="group.Combinations !== 0" data-testid="coupon-groupings-row"
      [hidden]="!(group | isGroupingVisible : isLast : instantCouponQuery.groupingsTabSelected)">
      <div class="data-column grouping" (click)="groupSelector($event, group)">
        <span class="grouping-container">
          <!-- one or the other with an if statement -->
          <input type="checkbox" *ngIf="(groups | hasGroupings) || !group.Selected" class="grouping-checkbox"
            data-testid="coupon-groupings-checkbox-has-grouping" [(ngModel)]="group.Selected"
            (ngModelChange)="updateGroupings([group])" />
          <input type="checkbox" *ngIf="!(groups | hasGroupings) && group.Selected" class="grouping-checkbox"
            data-testid="coupon-groupings-checkbox-has-not-grouping" [(ngModel)]="group.Selected" [disabled]="true" />
          <label></label>
        </span>
        <span class="group-name" data-testid="coupon-groupings-group-name">{{ group.Grouping | groupName }}</span>
      </div>

      <div class="data-column combinations">
        <span class="no-wrap" data-testid="coupon-groupings-group-combinations">{{ group.Combinations + 'x' }}</span>
      </div>

      <div class="data-column stake"
        *ngIf="{minGroupBet: this.instantCouponQuery.minGroupingBetStake$ | async} as values">
        <!-- one or the other with an if statement -->
        <input type="number" *ngIf="group.Stake !== -1" data-testid="coupon-groupings-group-stake"
          class="grouping-stake" [class.error]="group.Stake < values.minGroupBet"
          [attr.data-group]="'groupStakeValue_' + group.Grouping"
          [placeholder]="values.minGroupBet ? 'Min. ' + values.minGroupBet : ''"
          [value]="group.Stake > 0 ? group.Stake : ''" (input)="updateGroupStakeValue($event.target.value, group)"
          #groupingStake appSelectAll />
        <input type="number" *ngIf="group.Stake === -1" class="grouping-stake"
          data-testid="coupon-groupings-group-stake-disabled" [disabled]="group.Stake === -1 ? true : false"
          appSelectAll />
      </div>

      <div class="data-column winnings">
        <span data-testid="coupon-groupings-group-min-win">{{ group.MinWin | odd }}</span>
        <span data-testid="coupon-groupings-group-max-win">{{ group.MaxWin | odd }}</span>
      </div>
    </div>
  </ng-container>
</div>