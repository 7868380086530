/* eslint-disable no-underscore-dangle */
import { AfterViewInit, Component, ElementRef, HostListener, OnInit } from '@angular/core';

import { of, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

import { ApplicationService } from 'src/app/core/services/application.service';
import { DynamicScriptLoaderService } from 'src/app/core/services/dynamic-script-loader.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { expandCollapse } from 'src/app/shared/animations';

// eslint-disable-next-line no-var
declare var window: any;

@Component({
  selector: 'app-sfk-chat',
  templateUrl: './sfk-chat.component.html',
  styleUrls: ['./sfk-chat.component.scss'],
  animations: [expandCollapse()],
})
export class SfkChatComponent implements OnInit, AfterViewInit {
  destroySFK$ = new Subject<boolean>();
  windowInstance = window;
  private readonly showLoader$ = of(window._sfk && !window._sfk.loaded);

  constructor(
    readonly applicationQuery: ApplicationQuery,
    readonly applicationService: ApplicationService,
    readonly dynamicScriptLoaderService: DynamicScriptLoaderService,
    private readonly eRef: ElementRef,
    private readonly loadingService: LoadingService
  ) {}

  @HostListener('document:click', ['$event'])
  clickout(event: any): void {
    if (
      this.applicationQuery.showSFKChat &&
      !(
        this.eRef.nativeElement.contains(event.target) ||
        event.target.classList.contains('menu-item') ||
        event.target.parentElement.classList.contains('menu-item') ||
        event.target.classList.contains('quicklink') ||
        event.target.parentElement.classList.contains('quicklink') ||
        event.target.parentElement.parentElement.classList.contains('quicklink') ||
        event.target.parentElement.parentElement.parentElement.classList.contains('quicklink')
      )
    ) {
      this.applicationService.updateUI({ showSFKChat: false });
    }
  }

  ngOnInit(): void {
    this.showLoader$
      .pipe(
        distinctUntilChanged(),
        tap(showLoader => {
          if (showLoader) {
            this.loadingService.enqueueLoader();
            this.loadingService.updateFullscreen(false);
          } else {
            this.loadingService.dequeueLoader();
            this.loadingService.updateFullscreen(true);
          }
        }),
        takeUntil(this.destroySFK$)
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.applicationQuery.loadingSFKChat$.pipe(takeUntil(this.destroySFK$)).subscribe(s => {
      if (s) {
        this.dynamicScriptLoaderService.loadSFKLiveChat();
      }
    });
  }

  closeChat(): void {
    this.applicationService.updateUI({ showSFKChat: false });
    window._sfk.chatStarted = false;
  }
}
