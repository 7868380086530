import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { MySettledBetsState } from 'src/app/modules/my-bets/models/my-bets-state.model';
import { CouponStatus } from 'src/app/shared/models/coupon-details.model';
import { MySettledBetsStore } from './my-settled-bets.store';

@Injectable({ providedIn: 'root' })
export class MySettledBetsQuery extends QueryEntity<MySettledBetsState> {
  bets$ = this.selectAll();
  isLoading$ = this.selectLoading();

  constructor(protected store: MySettledBetsStore) {
    super(store);
  }

  get hasData(): boolean {
    return this.getCount() > 0;
  }

  get lostBetsLoaded(): boolean {
    return this.getValue().lostBetsLoaded;
  }

  get selectedCouponStatus(): CouponStatus {
    return this.getValue().ui.selectedCouponStatus;
  }
}
