import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByTermAndProperty',
})
export class FilterByTermAndPropertyPipe implements PipeTransform {
  // Filters an array based on the given term in the given property of the object
  // Array: the input variable
  // Term: the string what you are searching, for example: 'Juventus'
  // Property: what is the key in the object to use.
  // For example: array: [{sportName: 'Juventus}], term: 'Juventus', property: 'sportName' is a match.
  transform(array: any[], term: string, property: string): any {
    if (term === 'All' || !term || !array) {
      return array;
    }
    if (array[0][property]) {
      return array.filter(item => this.checkPresence(item, property, term));
    } else if (array[0][0][property]) {
      return array.filter(item => this.checkPresence(item[0], property, term));
    }
  }

  checkPresence(item: any, property: string, term: string) {
    if (typeof term === 'number') {
      return item[property] === term;
    }
    return item[property].toLowerCase().includes(term.toLowerCase());
  }
}
