import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Bonus } from 'clientside-coupon';
import { BehaviorSubject, Subject, fromEvent, combineLatest } from 'rxjs';
import { filter, takeUntil, tap, debounceTime, startWith, pairwise } from 'rxjs/operators';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';

@Component({
  selector: 'app-acca-bonus-progression-bar',
  templateUrl: './acca-bonus-progression-bar.component.html',
  styleUrls: ['./acca-bonus-progression-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccaBonusProgressionBarComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() showInfoIcon: boolean = true;
  @Input() noOfStepsToShow: number = 6;
  @Input() stepScrollingOffset: number = 2;
  @ViewChildren('accaBonusStep') accaBonusSteps!: QueryList<ElementRef>;

  accumulatorBonusPercentageIndex = 0;
  readonly showInfoModal$ = new BehaviorSubject(false);
  readonly hasAccumulatorBonus$ = new BehaviorSubject(false);
  readonly isVisible$ = new BehaviorSubject(false);
  readonly accumulatorBonusSelectionsTillBonusText$ = new BehaviorSubject('');
  readonly MIN_ACCA_ODD_VALUE_KEY = '[[Min_Acca_Odd_Value]]';
  private readonly minimumSelectionCount$ = new BehaviorSubject(0);
  private readonly stepsScrollingTrigger$ = new BehaviorSubject(false);
  private readonly selectionsTillBonusCount$ = new BehaviorSubject(0);
  private readonly destroy$ = new Subject<boolean>();

  constructor(readonly accumulatorBonusQuery: AccumulatorBonusQuery, private readonly applicationQuery: ApplicationQuery) {}

  ngOnInit(): void {
    combineLatest([this.applicationQuery.activeProduct$, this.applicationQuery.activeVirtualsLeague$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([activeProduct, activeVirtualsLeague]) => {
        if (activeProduct || activeVirtualsLeague) {
          this.init();
        }
      });
  }

  init() {
    combineLatest([this.accumulatorBonusQuery.cmsContent$, this.selectionsTillBonusCount$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([cmsContent, selectionsTillBonusCount]) => {
        if (cmsContent && selectionsTillBonusCount > 0) {
          this.accumulatorBonusSelectionsTillBonusText$.next(
            selectionsTillBonusCount === 1
              ? cmsContent.accumulatorBonusOneSelectionTillBonusText
              : cmsContent.accumulatorBonusSelectionsTillBonusText
                  .replace('[[Selection_Count]]', selectionsTillBonusCount.toString())
                  .toString()
          );
        } else {
          this.accumulatorBonusSelectionsTillBonusText$.next('');
        }
      });

    this.accumulatorBonusQuery.bonusList$
      .pipe(startWith(undefined as any), pairwise(), takeUntil(this.destroy$))
      .subscribe(([prevBonusList, bonusList]) => {
        this.reduceAccumulatorBonusList(bonusList);

        if (prevBonusList !== bonusList && bonusList.length) {
          this.minimumSelectionCount$.next(
            bonusList.reduce((prev, curr) => (prev.NumberOfEvents < curr.NumberOfEvents ? prev : curr)).NumberOfEvents
          );
        }

        if (bonusList && prevBonusList === undefined) {
          this.triggerStepScrolling();
        }
      });

    this.accumulatorBonusQuery.maxAccumulatorBonus$
      .pipe(startWith(undefined as any), pairwise(), takeUntil(this.destroy$))
      .subscribe(([prevMaxAccumulatorBonus, maxAccumulatorBonus]) => {
        if (prevMaxAccumulatorBonus !== maxAccumulatorBonus) {
          // Check Bonus state
          this.hasAccumulatorBonus$.next(maxAccumulatorBonus > 0);

          // Manage Step Scrolling
          this.triggerStepScrolling();
        }
      });

    combineLatest([this.accumulatorBonusQuery.accumulatorBonusSelectionCount$, this.minimumSelectionCount$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([bonusSelectionCount, minimumSelectionCount]) => {
        if (bonusSelectionCount) {
          // Calculate count till bonus progression start
          this.selectionsTillBonusCount$.next(minimumSelectionCount > 0 ? minimumSelectionCount - bonusSelectionCount : 0);
        }

        // Check component visibility
        this.isVisible$.next(this.handleIsVisible(bonusSelectionCount));
      });
  }

  ngAfterViewInit(): void {
    combineLatest([
      this.accumulatorBonusQuery.bonusList$,
      this.accumulatorBonusQuery.maxAccumulatorBonus$,
      this.accaBonusSteps.changes.pipe(startWith(undefined as any)),
      this.stepsScrollingTrigger$,
    ])
      .pipe(
        filter(() => this.accaBonusSteps?.length > 0),
        takeUntil(this.destroy$)
      )
      .subscribe(([bonusList, bonusPercentage, bonusSteps, stepsScrollingTrigger]) => {
        this.reduceAccumulatorBonusList(bonusList);
        this.handleStepScrolling(bonusList, bonusPercentage, this.accaBonusSteps);
      });

    fromEvent(window, 'resize')
      .pipe(
        debounceTime(500),
        tap(() => {
          this.triggerStepScrolling();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  openAccBonusInfoPopup(): void {
    this.showInfoModal$.next(true);
  }

  closeAccBonusInfoPopup(): void {
    this.showInfoModal$.next(false);
  }

  accumulatorBonusListTrackBy(bonus: Bonus): string {
    return `${bonus.NumberOfEvents}_${bonus.Percentage}`;
  }

  private reduceAccumulatorBonusList(bonusList: Bonus[]): void {
    if (this.accumulatorBonusQuery.lastPercentageItemShown) {
      this.accumulatorBonusPercentageIndex =
        bonusList?.findIndex(bonus => bonus.Percentage === this.accumulatorBonusQuery.lastPercentageItemShown) + 1;
    } else {
      this.accumulatorBonusPercentageIndex = bonusList?.length;
    }
  }

  private readonly handleIsVisible = (accumulatorBonusValidSelectionCount: number) =>
    this.hasAccumulatorBonus$.getValue() ||
    (!this.hasAccumulatorBonus$.getValue() &&
      accumulatorBonusValidSelectionCount > 1 &&
      this.accumulatorBonusSelectionsTillBonusText$.getValue() !== '');

  private handleStepScrolling(bonusList: Bonus[], bonusPercentage: number, steps: QueryList<ElementRef>): void {
    const scrollPosition = this.calculateScrollingPosition(bonusList, bonusPercentage, steps);

    steps.forEach(element => {
      element.nativeElement.style.right = scrollPosition;
    });
  }

  private calculateScrollingPosition(bonusList: Bonus[], bonusPercentage: number, steps: QueryList<ElementRef>): string {
    let scrollPosition = 0;
    // Get index to scroll to
    const scrollItemIndex = bonusList.findIndex(bonus => bonus.Percentage === bonusPercentage);

    // Get offset of current selected step to the middle of the screen
    if (scrollItemIndex > this.stepScrollingOffset && scrollItemIndex <= bonusList.length - this.stepScrollingOffset) {
      // // Get step element width
      // const stepWidth = steps.first.nativeElement.getBoundingClientRect().width;
      // // Get Current Position shift and parse to float
      // let currentPositionShift = steps.first.nativeElement.style.right.replace('px', '');
      // currentPositionShift = currentPositionShift === '' ? 0 : parseFloat(currentPositionShift);
      // // Get middle of window
      // const windowMidPosition = window.innerWidth / 2;
      // // Get offset of current item to window
      // scrollPosition = steps.get(scrollItemIndex).nativeElement.getBoundingClientRect().right - windowMidPosition - stepWidth / 2;
      // // Calculate scroll position
      // scrollPosition = scrollPosition + (currentPositionShift as number);

      // Reworked the above logic to have it work with varying bar sizes and step counts.
      // Keeping it commented for now but can be removed once fully tested by QAs.
      const stepWidth: number = steps.first.nativeElement.getBoundingClientRect().width;
      // Calculate the amount of px to shift items
      if (this.noOfStepsToShow % 2 === 0) {
        // even number of steps
        scrollPosition = stepWidth / 2;
        if (scrollItemIndex > this.stepScrollingOffset + 1) {
          scrollPosition += (scrollItemIndex - (this.stepScrollingOffset + 1)) * stepWidth;
        }
      } else {
        // odd number of steps
        scrollPosition = (scrollItemIndex - this.stepScrollingOffset) * stepWidth;
      }
    }

    // Set final scroll position
    return `${scrollPosition}px`;
  }

  private triggerStepScrolling(): void {
    this.stepsScrollingTrigger$.next(!this.stepsScrollingTrigger$.value);
  }
}
