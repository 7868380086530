import { NgModule } from '@angular/core';
import { LottieModule } from 'ngx-lottie';
import { MobileVerificationCampaignAlreadyVerifiedComponent } from 'src/app/modules/mobile-verification-campaign/components/already-verified/already-verified.component';
import { MobileNumberVerificationComponent } from 'src/app/modules/mobile-verification-campaign/components/mobile-number-verification/mobile-number-verification.component';
import { MobileVerificationCampaignSuccessComponent } from 'src/app/modules/mobile-verification-campaign/components/verify-success/verify-success.component';
import { MobileVerificationCampaignRoutingModule } from 'src/app/modules/mobile-verification-campaign/mobile-verification-campaign-routing.module';
import { MobileVerificationCampaignComponent } from 'src/app/modules/mobile-verification-campaign/mobile-verification-campaign.component';
import { SharedModule } from 'src/app/shared/shared.module';

const COMPONENTS = [
  MobileNumberVerificationComponent,
  MobileVerificationCampaignAlreadyVerifiedComponent,
  MobileVerificationCampaignComponent,
  MobileVerificationCampaignSuccessComponent,
];

@NgModule({
  imports: [SharedModule, MobileVerificationCampaignRoutingModule, LottieModule],
  declarations: [...COMPONENTS],
  exports: [MobileNumberVerificationComponent],
})
export class MobileVerificationCampaignModule {}
