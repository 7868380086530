<div class="wrapper"
  [class.block-scroll]="(applicationQuery.isSlideUpVisible$ | async) && (applicationQuery.showNavbar$ | async) === false"
  [class.navbar-padding]="applicationQuery.showNavbar$ | async"
  [class.navbar-betslip-selection-padding]="applicationQuery.isShowingNavbarBetslipSelections$ | async"
  [class.navbar-accumulator-bonus-progression-padding]="applicationQuery.isShowingNavbarBetslipAccumulatorBonusBar$ | async"
  [class.navbar-accumulator-bonus-unacceptable-selections-prompt-padding]="
    applicationQuery.isShowingAccumulatorBonusUnacceptableSelectionsPrompt$ | async
  " [class.is-virtuals-scheduled]="applicationQuery.isVirtualsScheduled$ | async"
  [class.has-virtuals-navbar]="virtualsQuery.useVirtualsNavbar$ | async">
  <router-outlet></router-outlet>
  <div *ngIf="seoFooterText && (applicationQuery.embedConfig$ | async)?.hideSEOFooter !== true && (applicationQuery.isVirtuals$ | async) === false"
    class="seo-footer-content" [innerHTML]="seoFooterText"></div>
  <div *ngIf="seoFooterText && (applicationQuery.embedConfig$ | async)?.hideSEOFooter !== true && (applicationQuery.isVirtuals$ | async) === true"
       [class.virtuals]="applicationQuery.isVirtuals$ | async"
       class="seo-footer-content virtuals">
    <div class="centered-content" [innerHTML]="seoFooterText"></div>
  </div>
</div>
<app-toast-notification></app-toast-notification>

<app-nav-bar
  [ngClass]="[(accountQuery.userData$ | async) && (accountQuery.userData$ | async).userStatus === 'PCHANG' ? 'disable-container' : '']"
  *ngIf="(applicationQuery.showNavbar$ | async) && (virtualsQuery.useVirtualsNavbar$ | async) === false">
</app-nav-bar>

<!-- If you need to add additional slide up elements, all you need is to do add the additional ui state property in the ApplicationStore -->
<!-- And create the additional open function in the ApplicationService file following the pattern -->
<ng-container *ngIf="{
    showMenu: applicationQuery.showMenu$ | async,
    showLogin: applicationQuery.showLogin$ | async,
    showCoupon: applicationQuery.showCoupon$ | async,
    showQuickCoupon: applicationQuery.showQuickCoupon$ | async,
    showMyBets: applicationQuery.showMyBets$ | async,
    activeUrl: applicationQuery.activeUrl$ | async,
    loginState: applicationQuery.loginState$ | async
  } as subscriptions">
  <app-slide-up title="Menu" type="showMenu" [@slideInToggle]="subscriptions.showMenu" *ngIf="subscriptions.showMenu">
    <app-menu></app-menu>
  </app-slide-up>

  <app-slide-up title="Betslip" type="showCoupon" [@slideInToggle]="subscriptions.showCoupon"
    *ngIf="subscriptions.showCoupon">
    <app-coupon></app-coupon>
  </app-slide-up>

  <div class="mini-slide-up-overlay" *ngIf="subscriptions.showQuickCoupon">
    <div class="overlay-content" #overlayContent></div>
  </div>
  <app-mini-slide-up type="showQuickCoupon" [@slideUp]="subscriptions.showQuickCoupon"
    *ngIf="subscriptions.showQuickCoupon"
    [class.navbar-padding]="(applicationQuery.showNavbar$ | async) && (applicationQuery.isVirtualsLeague$ | async) === false"
    [class.navbar-betslip-selection-padding]="applicationQuery.isShowingNavbarBetslipSelections$ | async"
    [class.navbar-accumulator-bonus-progression-padding]="applicationQuery.isShowingNavbarBetslipAccumulatorBonusBar$ | async"
    [class.navbar-accumulator-bonus-unacceptable-selections-prompt-padding]="
      applicationQuery.isShowingAccumulatorBonusUnacceptableSelectionsPrompt$ | async
    ">
    <app-quick-coupon></app-quick-coupon>
  </app-mini-slide-up>

  <app-slide-up title="My Bets" type="showMyBets" [@slideInToggle]="subscriptions.showMyBets"
    *ngIf="subscriptions.showMyBets">
    <my-bets [hideBreadcrumb]="true"></my-bets>
  </app-slide-up>

  <app-proceed-bars-container
    *ngIf="subscriptions.activeUrl && subscriptions.activeUrl[0] === 'sports' && subscriptions.activeUrl[1] !== 'match'"
    [class.navbar-padding]="(applicationQuery.showNavbar$ | async) && (applicationQuery.isVirtualsLeague$ | async) === false"
    [class.navbar-betslip-selection-padding]="applicationQuery.isShowingNavbarBetslipSelections$ | async"
    [class.navbar-accumulator-bonus-progression-padding]="applicationQuery.isShowingNavbarBetslipAccumulatorBonusBar$ | async"
    [class.navbar-accumulator-bonus-unacceptable-selections-prompt-padding]="
      applicationQuery.isShowingAccumulatorBonusUnacceptableSelectionsPrompt$ | async
    ">
  </app-proceed-bars-container>
</ng-container>

<app-insufficient-balance></app-insufficient-balance>
<app-coupon-evaluation></app-coupon-evaluation>
<app-phone-verification-popup></app-phone-verification-popup>
<app-login-dialog [show]="showLoginDialog$ | async" (closed)="loginDialogClosed()"></app-login-dialog>
<x-sell-casino-swiper *ngIf="applicationQuery.isCasinoXSellEnabled"></x-sell-casino-swiper>
<app-phone-unverified-tooltip></app-phone-unverified-tooltip>
