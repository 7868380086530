<div class="phone-verification-wrapper">
  <div class="header">
    <div class="header-top" *ngIf="mobileNumber">
      <span i18n>You should have received a code via SMS to</span>
      <span>{{ mobileNumber }}</span>
    </div>
    <div class="header-top" *ngIf="!mobileNumber">
      <span i18n>You should have received a code via SMS to the number registered with this username.</span>
    </div>
    <div class="header-bottom">
      <span i18n>Input the number you received in the space below.</span>
    </div>
  </div>

  <div class="content" [ngClass]="{'invalid': (invalidCode$ | async) || (limitExceeded$ | async)}">
    <app-otp-input-field [limitExceeded$]="limitExceeded$" [invalidCode$]="invalidCode$"
      [verifyingCode$]="verifyingCode$" [otpCodeLength]="otpCodeLength" (verify)="verifyCode($event)"
      (resend)="resendCode()">
    </app-otp-input-field>
    <div class="content-bottom" *ngIf="showDNDNotice">
      <span *ngIf="!applicationQuery.isBrand(['Ethiopia'])">
        <span i18n>Did not receive your pass code? Text “ALLOW” to </span>
        <b>2442</b>
        <span i18n> to disable DND on your line. You may also text “12” to </span>
        <b>2442</b>
        <span i18n> to activate partial DND on your line.</span>
      </span>
    </div>
  </div>
  <div class="buttons" *ngIf="resendCodeVerification">
    <button class="btn cancel" (click)="onCancelClick()">
      <span class="btn-text" i18n>Cancel</span>
    </button>
  </div>
</div>
