import { OddModel } from 'src/app/shared/models/coupon.model';

export class LiveState {
  liveFeeds: LiveFeedModel[];
  selectedFeedId: number;
  selectedMarkets: MarketModel[];
  selectedAreaInMatchView: AreaModel;
  selectedLiveEvent: LiveEventByArea;
  liveViewEnabledByUser: boolean;
  favouritesEventsList: FavouritesEventListModel[];
  marketGroupingDetails: MarketGroupingDetailsModel[];
  liveMatchStatuses: LiveMatchStatuses;
  oldLiveMatchStatuses: OldLiveMatchStatuses;
  oldLiveMatchStatusesAbbr: OldLiveMatchStatuses;
  liveAreaSelectionIds: any;
  ui: LiveUIState;
  liveMatchTrackerProvider: LiveMatchTrackerProvider;
  betGeniusLMT: string;
}

export enum LiveMatchTrackerProvider {
  BetGenius = 1,
  BetRadar = 2,
  VSL = 5,
  CPP = 7, // Content Player Pro
}

export class LiveUIState {
  noLiveFeedData: boolean;
  notValidEventId: boolean;
  liveFeedsLoading: boolean;
  selectedLiveEventLoading: boolean;

  constructor(init: Partial<LiveUIState>) {
    Object.assign(this, init);
  }
}

export class MarketGroupingDetailsModel {
  sportID: number;
  marketsCount: number;

  constructor(init: Partial<MarketGroupingDetailsModel>) {
    Object.assign(this, init);
  }
}

export class FavouritesEventListModel {
  sportId: number;
  eventId: number;

  constructor(init: Partial<FavouritesEventListModel>) {
    Object.assign(this, init);
  }
}

export class SelectionModel {
  id: number;
  name: string;
  typeId: number;
  odds: OddModel[];

  constructor(init: Partial<SelectionModel>) {
    Object.assign(this, init);
  }
}

export class MarketModel {
  id: number;
  name: string;
  marketNameRaw?: any;
  typeId: number;
  typeIdGroup?: any;
  status: number;
  selections: SelectionModel[];
  specialValue: string;
  scheduleStatus: number;
  combinability: number;
  groupNo: number;
  compatibleMarkets?: any;
  sortOrder?: number;
  sportId?: number;

  constructor(init: Partial<MarketModel>) {
    Object.assign(this, init);
  }
}

export class TeamModel {
  id: number;
  name: string;
  itemOrder: number;

  constructor(init: Partial<TeamModel>) {
    Object.assign(this, init);
  }
}

export class EventModel {
  id: number;
  name: string;
  status: number;
  score: string;
  homeGameScore: number;
  awayGameScore: number;
  markets: MarketModel[];
  groupedMarkets: GroupedMarketsModel[];
  date: string;
  teams: TeamModel[];
  smartCode: number;
  serviceOwner: number;
  setScores?: any;
  matchTime: number;
  eventStatus: { Id: number; Description: string }; // new Match Status
  matchStatus: number; // Old Match Status for backwards compatibility
  popularity: number;
  sportId: number;
  isStreamed: boolean;
  aamsMatchID?: any;
  aamsTemplateID?: any;
  selectionCount: number;
  tournamentName: any;
  providerSourceID: number;
  hasWidget: boolean;
  categoryId: number;
  categoryName: any;
  incompatibleEvents?: any;

  constructor(init: Partial<EventModel>) {
    Object.assign(this, init);
  }
}

export class TournamentModel {
  id: number;
  name: string;
  events: EventModel[];
  noOfOdds: number;

  constructor(init: Partial<TournamentModel>) {
    Object.assign(this, init);
  }
}

export class LiveFeedModel {
  id: number;
  name: string;
  eventCount: number;
  tournaments: TournamentModel[];
  currentArea?: any;
  areas?: any;
  noOfOdds: number;
  markets?: MarketModel[];

  constructor(init: Partial<LiveFeedModel>) {
    Object.assign(this, init);
  }
}

export class LiveEventByArea {
  areas: AreaModel[];
  currentArea: AreaModel;
  eventCount: number;
  id: number;
  name: string;
  noOfOdds: number;
  tournaments: TournamentModel[];

  constructor(init: Partial<LiveEventByArea>) {
    Object.assign(this, init);
  }
}

export class AreaModel {
  id: number;
  name: string;
  order: number;

  constructor(init: Partial<AreaModel>) {
    Object.assign(this, init);
  }
}

export interface GroupedMarketsModel {
  title: string;
  markets: MarketModel[];
  /**
   * This is the index of the first market in the group as it was in the API response.
   * Used to keep position of the grouped market in the match view.
   */
  sortOrder?: number;
  /**
   * This is the grouping type first market in the group as it was in the API response.
   * Used to identify what type of group it is when displaying the group.
   */
  groupNo: number;
}
export interface LiveMatchStatuses {
  [key: number]: {
    statusId: number;
    statusLabel: string;
  }[];
}

export interface OldLiveMatchStatuses {
  [key: number]: string;
}
