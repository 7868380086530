<div class="selection-container">
  <ng-container *ngFor="let selection of instantCouponQuery.selections$ | async; trackBy: selectionTrackBy">
    <ng-container *ngFor="let category of selection.categories; trackBy: categoryTrackBy">
      <div class="selection-content"
        *ngFor="let tournament of category.tournaments; trackBy: tournamentTrackBy">
        <div class="tournament-name" data-testid="coupon-selections-tournament">
          <span class="virtual-league-icon icon-generic icon-{{category.name}}"
                data-testid="coupon-selections-tournament-icon">
          </span>
          <span i18n class="name" data-testid="coupon-selections-tournament-name">
            Kings {{ category.name }} - {{ tournament.name}}
          </span>
        </div>
        <div class="event-content" data-testid="coupon-selections-event" *ngFor="let match of tournament.matches trackBy: matchTrackBy">
          <div class="event-container">
            <div class="event-details-container" [class.one-market]="match.odds.length === 1">
              <div class="event-details">
                <div class="match-name" data-testid="coupon-selections-events-match-name">{{ match.name }}</div>
              </div>
            </div>
            <div class="market-odds-container">
              <app-virtuals-instant-coupon-selection-item
                [matchName]="match.name"
                *ngFor="let marketOdds of match.odds | groupOddsByMarket; trackBy: oddsByMarketTrackBy"
                [marketSelections]="marketOdds" [smartBetCode]="match.smartBetCode"
                [matchOddsLength]="match.odds.length">
              </app-virtuals-instant-coupon-selection-item>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
