<div class="event-header-details">
  <div class="header-details">
    <div *ngIf="sportId && showSportIcon" class="product-icon sport-icon icon-{{ sportId }}" [ngClass]="productIconClass"></div>
    <div class="event-status">
      <span class="material-icons">{{ 'schedule' }}</span>
      <div class="event-period" [class.no-timer]="!event.matchTime">
        {{ liveQuery.getLiveMatchStatusLabel(event) }}
      </div>
      <div class="event-time" *ngIf="event.matchTime">
        <span [@fadeIn]>{{ event.matchTime }}</span>
        <span class="blink">'</span>
      </div>
    </div>
    <div class="location" [routerLink]="['/live', event.id]" *ngIf="showTournamentName">
      <div class="category-tournament">
        <span>{{ event.categoryName | titleCase }}</span> - <span>{{ event.tournamentName | titleCase }}</span>
      </div>
    </div>
  </div>
</div>
