import { Pipe, PipeTransform } from '@angular/core';

type TeamOptions = 'home' | 'away';

@Pipe({ name: 'displayScore' })
export class DisplayScorePipe implements PipeTransform {
  transform(score: string, team: TeamOptions): string {
    const scores = score.split(':');

    return (
      {
        home: scores[0],
        away: scores[1],
      }[team] || '-'
    );
  }
}
