import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingBarComponent implements OnChanges {
  @Input() show: boolean = false;
  @Input() delay = 0;
  @Input() transparent = false;

  readonly show$ = new BehaviorSubject(false);

  private showTimeout: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.show) {
      this.propagateShowChange(changes.show.currentValue);
    }
  }

  private propagateShowChange(showLoadingBar: boolean): void {
    const clearLastTimeout = () => {
      if (this.showTimeout) {
        clearTimeout(this.showTimeout);
      }
    };

    if (!this.delay || !showLoadingBar) {
      this.show$.next(showLoadingBar);
      clearLastTimeout();
    } else {
      clearLastTimeout();

      this.showTimeout = setTimeout(() => {
        this.show$.next(showLoadingBar);
      }, this.delay);
    }
  }
}
