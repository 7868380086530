import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { QuickBetModel, QuickBetState } from 'src/app/shared/models/quick-bet.model';

function createInitialState(): QuickBetState {
  return {
    quickBetData: undefined,
    onlineCodesData: undefined,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'quick-bet' })
export class QuickBetStore extends Store<QuickBetState> {
  constructor() {
    super(createInitialState());
  }

  updateQuickBetData(quickBetData: QuickBetModel): void {
    if (!quickBetData) {
      this.clearQuickBetData();
      return;
    }

    this.update({
      quickBetData,
    });
  }

  clearQuickBetData(): void {
    this.update({
      quickBetData: undefined,
    });
  }

  updateOnlineCodesData(onlineCodesData: number[]): void {
    if (!onlineCodesData) {
      this.clearOnlineCodesData();
      return;
    }

    this.update({
      onlineCodesData,
    });
  }

  clearOnlineCodesData(): void {
    this.update({
      onlineCodesData: undefined,
    });
  }
}
