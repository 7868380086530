import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { XSellState } from 'src/app/modules/x-sell/models/x-sell-state.model';
import { XSellStore } from 'src/app/modules/x-sell/state/x-sell.store';

@Injectable({ providedIn: 'root' })
export class CasinoXSellQuery extends Query<XSellState> {
  // Initialisation
  isInitialised$ = this.select(state => state.casinoXSellInitialised);

  // Checks
  hasContentURL$ = this.select(state => this.checkStringValue(state.casinoContent.contentURL));

  // Access
  enabled$ = this.select(state => state.casinoAccessConfiguration.enabled);
  showWhenLoggedIn$ = this.select(state => state.casinoAccessConfiguration.showWhenLoggedIn);
  showWhenLoggedOut$ = this.select(state => state.casinoAccessConfiguration.showWhenLoggedOut);
  whitelist$ = this.select(state => state.casinoAccessConfiguration.whitelist);
  startingScreenPosition$ = this.select(state => state.casinoAccessConfiguration.screenPosition);

  // Content
  contentURL$ = this.select(state => state.casinoContent.contentURL);
  spbColor$ = this.select(state => state.casinoContent.spbColor);
  spbIcon$ = this.select(state => state.casinoContent.spbIcon);
  xSellColor$ = this.select(state => state.casinoContent.xSellColor);
  xSellIcon$ = this.select(state => state.casinoContent.xSellIcon);

  constructor(
    protected xSellStore: XSellStore,
    private readonly applicationQuery: ApplicationQuery,
    private readonly accountQuery: AccountQuery
  ) {
    super(xSellStore);
  }

  get isInitialised(): boolean {
    return this.getValue().casinoXSellInitialised;
  }

  get isWhitelistedToShow$(): Observable<boolean> {
    return combineLatest([this.applicationQuery.activeUrl$, this.select(state => state.casinoAccessConfiguration.whitelist)]).pipe(
      map(([wholeUrl, whiteList]) => {
        if (wholeUrl) {
          const url = wholeUrl[0].split('?', 1)[0];
          return !!whiteList?.find(route => route === url);
        } else {
          return false;
        }
      })
    );
  }

  get showInRelationToAuthCheck$(): Observable<boolean> {
    return combineLatest([this.accountQuery.isAuthenticated$, this.showWhenLoggedIn$, this.showWhenLoggedOut$]).pipe(
      map(([isAuthenticated, showWhenLoggedIn, showWhenLoggedOut]) => (isAuthenticated ? showWhenLoggedIn : showWhenLoggedOut))
    );
  }

  get contentProduct(): string {
    return this.isInitialised ? this.getValue().casinoContent.contentProduct : '';
  }

  private readonly checkStringValue = (value: string): boolean => !!value && value.trim().length > 0;
}
