export enum IdType {
  Competition,
  Prematch,
  Category,
  Live,
  Market,
  Selection,
}

export const convertToKMId = (id: number, type: IdType): number => {
  switch (type) {
    case IdType.Category:
      return id - 10000000;
    case IdType.Competition:
      return id - 20000000;
    case IdType.Live:
      return id - 30000000;
    case IdType.Prematch:
      return id - 1000000000;
    case IdType.Market:
      return id - 240000000;
    case IdType.Selection:
      return id - 820000000;
    default:
      return id;
  }
};

export const convertToRaptorId = (id: number, type: IdType): number => {
  switch (type) {
    case IdType.Category:
      return id + 10000000;
    case IdType.Competition:
      return id + 20000000;
    case IdType.Live:
      return id + 30000000;
    case IdType.Prematch:
      return id + 1000000000;
    case IdType.Market:
      return id + 240000000;
    case IdType.Selection:
      return id + 820000000;
    default:
      return id;
  }
};
