import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appVirtualsLoader]',
})
export class VirtualsLoaderDirective implements AfterViewInit {
  @Input() appVirtualsLoader: any;

  constructor(public el: ElementRef, readonly renderer: Renderer2) {}

  createIframeElement(): void {
    const iFrameUrl = this.appVirtualsLoader.FullPath || this.appVirtualsLoader.Result;
    const iframe = this.renderer.createElement('iframe');
    this.renderer.addClass(iframe, 'iframe-virtuals');
    this.renderer.setAttribute(iframe, 'src', iFrameUrl);
    this.renderer.setAttribute(iframe, 'width', '100%');
    this.renderer.setAttribute(iframe, 'height', '100vh');
    this.renderer.setAttribute(iframe, 'frameborder', '0');
    this.renderer.appendChild(this.el.nativeElement, iframe);
  }

  ngAfterViewInit(): void {
    const childArray = Array.from(this.el.nativeElement.children);
    if (!this.el.nativeElement.children.length) {
      this.createIframeElement();
    } else {
      const dynamicChildren = childArray.filter((child: any) => child.classList.contains('iframe-virtuals'));
      if (dynamicChildren.length) {
        dynamicChildren.forEach(child => {
          this.renderer.removeChild(this.el.nativeElement, child);
        });
        this.createIframeElement();
      }
    }
  }
}
