<div class="odds" *ngIf="event && event.markets" >
  <div class="top-spread" *ngIf="event.markets[0].specialValue && event.markets[0].specialValue !== ''">
    <div class="spread-value" [ngClass]="{'by-two': event.markets[0].selections.length === 2, 'by-three': event.markets[0].selections.length === 3}" >
      {{event.markets[0].specialValue}}
    </div>
  </div>
  <ng-container
    *ngFor="let selection of event.markets[0].selections; let first = first; let last = last; trackBy: selectionTrackBy">
    <ng-container *ngFor="let odd of selection.odds; trackBy: oddTrackBy">
      <app-live-odd [ngClass]="{'first': first, 'last': last}" [odd]="odd" [eventLocation]="eventLocation"></app-live-odd>
    </ng-container>
  </ng-container>
</div>
