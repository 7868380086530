import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { MyOpenBetsState } from 'src/app/modules/my-bets/models/my-bets-state.model';
import { MyOpenBetsStore } from './my-open-bets.store';

@Injectable({ providedIn: 'root' })
export class MyOpenBetsQuery extends QueryEntity<MyOpenBetsState> {
  bets$ = this.selectAll();
  expandedLiveBetsCount$ = this.selectAll().pipe(
    map(bets => {
      const openLiveBets = bets.filter(bet => !bet.collapsed && bet.betDetails && bet.betDetails.events.some(event => event.isLive));
      return openLiveBets ? openLiveBets.length : 0;
    })
  );
  expandedLiveBetsDetails$ = this.select(state => state.liveBetsDetails);
  isLoading$ = this.selectLoading();
  openBetsCount$ = this.select(state => state.betCount);

  constructor(protected store: MyOpenBetsStore) {
    super(store);
  }
}
