import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoAuthGuard } from 'src/app/core/guards/no-auth-guard.service';
import { QuickCouponComponent } from './quick-coupon.component';

const routes: Routes = [
  {
    path: '',
    component: QuickCouponComponent,
    canActivate: [NoAuthGuard],
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuickCouponRoutingModule {}
