import { NgModule } from '@angular/core';
import { MenuHowToPlayComponent } from 'src/app/modules/menu/components/menu-how-to-play/menu-how-to-play.component';
import { MenuPageWrapperComponent } from 'src/app/modules/menu/components/menu-page-wrapper/menu-page-wrapper.component';
import { MenuPromosComponent } from 'src/app/modules/menu/components/menu-promos/menu-promos.component';
import { MenuQuicklinksComponent } from 'src/app/modules/menu/components/menu-quicklinks/menu-quicklinks.component';
import { MenuSportsComponent } from 'src/app/modules/menu/components/menu-sports/menu-sports.component';
import { MenuTabsComponent } from 'src/app/modules/menu/components/menu-tabs/menu-tabs.component';
import { MenuWrapperComponent } from 'src/app/modules/menu/components/menu-wrapper/menu-wrapper.component';
import { MenuRoutingModule } from 'src/app/modules/menu/menu-routing.module';
import { VirtualsWidgetsModule } from 'src/app/modules/virtuals-widgets/virtuals-widgets.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    MenuHowToPlayComponent,
    MenuPageWrapperComponent,
    MenuPromosComponent,
    MenuQuicklinksComponent,
    MenuSportsComponent,
    MenuTabsComponent,
    MenuWrapperComponent,
  ],
  imports: [SharedModule, MenuRoutingModule, VirtualsWidgetsModule],
  exports: [
    MenuHowToPlayComponent,
    MenuPageWrapperComponent,
    MenuPromosComponent,
    MenuQuicklinksComponent,
    MenuSportsComponent,
    MenuTabsComponent,
    MenuWrapperComponent,
  ],
})
export class MenuModule {}
