import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { CookieService } from 'src/app/core/services/cookie.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { GeoAccessControlQuery } from 'src/app/core/state/geo-access-control/geo-access-control.query';
import { GeoAccessControlStore } from 'src/app/core/state/geo-access-control/geo-access-control.store';
import { APIType } from 'src/app/shared/models/api.model';
import { GeoAccessControlConfig, GeoAccessModel } from 'src/app/shared/models/geo-access-control.model';

@Injectable({
  providedIn: 'root',
})
export class GeoAccessControlService {
  accessBlockingEnabled: boolean;
  tldRedirectionEnabled: boolean;
  siteCountryCode: string;

  readonly csCookieName: string = 'countrySelection';
  readonly fromCSPageQS: string = 'fromcs';
  readonly clearCookieQS: string = 'cc';
  readonly disableRedirectQS: string = 'dr';

  constructor(
    private readonly apiService: APIService,
    private readonly appConfig: AppConfigService,
    private readonly cookieService: CookieService,
    private readonly geoAccessControlQuery: GeoAccessControlQuery,
    private readonly geoAccessControlStore: GeoAccessControlStore,
    private readonly router: Router,
    private readonly loggingService: LoggerService
  ) {
    const config: GeoAccessControlConfig = this.appConfig.get('geoAccessControl');
    this.accessBlockingEnabled = config ? config.accessBlockingEnabled : false;
    this.tldRedirectionEnabled = config ? config.tldRedirectionEnabled : false;
    this.siteCountryCode = config ? config.siteCountryCode : '';
  }

  initialize(): void {
    // check if the current request originated from a country selection page
    const urlParams = new URLSearchParams(window.location.search);
    const fromCSPage = urlParams.has(this.fromCSPageQS) ? urlParams.get(this.fromCSPageQS) === 'true' : false;
    if (fromCSPage && this.siteCountryCode) {
      // set the country selection cookie to the country code of the current site
      this.cookieService.setCookie(this.csCookieName, this.siteCountryCode);
    }

    if (!this.accessBlockingEnabled && !this.tldRedirectionEnabled) {
      return;
    }

    try {
      this.geoAccessControlStore.setLoading(true);

      const siteVersion = this.appConfig.get('siteVersion');
      this.apiService.get(APIType.Website, `api/Location/BlockedActions?sv=${siteVersion}`).subscribe(
        data => {
          if (!data) {
            this.geoAccessControlStore.setLoading(false);
            return;
          }

          const accessList = this.parseAccessList(data.GeoAccessResultList);
          const userCountryCode = data.CountryCode;

          this.geoAccessControlStore.updateAccessList(accessList);
          this.geoAccessControlStore.updateUserCountryCode(userCountryCode);

          if (this.accessBlockingEnabled && !this.hasSiteAccess()) {
            this.router.navigate(['/', 'site-unavailable']);
            return;
          }

          this.redirectToCountrySelection();

          this.geoAccessControlStore.setLoading(false);
        },
        error => {
          this.geoAccessControlStore.setLoading(false);
          this.loggingService.logEvent('GAC Network Error', error.message, SeverityLevel.Error);
        }
      );
    } catch (error) {
      this.geoAccessControlStore.setLoading(false);
      this.loggingService.logEvent('GAC Error', error.message, SeverityLevel.Error);
    }
  }

  getCountrySelectionDetails(): Observable<void> {
    this.geoAccessControlStore.setLoading(true);

    return this.apiService.get(APIType.CMS, 'CountrySelection/GetPageDetails').pipe(
      map(data => {
        if (data) {
          this.geoAccessControlStore.updateCountrySelectionDetails(data);
        }
        this.geoAccessControlStore.setLoading(false);
      })
    );
  }

  getUserCountry(): Observable<void> {
    this.geoAccessControlStore.setLoading(true);

    return this.apiService.get(APIType.Platform, 'api/Location/Countries/CountryOfUser').pipe(
      map(data => {
        if (data && data.Result) {
          this.geoAccessControlStore.updateUserCountryCode(data.Result.TwoLetterCode);
          this.geoAccessControlStore.updateUserCountryName(data.Result.Name);
        }
        this.geoAccessControlStore.setLoading(false);
      })
    );
  }

  setCookieAndRedirect(countryCode: string, siteUrl: string): void {
    if (!countryCode || !siteUrl) {
      return;
    }

    // the fromcs querystring will be used to stop the target site from redirecting to
    // the country selection page
    let queryString: string = window.location.search;
    if (queryString.length === 0) {
      queryString = `?${this.fromCSPageQS}=true`;
    } else {
      queryString += `&${this.fromCSPageQS}=true`;
    }

    this.cookieService.setCookie(this.csCookieName, countryCode, 365);
    window.location.href = siteUrl + queryString;
  }

  private parseAccessList(data: any[]): GeoAccessModel[] {
    const accessList: GeoAccessModel[] = [];

    if (data && data.length) {
      data.forEach(e => accessList.push(new GeoAccessModel({ actionName: e.ActionName, isAllowed: e.IsAllowed })));
    }

    return accessList;
  }

  private hasSiteAccess(): boolean {
    const siteAccessElement: GeoAccessModel = this.geoAccessControlQuery.accessList.find(am => am.actionName === 'SiteAccess');
    if (siteAccessElement && !siteAccessElement.isAllowed) {
      return false;
    }
    return true;
  }

  private redirectToCountrySelection(): void {
    if (!this.tldRedirectionEnabled || !this.siteCountryCode) {
      // the country selection feature is disabled
      return;
    }

    const csRegex = new RegExp('^/country-selection/?$');
    if (csRegex.test(window.location.pathname)) {
      // user is already on the country selection page
      return;
    }

    if (
      this.geoAccessControlQuery.userCountryCode &&
      this.siteCountryCode.toUpperCase() === this.geoAccessControlQuery.userCountryCode.toUpperCase()
    ) {
      // user is accessing the current BetKing site from its intended country
      return;
    }

    const savedCountryCode = this.cookieService.getCookie(this.csCookieName);
    if (savedCountryCode && this.siteCountryCode.toUpperCase() === savedCountryCode.toUpperCase()) {
      // the user is accessing the BetKing site that's saved in their country selection cookie.
      return;
    }

    // get the country selection details to check if the user's country has a dedicated BetKing site
    this.getCountrySelectionDetails().subscribe(() => {
      if (savedCountryCode) {
        const savedCountryLocaleData = this.geoAccessControlQuery.countrySelectionDetails.countryList.find(
          c => c.countryCode === savedCountryCode
        );
        if (savedCountryLocaleData && savedCountryLocaleData.siteUrl) {
          // redirect to the site that's saved in the country selection cookie
          this.setCookieAndRedirect(savedCountryCode, savedCountryLocaleData.siteUrl);
          return;
        }
      }

      const userLocaleData = (this.geoAccessControlQuery.countrySelectionDetails?.countryList || []).find(
        c => c.countryCode === this.geoAccessControlQuery.userCountryCode
      );
      if (userLocaleData) {
        // redirect to the country selection page if the country selection details contain
        // an entry for the user's country
        this.router.navigateByUrl(`/country-selection${window.location.search}`);
      }
    });
  }
}
