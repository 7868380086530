import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Bonus } from 'clientside-coupon';
import { combineLatest, Observable, BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { VirtualsCouponQuery } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.query';
import { AccumulatorBonusState } from 'src/app/modules/accounts/modules/auth/models/bonus.model';
import { InstantCouponQuery } from 'src/app/core/state/instant-coupon/instant-coupon.query';
import { AccumulatorBonusStore } from './accumulator-bonus.store';

@Injectable({
  providedIn: 'root',
})
export class AccumulatorBonusQuery extends Query<AccumulatorBonusState> {
  couponDataQuery = this.applicationQuery.isVirtualsScheduled
    ? this.virtualsCouponQuery
    : this.applicationQuery.isVirtualsInstant
    ? this.instantCouponQuery
    : this.couponQuery;
  private readonly accumulatorBonusConfig = new BehaviorSubject(undefined);

  cmsContent$ = this.select(state => state.cmsContent);
  showModal$ = this.select(state => state.showInfoModal);
  bonusList$ = this.select(state => state.bonusList);
  isSportsBonusListInit$ = this.select(state => state.isSportsBonusListInit);

  constructor(
    protected store: AccumulatorBonusStore,
    private readonly appConfigService: AppConfigService,
    private readonly applicationQuery: ApplicationQuery,
    private readonly couponQuery: CouponQuery,
    private readonly virtualsCouponQuery: VirtualsCouponQuery,
    private readonly instantCouponQuery: InstantCouponQuery
  ) {
    super(store);

    combineLatest([this.applicationQuery.isVirtualsInstant$, this.applicationQuery.isVirtualsScheduled$]).subscribe(
      ([isInstant, isScheduled]) => {
        this.couponDataQuery = isInstant ? this.instantCouponQuery : isScheduled ? this.virtualsCouponQuery : this.couponQuery;
        this.accumulatorBonusConfig.next(
          isScheduled
            ? this.appConfigService.get('virtuals').scheduledLeague.accumulatorBonus
            : isInstant
            ? this.appConfigService.get('virtuals').instantLeague.accumulatorBonus
            : this.appConfigService.get('sports').accumulatorBonus
        );
      }
    );
  }

  get isAccumulatorBonusProgressionBarEnabled(): boolean {
    return this.accumulatorBonusConfig.getValue().showProgressionBar;
  }

  get isActiveUrlInAccumulatorBonusWhiteList$(): Observable<boolean> {
    return combineLatest([this.applicationQuery.activeUrl$, this.applicationQuery.isVirtuals$]).pipe(
      map(([url, isVirtuals]) =>
        url
          ? this.accumulatorBonusConfig.getValue().whiteList?.find(route =>
              isVirtuals
                ? route === url[1] // Must cater for the '/virtual' Sub League in path
                : route === url[0]
            ) !== undefined
          : false
      )
    );
  }

  get accumulatorBonusMaxSelections(): number {
    return this.bonusList ? Math.max(...this.bonusList.map(bonus => bonus.NumberOfEvents)) : 0;
  }

  get hasAccumulatorBonus$(): Observable<boolean> {
    return this.couponDataQuery.couponData$.pipe(
      map(couponData => (couponData ? couponData.MinPercentageBonus + couponData.MaxPercentageBonus > 0 : false))
    );
  }

  get hasAccumulatorBonus(): boolean {
    return this.couponDataQuery.couponData
      ? this.couponDataQuery.couponData.MinPercentageBonus + this.couponDataQuery.couponData.MaxPercentageBonus > 0
      : false;
  }

  get minAccumulatorBonus$(): Observable<number> {
    return this.couponDataQuery.couponData$.pipe(map(couponData => (couponData ? Math.round(couponData.MinPercentageBonus * 100) : 0)));
  }

  get minAccumulatorBonus(): number {
    return this.couponDataQuery.couponData ? Math.round(this.couponDataQuery.couponData.MinPercentageBonus * 100) : 0;
  }

  get maxAccumulatorBonus$(): Observable<number> {
    return this.couponDataQuery.couponData$.pipe(map(couponData => (couponData ? Math.round(couponData.MaxPercentageBonus * 100) : 0)));
  }

  get maxAccumulatorBonus(): number {
    return this.couponDataQuery.couponData ? Math.round(this.couponDataQuery.couponData.MaxPercentageBonus * 100) : 0;
  }

  get accumulatorBonusSelectionCount$(): Observable<number> {
    return this.couponDataQuery.couponData$.pipe(
      filter(couponData => !!couponData),
      map(couponData => {
        // First only get selections that are greater than the minimum value
        const applicableSelections = couponData.Odds.filter(odd => odd.OddValue >= this.couponDataQuery.globalVariables?.MinBonusOdd);
        // Then get the unique matches of the applicable selections,
        // as multiple selections for a single match only count once in terms of accumulator bonus progression
        const applicableSelectionsUniqueMatches = [...new Set(applicableSelections.map(odd => odd.MatchId))];
        // Return the amount of events as the amount of accumulator bonus applicable selections
        return applicableSelectionsUniqueMatches.length;
      })
    );
  }

  /**
   * Show popup when there is a selection that is below the minimum ACCA bonus odds value and the prompt has not yet been dismissed
   */
  get showOddsValueInfoPopup$(): Observable<boolean> {
    return combineLatest([
      this.applicationQuery.isVirtuals$, // Need this to trigger Observable is we change to and from Virtuals
      this.couponDataQuery.couponData$,
      this.select(state => state.dismissedAccumulatorBonusOddsValuePopup),
    ]).pipe(
      map(([_isVirtuals, couponData, dismissedPopup]) =>
        couponData
          ? !dismissedPopup && !!couponData.Odds.find(odd => odd.OddValue < this.couponDataQuery.globalVariables?.MinBonusOdd)
          : false
      )
    );
  }

  get bonusList(): Bonus[] {
    return this.getValue().bonusList;
  }

  get lastPercentageItemShown(): number {
    return this.accumulatorBonusConfig.getValue().lastPercentageItemShown;
  }

  get minOddsValue(): number {
    return this.couponDataQuery.globalVariables?.MinBonusOdd;
  }

  getSelectionNumberPercentage(selectionNumber: number): number {
    return this.bonusList ? this.bonusList.find(bonus => bonus.NumberOfEvents === selectionNumber).Percentage : 0;
  }

  getPercentageSelectionNumber(percentage: number): number {
    return this.bonusList ? this.bonusList.find(bonus => bonus.Percentage === percentage).NumberOfEvents : 0;
  }
}
