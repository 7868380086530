import { Pipe, PipeTransform } from '@angular/core';
import { EventSummaryModel } from 'src/app/shared/models/sport.model';

@Pipe({
  name: 'filterMatches',
  // TODO: Remove `pure: false`
  // eslint-disable-next-line @angular-eslint/no-pipe-impure
  pure: false,
})
export class FilterMatchesPipe implements PipeTransform {
  transform(eventSummaryModel: EventSummaryModel, term: string, property: string): any {
    if (term === 'All' || !term || !eventSummaryModel) {
      return eventSummaryModel;
    }

    const retVal = { ...eventSummaryModel };

    retVal.matches = eventSummaryModel.matches.filter(item => item[property].toLowerCase().includes(term.toLowerCase()));

    return retVal;
  }
}
