<div class="selection-container">
  <ng-container *ngFor="let selection of couponQuery.groupedSelections$| async; trackBy: selectionTrackBy">
    <div class="selection-content" *ngFor="let tournament of selection.categories[0].tournaments; trackBy: tournamentTrackBy">
      <ng-container *ngFor="let match of tournament.matches; trackBy: matchTrackBy">
        <div class="event-details-container">
          <div class="event-details">
            <div class="left-elements">
              <div class="match-name">{{ match.name }}</div>
              <div class="market-container">
                <span class="market-name">{{ tournament.regions[0].areas[0].markets[0].name }}:</span>
                <span class="selection-name">{{
                  parseSelectionName(match.odds[0].marketTypeId, match.odds[0].selectionName, match.name)
                }}</span>
              </div>
            </div>
            <div class="right-elements" [ngClass]="hasOddChanges$ | async | oddChanged: match.odds[0].id">
              <div class="odd-value">{{ match.odds[0].value | number: '1.2-2' }}</div>
              <span class="close-odd material-icons" (click)="removeOdd(match.odds[0].id)">{{ 'delete' }}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
