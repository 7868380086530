export interface FreebetsState {
  sportsBook: FreebetsProductState;
  virtualsScheduled: FreebetsProductState;
  activeProduct: FreeBetProductType;
}
export interface FreebetsProductState {
  freeBetsConfig: FreebetsConfiguration;
  freeBetsBetslipContent: FreebetsBetslipContent;
  freeBetsMyBetsContent: FreebetsMyBetsContent;
  configCallDone: boolean;
  betslipContentCallDone: boolean;
  mybetsContentCallDone: boolean;
  vouchers: FreebetsVoucher[];
  voucherCallIsLoading: boolean;
  lastTimeVouchersHaveBeenRetrieved: number;
}

export interface FreebetsConfiguration {
  allowFreeBets: boolean;
  allowOnMultipleBets: boolean;
  allowOnSingleBets: boolean;
  allowOnSystemBets: boolean;
}

export interface FreebetsBetslipContent {
  freebetApplied: string;
  modalHeaderTitle: string;
  modalSubHeaderDescription: string;
  modalSubHeaderTitle: string;
  multipleFreebetsAvailable: string;
  singleFreebetAvailable: string;
  tcLabel: string;
  tcUrl: string;
}

export interface FreebetsMyBetsContent {
  freebetApplied: string;
  freebetsStakeDisclaimer: string;
}

export interface FreebetsVoucher {
  code: string;
  endDate: Date;
  name: string;
  reward: FreebetsReward;
  startDate: Date;
  finalisedOn?: Date;
}

export interface FreebetsReward {
  allowsUserOnlyOnce: boolean;
  id: number;
  initialValue: number;
  isActive: boolean;
  isExclusive: boolean;
  remainingValue: number;
  rewardTypeCode: string;
  rewardTypeId: number;
  status: FreebetRewardStatus;
  voucherId: number;
  products: {
    id: number;
    name: string;
    code: string;
    resourceKey: string;
  }[];
}

export enum FreebetRewardStatus {
  Available = 'Available',
  Expired = 'Expired',
  Authorised = 'Authorised',
  Cancelled = 'Cancelled',
  Used = 'Used',
}

export interface OldFreebetsVoucher {
  UserId: string;
  Name: string;
  Code: string;
  StartDate: string;
  FinalisedOn?: string;
  mutatedDate?: string;
  EndDate: string;
  Reward: Reward;
  totalFreeBet: number;
}

export interface Reward {
  Id: number;
  VoucherId: number;
  RewardTypeId: number;
  RewardTypeCode: string;
  InitialValue: number;
  RemainingValue: number;
  IsExclusive: boolean;
  IsActive: boolean;
  AllowsUserOnlyOnce: boolean;
  Products: RewardProduct[];
  Status: FreebetRewardStatus;
}

export interface RewardProduct {
  Id: number;
  Name: string;
  Code: string;
  ResourceKey: string;
}

export enum FreeBetProductType {
  SportsBook = 'sportsBook',
  VirtualsScheduled = 'virtualsScheduled',
  VirtualsInstant = 'virtualsInstant',
}
