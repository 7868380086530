import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { HeaderContent } from 'src/app/modules/header/models/header.model';
import { HeaderStore } from 'src/app/modules/header/state/header.store';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  constructor(
    private readonly apiService: APIService,
    private readonly languageService: LanguageService,
    private readonly headerStore: HeaderStore
  ) {}

  initialize(): void {
    this.apiService
      .gqlQuery<{ betkingHeader: { data: { attributes: HeaderContent } } }>(
        `
        query BetkingHeaderContent($locale: I18NLocaleCode) {
          betkingHeader(locale: $locale) {
            data {
              attributes {
                loginLabel
                registerLabel
                loginUrl
                registerUrl
                messageCenterUrl
                accountMenuUrl
                productSwitcherList {
                  link {
                    title
                    url
                  }
                  hasNewBadge
                }
              }
            }
          }
        }
        `,
        this.languageService.strapiCMSLocale
      )
      .pipe(
        map(res => {
          return {
            loading: res.loading,
            data: !!res.data && res.data.betkingHeader.data.attributes,
          };
        }),
        tap(({ loading, data }) => {
          this.headerStore.updateHeaderContentLoading(loading);
          !!data && this.headerStore.updateHeaderContent(data);
        })
      )
      .subscribe();
  }
}
