import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { HelpStore } from 'src/app/core/state/help/help.store';
import { HelpState } from 'src/app/shared/models/help.model';

@Injectable({ providedIn: 'root' })
export class HelpQuery extends Query<HelpState> {
  sections$ = this.select(state => state.sections);

  constructor(protected store: HelpStore) {
    super(store);
  }
}
