import { OverlayDropdownOption } from 'src/app/shared/components/overlay-dropdown/overlay-dropdown-option';
import { EventSummaryModel } from './sport.model';

export class LastMinuteState {
  data: EventSummaryModel[];
  selectedSportLastMinuteEvents: EventSummaryModel;
  selectedCompetition: OverlayDropdownOption<any>;
  sortBy: any;

  constructor(init: Partial<LastMinuteState>) {
    Object.assign(this, init);
  }
}

export class LastMinuteConfig {
  count: number;
  sportIds: number[];

  constructor(init: Partial<LastMinuteConfig>) {
    Object.assign(this, init);
  }
}

export enum LastMinuteSortType {
  ByTime,
  ByOddsLowestFirst,
  ByOddsHighestFirst,
}
