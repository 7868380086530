<div class="card" [ngClass]="{ 'collapsible-card': collapsible }">
  <div class="title-header" *ngIf="titleHeaderText || collapsible" (click)="toggleWidget()">
    <div class="title-details">
      <h1>{{ titleHeaderText }}</h1>
    </div>
    <i class="fa {{ rightIcon }}" *ngIf="rightIcon" [appDropdownArrowRotator]="!collapsed"
      [openAndCloseColors]="[variablesService.colorBrand]"></i>
  </div>
  <div class="card-content" [ngClass]="{ loading: loading }" [@expandCollapse]="expandState">
    <div class="content">
      <ng-content></ng-content>
    </div>
    <app-loading-bar [show]="loading"></app-loading-bar>
  </div>
</div>
