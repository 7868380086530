import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { MenuStore } from 'src/app/core/state/menu/menu.store';
import { APIType } from 'src/app/shared/models/api.model';
import { MenuTab } from 'src/app/shared/models/menu.model';
@Injectable({
  providedIn: 'root',
})
export class MenuService implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly apiService: APIService,
    private readonly menuStore: MenuStore,
    private readonly applicationService: ApplicationService
  ) {}

  changeTab(tab: MenuTab): void {
    this.menuStore.changeTab(tab);
  }

  getQuicklinks(): Observable<void> {
    return this.apiService.get(APIType.CMS, `SiteRoot/GetLandingQuicklinks`).pipe(
      map(data => {
        // TODO for mobile virtuals additional quicklink needed to be added for the virtuals product selector page

        this.menuStore.update({ quickLinks: data });
      }),
      tap(() => {
        this.applicationService.updateCms({ lastQuicklinksUpdate: this.applicationService.getEpochTime() });
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
