import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CorrectScoreEventService } from 'src/app/core/services/correct-score-event.service';

import { catchError, finalize, first, map, switchMap, tap } from 'rxjs/operators';

import { kebabCase, orderBy } from 'lodash-es';
import { Observable, throwError } from 'rxjs';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { OddModel } from 'src/app/shared/models/coupon.model';
import {
  AreaModel,
  CategoryModel,
  CompetitionMatch,
  GoalscorerAvailableMarketsModel,
  GoalscorerModel,
  GoalscorerPlayersModel,
  GoalscorerTeamsModel,
  MarketModel,
  MatchModel,
  RegionModel,
  SelectionModel,
  SourceModel,
  SportModel,
  TournamentModel,
} from 'src/app/shared/models/sport.model';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { APIService } from 'src/app/core/services/api.service';
import { MatchStore } from 'src/app/modules/sport/state/match-view/match.store';
import { EventBetInsightModel, MatchMarketCategoryTab } from 'src/app/modules/sport/models/event-view/prematch.model';
import { SelectionDescriptionService } from 'src/app/modules/sport/services/selection-description.service';
import { GoalscorerService } from 'src/app/modules/sport/services/goalscorer.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { MatchQuery } from 'src/app/modules/sport/state/match-view/match.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { convertToKMId, IdType } from 'src/app/shared/utils/id-convertor';

@Injectable({
  providedIn: 'root',
})
export class MatchService {
  private readonly sportsConfig = this.appConfig.get('sports');

  constructor(
    private readonly apiService: APIService,
    private readonly appConfig: AppConfigService,
    private readonly correctScoreEventService: CorrectScoreEventService,
    private readonly couponQuery: CouponQuery,
    private readonly couponService: CouponService,
    private readonly goalscorerService: GoalscorerService,
    private readonly languageService: LanguageService,
    private readonly matchQuery: MatchQuery,
    private readonly matchStore: MatchStore,
    private readonly router: Router,
    private readonly selectionDescriptionService: SelectionDescriptionService
  ) {}

  clearError(): void {
    this.matchStore.setError(undefined);
  }

  clearData(): void {
    this.matchStore.updateMatch(undefined);
    this.matchStore.updateEventBetInsight(undefined);
  }

  navigateToMatch(matchId: number, name?: string, areaId?: string): void {
    const kebabCaseName = kebabCase(name);
    this.router.navigate([`sports/match/${matchId}${kebabCaseName ? `/${kebabCaseName}` : ''}${areaId ? `/${areaId}` : ''}`]);
  }

  getUrlMatch(matchId: number, name?: string): string {
    const kebabCaseName = kebabCase(name);
    return `sports/match/${matchId}${kebabCaseName ? `/${kebabCaseName}` : ''}`;
  }

  getFixtureInfo(matchId: number, matchTypeId: IdType = IdType.Prematch, isKmID: boolean = false): Observable<void> {
    const KmEventId = isKmID ? matchId : convertToKMId(matchId, matchTypeId);
    return this.apiService.get(APIType.BFFSportsbook, `v2/prematch/fixture-info?id=${KmEventId}`).pipe(
      first(),
      map(response => {
        const dynamicBetBuilderFixtureId = response.data[0]?.sources?.find(
          (source: SourceModel) =>
            source.offering.trim() === this.appConfig.get('sports').betBuilderOffering &&
            source.sourceId === this.appConfig.get('sports').betBuilderSourceId
        )?.providerId;
        this.matchStore.update({
          fixtureInfo: response.data,
          dynamicBetBuilderFixtureId,
        });
      }),
      catchError(error => {
        this.matchStore.update({
          dynamicBetBuilderFixtureId: undefined,
          fixtureInfo: undefined,
        });
        return throwError(error);
      })
    );
  }

  getBoostedOdds = (match: MatchModel, matchId: number, matchTypeId: number) => {
    return this.apiService.get(APIType.SportsbookFeed, `api/feeds/prematch/boostedmarkets/${matchId}/${matchTypeId}`).pipe(
      first(),
      map(response => {
        const result = this.mapBoostedOddsDataToModel(match, response, matchTypeId);
        this.matchStore.updateMatchBoostedOdds(result);
      })
    );
  };

  getEventBetInsight(eventId: number, match: MatchModel, coverage: number = 1): Observable<EventBetInsightModel[]> {
    if (!eventId || !match) return;
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });
    const kmEventId = convertToKMId(eventId, IdType.Prematch);
    const url = `v1/prematch/bet-insight/event?fixtureId=${kmEventId}&coverage=${coverage}`;
    return this.apiService.get(APIType.BFFSportsbook, url, apiSettings).pipe(
      catchError(error => {
        this.matchStore.updateEventBetInsight(undefined);
        return throwError(error);
      }),
      tap((data: any) => {
        const parsedInsight = this.handleBetInsightData(data.data, match);
        this.matchStore.updateEventBetInsight(parsedInsight);
      })
    );
  }

  handleBetInsightData(data: any, match: MatchModel): EventBetInsightModel[] {
    data.forEach((insight: EventBetInsightModel) => {
      insight.odds = this.mapBetInsightOddsDataToModel(insight, match);
    });
    return data;
  }

  mapBetInsightOddsDataToModel = (insight: EventBetInsightModel, match: MatchModel): OddModel[] => {
    const odds: OddModel[] = [];
    insight.selection.forEach(odd => {
      odds.push(
        new OddModel({
          id: odd.selectionId,
          value: odd.value,
          unboostedValue: odd.unboostedValue,
          sportId: match?.sportId,
          sportName: match?.sportName,
          categoryId: match?.categoryId,
          categoryName: match?.categoryName,
          tournamentId: match?.tournamentId,
          tournamentName: match?.tournamentName?.trim(),
          matchId: match?.id,
          matchName: match?.name,
          matchDate: match?.date,
          marketId: insight.marketId,
          marketTypeId: insight.marketTypeId,
          marketName: insight.marketTypeName,
          smartCode: parseInt(match?.smartBetCode, 10),
          eventCategory: match?.eventCategory,
          combinability: match?.odds[0]?.combinability,
          selectionId: odd.selectionTypeId,
          selectionTypeId: odd.selectionId,
          spreadValueDisplay: insight?.lineValue?.toString() || 0,
          spreadValue: insight?.lineValue || 0,
          selectionName: odd.selectionName,
          incompatibleEvents: match?.odds[0]?.incompatibleEvents,
          selected: this.couponService.isOddInCoupon(odd.selectionTypeId),
          enabled: true,
          showSelectionName: true,
        })
      );
    });
    return odds;
  };

  mapBoostedOddsDataToModel = (match: MatchModel, boostedOdd: any[], marketTypeId: number): OddModel[] => {
    const odds: OddModel[] = [];
    boostedOdd.forEach(boosted => {
      odds.push(
        new OddModel({
          id: boosted.IDSelection,
          value: boosted.OddValue,
          unboostedValue: boosted.UnboostedOddValue,
          sportId: match?.sportId,
          sportName: match?.sportName,
          categoryId: match?.categoryId,
          categoryName: match?.categoryName,
          tournamentId: match?.tournamentId,
          tournamentName: match?.tournamentName?.trim(),
          matchId: match?.id,
          matchName: match?.name,
          matchDate: match?.date,
          marketId: boosted.IDMarket,
          marketTypeId: marketTypeId,
          marketName: boosted.MarketTypeName,
          smartCode: parseInt(match?.smartBetCode, 10),
          eventCategory: match?.eventCategory,
          combinability: match?.odds[0]?.combinability,
          selectionId: boosted.IDSelection,
          selectionTypeId: match?.odds[0]?.selectionTypeId,
          selectionName: boosted.SelectionName,
          selectionNames: boosted.SelectionName?.split(/[,&]/).map(name => name.trim()),
          incompatibleEvents: match?.odds[0]?.incompatibleEvents,
          selected: this.couponService.isOddInCoupon(boosted.IDSelection),
          enabled: true,
        })
      );
    });
    return odds;
  };

  getBetBuilderOddFilter() {
    return this.apiService
      .gqlQuery<{ sportsbookPage: { data: { attributes: any } } }>(
        `
        query SportsbookPageContent($locale: I18NLocaleCode) {
          sportsbookPage(locale: $locale) {
            data {
              attributes {
                  displayDynamicBetBuilderBanner
                  displayBoostedBetBuilderFilter
                  betBuilderOddFilter {
                      filterName
                      lessOddRange
                      greaterOddRange
                }
              }
            }
          }
        }
        `,
        this.languageService.strapiCMSLocale
      )
      .pipe(
        map(res => {
          return {
            loading: res.loading,
            data: !!res.data && res.data.sportsbookPage.data.attributes,
          };
        })
      );
  }

  getMatchMarketCategories = (matchId: number, areaId?: number) =>
    this.apiService.get(APIType.SportsbookFeed, `api/feeds/prematch/event/lite/allgroups/${matchId}`).pipe(
      first(),
      map(response => {
        if (
          response.AR.find(t => t.AI === this.appConfig.get('sports').betBuilderId) === undefined &&
          this.matchQuery.getValue()?.dynamicBetBuilderFixtureId
        ) {
          const betBuilderTab = {
            AI: this.appConfig.get('sports').betBuilderId,
            AN: this.appConfig.get('sports').betBuilderName,
            AO: 1,
            MC: 0,
          };
          response.AR.push(betBuilderTab);
        }
        return this.marketCategoryTabMapping(response.AR);
      }),
      tap(result => {
        this.matchStore.updateMarketCategories(result);
      }),
      switchMap(result => {
        const totalMarketCount = result.map(category => category.marketCount).reduce((prev, curr) => prev + curr);
        return this.updateSelectedMarketTab(
          matchId,
          totalMarketCount <= this.sportsConfig.matchView.marketCountForNonGroupedView
            ? undefined
            : areaId
            ? // Set the selected tab to the areaId if it exists, otherwise set it to the first tab
              result.find(t => t.id === areaId) || result[0]
            : result[0]
        );
      })
    );

  updateSelectedMarketCategoryTab = (areaTab?: MatchMarketCategoryTab) => {
    this.matchStore.updateSelectedMarketCategoryTab(areaTab);
  };

  updateSelectedMarketTab = (matchId: number, areaTab?: MatchMarketCategoryTab) => {
    this.updateSelectedMarketCategoryTab(areaTab);
    this.matchStore.setLoading(true);
    return this.getLiteMarketCategoryData(matchId, areaTab?.id);
  };

  getMatchesByCompetitionId(competitionId: number, language: string = 'en', scheduleTimeFrame: number = 4) {
    return this.apiService
      .get(APIType.SportsbookFeed, `api/feeds/prematch/event/bycompetitionid/${language}/${scheduleTimeFrame}/${competitionId}`)
      .pipe(map(response => this.parseCompetitionMatch(response.PrematchEvents)));
  }
  private readonly marketCategoryTabMapping = (response: any[]): MatchMarketCategoryTab[] =>
    response
      .sort((prev, curr) => prev.AO - curr.AO)
      .map(data => ({
        id: data.AI,
        name: data.AN,
        marketCount: data.MC,
      }));

  // Non-Lite
  private readonly getMarketCategoryData = (matchId: number, marketCategoryId?: number) => {
    // TODO: Remove sport ID
    this.matchStore.setLoading(true);

    return this.apiService
      .get(
        APIType.SportsbookFeed,
        marketCategoryId
          ? `api/feeds/prematch/event/grouped/${matchId}/${marketCategoryId}`
          : `api/feeds/prematch/event/ungrouped/en/4/${matchId}`
      )
      .pipe(
        first(),
        catchError(error => {
          this.matchStore.setError(error);
          return throwError(error);
        }),
        tap(data => {
          const match: SportModel = this.parseNormal(data);
          this.matchStore.updateMatch(match);
        }),
        finalize(() => {
          this.matchStore.setLoading(false);
        })
      );
  };

  private readonly parseNormal = (data: any) => {
    const match: SportModel = new SportModel({});
    const odds = this.mapNormalOddsDataToModel(data.AreaMatches[0].Items[0], data.AreaMatches[0].SportID, data.AreaMatches[0].SportName);
    const goalscorer = this.goalscorerService.mapGoalscorerDataItemsToModel(
      data.AreaMatches[0].Items[0],
      data.AreaMatches[0].SportID,
      data.AreaMatches[0].SportName
    );

    // There will only be one sport, category, and tournament for a single match
    match.id = data.AreaMatches[0].SportID;
    match.name = data.AreaMatches[0].SportName;
    match.groupingType = data.AreaMatches[0].GroupingType;
    match.categories = [
      new CategoryModel({
        id: data.AreaMatches[0].Items[0].CategoryID,
        name: data.AreaMatches[0].Items[0].CategoryName,
        tournaments: [
          new TournamentModel({
            id: data.AreaMatches[0].Items[0].TournamentID,
            name: data.AreaMatches[0].Items[0].tournamentName,
            regions: this.mapNormalMarketDataToModel(data.AreaMatches[0].Items[0].OddsCollection),
            matches: [
              new MatchModel({
                id: data.AreaMatches[0].Items[0].ItemID,
                date: data.AreaMatches[0].Items[0].ItemDate,
                name: data.AreaMatches[0].Items[0].ItemName,
                smartBetCode: data.AreaMatches[0].Items[0].SmartBetCode,
                eventCategory: data.AreaMatches[0].Items[0].EventCategory,
                categoryName: data.AreaMatches[0].Items[0].CategoryName,
                tournamentName: data.AreaMatches[0].Items[0].TournamentName.trim(),
                sportId: data.AreaMatches[0].SportID,
                odds,
                goalscorer,
                correctScoreOdds: this.correctScoreEventService.parseOddsForCorrectScoreView(odds),
              }),
            ],
          }),
        ],
      }),
    ];
    return match;
  };

  private mapNormalMarketDataToModel(data: any): RegionModel[] {
    return [
      new RegionModel({
        isDefault: true,
        areas: [
          new AreaModel({
            isDefault: true,
            markets: data.map(
              oc =>
                new MarketModel({
                  id: oc.OddsType.OddsTypeID,
                  name: oc.OddsType.OddsTypeName,
                  marketType: oc.OddsType.MultilineType,
                  spreadValue: oc.SpecialBetValue,
                  spreadDisplayValue: oc.MatchOdds[0].OddAttribute.SpecialValueDisplay,
                  selections: oc.MatchOdds.map(
                    selection =>
                      new SelectionModel({
                        id: oc.OddsType.MatchOddsID,
                        name: selection.OddAttribute.OddName,
                        spreadValue: selection.OddAttribute.SpecialValue,
                        spreadDisplayValue: selection.OddAttribute.SpecialValueDisplay,
                        showSpreadValue: selection.OddAttribute.SpecialValueString !== '',
                      })
                  ),
                  description: oc.OddsType.OddsDescription,
                  typeId: oc.OddsType.IDGroupMarketType,
                  order: oc.OddsType.OddsTypeOrder,
                })
            ),
          }),
        ],
      }),
    ];
  }

  private readonly mapNormalOddsDataToModel = (data: any, sportId: number, sportName: string): OddModel[] => {
    const odds: OddModel[] = [];
    data.OddsCollection.forEach(oddCollection => {
      oddCollection.MatchOdds.forEach(odd => {
        odds.push(
          new OddModel({
            id: odd.MatchOddsID,
            value: odd.Outcome.OddOutcome,
            unboostedValue: odd.Outcome.UnboostedOddValue,
            spreadValue: odd.OddAttribute.SpecialValue,
            sportId,
            sportName,
            categoryId: data.CategoryId,
            categoryName: data.CategoryName,
            tournamentId: data.TournamentId,
            tournamentName: data.TournamentName.trim(),
            matchId: data.ItemID,
            matchName: data.ItemName,
            matchDate: data.ItemDate,
            marketId: oddCollection.OddCollectionID,
            marketTypeId: oddCollection.OddsType.OddsTypeID,
            marketName: oddCollection.OddsType.OddsTypeName,
            smartCode: odd.SmartBetCode,
            eventCategory: data.EventCategory,
            combinability: oddCollection.Combinability,
            selectionId: odd.Outcome.OutcomeId,
            selectionTypeId: odd.OddAttribute.OddTypeID,
            selectionName: odd.OddAttribute.OddName,
            selectionDescription: odd.OddAttribute.SelectionDescription
              ? this.selectionDescriptionService.parseSelectionDescription(data.ItemName, odd.OddAttribute.SelectionDescription)
              : `${oddCollection.OddsType.OddsTypeName} - ${odd.OddAttribute.OddName}`,
            incompatibleEvents: data.IncompatibleEvents,
            selected: this.couponService.isOddInCoupon(odd.MatchOddsID),
            enabled: true,
          })
        );
      });
    });
    return odds;
  };

  // Lite
  private readonly getLiteMarketCategoryData = (matchId: number, marketCategoryId?: number) =>
    this.apiService
      .get(
        APIType.SportsbookFeed,
        marketCategoryId
          ? `api/feeds/prematch/event/lite/grouped/${matchId}/${marketCategoryId}`
          : `api/feeds/prematch/lite/event/ungrouped/en/4/${matchId}`
      )
      .pipe(
        first(),
        catchError(error => {
          this.matchStore.setError(error);
          return throwError(error);
        }),
        tap(data => {
          this.matchStore.setLoading(true);
          const match: SportModel = this.parseLite(data);
          this.matchStore.updateMatch(match);
          this.getEventBetInsight(matchId, match.categories[0]?.tournaments[0]?.matches[0]).pipe(first()).subscribe();
          if (match?.categories[0]?.tournaments[0]?.matches[0]?.betBuilder?.length > 0)
            this.getBoostedOdds(
              match.categories[0].tournaments[0].matches[0],
              matchId,
              this.couponQuery.preCannedBetBuilderMarketTypeID
            ).subscribe();
        }),
        finalize(() => {
          this.matchStore.setLoading(false);
        })
      );

  private readonly parseLite = (data: any) => {
    const match: SportModel = new SportModel({});
    const odds = this.mapLiteOddsDataToModel(data.AM[0].IT[0], data.AM[0].SI, data.AM[0].SN);
    const betBuilder =
      data.AR[0].AI === this.appConfig.get('sports').betBuilderId
        ? this.mapLiteOddsDataToModel(data.AM[0].IT[0], data.AM[0].SI, data.AM[0].SN, true)
        : [];
    const goalscorer = this.mapLiteGoalscorerDataToModel(data.AM[0].IT[0], data.AM[0].SI, data.AM[0].SN);

    // There will only be one sport, category, and tournament for a single match
    match.id = data.AM[0].SI;
    match.name = data.AM[0].SN;
    match.groupingType = data.AM[0].GT;
    match.categories = [
      new CategoryModel({
        id: data.AM[0].IT[0].CI,
        name: data.AM[0].IT[0].CN,
        tournaments: [
          new TournamentModel({
            id: data.AM[0].IT[0].TI,
            name: data.AM[0].IT[0].TN,
            regions: this.mapLiteMarketDataToModel(data.AM[0].IT[0].OC),
            matches: [
              new MatchModel({
                id: data.AM[0].IT[0].II,
                date: data.AM[0].IT[0].ID,
                name: data.AM[0].IT[0].IN,
                smartBetCode: data.AM[0].IT[0].SB,
                eventCategory: data.AM[0].IT[0].EC,
                categoryName: data.AM[0].IT[0].CN,
                categoryId: data.AM[0].IT[0].CI,
                tournamentName: data.AM[0].IT[0].TN.trim(),
                tournamentId: data.AM[0].IT[0].TI,
                sportId: data.AM[0].SI,
                sportName: data.AM[0].SN,
                odds,
                goalscorer,
                correctScoreOdds: this.correctScoreEventService.parseOddsForCorrectScoreView(odds),
                betBuilder,
              }),
            ],
          }),
        ],
      }),
    ];
    return match;
  };

  private mapLiteMarketDataToModel(data: any): RegionModel[] {
    return [
      new RegionModel({
        isDefault: true,
        areas: [
          new AreaModel({
            isDefault: true,
            markets: data.map(
              oc =>
                new MarketModel({
                  id: oc.OI,
                  name: oc.OT.ON,
                  marketType: oc.OT.TT, // Maps to MultilineType
                  spreadValue: oc.SB,
                  spreadDisplayValue: oc.MO[0].OA.VD,
                  selections: oc.MO.map(
                    selection =>
                      new SelectionModel({
                        id: selection.OI,
                        name: selection.OA.ON,
                        names: selection.OA.ON.split(/[,&]/).map(name => name.trim()), // Split the bet builder names using & and ,
                        spreadValue: selection.OA.SV,
                        spreadDisplayValue: selection.OA.VD,
                        showSpreadValue: selection.OA.SP !== '',
                      })
                  ),
                  description: oc.OT.OD,
                  typeId: oc.OT.OI,
                  order: oc.OT.OO,
                })
            ),
          }),
        ],
      }),
    ];
  }

  private readonly mapLiteOddsDataToModel = (data: any, sportId: number, sportName: string, sortable: boolean = false): OddModel[] => {
    let odds: OddModel[] = [];
    data.OC.forEach(oddCollection => {
      oddCollection.MO.forEach(odd => {
        odds.push(
          new OddModel({
            id: odd.OI,
            value: odd.OT.OO,
            unboostedValue: odd.OT.UO,
            spreadValueDisplay: oddCollection.OT.IS ? oddCollection.OT.ON : odd.OA.VD === '0' ? odd.OA.SV : odd.OA.VD,
            spreadValue: oddCollection.OT.IS ? oddCollection.OT.ON : odd.OA.SV,
            sportId,
            sportName,
            categoryId: data.CI,
            categoryName: data.CN,
            tournamentId: data.TI,
            tournamentName: data.TN.trim(),
            matchId: data.II,
            matchName: data.IN,
            matchDate: data.ID,
            marketId: oddCollection.OI,
            marketTypeId: oddCollection.OT.OI,
            marketName: oddCollection.OT.ON,
            smartCode: odd.SB,
            eventCategory: data.EC,
            combinability: oddCollection.CB,
            selectionId: odd.OA.OI,
            selectionTypeId: odd.OT.OI,
            selectionName: odd.OA.ON,
            selectionNames: odd.OA.ON?.split(/[,&]/).map(name => name.trim()),
            selectionDescription: odd.OA.SD
              ? this.selectionDescriptionService.parseSelectionDescription(data.IN, odd.OA.SD)
              : `${oddCollection.OT.ON} - ${odd.OA.ON}`,
            incompatibleEvents: data.IE,
            selected: this.couponService.isOddInCoupon(odd.OI),
            enabled: true,
          })
        );
      });
    });
    if (sortable) {
      odds = odds.sort((prev, cur) => prev.value - cur.value);
    }
    return odds;
  };

  private readonly mapLiteGoalscorerDataToModel = (data: any, sportId: number, sportName: string): GoalscorerModel => {
    if (!data || !data.GS) {
      return;
    }

    const showPlayersCount = this.sportsConfig.goalscorerShowPlayersCount;

    const goalscorer: GoalscorerModel = {
      hasMorePlayers: false,
      hasMarketDescriptions: false,
      marketOrder: -1,
      availableMarkets: [],
      teams: [],
    };

    let marketCount = 0;
    data.GS.GT.forEach(team => {
      const objTeam: GoalscorerTeamsModel = {
        teamID: team.IT,
        teamName: team.TN,
        teamOrder: team.TO,
        players: [],
      };

      if (team.GP.length > showPlayersCount) {
        goalscorer.hasMorePlayers = true;
      }

      team.GP.forEach(player => {
        const objPlayer: GoalscorerPlayersModel = {
          playerID: player.IP,
          playerName: player.PN,
          markets: [],
        };

        player.MK.forEach(market => {
          // get the first market order for the whole goalscorer section
          if (marketCount === 0) {
            goalscorer.marketOrder = market.OT.OO;
          }
          marketCount++;

          if (!goalscorer.hasMarketDescriptions && market.OT.OD) {
            goalscorer.hasMarketDescriptions = true;
          }

          const selectionDescription = market.MO[0].OA.SD;
          const selectionDescriptionDisplay = this.selectionDescriptionService.parseSelectionDescription(data.IN, selectionDescription);

          if (!goalscorer.hasMarketDescriptions && selectionDescriptionDisplay) {
            goalscorer.hasMarketDescriptions = true;
          }

          const marketTypeId = market.OT.OI;
          const marketName = market.OT.ON;

          const selectionId = market.MO[0].OA.OI;
          const selectionName = market.MO[0].OA.ON;

          const uniqueKey = [marketTypeId, marketName].join('_');

          objPlayer.markets[uniqueKey] = new OddModel({
            id: market.MO[0].OI,
            value: market.MO[0].OT && market.MO[0].OT.OO ? market.MO[0].OT.OO : undefined,
            unboostedValue: market.MO[0].OT && market.MO[0].OT.UO ? market.MO[0].OT.UO : undefined,
            spreadValue: market.MO[0].OA.SV,
            sportId,
            sportName,
            categoryId: data.CI,
            categoryName: data.CN,
            tournamentId: data.TI,
            tournamentName: data.TN,
            matchId: data.II,
            matchName: data.IN,
            matchDate: data.ID,
            marketId: market.OI,
            marketTypeId: marketTypeId,
            marketName: marketName,
            smartCode: data.SB,
            eventCategory: data.EC,
            combinability: market.CB,
            selectionId: selectionId,
            selectionName: selectionName,
            selectionDescription: selectionDescriptionDisplay,
            incompatibleEvents: data.IE,
            selected: this.couponService.isOddInCoupon(market.MO[0].OI),
            enabled: true,
          });

          const marketAlreadyExist = goalscorer.availableMarkets.filter(item => item.marketTypeId === marketTypeId);
          if (marketAlreadyExist.length === 0) {
            const objAvailableMarket: GoalscorerAvailableMarketsModel = {
              uniqueKey: uniqueKey,
              marketTypeId: marketTypeId,
              marketName: marketName,
              marketDescription: market.OT.OD,
            };

            goalscorer.availableMarkets.push(objAvailableMarket);
          }
        });

        objTeam.players.push(objPlayer);
      });

      goalscorer.teams.push(objTeam);
    });

    return goalscorer;
  };

  private readonly parseCompetitionMatch = (matches: any): CompetitionMatch[] => {
    return orderBy(matches, 'EventDate').map(data => {
      return new CompetitionMatch({
        eventDate: data.EventDate,
        eventName: data.EventName,
        idEvent: data.IDEvent,
        kmEventId: data.KmEventId,
      });
    });
  };
}
