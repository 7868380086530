import { Injectable, OnDestroy } from '@angular/core';
import { AppConfigService } from 'src/app/core/services/app-config.service';

import { ID } from '@datorama/akita';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, first, map } from 'rxjs/operators';

import { format } from 'date-fns';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { MessagesQuery } from 'src/app/core/state/messages/messages.query';
import { MessagesStore } from 'src/app/core/state/messages/messages.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { MessageFilterModel, MessageModel, MessagesState } from 'src/app/shared/models/message.model';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { APIService } from './api.service';
import { ApplicationService } from './application.service';

@Injectable({
  providedIn: 'root',
})
export class MessageService implements OnDestroy {
  private signalrIntegration: HubConnection;

  constructor(
    private readonly apiService: APIService,
    private readonly appConfig: AppConfigService,
    private readonly accountQuery: AccountQuery,
    private readonly messagesQuery: MessagesQuery,
    private readonly messagesStore: MessagesStore,
    private readonly applicationService: ApplicationService
  ) {
    this.accountQuery.isAuthenticated$.subscribe(auth => {
      if (!auth) {
        this.messagesStore.clear();
        this.closeSignalrConnection();
      } else {
        this.initializeSignalrNotifications();
      }
    });
  }

  get defaultFilter(): MessageFilterModel {
    return this.messagesStore.defaultFilter;
  }

  getMessages(appendOrSet: 'append' | 'set'): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken,
    });

    return appendOrSet === 'set' ? this.getMessagesSet(apiSettings) : this.getMessagesAppend(apiSettings);
  }

  getMessage(id: number): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken,
    });

    this.messagesStore.setLoading(true);
    return this.apiService.get(APIType.Platform, `api/User/Messages/${id}`, apiSettings).pipe(
      first(),
      finalize(() => {
        this.messagesStore.setLoading(false);
      }),
      map(responseData => {
        if (!(responseData === undefined || responseData.Result === undefined)) {
          this.messagesStore.updateViewingMessage(this.mapMessageDataToModel(responseData.Result));
        }
      }),
      catchError(err => {
        this.messagesStore.setError(err);
        return throwError(err);
      })
    );
  }

  deleteMessage(id: ID): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken,
    });

    this.messagesStore.setLoading(true);
    return this.apiService.post(APIType.Platform, `api/User/Messages/${id}/Delete`, undefined, apiSettings).pipe(
      first(),
      finalize(() => {
        this.messagesStore.setLoading(false);
      }),
      catchError(err => {
        this.messagesStore.setError(err);
        return throwError(err);
      })
    );
  }

  sendMessage(subject: string, reply: string, id: ID): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken,
    });

    this.messagesStore.setLoading(true);
    return this.apiService
      .post(
        APIType.Platform,
        `api/User/Messages/PostMessage`,
        { MessageSubject: subject, MessageContent: reply, PreviousMessageId: id },
        apiSettings
      )
      .pipe(
        first(),
        finalize(() => {
          this.messagesStore.setLoading(false);
        }),
        catchError(err => {
          this.messagesStore.setError(err);
          return throwError(err);
        })
      );
  }

  clearViewingMessage(): void {
    this.messagesStore.updateViewingMessage(undefined);
  }

  markMessageAsRead(id: ID): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken,
    });

    return this.apiService.post(APIType.Platform, `api/User/Messages/${id}/MarkRead`, apiSettings).pipe(first());
  }

  getUnreadMessagesCount(): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken,
    });

    return this.apiService.get(APIType.Platform, 'api/User/Messages/UnreadCount', apiSettings).pipe(
      map(responseData => {
        this.applicationService.updateCms({ lastUnreadMessagesUpdate: this.applicationService.getEpochTime() });
        if (responseData === undefined || responseData.Result === undefined) {
          return;
        }
        this.messagesStore.updateUI({ unreadMessageCount: responseData.Result });
      })
    );
  }

  updateFilter(filteredState: boolean, dateFrom: Date, dateTo: Date, state: number): void {
    this.messagesStore.updateFilter({
      filteredState,
      dateFrom,
      dateTo,
      state,
    });

    this.resetMessagePage();
  }

  updatePartialFilter(filter: Partial<MessagesState['filter']>): void {
    this.messagesStore.updateFilter({ ...filter });
    this.resetMessagePage();
  }

  incrementMessagesPage(): number {
    let page = this.messagesQuery.messagesPage;
    page += 1;
    this.messagesStore.updateUI({ messagesPage: page });
    return page;
  }

  resetMessagePage(): void {
    this.messagesStore.updateUI({ messagesPage: 1 });
  }

  clearError(): void {
    this.messagesStore.setError(undefined);
  }

  private mapMessageDataToModel(data: any): MessageModel {
    return new MessageModel({
      id: data.Id,
      date: data.SentOnDate,
      read: data.ReadOnDate,
      priority: data.Priority,
      state: data.State,
      subject: data.Title,
      body: data.Contents,
      direction: data.MessageDirection,
    });
  }

  initializeSignalrNotifications(): void {
    const signalrNotificationsEnabled = this.appConfig.get('signalrNotificationsEnabled');
    if (signalrNotificationsEnabled) {
      const apiBaseUrl = this.apiService.getAPIUrl(APIType.SignalrNotifications);
      const signalrNotificationsUrl = `${apiBaseUrl}?access_token=${this.accountQuery.accessToken}`;
      this.signalrIntegration = new HubConnectionBuilder().withUrl(signalrNotificationsUrl).configureLogging(LogLevel.Error).build();
      this.signalrIntegration.on('InstantMessage', (response: string) => {
        try {
          const data = JSON.parse(response) as { EventType: 'Read' | 'Delete' | 'New' };
          const count = this.messagesQuery.unreadMessageCount;
          let updatedCount = data.EventType === 'Read' || data.EventType === 'Delete' ? count - 1 : Number(count) + 1;
          updatedCount = Math.max(0, updatedCount); // Ensure count is not negative
          this.messagesStore.updateUI({ unreadMessageCount: updatedCount });
        } catch (error) {
          /* empty */
        }
      });
      this.signalrIntegration.start();
    }
  }

  ngOnDestroy(): void {
    this.closeSignalrConnection();
  }

  closeSignalrConnection(): void {
    if (this.signalrIntegration) {
      this.signalrIntegration.stop();
    }
  }

  private getMessagesSet(apiSettings: APISettings): Observable<void> {
    this.messagesStore.setLoading(true);
    const filter = this.messagesQuery.filter;

    return this.apiService
      .get(
        APIType.Platform,
        `api/User/Messages/Search?from=${format(new Date(filter[0]), 'yyyy-MM-dd')}&to=${format(new Date(filter[1]), 'yyyy-MM-dd')}${
          filter[2] !== -1 && filter[2] !== '-1' ? `&state=${filter[2]}` : ''
        }&rowsPerPage=${this.appConfig.get('messages').defaultPageAmount}&pageNumber=${this.messagesQuery.messagesPage}`,
        apiSettings
      )
      .pipe(
        first(),
        finalize(() => {
          this.messagesStore.setLoading(false);
        }),
        map(responseData => {
          if (!(responseData === undefined || responseData.Result === undefined)) {
            if (responseData.Result.Messages.length > 0) {
              const messages: MessageModel[] = [];
              responseData.Result.Messages.forEach(data => {
                messages.push(this.mapMessageDataToModel(data));
              });
              this.messagesStore.setMessages(messages);
            } else {
              this.messagesStore.setMessages(undefined);
            }

            this.messagesStore.updateUI({ totalMessageCount: responseData.Result.TotalMessagesSearchCount });
          }
        }),
        catchError(err => {
          this.messagesStore.setError(err);
          return throwError(err);
        })
      );
  }

  private getMessagesAppend(apiSettings: APISettings): Observable<void> {
    this.messagesStore.updateUI({ moreMessagesLoading: true });
    const filter = this.messagesQuery.filter;

    return this.apiService
      .get(
        APIType.Platform,
        `api/User/Messages/Search?from=${format(new Date(filter[0]), 'yyyy-MM-dd')}&to=${format(new Date(filter[1]), 'yyyy-MM-dd')}${
          filter[2] !== -1 && filter[2] !== '-1' ? `&state=${filter[2]}` : ''
        }&rowsPerPage=${this.appConfig.get('messages').defaultPageAmount}&pageNumber=${this.messagesQuery.messagesPage}`,
        apiSettings
      )
      .pipe(
        first(),
        map(responseData => {
          if (!(responseData === undefined || responseData.Result === undefined)) {
            if (responseData.Result.Messages.length > 0) {
              const messages: MessageModel[] = [];
              responseData.Result.Messages.forEach(data => {
                messages.push(this.mapMessageDataToModel(data));
              });
              this.messagesStore.appendMessages(messages);
            }

            this.messagesStore.updateUI({ totalMessageCount: responseData.Result.TotalMessagesSearchCount });
          }
          this.messagesStore.updateUI({ moreMessagesLoading: false });
        }),
        catchError(err => {
          this.messagesStore.updateUI({ moreMessagesLoading: false });
          this.messagesStore.setError(err);
          return throwError(err);
        })
      );
  }
}
