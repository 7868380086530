import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { BookBetService } from 'src/app/core/services/book-bet.service';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';

@Injectable({
  providedIn: 'root',
})
export class BookBetResolver implements Resolve<any>, OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly notificationService: NotificationService,
    private readonly applicationService: ApplicationService,
    private readonly bookBetService: BookBetService,
    private readonly couponQuery: CouponQuery
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    this.bookBetService
      .getBookBet(route.params.bookingCode)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (!this.couponQuery.bookedBetData.hasExpiredEvents) {
          if (this.couponQuery.bookedBetData.bookedCoupon) {
            this.applicationService.showCoupon();
            this.notificationService.showSuccessNotification(
              $localize`Successfully loaded booked coupon ${this.couponQuery.bookedBetData.bookCode.toLocaleUpperCase()}`
            );
          } else {
            this.notificationService.showWarningNotification(
              $localize`Booked coupon ${this.couponQuery.bookedBetData.bookCode.toLocaleUpperCase()} was not found`
            );
          }
        }
        if (route.url.some(seg => seg.path === 'book-bet')) {
          window.history.replaceState({}, document.title, '/');
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
