import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { APIType } from 'src/app/shared/models/api.model';
import { MobileVerificationContentResponseData } from 'src/app/shared/models/mobile-verification-campaign/mobile-verification-campaign.model';

@Injectable({
  providedIn: 'root',
})
export class MobileVerificationCampaignService {
  constructor(private readonly apiService: APIService) {}

  getContent(): Observable<MobileVerificationContentResponseData> {
    return this.apiService
      .get(APIType.CMS, 'MobileVerificationCampaign/MobileVerificationCampaignContent')
      .pipe(map(response => this.mapContent(response)));
  }

  private mapContent(response: any): MobileVerificationContentResponseData {
    return {
      alreadyVerified: {
        header: response.mobileAlreadyVerifiedHeader,
        ctaText: response.mobileAlreadyVerifiedCTAText,
        ctaURL: response.mobileAlreadyVerifiedCTAURL,
      },
      mobileInput: {
        phoneNumberInputHeader: response.mobileInputHeader,
        phoneNumberInputMessage: response.mobileInputMessage,
        phoneVerificationSendCodeCTAText: $localize`SEND VERIFICATION CODE`,
      },
      numberVerified: {
        bodyHTML: response.mobileVerifiedSuccessMessage,
        ctaText: response.mobileVerifiedSuccessCTAText,
        ctaURL: response.mobileVerifiedSuccessCTAURL,
        header: response.mobileVerifiedSuccessHeader,
      },
    };
  }
}
