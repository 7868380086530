import { Component, Input, Output, HostListener, EventEmitter } from '@angular/core';
import { SportsBreadcrumbService } from 'src/app/modules/sport/services/sports-breadcrumb.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SportBreadcrumb } from 'src/app/shared/models/sport.model';
import { SportQuery } from 'src/app/core/state/sport/sport.query';
@Component({
  selector: 'app-sports-breadcrumb',
  templateUrl: './sports-breadcrumb.component.html',
  styleUrls: ['./sports-breadcrumb.component.scss'],
})
export class SportsBreadcrumbComponent {
  @Input() breadcrumbs: SportBreadcrumb[];
  @Input() readonly headerPinToTop: boolean = false;
  @Output() readonly backButtonClick = new EventEmitter();

  readonly openedBreadcrumbSection$ = this.sportQuery.openedBreadcrumbSection$;

  constructor(
    private readonly location: Location,
    private readonly router: Router,
    private readonly sportQuery: SportQuery,
    private readonly sportsBreadcrumbService: SportsBreadcrumbService
  ) {}

  @HostListener('window:scroll', ['$event']) onWindowScroll(): void {
    this.closeDropdown();
  }

  goBack(): void {
    if (this.backButtonClick.observers.length > 0) {
      this.backButtonClick.emit();
    } else {
      this.location.back();
    }
  }

  goToPage(item): void {
    this.closeDropdown();
    this.router.navigateByUrl(item.url);
  }

  closeDropdown(): void {
    this.sportsBreadcrumbService.closeDropdown();
  }
}
