import { Injectable } from '@angular/core';

import { Store, StoreConfig } from '@datorama/akita';

import { ApplicationState, CmsModel, EmbedConfig, LanguageModel, LoaderState } from 'src/app/shared/models/application.model';
import { ProductType } from 'src/app/shared/models/product.model';
import { MenuItemModel, MenuTab, MenuTabModel } from 'src/app/shared/models/menu.model';

export const createInitialApplicationState = (): ApplicationState => ({
  brandId: undefined,
  ui: {
    inCoupon: false,
    inMyBets: false,
    isShowingAccumulatorBonusUnacceptableSelectionsPrompt: false,
    isShowingNavbarBetslipAccumulatorBonusBar: false,
    isShowingNavbarBetslipSelections: false,
    isSportsSection: false,
    isZohoLiveChatLoading: false,
    isInstant: false,
    isVirtuals: false,
    loader: {
      show: false,
      isFullscreen: true,
      text: '',
    },
    landingMenuExpanded: false,
    loadingQuicklinks: false,
    loadingSFKChat: false,
    loginState: 'Login',
    scrollY: 0,
    showAccountMenu: false,
    showCoupon: false,
    showDropdownOverlay: false,
    showLogin: false,
    showLoginDialog: false,
    showMenu: false,
    showMyBets: false,
    showNavbar: true,
    showQuickCoupon: false,
    showSFKChat: false,
  },
  sideBarQuickLinks: [],
  sideBarMenu: [
    {
      title: 'A-Z Sports',
      type: MenuTab.SportsAZ,
    },
    {
      title: 'Promos',
      type: MenuTab.Promos,
    },
    {
      title: 'Virtual',
      type: MenuTab.Virtuals,
    },
    {
      title: 'Quicklinks',
      type: MenuTab.QuickLinks,
    },
  ],
  activeUrl: undefined,
  siteMessages: {
    landing: [],
    sports: [],
  },
  cms: {
    cacheTTL: undefined,
    fallbackTimer: 10,
    lastQuicklinksUpdate: undefined,
    lastSportsBannerUpdate: undefined,
    lastPaymentsBannerUpdate: undefined,
    lastLandingPageMenuItemsUpdate: undefined,
    lastLandingPageWidgetsUpdate: undefined,
    lastSidebarQuicklinksUpdate: undefined,
    lastLivePageMarketGroupingDetailsUpdate: undefined,
    lastUnreadMessagesUpdate: undefined,
  },
  selectedLanguage: undefined,
  activeProduct: ProductType.SportsBook,
  activeVirtualsLeague: undefined,
  embedConfig: undefined,
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'application' })
export class ApplicationStore extends Store<ApplicationState> {
  constructor() {
    super(createInitialApplicationState());
  }

  updateBrandId(brandId: number): void {
    this.update({ brandId });
  }

  updateUI(ui: Partial<ApplicationState['ui']>): void {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui,
      },
    }));
  }

  updateCms(cms: Partial<CmsModel>): void {
    this.update(state => ({
      cms: {
        ...state.cms,
        ...cms,
      },
    }));
  }

  updateSiteMessages(siteMessages: Partial<ApplicationState['siteMessages']>): void {
    this.update(state => ({
      siteMessages: {
        ...state.siteMessages,
        ...siteMessages,
      },
    }));
  }

  updateLoader(loader: Partial<LoaderState>): void {
    this.update(state => ({
      ...state,
      ui: {
        ...state.ui,
        loader: {
          ...state.ui.loader,
          ...loader,
        },
      },
    }));
  }

  closeSlideUp(type: string): void {
    const ui = {};
    ui[type] = false;
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui,
      },
    }));
  }

  closeAnySlideUps(): void {
    const DEFAULT_UI = createInitialApplicationState().ui;
    const ui = {
      showMenu: DEFAULT_UI.showMenu,
      showAccountMenu: DEFAULT_UI.showAccountMenu,
      showLogin: DEFAULT_UI.showLogin,
      showCoupon: DEFAULT_UI.showCoupon,
      showQuickCoupon: DEFAULT_UI.showQuickCoupon,
      showMyBets: DEFAULT_UI.showMyBets,
      showDropdownOverlay: DEFAULT_UI.showDropdownOverlay,
    };
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui,
      },
    }));
  }

  updateSidebarQuickLinks(sideBarQuickLinks: MenuItemModel[]): void {
    this.update({ sideBarQuickLinks });
  }

  updateSideBarMenu(sideBarMenu: MenuTabModel[]): void {
    this.update({ sideBarMenu });
  }

  updateActiveUrl(activeUrl: string[]): void {
    this.update({ activeUrl });
  }

  updateSelectedLanguage(selectedLanguage: LanguageModel): void {
    this.update({ selectedLanguage });
  }

  updateEmbedConfig(embedConfig: EmbedConfig): void {
    this.update({ embedConfig });
  }
}
