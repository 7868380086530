/**
 * This file will host any API helper methods that are common between all my-bets product services
 */

import { format } from 'date-fns';
import { APIMyBetsCouponStatus, MyBetsCouponStatus, RecentBetsStatus } from 'src/app/modules/my-bets/models/my-bets-enums.model';
import {
  EventOddsModel,
  RecentBetModel,
  SportVirtualBetDetailsModel,
  SportVirtualEventModel,
} from 'src/app/modules/my-bets/models/my-bets.model';
import { BetFinalState, CouponStatus } from 'src/app/shared/models/coupon-details.model';

export function mapCouponStatus(couponStatus: MyBetsCouponStatus): APIMyBetsCouponStatus {
  switch (couponStatus) {
    case MyBetsCouponStatus.Open:
      return APIMyBetsCouponStatus.Open;
    case MyBetsCouponStatus.Settled:
      return APIMyBetsCouponStatus.Settled;
    default:
      return APIMyBetsCouponStatus.Settled;
  }
}

export function mapGetMyBetsCallResponse(response: any, isOpen: boolean = true): RecentBetModel[] {
  const retVal = [];

  response.Coupons.forEach(data => {
    if (
      data.CouponStatusId !== CouponStatus.SystemEvaluation &&
      data.CouponStatusId !== CouponStatus.UserEvaluation &&
      !(isOpen && data.CouponStatusId === 3)
    ) {
      retVal.push(
        new RecentBetModel({
          couponCode: data.CouponCode,
          betFinalState: data.BetFinalState,
          couponDate: format(new Date(data.CouponDate), 'd MMM yyyy HH:mm:ss'),
          couponType: data.CouponType,
          couponTypeId: data.CouponTypeId,
          couponStatusId: data.CouponStatusId,
          stakeGross: data.StakeGross,
          totalOdds: data.TotalOdds,
          totalCombinations: data.TotalCombinations,
          minOdd: data.MinOdd,
          maxOdd: data.MaxOdd,
          won: data.Won,
          maxWinNet: data.MaxWinNet,
          userId: data.UserId,
          selectionCount: data.SelectionCount,
          jackpotId: data.JackpotId,
          jackpotWinnings: data.JackpotValue,
          freebetsVoucher: data.BetDetails?.FreeBetDetails && {
            code: data.BetDetails.FreeBetDetails.Code,
            name: data.BetDetails.FreeBetDetails.Name,
            type: data.BetDetails.FreeBetDetails.Type,
          },
          flexicutDetails: data.BetDetails?.FlexiCutDetails && {
            Cut: data.BetDetails.FlexiCutDetails.Cut,
          },
          virtualsProductType: data.Product,
        })
      );
    }
  });

  return retVal;
}

export function parseOddResultState(oddResponseState: number) {
  switch (oddResponseState) {
    case -1:
      return RecentBetsStatus.Cancelled;
    case 0:
      return RecentBetsStatus.Lost;
    case 1:
      return RecentBetsStatus.Won;
    case 3:
      return RecentBetsStatus.HalfLost;
    case 4:
      return RecentBetsStatus.HalfWon;
    default:
      return RecentBetsStatus.Running;
  }
}

export function mapGetBetDetailsCallResponse(response: any): SportVirtualBetDetailsModel {
  return {
    couponType: response.CouponType,
    totalOdds: response.TotalOdds,
    totalCombinations: response.TotalCombinations,
    minWin: response.MinWin,
    maxWin: response.MaxWin,
    netStakeMinWin: response.NetStakeMinWin,
    netStakeMaxWin: response.NetStakeMaxWin,
    maxWinNet: response.MaxWinNet,
    minWithholdingTax: response.MinWithholdingTax,
    maxWithholdingTax: response.MaxWithholdingTax,
    minBonus: response.MinBonus,
    maxBonus: response.MaxBonus,
    minPercentageBonus: response.MinBonusPerc,
    maxPercentageBonus: response.MaxBonusPerc,
    turnoverTax: response.TurnoverTax,
    won: response.Won,
    wonTax: response.TotalTaxed,
    events: groupOddsWithSameEventAndMarket(response),
    minOdd: response.MinOdd,
    maxOdd: response.MaxOdd,
    virtualsProductType: response.Product,
  };
}

function groupOddsWithSameEventAndMarket(response: any) {
  const betEvents: SportVirtualEventModel[] = [];

  if (response.Odds) {
    const eventsMap = new Map<string, { event: any; odds: EventOddsModel[] }>();
    response.Odds.forEach(odd => {
      const oddDetails: EventOddsModel = {
        oddStatusId: response.BetFinalState === BetFinalState.Cashout ? RecentBetsStatus.CashedOut : parseOddResultState(odd.Win),
        marketName: odd.MarketName,
        selectionName: odd.SelectionName,
        isBoosted: !!odd.UnboostedOddValue && odd.UnboostedOddValue < odd.OddValue,
        unboostedOddValue: odd.UnboostedOddValue,
        oddValue: odd.OddValue,
        isBanker: odd.FixedOdd,
        marketTypeId: odd.IDMarketType,
      };

      const mapKey = `${odd.IDEvent}_${odd.MarketName}`;

      const foundEventOdds = eventsMap.has(mapKey) ? eventsMap.get(mapKey).odds : [];

      foundEventOdds.push(oddDetails);
      eventsMap.set(mapKey, { event: odd, odds: foundEventOdds });
    });

    eventsMap.forEach(value => {
      const event: SportVirtualEventModel = {
        championship: value.event.Championship,
        categoryName: value.event.CategoryName,
        eventId: value.event.IDEvent,
        eventName: value.event.EventName ? value.event.EventName.trim() : '',
        homeTeamName: value.event.HomeTeam ? value.event.HomeTeam.trim() : '',
        awayTeamName: value.event.AwayTeam ? value.event.AwayTeam.trim() : '',
        marketOutright: value.event.MarketOutright,
        isGoalScorer: value.event.IsGoalScorer,
        sportId: value.event.IDSport,
        eventDate: value.event.EventDate,
        eventStatusId: parseOddResultState(value.event.Win),
        isLive: value.event.EventCategory === 'L',
        halfTimeScore: calculateHTScore(value.event.Results),
        fullTimeScore: calculateFTScore(value.event.Results),
        result: value.event.Result,
        odds: value.odds,
      };

      if (value.odds.length > 1 && value.odds.some(odd => odd.oddStatusId === 1)) {
        // if any odd is won, set the main state as won
        event.eventStatusId = RecentBetsStatus.Won;
      }

      betEvents.push(event);
    });
  }
  return betEvents;
}

function calculateHTScore(results: any) {
  if (results && results.length > 0) {
    const halfTimeHome = getResults(results, 'HT1');
    const halfTimeAway = getResults(results, 'HT2');
    return halfTimeHome !== undefined && halfTimeAway !== undefined ? `${halfTimeHome}-${halfTimeAway}` : undefined;
  } else {
    return undefined;
  }
}

function calculateFTScore(results: any) {
  if (results && results.length > 0) {
    const fullTimeHome = getResults(results, 'FT1');
    const fullTimeAway = getResults(results, 'FT2');
    return fullTimeHome !== undefined && fullTimeAway !== undefined ? `${fullTimeHome}-${fullTimeAway}` : undefined;
  } else {
    return undefined;
  }
}

function getResults(results: any[], resultKey: string): string {
  const result = results.find(item => item.Symbol === resultKey);
  return result ? result.Value : undefined;
}
