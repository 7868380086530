<app-breadcrumb i18n-title title="Book Bet"></app-breadcrumb>
<app-card>
  <div class="booked-bet" body>
    <app-booked-bet-input
      *ngIf="!(this.couponQuery.bookedBetData$ | async)?.hasExpiredEvents"
      (submitCode)="bookBet($event)"
      (cancel)="onCancel()"
    >
    </app-booked-bet-input>
    <app-expired-booked-bet-prompt
      *ngIf="(this.couponQuery.bookedBetData$ | async)?.hasExpiredEvents"
      [bookedBetData]="(this.couponQuery.bookedBetData$ | async)"
      (cancel)="onCancel()"
      (confirm)="onExpiredSubmit()"
    >
    </app-expired-booked-bet-prompt>
    <div class="saved-coupons-container" *ngIf="(couponQuery.bookedCoupons$ | async)">
      <app-saved-coupons></app-saved-coupons>
    </div>
  </div>
</app-card>
