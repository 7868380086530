import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { LoginService } from 'src/app/core/services/account/login.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { InsufficientBalanceService } from 'src/app/core/services/insufficient-balance.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { NativeUserDetailsService } from 'src/app/modules/native-app/services/native-user-details.service';
import { LoginResponseModel } from 'src/app/shared/models/account.model';

@Injectable({
  providedIn: 'root',
})
export class SecureLoginService implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    readonly accountQuery: AccountQuery,
    private readonly appConfig: AppConfigService,
    private readonly insufficientBalanceService: InsufficientBalanceService,
    private readonly loginService: LoginService,
    private readonly nativeUserDetailsService: NativeUserDetailsService,
    private readonly router: Router
  ) {}

  reLogin(logout: Function): void {
    this.nativeUserDetailsService.getUser().subscribe((data: any) => {
      const savedUser = this.nativeUserDetailsService.verifyUsersData(data);
      if (savedUser === false) {
        logout();
        return;
      } else {
        this.loginService.login(savedUser.name, savedUser.password, false, false).subscribe(
          (response: LoginResponseModel) => {
            if (!response || !response.success) {
              // Although the call was successfully done, it might still return an error state
              logout();
            } else {
              // Call was successfully done, and returned a sucecssfull state
              this.successfulLogin(this.router);
            }
          },
          () => {
            logout();
          }
        );
      }
    });
  }

  successfulLogin(router: any): void {
    if (this.accountQuery.userData && this.accountQuery.userData.userStatus === 'PCHANG') {
      router.navigate(['account/password-reset']);
    } else if (
      this.accountQuery.userData &&
      (this.accountQuery.userData.userStatus !== 'UNCONF' || this.appConfig.get('otp').allowUnconfirmedUsers) &&
      !this.accountQuery.verifyAccountState
    ) {
      this.insufficientBalanceService.checkBalance();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
