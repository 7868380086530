import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { fadeInByStaggered, fadeInOutBy } from 'src/app/shared/animations';
import { SportModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-sport-list',
  templateUrl: './sports-list.component.html',
  styleUrls: ['./sports-list.component.scss'],
  animations: [fadeInByStaggered(), fadeInOutBy()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SportsListComponent {
  @Input() sports: SportModel[] = [];
  @Input() title?: string;
  @Input() selectedSport?: SportModel;

  isSportSelected(sport: SportModel): boolean {
    return this.selectedSport && sport.id === this.selectedSport.id;
  }

  sportsTrackBy(index: number, sport: SportModel): number {
    return sport.id;
  }
}
