import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ApplicationService } from 'src/app/core/services/application.service';
import { HeaderQuery } from 'src/app/modules/header/state/header.query';

@Component({
  selector: 'header-bk-guest',
  templateUrl: './bk-guest-header.component.html',
  styleUrls: ['./bk-guest-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BKGuestHeaderComponent {
  readonly content$ = this.headerQuery.guestHeaderContent$;
  protected readonly document = document;
  constructor(private readonly applicationService: ApplicationService, private readonly headerQuery: HeaderQuery) {}

  openLoginDialog(): void {
    const isAppLoginDialogEnabled: boolean = 
      typeof window.parent.isAppLoginDialogEnabled === 'boolean' && 
      window.parent.isAppLoginDialogEnabled;

    if (isAppLoginDialogEnabled) {
      window.parent.sendEventToApp('openNativeScreen', 'loginDialog', {});
    } else {
      this.applicationService.setShowLoginDialog(true);
    }
  }
}