import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ApplicationService } from 'src/app/core/services/application.service';
import { BonusService } from 'src/app/core/services/bonuses/bonus.service';
import { MenuService } from 'src/app/core/services/menu.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { MenuQuery } from 'src/app/core/state/menu/menu.query';
import { MenuTab } from 'src/app/shared/models/menu.model';

@Component({
  selector: 'app-menu-tabs',
  templateUrl: './menu-tabs.component.html',
  styleUrls: ['./menu-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuTabsComponent implements OnInit {
  menuTab = MenuTab;
  menuTabsIcons = {
    0: 'sports-atoz',
    1: 'bonus',
    2: 'virtuals',
    3: 'quicklinks',
    4: 'how-to-play',
  };

  constructor(
    private readonly menuService: MenuService,
    private readonly bonusService: BonusService,
    readonly applicationQuery: ApplicationQuery,
    readonly applicationService: ApplicationService,
    readonly menuQuery: MenuQuery,
    readonly accountQuery: AccountQuery
  ) {}

  ngOnInit(): void {
    if (!this.accountQuery.hasBonusInfo) {
      this.bonusService.getBonuses().subscribe();
    }

    this.applicationService.getSideMenuTabs();
  }

  changeTab(tab: MenuTab): void {
    this.menuService.changeTab(tab);
  }
}
