<div class="jackpot-banner" #bannerContainer
     *ngIf="(jackpotBannerItems$ | async)?.length"
     [class.virtuals-jackpot-banner-initial]="initialState"
     [class.in-game-banner]="inGameBanner"
>
<div class="jackpot-banner-content">
    <div class="jackpot-banner-slides">
      <div class="jackpot-banner-slides-slide" *ngFor="let banner of (jackpotBannerItems$ | async); let i = index" [class.active]="i === currentIndex">
        <virtuals-jackpot-banner-item
          [jackpotAmount]="banner.jackpotAmount"
          [jackpotDropInfo]="banner.jackpotDropInfo"
          [jackpotInfo]="banner.jackpotInfo"
          [jackpotClass]="banner.jackpotSlug"
          [jackpotLogoUrl]="banner.jackpotLogo"
          [latestJackpotUrl]="banner.latestJackpotUrl"
          [jackpotNextDrop]="banner.jackpotNextDrop"
          [jackpotDropAmount]="banner.jackpotDropAmount"
          [calculateByDropAmount]="banner.calculateProgressBarByDropAmount"
          [inGameBanner]="inGameBanner"
        >
        </virtuals-jackpot-banner-item>
      </div>
    </div>
    <div class="jackpot-banner-pagination" *ngIf="!inGameBanner">
    <span *ngFor="let banner of (jackpotBannerItems$ | async); let i = index"
          class="jackpot-banner-pagination-item"
          [class.active]="i === currentIndex"></span>
    </div>
  </div>
</div>
