import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AndroidSettingsQuery } from 'src/app/modules/native-app/state/android-settings.query';

@Component({
  selector: 'app-native-wrapper',
  templateUrl: './app-native-wrapper.component.html',
  styleUrls: ['./app-native-wrapper.component.scss'],
})
export class NativeWrapperComponent {
  showWebView$ = this.androidSettingsQuery.useWebViewMode$.pipe(map(select => select));
  showOfflineMode$ = this.androidSettingsQuery.useOfflineMode$.pipe(map(select => select));
  forceUpdate$ = this.androidSettingsQuery.forceUpdate$.pipe(map(select => select));
  appDownloadLink = this.appConfig.get('nativeApp').appDownloadUrl.toString();
  websiteUrl = this.appConfig.get('nativeApp').appUrl.toString();

  constructor(private readonly appConfig: AppConfigService, readonly androidSettingsQuery: AndroidSettingsQuery) {}
}
