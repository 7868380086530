<swal #phoneVerification [swalOptions]="options">
  <div *swalPortal class="phone-verification-popup-container">
    <div class="title-header" *ngIf="verifyData.type !== verifyAccountType.Locked">
      <i class="fa fa-warning"></i>
      <span i18n>Important</span>
    </div>
    <div class="title-header" *ngIf="verifyData.type === verifyAccountType.Locked">
      <i class="fa fa-lock"></i>
      <span i18n>Account Locked!</span>
    </div>

    <div class="description" *ngIf="verifyData.type === verifyAccountType.VerifyInProgress">
      <div i18n>Please note that the phone number associated with this account has not yet been verified.</div>
      <div i18n>Select an option below to proceed.</div>
    </div>
    <div class="description" *ngIf="verifyData.type === verifyAccountType.AlreadyRegistered">
      <div i18n>Your Highness,</div>
      <div><b>{{ verifyData.mobileNumber }}</b><span i18n> is already registered on another account. If you choose to proceed,
        all other accounts associated with this phone number will become inactive.</span></div>
    </div>
    <div class="description" *ngIf="verifyData.type === verifyAccountType.Locked">
      <div i18n>Your account is locked as the registered phone number is already verified against another online
        account. To unlock your account, you may update your phone number or contact customer support.</div>
    </div>

    <div class="buttons">
      <button class="btn" *ngIf="verifyData.type === verifyAccountType.VerifyInProgress"
        (click)="verifyExistingPhoneNumber()">
        <span class="btn-text" i18n>Verify Existing Phone Number</span>
      </button>
      <button class="btn" *ngIf="verifyData.type === verifyAccountType.AlreadyRegistered"
        (click)="verifyData.callback(); closePopup();">
        <span class="btn-text" i18n>Complete Registration</span>
      </button>
      <button class="btn" *ngIf="verifyData.type === verifyAccountType.Locked"
        (click)="navigateTo('/account/change-phone-number')">
        <span class="btn-text" i18n>Update Phone Number</span>
      </button>
      <div class="separator" *ngIf="verifyData.type !== verifyAccountType.AlreadyRegistered" i18n>or</div>
      <button class="btn" *ngIf="verifyData.type === verifyAccountType.VerifyInProgress"
        (click)="navigateTo('/account/change-phone-number')">
        <span class="btn-text" i18n>Change Phone Number</span>
      </button>
      <button class="btn cancel" *ngIf="verifyData.type === verifyAccountType.AlreadyRegistered" (click)="closePopup()">
        <span class="btn-text" i18n>Cancel</span>
      </button>
    </div>

    <div class="footer">
      <div *ngIf="verifyData.type === verifyAccountType.VerifyInProgress">
        <span i18n>Please verify your account within</span>
        <ng-container *ngIf="verifyData.daysLeft !== 0">
          <span class="bold">{{ verifyData.daysLeft }}</span><span i18n> days</span>
        </ng-container>
        <ng-container *ngIf="verifyData.daysLeft !== 0 && verifyData.hoursLeft !== 0" i18n> and </ng-container>
        <ng-container *ngIf="verifyData.hoursLeft !== 0">
          <span class="bold">{{ verifyData.hoursLeft }}</span><span i18n> hours</span>
        </ng-container>
        <span i18n>so it does not become inactive.</span>
      </div>
      <div i18n *ngIf="verifyData.type === verifyAccountType.Locked">
        <span class="bold contact-btn" (click)="navigateTo('/help/contact-us')">Contact</span> customer
        support.
      </div>
    </div>
  </div>
</swal>
