import { Pipe, PipeTransform } from '@angular/core';
import { SortType } from 'src/app/modules/sport/models/todays-events/todays-events.model';
import { TodaysEventsQuery } from 'src/app/modules/sport/state/todays-events/todays-events.query';
import { MarketModel, MatchModel } from 'src/app/shared/models/sport.model';
import { orderBy } from 'lodash-es';

@Pipe({
  name: 'sortTodaysEventsByOdd',
})
export class SortTodaysEventsByOddPipe implements PipeTransform {
  constructor(private readonly todaysEventsQuery: TodaysEventsQuery) {}

  transform(array: any[], sortType: SortType, selectedMarket: MarketModel = this.todaysEventsQuery.selectedMarket): any {
    const isItPlayerView = array[0].length === 3;
    const arrayCopy = [...array];

    let sortedArray: MatchModel[];
    if (sortType === SortType.ByTime) {
      return orderBy(array, 'date');
    }
    if (!isItPlayerView) {
      if (sortType === SortType.ByOddsLowestFirst) {
        sortedArray = arrayCopy.sort((aItem: MatchModel, bItem: MatchModel) => {
          let lowestOddA = 100;
          let lowestOddB = 100;
          aItem.odds.forEach(odd => {
            if (odd.marketName === selectedMarket.name) {
              if (odd.value < lowestOddA) {
                lowestOddA = odd.value;
              }
            }
          });
          bItem.odds.forEach(odd => {
            if (odd.marketName === selectedMarket.name) {
              if (odd.value < lowestOddB) {
                lowestOddB = odd.value;
              }
            }
          });
          if (lowestOddA < lowestOddB) {
            return -1;
          }
          if (lowestOddA > lowestOddB) {
            return 1;
          }
          return 0;
        });
      } else if (sortType === SortType.ByOddsHighestFirst) {
        sortedArray = arrayCopy.sort((aItem: MatchModel, bItem: MatchModel) => {
          let highestOddA = 0;
          let highestOddB = 0;
          aItem.odds.forEach(odd => {
            if (odd.marketName === selectedMarket.name) {
              if (odd.value > highestOddA) {
                highestOddA = odd.value;
              }
            }
          });
          bItem.odds.forEach(odd => {
            if (odd.marketName === selectedMarket.name) {
              if (odd.value > highestOddB) {
                highestOddB = odd.value;
              }
            }
          });
          if (highestOddA > highestOddB) {
            return -1;
          }
          if (highestOddA < highestOddB) {
            return 1;
          }
          return 0;
        });
      }
    } else if (isItPlayerView) {
      if (sortType === SortType.ByOddsLowestFirst) {
        sortedArray = arrayCopy.sort((aItem: MatchModel, bItem: MatchModel) => {
          let lowestOddA = 100;
          let lowestOddB = 100;
          aItem[0].data.forEach(item => {
            item.odds.forEach(odd => {
              if (odd.marketName === selectedMarket.name) {
                if (odd.value < lowestOddA) {
                  lowestOddA = odd.value;
                }
              }
            });
          });
          aItem[1].data.forEach(item => {
            item.odds.forEach(odd => {
              if (odd.marketName === selectedMarket.name) {
                if (odd.value < lowestOddA) {
                  lowestOddA = odd.value;
                }
              }
            });
          });
          bItem[0].data.forEach(item => {
            item.odds.forEach(odd => {
              if (odd.marketName === selectedMarket.name) {
                if (odd.value < lowestOddB) {
                  lowestOddB = odd.value;
                }
              }
            });
          });
          bItem[1].data.forEach(item => {
            item.odds.forEach(odd => {
              if (odd.marketName === selectedMarket.name) {
                if (odd.value < lowestOddB) {
                  lowestOddB = odd.value;
                }
              }
            });
          });
          if (lowestOddA < lowestOddB) {
            return -1;
          }
          if (lowestOddA > lowestOddB) {
            return 1;
          }
          return 0;
        });
      } else {
        sortedArray = arrayCopy.sort((aItem: MatchModel, bItem: MatchModel) => {
          let highestOddA = 100;
          let highestOddB = 100;
          aItem[0].data.forEach(item => {
            item.odds.forEach(odd => {
              if (odd.marketName === selectedMarket.name) {
                if (odd.value > highestOddA) {
                  highestOddA = odd.value;
                }
              }
            });
          });
          aItem[1].data.forEach(item => {
            item.odds.forEach(odd => {
              if (odd.marketName === selectedMarket.name) {
                if (odd.value > highestOddA) {
                  highestOddA = odd.value;
                }
              }
            });
          });
          bItem[0].data.forEach(item => {
            item.odds.forEach(odd => {
              if (odd.marketName === selectedMarket.name) {
                if (odd.value > highestOddB) {
                  highestOddB = odd.value;
                }
              }
            });
          });
          bItem[1].data.forEach(item => {
            item.odds.forEach(odd => {
              if (odd.marketName === selectedMarket.name) {
                if (odd.value > highestOddB) {
                  highestOddB = odd.value;
                }
              }
            });
          });
          if (highestOddA < highestOddB) {
            return -1;
          }
          if (highestOddA > highestOddB) {
            return 1;
          }
          return 0;
        });
      }
    }

    return sortedArray ? sortedArray : arrayCopy;
  }
}
