<div class="bet-insight-card">
  <ng-container *ngIf="showInfo">
    <div class="info" (click)="navigateToMatch(betInsight.fixtureId, betInsight.fixtureName)">
      <div class="team">{{betInsight.fixtureName}}</div>
      <div class="time">
        {{betInsight.fixtureDate | todayDate}}
        {{betInsight.fixtureDate | date:'HH:mm'}}
      </div>
    </div>
    <div class="divider"></div>
  </ng-container>
  <div class="detail">
    <div class="insight">
      <div class="title">
        <div>{{betInsight.marketTypeName}}</div>
        <div class="popularity" *ngIf="betInsight.popularity">
          <i></i>
          <span>{{betInsight.popularity}}</span>
        </div>
      </div>
      <div class="content">{{betInsight.insightDescription}}</div>
    </div>
    <div class="odds">
      <div class="odd" *ngFor="let odd of betInsight.odds">
        <app-odd [odd]="odd" [eventLocation]="eventLocation"></app-odd>
      </div>
    </div>
  </div>
</div>
