<div class="cashout-container" *ngIf="item$ | async as item" [@expandCollapseSimpler]>
  <div *ngIf="item.betFinalState === betFinalState.Placed">
    <!-- Cashout requested section -->
    <div class="content" *ngIf="item.cashoutStatusTab === cashoutStatusTab.Requested">
      <div class="cash-out-details">
        <button class="cash-out-button disabled">
          <div class="label" i18n>Cash Out In Evaluation</div>
        </button>
        <span class="cash-out-spinner"></span>
      </div>
    </div>
    <!-- Cashout available section -->
    <!-- enableCashout$ - has been moved to the parent node to avoid empty padding since the disclaimer has been hidden below -->
    <div class="content" *ngIf="(enableCashout$ | async) && item.cashoutStatusTab === cashoutStatusTab.Available">
      <div class="cash-out-details">
        <button
          class="cash-out-button"
          (click)="handleCashout(item)"
          [class.disabled]="
            item.cashoutInProgress || item.cashoutRefreshing || (enableCashout$ | async) === false || item.betCashout.allowCashout === false
          "
        >
          <div class="label" i18n>Cash Out:</div>
          <div class="value">{{ item.betCashout.valueNet | currencyFormat }}</div>
        </button>
        <button class="cash-out-refresh material-icons" (click)="handleRefreshCashout(item)">{{ 'refresh' }}</button>
      </div>

      <!-- this section has been hidden via CSS as requested -->
      <div class="cashout-unavailable-disclaimer" *ngIf="(enableCashout$ | async) === false">
        <!-- Cashout blocked disclaimer section -->
        <span *ngIf="(cashoutWhitelist$ | async) === false" i18n>Account not eligible for cashout</span>
        <span
          i18n
          *ngIf="
            (cashoutWhitelist$ | async) &&
            ((cashoutLoggedIn$ | async) === false || (cashoutSameUser$ | async) === false) &&
            (accountQuery.isShopOwner$ | async) === false
          "
        >
          Please log in with coupon user's account to cashout
        </span>
        <span
          i18n
          *ngIf="(cashoutWhitelist$ | async) && (accountQuery.isShopOwner$ | async) && (cashoutShopOwnerSubUser$ | async) === false"
        >
          Coupon not created by a user of your shop
        </span>
      </div>
    </div>
    <!-- Cashout unavailable section or Running Market -->
    <!-- <div class="content" *ngIf="item.cashoutStatusTab === cashoutStatusTab.Unavailable || item.betCashout.result === 312">
      <div class="cash-out-details">
        <button class="cash-out-button disabled">
          <div class="label" i18n>Cash Out Unavailable</div>
        </button>
        <button class="cash-out-info material-icons" (click)="toggleMoreInfo()">{{ 'info' }}</button>
      </div>

      <div class="cash-out-value" *ngIf="showMoreInfo$ | async" [@expandCollapseSimpler]>
        <ng-container *ngIf="item.betCashout.result === 312; else message">
          <div class="label" i18n>Running market not allowed for cashout</div>
        </ng-container>
        <ng-template #message>
          <div class="label">{{ item.betCashout.message }}</div>
        </ng-template>
      </div>
    </div> -->
  </div>
</div>

<ng-container
  *ngIf="{
    isCashoutConfirmModalOpen: isCashoutConfirmModalOpen$ | async,
    activeCashout: activeCashout$ | async,
    isPerformingCashout: isPerformingCashout$ | async,
    cashoutConfirmResponseModalState: cashoutConfirmResponseModalState$ | async,
    cashoutConfirmResponseModalDetails: cashoutConfirmResponseModalDetails$ | async
  } as cashoutModalSubs"
>
  <div class="cashout-modal-container" *ngIf="cashoutModalSubs.isCashoutConfirmModalOpen">
    <div class="cashout-modal">
      <ng-container [ngSwitch]="cashoutModalSubs.cashoutConfirmResponseModalState">
        <ng-container *ngSwitchCase="'noresponse'">
          <div *ngIf="!!cashoutModalSubs.activeCashout.oldBetCashout; else noChanges">
            <div i18n>The cashout offer on this event has changed</div>
            <div class="grid">
              <div class="previous-offer" i18n>Previous Offer:</div>
              <div class="previous-offer cashout-value">
                {{
                  (cashoutModalSubs.activeCashout.oldBetCashout.tax > 0
                    ? cashoutModalSubs.activeCashout.oldBetCashout.valueNet
                    : cashoutModalSubs.activeCashout.oldBetCashout.value
                  ) | currencyFormat
                }}
              </div>
              <div class="new-offer" i18n>New Offer:</div>
              <div class="new-offer cashout-value">
                <i
                  *ngIf="cashoutModalSubs.activeCashout.betCashout.value > cashoutModalSubs.activeCashout.oldBetCashout.value"
                  class="material-icons icon"
                  >{{ 'arrow_drop_up' }}</i
                >
                <i
                  *ngIf="cashoutModalSubs.activeCashout.betCashout.value < cashoutModalSubs.activeCashout.oldBetCashout.value"
                  class="material-icons icon"
                  >{{ 'arrow_drop_down' }}</i
                >
                {{
                  (cashoutModalSubs.activeCashout.betCashout.tax > 0
                    ? cashoutModalSubs.activeCashout.betCashout.valueNet
                    : cashoutModalSubs.activeCashout.betCashout.value
                  ) | currencyFormat
                }}
              </div>
            </div>
          </div>
          <div class="actions">
            <button class="cashout-modal-button cancel" (click)="handleCloseCashoutConfirm()" i18n>CANCEL</button>
            <button
              class="cashout-modal-button proceed"
              [class.disabled]="cashoutModalSubs.isPerformingCashout"
              (click)="!isPerformingCashout && handleCashoutConfirm(cashoutModalSubs.activeCashout)"
            >
              <span i18n>CASH OUT NOW</span>
            </button>
          </div>
          <ng-template #noChanges>
            <span i18n>
              Do you wish to cashout at a value of
              {{
                (cashoutModalSubs.activeCashout.betCashout.tax > 0
                  ? cashoutModalSubs.activeCashout.betCashout.valueNet
                  : cashoutModalSubs.activeCashout.betCashout.value
                ) | currencyFormat
              }}?
            </span>
            <span *ngIf="cashoutModalSubs.activeCashout.betCashout.tax > 0">
              (<span i18n> Tax: </span>{{ cashoutModalSubs.activeCashout.betCashout.tax | currencyFormat }})
            </span>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'success'">
          <div i18n class="cashout-confirm-modal-text success">
            <span>{{ cashoutModalSubs.cashoutConfirmResponseModalDetails }}</span> cashout successful!
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'refresh'">
          <div i18n>The cashout offer has expired, please refresh to receive a new offer.</div>
          <div class="actions">
            <button class="cashout-modal-button cancel" (click)="handleCloseCashoutConfirmResponseModal()" i18n>CANCEL</button>
            <button class="cashout-modal-button refresh" (click)="handleRefreshCashoutFromModal()" i18n>REFRESH</button>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'fail'">
          <div class="cashout-confirm-modal-text failed">
            <span i18n>Cashout Failed</span
            ><span>{{
              cashoutModalSubs.cashoutConfirmResponseModalDetails === '' ? '!' : ': ' + cashoutModalSubs.cashoutConfirmResponseModalDetails
            }}</span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'pending'">
          <div class="cashout-confirm-modal-text pending">{{ cashoutModalSubs.cashoutConfirmResponseModalDetails }}</div>
          <button class="cashout-modal-button cancel" (click)="handleCloseCashoutConfirmResponseModal()" i18n>OK</button>
        </ng-container>
      </ng-container>
    </div>
    <div
      class="cashout-modal-overlay"
      (click)="
        cashoutModalSubs.cashoutConfirmResponseModalState === 'noresponse'
          ? handleCloseCashoutConfirm()
          : handleCloseCashoutConfirmResponseModal()
      "
    ></div>
  </div>
</ng-container>
