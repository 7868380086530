import { Pipe, PipeTransform } from '@angular/core';
import { BetCouponGroup } from 'clientside-coupon';
import { CouponGroupingType } from 'src/app/shared/models/coupon.model';

@Pipe({
  name: 'isGroupingVisible',
})
export class IsGroupingVisiblePipe implements PipeTransform {
  transform(group: BetCouponGroup, isLast: boolean, groupingsTabSelected: number): boolean {
    if (groupingsTabSelected === CouponGroupingType.Split && isLast) {
      return true;
    } else if (groupingsTabSelected === CouponGroupingType.Combination) {
      if (!isLast && group.Grouping !== 1) {
        return true;
      }
    } else if (groupingsTabSelected === CouponGroupingType.Singles && group.Grouping === 1) {
      return true;
    }

    return false;
  }
}
