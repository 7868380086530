import { Pipe, PipeTransform } from '@angular/core';
import { LastMinuteSortType } from 'src/app/shared/models/last-minute.model';
import { EventSummaryModel, MatchModel } from 'src/app/shared/models/sport.model';

@Pipe({
  name: 'lastMinuteSortBy',
})
export class LastMinuteSortByPipe implements PipeTransform {
  transform(eventSummary: EventSummaryModel, sortBy: { name: string; id: number }): any {
    if (!sortBy) {
      return eventSummary;
    }
    const eventSummaryCopy = { ...eventSummary };
    const matchesCopy = [...eventSummaryCopy.matches];

    if (sortBy.id === LastMinuteSortType.ByTime) {
      return eventSummary;
    }
    if (sortBy.id === LastMinuteSortType.ByOddsLowestFirst) {
      matchesCopy.sort((aItem: MatchModel, bItem: MatchModel) => {
        let lowestOddA = 100;
        let lowestOddB = 100;
        aItem.odds.forEach(odd => {
          if (odd.value < lowestOddA) {
            lowestOddA = odd.value;
          }
        });
        bItem.odds.forEach(odd => {
          if (odd.value < lowestOddB) {
            lowestOddB = odd.value;
          }
        });
        if (lowestOddA < lowestOddB) {
          return -1;
        }
        if (lowestOddA > lowestOddB) {
          return 1;
        }
        return 0;
      });
    } else if (sortBy.id === LastMinuteSortType.ByOddsHighestFirst) {
      matchesCopy.sort((aItem: MatchModel, bItem: MatchModel) => {
        let highestOddA = 0;
        let highestOddB = 0;
        aItem.odds.forEach(odd => {
          if (odd.value > highestOddA) {
            highestOddA = odd.value;
          }
        });
        bItem.odds.forEach(odd => {
          if (odd.value > highestOddB) {
            highestOddB = odd.value;
          }
        });
        if (highestOddA > highestOddB) {
          return -1;
        }
        if (highestOddA < highestOddB) {
          return 1;
        }
        return 0;
      });
    }

    eventSummaryCopy.matches = matchesCopy;

    return eventSummaryCopy;
  }
}
