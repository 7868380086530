<div *ngIf="eventsInfo?.matches" class="odds-table-container" [class.one-event]="eventsInfo.matches.length === 1" appAdjustContainerOnHorizontalScroll>
  <app-single-line-event-live
    *ngFor="let event of eventsInfo.matches; trackBy: eventTrackBy; let i = index; let first=first; let last=last"
    [event]="event"
    [sportId]="event.sportId"
    [first]="first"
    [last]="last"
    [selectedMarket]="eventsInfo.area.markets[0]"
    [eventLocation]="eventLocation"
    [matchPosition]="i + 1">
  </app-single-line-event-live>
</div>
