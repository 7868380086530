import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VariablesService {
  colorBrand = '#255ebd'; // $color-brand
  colorBrandDarker = '#001041'; // $color-brand-darker
  colorSuccess = '#2da050'; // $color-success
  colorWarning = '#ffbc16'; // $color-warning
  colorDanger = '#ff423d'; // $color-danger
  colorBrandAccent = '#ffbd00'; // $color-brand-accent
  colorTextLight = '#fff'; // $color-text-light
  colorContainerBackground = '#fff'; // $color-container-background

  screenWidth = 340;
}

export const animationDuration: number = 400;
