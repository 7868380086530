import { Injectable } from '@angular/core';

import { EntityStore, StoreConfig } from '@datorama/akita';
import { LocalStorageService } from 'ngx-webstorage';

import { EvaluationSignalrService } from 'src/app/core/services/evaluation-signalr.service';
import { BetCashoutModel, BetCashoutResponseModel, CashoutModel, CashoutState } from 'src/app/shared/models/cashout.model';

const createInitialState = (): CashoutState => ({
  pendingCashoutList: [],
  timeofBulkCashoutRetrieval: undefined,
  active: undefined,
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cashout' })
export class CashoutStore extends EntityStore<CashoutState> {
  readonly evaluationListKey: string = 'evaluationList';
  readonly pendingCashoutListKey: string = 'pendingCashoutList';

  constructor(private readonly localStorage: LocalStorageService, private readonly evaluationSignalrService: EvaluationSignalrService) {
    super(createInitialState());

    this.updatePendingCashoutList(this.localStorage.retrieve(this.pendingCashoutListKey));
    this.localStorage.observe(this.pendingCashoutListKey).subscribe(value => {
      this.updatePendingCashoutList(value);
    });
  }

  updatePendingCashoutList(pendingCashoutList: CashoutModel[]): void {
    if (!pendingCashoutList) {
      this.clearPendingCashoutList();
      return;
    }

    this.update({ pendingCashoutList });
    this.localStorage.store(this.pendingCashoutListKey, pendingCashoutList);
  }

  addToPendingList(cashoutId: number, couponCode: string): void {
    this.update(state => {
      const isInPending = state.pendingCashoutList.find(
        i => (cashoutId && i.cashoutId === cashoutId) || (couponCode && i.couponCode === couponCode)
      );

      if (isInPending !== undefined) {
        return;
      }

      const pendingCashoutList = [
        ...state.pendingCashoutList,
        new CashoutModel({
          cashoutId: cashoutId,
          couponCode: couponCode,
        }),
      ];

      this.localStorage.store(this.pendingCashoutListKey, pendingCashoutList);
      return { pendingCashoutList };
    });
  }

  removeFromPendingList(cashoutId: number): void {
    this.update(state => {
      const evaluationList = this.localStorage.retrieve(this.evaluationListKey) || [];
      const pendingCashoutList = state.pendingCashoutList.filter(o => o.cashoutId !== cashoutId);
      this.localStorage.store(this.pendingCashoutListKey, pendingCashoutList);

      // Stop evaluation Connection when list is empty
      if (pendingCashoutList.length === 0 && evaluationList.length === 0) {
        this.evaluationSignalrService.stopConnection();
      }

      return { pendingCashoutList };
    });
  }

  removeCashout(couponCode: string): void {
    this.remove(cashout => {
      return cashout.couponCode === couponCode;
    });
  }

  clearPendingCashoutList(): void {
    this.update({ pendingCashoutList: [] });
    this.localStorage.store(this.pendingCashoutListKey, []);
  }

  addCashoutData(cashout: CashoutModel): void {
    this.add(cashout);
  }

  updateBetCashoutData(betCashout: BetCashoutModel): void {
    if (betCashout) {
      this.updateActive(active => ({
        betCashout,
        oldBetCashout: betCashout.value === active.betCashout.value ? undefined : active.betCashout,
        timeOfRetrieval: Date.now(),
      }));
    }
  }

  updateCashoutResponseData(cashoutResponse: BetCashoutResponseModel): void {
    if (cashoutResponse) {
      this.updateActive(() => ({
        cashoutResponse,
      }));
    }
  }

  cashoutInProgress(value: boolean): void {
    this.updateActive(() => ({
      cashoutInProgress: value,
    }));
  }

  cashoutRefreshing(value: boolean): void {
    this.updateActive(() => ({
      cashoutRefreshing: value,
    }));
  }

  cashoutRequested(value: boolean): void {
    this.updateActive(() => ({
      cashoutRequested: value,
    }));
  }

  clear(): void {
    this.update(createInitialState());
  }
}
