import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { first } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { RegistrationService } from 'src/app/core/services/registration.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { VerifyAccountType } from 'src/app/shared/models/account.model';

@Component({
  selector: 'app-phone-verification-popup',
  templateUrl: './phone-verification-popup.component.html',
  styleUrls: ['./phone-verification-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneVerificationPopupComponent implements OnInit {
  verifyData: any;
  options: any = {
    allowOutsideClick: true,
    backdrop: 'rgba(0, 0, 0, 0.8)',
    buttonsStyling: false,
    customClass: {
      container: 'notif-container',
      popup: 'notif-popup',
      header: 'notif-header',
      title: 'notif-title',
      image: 'notif-image',
      content: 'notif-content',
      input: 'notif-input',
      actions: 'notif-actions',
      cancelButton: 'notif-cancel-button',
      closeButton: 'notif-close-button',
      confirmButton: 'notif-confirm-button',
      footer: 'notif-footer',
    },
    heightAuto: false,
    reverseButtons: true,
    showCancelButton: false,
    showCloseButton: true,
    showConfirmButton: false,
  };

  verifyAccountType: typeof VerifyAccountType = VerifyAccountType;
  daysLeft: number;
  hoursLeft: number;

  @ViewChild('phoneVerification', { static: true }) phoneVerification: SwalComponent;

  constructor(
    readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly router: Router,
    private readonly registrationService: RegistrationService
  ) {}

  ngOnInit(): void {
    this.accountQuery.verifyAccountState$.subscribe(data => {
      if (
        data &&
        data.type !== this.verifyAccountType.Verified &&
        this.accountQuery.userData &&
        this.accountQuery.userData.userStatus !== 'UNCONF'
      ) {
        this.verifyData = data;
        this.phoneVerification.fire();
      }

      if (data && data.type === this.verifyAccountType.AlreadyRegistered) {
        this.verifyData = data;
        this.phoneVerification.fire();
      }
    });
  }

  verifyExistingPhoneNumber(): void {
    this.registrationService
      .checkPhoneNumber(undefined, this.verifyData.mobileNumber)
      .pipe(first())
      .subscribe(
        () => {
          // Phone Number not exist
        },
        err => {
          if (err.error.ResponseCode === 20214) {
            // Mobile number already exists on multiple accounts
            this.accountService.updateMultipleUnconfirmedUsers(true);
          } else if (err.error.ResponseCode === 20216) {
            // UnConfirmed Mobile Number Already Exists
            this.accountService.updateMultipleUnconfirmedUsers(false);
          }

          this.navigateTo('/account/phone-number-verification');
        }
      );
  }

  closePopup(): void {
    this.phoneVerification.dismiss();
  }

  navigateTo(url: string): void {
    this.closePopup();
    this.router.navigate([url]);
  }
}
