import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash-es';

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
  transform(value: any, order: string): any {
    if (!order) {
      return value;
    }

    return orderBy(value, order);
  }
}
