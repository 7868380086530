import { Injectable } from '@angular/core';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { VirtualsCouponService } from 'src/app/core/services/virtuals-coupon/virtuals-coupon.service';
import { determineCouponProduct } from 'src/app/shared/utils/determine-coupon-product';
import { ProductType, VirtualsLeagueType } from 'src/app/shared/models/product.model';
@Injectable({
  providedIn: 'root',
})
export class RebetService {
  constructor(private readonly couponService: CouponService, private readonly virtualsCouponService: VirtualsCouponService) {}

  performRebet(couponCode: string) {
    const couponProduct = determineCouponProduct(couponCode);
    if (couponProduct.productType === ProductType.Virtuals && couponProduct.virtualsLeagueType === VirtualsLeagueType.Scheduled) {
      return this.virtualsCouponService.rebetCoupon(couponCode);
    } else if (couponProduct.productType === ProductType.SportsBook) {
      return this.couponService.rebetCoupon(couponCode);
    }
  }
}
