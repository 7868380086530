import { Injectable } from '@angular/core';

import { OddModel } from 'src/app/shared/models/coupon.model';
import { CorrectScoreOddsModel, MatchModel } from 'src/app/shared/models/sport.model';

@Injectable({
  providedIn: 'root',
})
export class CorrectScoreEventService {
  parseOddsForCorrectScoreView(odds: OddModel[]): CorrectScoreOddsModel {
    const homeToWin = [];
    const awayToWin = [];
    const draw = [];
    const others = [];
    odds.forEach((odd: OddModel) => {
      if (odd.selectionName === 'Others' || odd.selectionName === 'Other') {
        others.push(odd);
      } else {
        if (odd.selectionName.length < 4) {
          const selectionName = odd.selectionName.split(':');

          if (selectionName[0] > selectionName[1]) {
            homeToWin.push(odd);
          } else if (selectionName[0] < selectionName[1]) {
            awayToWin.push(odd);
          } else if (selectionName[0] === selectionName[1]) {
            draw.push(odd);
          }
        } else if (odd.selectionName.indexOf('/') > -1) {
          const selections = odd.selectionName.split('/');
          const selectionName = selections[selections.length - 1].split(':');

          if (selectionName[0] > selectionName[1]) {
            homeToWin.push(odd);
          } else if (selectionName[0] < selectionName[1]) {
            awayToWin.push(odd);
          } else if (selectionName[0] === selectionName[1]) {
            draw.push(odd);
          } else if (selectionName[0] === 'Other') {
            others.push(odd);
          }
        } else if (odd.selectionName.indexOf('-') > -1) {
          const selections = odd.selectionName.split(' ');
          const selectionName = selections[selections.length - 1].split('-');

          if (selectionName.length === 2 && !isNaN(+selectionName[0]) && !isNaN(+selectionName[1])) {
            if (selectionName[0] > selectionName[1]) {
              homeToWin.push(odd);
            } else if (selectionName[0] < selectionName[1]) {
              awayToWin.push(odd);
            } else if (selectionName[0] === selectionName[1]) {
              draw.push(odd);
            }
          } else if (selectionName[0] === 'Other') {
            others.push(odd);
          }
        }
      }
    });

    let longestArrayLength = homeToWin.length;

    if (draw.length > homeToWin.length) {
      longestArrayLength = draw.length;
    }

    if (awayToWin.length > draw.length) {
      longestArrayLength = awayToWin.length;
    }

    if (homeToWin.length < longestArrayLength) {
      while (homeToWin.length < longestArrayLength) {
        homeToWin.push({});
      }
    }
    if (draw.length < longestArrayLength - 1) {
      while (draw.length < longestArrayLength - 1) {
        draw.push({});
      }
    }
    if (awayToWin.length < longestArrayLength) {
      while (awayToWin.length < longestArrayLength) {
        awayToWin.push({});
      }
    }

    return new CorrectScoreOddsModel({
      homeToWin,
      awayToWin,
      draw,
      others,
    });
  }

  matchFactoryForCorrectScoreView(match: MatchModel): MatchModel {
    const newMatch = new MatchModel({
      ...match,
      correctScoreOdds: this.parseOddsForCorrectScoreView(match.odds),
    });
    return newMatch;
  }
}
