<div class="market-wrapper">
  <div class="market-name" *ngIf="showMarketName && marketName">
    {{ marketName }}
  </div>
  <div class="separator" *ngIf="separatorText"
    [class.last-minute]="(applicationQuery.activeUrl$ | async)[0] === 'last-minute'">
    {{ separatorText }}
  </div>
  <div class="markets" [class.separator]="separator">
    <div class="market" *ngIf="groupedMarkets?.length > 0">
      <app-spread-line-selector [openModal]="openDropDown" [options]="groupedMarkets"
      (optionSelected)="handleOptionSelected($event)" [selectedOption]="selectedOption" i18n-placeholder
      placeholder="Change"></app-spread-line-selector>
    </div>
    <div *ngFor="let selection of markets; trackBy: marketTrackBy" class="market">
      {{ selection.name }}
    </div>
  </div>
</div>
