import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'odd' })
export class OddPipe implements PipeTransform {
  transform(value: any): string {
    let odd = value;

    if (!odd) {
      return odd;
    }

    if (odd >= 100) {
      return numberWithCommas(parseFloat(odd));
    } else {
      const splittedOdd = odd.toString().split('.');
      if (!splittedOdd[1]) {
        odd = `${odd}.00`;
      } else if (splittedOdd[1].length === 1) {
        odd = `${odd}0`;
      }
      return numberWithCommas(odd);
    }
  }
}

function numberWithCommas(value: number): string {
  const parts = value.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (parts[1]) {
    parts[1] = parts[1].substring(0, 2);
  }
  return parts.join('.');
}
