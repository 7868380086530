import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { from as observableFrom, Observable, of as observableOf } from 'rxjs';

import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { MetaData } from 'src/app/modules/meta/models/meta-data.model';
import { MetaDataQuery } from 'src/app/modules/meta/store/meta-data.query';
import { MetaDataStore } from 'src/app/modules/meta/store/meta-data.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { APIService } from 'src/app/core/services/api.service';
import { MetaLoader } from './meta.loader';
import { MetaSettings } from './models/meta-settings.model';
import { PageTitlePositioning } from './models/page-title-positioning.model';
import { isObservable, isPromise } from './utils';

// TODO: Release as a separate package
@Injectable()
export class MetaService {
  protected readonly settings: MetaSettings;
  private readonly isMetaTagSet: any;

  constructor(
    readonly loader: MetaLoader,
    private readonly title: Title,
    private readonly meta: Meta,
    private readonly metaDataQuery: MetaDataQuery,
    private readonly metaDataStore: MetaDataStore,
    private readonly http: HttpClient,
    @Inject(DOCUMENT) private readonly dom: Document,
    private readonly router: Router,
    private readonly apiService: APIService
  ) {
    this.settings = loader.settings;
    this.isMetaTagSet = {};
  }

  initialize(cmsBaseUrl: string, preloadMetaData: boolean, language: string): void {
    this.metaDataStore.updateCmsUrl(cmsBaseUrl);
    this.metaDataStore.updateLanguage(language);
    if (preloadMetaData) {
      this.getMetaData();
    }
  }

  getMetaData(): Observable<any> {
    const url: string = `betking-mobile-pages?filters[url][$eq]=${this.router.url}&populate=*`;

    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    return this.apiService.get(APIType.StrapiCms, url, apiSettings).pipe(
      first(),
      map(response => {
        const { data = [] } = response;
        if (data.length > 0) {
          const { attributes } = data[data.length - 1];

          this.updateMetaData(attributes);
          return attributes;
        }
        return null;
      })
    );
  }

  updateMetaData(metaData: MetaData): void {
    this.metaDataStore.updateMetaData(metaData);
  }

  injectDataToHTML(data: MetaData, callback = undefined) {
    if (data) {
      data.footerText && callback && callback(data.footerText);
      if (data.seo) {
        const { title, description, keywords } = data.seo;
        title && this.setTitle(title);
        description && this.setTag('description', description);
        keywords && this.setTag('keywords', keywords);
      }
    }
  }

  useDataFromCMS(event: NavigationEnd, callback = undefined): void {
    callback('');
    this.meta.removeTag('name=description');
    this.meta.removeTag('property="og:description"');

    this.meta.removeTag('name=keywords');
    this.meta.removeTag('property="og:keywords"');
    if (this.metaDataQuery.metaData && this.metaDataQuery.metaData[this.router.url]) {
      this.injectDataToHTML(this.metaDataQuery.metaData[this.router.url], callback);
    } else {
      this.getMetaData().subscribe((data: MetaData) => {
        this.injectDataToHTML(data, callback);
      });
    }
  }

  setTitle(title: string, override: boolean = false): void {
    const title$ = title ? this.callback(title) : observableOf('');

    title$.subscribe((res: string) => {
      let fullTitle = '';

      if (!res) {
        const defaultTitle$ =
          this.settings.defaults && this.settings.defaults.title ? this.callback(this.settings.defaults.title) : observableOf('');

        defaultTitle$.subscribe((defaultTitle: string) => {
          if (!override && this.settings.pageTitleSeparator && this.settings.applicationName) {
            this.callback(this.settings.applicationName).subscribe((applicationName: string) => {
              fullTitle = applicationName ? this.getTitleWithPositioning(defaultTitle, applicationName) : defaultTitle;
              this.updateTitle(fullTitle);
            });
          } else {
            this.updateTitle(defaultTitle);
          }
        });
      } else if (!override && this.settings.pageTitleSeparator && this.settings.applicationName) {
        this.callback(this.settings.applicationName).subscribe((applicationName: string) => {
          fullTitle = applicationName ? this.getTitleWithPositioning(res, applicationName) : res;
          this.updateTitle(fullTitle);
        });
      } else {
        this.updateTitle(res);
      }
    });
  }

  setTag(key: string, value: string): void {
    if (key === 'title') {
      throw new Error(
        `Attempt to set ${key} through "setTag": "title" is a reserved tag name. ` + 'Please use `MetaService.setTitle` instead.'
      );
    }

    const cur = value || (this.settings.defaults && this.settings.defaults[key] ? this.settings.defaults[key] : '');

    const value$ = key !== 'og:locale' && key !== 'og:locale:alternate' ? this.callback(cur) : observableOf(cur);

    value$.subscribe((res: string) => {
      this.updateTag(key, res);
    });
  }

  update(currentUrl: string, metaSettings?: any): void {
    if (!metaSettings) {
      const fallbackTitle = this.settings.defaults
        ? `${this.settings.defaults.title} - ${this.settings.applicationName}`
        : this.settings.applicationName;

      this.setTitle(fallbackTitle, true);
    } else {
      if (metaSettings.disabled) {
        this.update(currentUrl);

        return;
      }

      this.setTitle(metaSettings.title, metaSettings.override);

      Object.keys(metaSettings).forEach(key => {
        let value = metaSettings[key];

        if (key === 'title' || key === 'override') {
          return;
        } else if (key === 'og:locale') {
          value = value.replace(/-/g, '_');
        } else if (key === 'og:locale:alternate') {
          const currentLocale = metaSettings['og:locale'];
          this.updateLocales(currentLocale, metaSettings[key]);

          return;
        }

        this.setTag(key, value);
      });
    }

    if (this.settings.defaults) {
      Object.keys(this.settings.defaults).forEach(key => {
        let value = this.settings.defaults[key];

        if ((metaSettings && (key in this.isMetaTagSet || key in metaSettings)) || key === 'title' || key === 'override') {
          return;
        } else if (key === 'og:locale') {
          value = value.replace(/-/g, '_');
        } else if (key === 'og:locale:alternate') {
          const currentLocale = metaSettings ? metaSettings['og:locale'] : undefined;
          this.updateLocales(currentLocale, value);

          return;
        }

        this.setTag(key, value);
      });
    }

    const baseUrl = this.settings.applicationUrl ? this.settings.applicationUrl : '/';
    const url = `${baseUrl}${currentUrl}`.replace(/(https?:\/\/)|(\/)+/g, '$1$2').replace(/\/$/g, '');

    this.setTag('og:url', url ? url : '/');
  }

  removeTag(key: string): void {
    this.meta.removeTag(key);
  }

  setPrefetchLink(href: string): void {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'prefetch');
    link.setAttribute('href', href);
    this.dom.head.appendChild(link);
  }

  unsetPrefetchLink(href: string): void {
    const link: HTMLLinkElement | null = this.dom.head.querySelector(`link[href="${href}"]`);
    if (link) {
      link.remove();
    }
  }

  setCanonicalURL(canonicalUrl: string): void {
    if (!canonicalUrl) {
      console.warn('MetaService - Invalid canonicalUrl supplied');
      return;
    }

    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    const pathName: string = this.dom.location.pathname;

    // format some canonical urls
    const urlPath = this.formatUrlPath(pathName);

    link.setAttribute('href', `${canonicalUrl}${urlPath}`);
  }

  private formatUrlPath(pathName: string): string {
    let urlPath: string = pathName === '/' ? '' : pathName;

    if (pathName.startsWith('/sports') && pathName.indexOf('/competitions') !== -1) {
      // check for path '/sports/{{sportname}}/competitions'
      // replace the path with '/sports/s/prematch/{{sportname}}'
      urlPath = `/sports/s/prematch${pathName.substring(pathName.indexOf('/sports') + 7, pathName.indexOf('/competitions'))}`;
    } else if (pathName.startsWith('/sports/events/prematch') || pathName.startsWith('/sports/events/outright')) {
      // check for path '/sports/events/{{prematch|outright}}/{{sportname}}/{{categoryname}}/{{tournamentname}}
      // replace the path with '/sports/s/event/{p|o}/{{sportname}}/{{categoryname}}/{{tournamentname}}/{areaId}/{regionId}
      urlPath = `${pathName.replace('/events/prematch', '/s/event/p').replace('/events/outright', '/s/event/o')}${
        pathName.indexOf('/outright') !== -1 ? '/2/60' : '/0/0'
      }`;
    }
    return urlPath;
  }

  private callback(value: string): Observable<any> {
    if (this.settings.callback) {
      const value$ = this.settings.callback(value);

      if (!isObservable(value$)) {
        return isPromise(value$) ? observableFrom(value$) : observableOf(value$);
      }

      return value$;
    }

    return observableOf(value);
  }

  private getTitleWithPositioning(title: string, applicationName: string): string {
    switch (this.settings.pageTitlePositioning) {
      case PageTitlePositioning.AppendPageTitle:
        return applicationName + String(this.settings.pageTitleSeparator) + title;
      case PageTitlePositioning.PrependPageTitle:
        return title + String(this.settings.pageTitleSeparator) + applicationName;
      default:
        throw new Error(`Invalid pageTitlePositioning specified [${this.settings.pageTitlePositioning}]!`);
    }
  }

  private updateTitle(title: string): void {
    this.title.setTitle(title);
    this.meta.updateTag({
      property: 'og:title',
      content: title,
    });
  }

  private updateLocales(currentLocale: string, availableLocales: string): void {
    const cur = currentLocale || (this.settings.defaults ? this.settings.defaults['og:locale'] : '');

    if (cur && this.settings.defaults) {
      this.settings.defaults['og:locale'] = cur.replace(/_/g, '-');
    }

    // TODO: set HTML lang attribute - https://github.com/ngx-meta/core/issues/32
    // const html = this.document.querySelector('html');
    // html.setAttribute('lang', cur);

    const elements = this.meta.getTags('property="og:locale:alternate"');

    elements.forEach((element: any) => {
      this.meta.removeTagElement(element);
    });

    if (cur && availableLocales) {
      availableLocales.split(',').forEach((locale: string) => {
        if (cur.replace(/-/g, '_') !== locale.replace(/-/g, '_')) {
          this.meta.addTag({
            property: 'og:locale:alternate',
            content: locale.replace(/-/g, '_'),
          });
        }
      });
    }
  }

  private updateTag(key: string, value: string): void {
    if (key.lastIndexOf('og:', 0) === 0) {
      this.meta.updateTag({
        property: key,
        content: key === 'og:locale' ? value.replace(/-/g, '_') : value,
      });
    } else {
      this.meta.updateTag({
        name: key,
        content: value,
      });
    }

    this.isMetaTagSet[key] = true;

    if (key === 'description') {
      this.meta.updateTag({
        property: 'og:description',
        content: value,
      });
    } else if (key === 'keywords') {
      this.meta.updateTag({
        property: 'og:keywords',
        content: value,
      });
    } else if (key === 'author') {
      this.meta.updateTag({
        property: 'og:author',
        content: value,
      });
    } else if (key === 'publisher') {
      this.meta.updateTag({
        property: 'og:publisher',
        content: value,
      });
    } else if (key === 'og:locale') {
      const availableLocales = this.settings.defaults ? this.settings.defaults['og:locale:alternate'] : '';

      this.updateLocales(value, availableLocales);
      this.isMetaTagSet['og:locale:alternate'] = true;
    } else if (key === 'og:locale:alternate') {
      const currentLocale = this.meta.getTag('property="og:locale"').content;

      this.updateLocales(currentLocale, value);
      this.isMetaTagSet['og:locale'] = true;
    }
  }
}
