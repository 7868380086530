import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { MarketModel, SelectionModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-events-markets-header',
  templateUrl: './events-markets-header.component.html',
  styleUrls: ['./events-markets-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsMarketsHeaderComponent implements OnInit, OnDestroy {
  @Input() markets: SelectionModel[];
  @Input() showMarketName: boolean = false;
  @Input() marketName: string;
  @Input() separatorText: string;
  @Input() separator: boolean = true;
  @Input() selectedOption: MarketModel;
  // Although not currently being dynamically set, in the next phases of the rebrand we will have different colors
  // for headers.
  @Input() groupedMarkets: MarketModel[] = [];

  @Output() readonly optionSelected = new EventEmitter<MarketModel>();

  openDropDown = false;
  destroy$ = new Subject<boolean>();

  constructor(public applicationQuery: ApplicationQuery) {}

  marketTrackBy(index: number, selection: SelectionModel): number {
    return selection.id;
  }

  ngOnInit(): void {
    this.applicationQuery.activeUrl$.pipe(takeUntil(this.destroy$)).subscribe(url => {
      if (url[0] === 'last-minute') {
        this.separatorText = 'Starting soon';
      }
    });
  }

  handleOptionSelected(selectedEvent: MarketModel) {
    this.optionSelected.emit(selectedEvent);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
