import { Injectable } from '@angular/core';
import { MatchModel } from 'src/app/shared/models/sport.model';

import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { APIService } from 'src/app/core/services/api.service';
import { CorrectScoreEventService } from 'src/app/core/services/correct-score-event.service';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { animationDuration } from 'src/app/core/services/variables.service';
import { CouponStore } from 'src/app/core/state/coupon/coupon.store';
import { APIType } from 'src/app/shared/models/api.model';
import { OddModel } from 'src/app/shared/models/coupon.model';
import { QuickBetService } from 'src/app/core/services/quick-bet.service';

@Injectable({
  providedIn: 'root',
})
export class CouponEditService {
  constructor(
    private readonly apiService: APIService,
    private readonly correctScoreEventService: CorrectScoreEventService,
    private readonly couponService: CouponService,
    private readonly couponStore: CouponStore,
    private readonly quickbetService: QuickBetService
  ) {}

  getMarketSelections(smartBetCode: number, marketId: number, spreadValue?: number | undefined, language: string = 'en'): Observable<void> {
    return this.apiService
      .get(APIType.SportsbookFeed, `api/feeds/prematch/QuickBet/ByMarketTypes/${smartBetCode}/${marketId}/${language}`)
      .pipe(
        first(),
        map(data => {
          const selections: OddModel[] = [];
          this.quickbetService.mapQuickBetMarketsToModel(data, spreadValue).forEach(element => {
            const selection = OddModel.fromQuickBetMarketModel(element);
            selection.categoryId = data.IDCategory;
            selection.categoryName = data.CategoryName;
            selection.eventCategory = data.EventCategory;
            selection.matchDate = data.EventDate;
            selection.matchName = data.EventName;
            selection.matchId = data.IDEvent;
            selection.sportId = data.IDSport;
            selection.sportName = data.SportName;
            selection.tournamentId = data.IDTournament;
            selection.tournamentName = data.TournamentName;
            selection.combinability = data.Markets[0].GamePlay;
            selections.push(selection);
          });

          this.couponStore.updateOpenedForEditCoupon(smartBetCode, marketId);
          if (data.Markets.length > 0) {
            if (selections.length > 3) {
              // could be correct score
              const csOdds = this.correctScoreEventService.parseOddsForCorrectScoreView(selections);
              this.couponStore.updateEditCouponData(
                new MatchModel({
                  id: data.IDEvent,
                  smartBetCode: smartBetCode.toString(),
                  oddCount: selections.length,
                  correctScoreOdds: csOdds.homeToWin.length > 0 ? csOdds : undefined,
                  odds: csOdds.homeToWin.length > 0 ? [] : selections,
                })
              );
            } else {
              this.couponStore.updateEditCouponData(
                new MatchModel({
                  id: data.IDEvent,
                  smartBetCode: smartBetCode.toString(),
                  oddCount: selections.length,
                  correctScoreOdds: undefined,
                  odds: selections,
                  isOutright: data.Markets[0].OddsType.IsOutright,
                  name: data.EventName,
                })
              );
            }
          }
        })
      );
  }

  clearEditData(): void {
    this.couponService.allowCompleteDeselectOfEventOdds = true;
    this.couponStore.updateOpenedForEditCoupon(undefined, undefined);
    setTimeout(() => {
      this.couponStore.updateEditCouponData(undefined);
    }, animationDuration);
  }
}
