import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { AppConfigService } from 'src/app/core/services/app-config.service';

import { ToastNotificationStore } from 'src/app/core/state/toast-notifications/toast-notifications.store';
import { ToastNotificationState } from 'src/app/shared/models/notification.model';

@Injectable({
  providedIn: 'root',
})
export class ToastNotificationQuery extends QueryEntity<ToastNotificationState> {
  protected limit: number = this.appConfigService.get('maximumNumberOfToasts');

  list$ = this.selectAll({
    limitTo: this.limit,
  });

  constructor(protected store: ToastNotificationStore, private readonly appConfigService: AppConfigService) {
    super(store);
  }
}
