import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';

/* eslint-disable @typescript-eslint/member-ordering */
@Injectable({
  providedIn: 'root',
})
export class InsufficientBalanceService {
  private readonly checkBalanceSubject = new Subject<any>();
  private readonly showProgressionBarSubject = new Subject<any>();
  showProgressionBar$ = this.showProgressionBarSubject.asObservable();

  constructor(private readonly appService: AppConfigService, private readonly accountQuery: AccountQuery) {
    this.checkBalanceSubject.pipe(withLatestFrom(this.accountQuery.totalFunds$)).subscribe(([_, balance]) => {
      const minBalance = this.appService.get('account').insufficientBalanceThreshold;

      if (minBalance && balance < minBalance) {
        this.showProgressionBarSubject.next();
      }
    });
  }

  checkBalance(): void {
    this.checkBalanceSubject.next();
  }
}
