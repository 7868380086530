import { Injectable } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { format } from 'date-fns';
import { forkJoin, Observable } from 'rxjs';
import { APIService } from 'src/app/core/services/api.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { MyBetsCouponStatus, RecentBetsStatus } from 'src/app/modules/my-bets/models/my-bets-enums.model';
import {
  BetDetailsModel,
  BetLiveDetailsModel,
  EventModel,
  EventOddsModel,
  JackpotBetsRecentBetModel,
} from 'src/app/modules/my-bets/models/my-bets.model';
import { MyBetsProductInterface } from 'src/app/modules/my-bets/services/interfaces/my-bets-product-interface';
import { APIType } from 'src/app/shared/models/api.model';
import { BetFinalState } from 'src/app/shared/models/coupon-details.model';
import { LogTypeModel } from 'src/app/shared/models/log.model';
import { MyBetsStore } from 'src/app/modules/my-bets/state/my-bets.store';
import { LanguageService } from 'src/app/core/services/language.service';

@Injectable({
  providedIn: 'root',
})
export class JackpotBetsMyBetsService implements MyBetsProductInterface {
  private readonly _JACKPOTBETS_APP_CONFIG = this.appConfigService.get('jackpotBets');

  constructor(
    private readonly apiService: APIService,
    private readonly appConfigService: AppConfigService,
    private readonly languageService: LanguageService,
    private readonly loggerService: LoggerService,
    private readonly myBetsStore: MyBetsStore
  ) {}

  getMyBetsCall(status: MyBetsCouponStatus): Observable<any> {
    const pageSize = (this._JACKPOTBETS_APP_CONFIG.myBets.couponCount as number) + 1;

    const isRunningStatus = this.parseRunningStatus(status);

    const apiCalls = [];
    apiCalls.push(
      this.apiService.get<any>(APIType.JackpotBets, `api/v1/tickets?pagenumber=1&pagesize=${pageSize}&running=${isRunningStatus}`)
    );
    apiCalls.push(
      this.apiService.gqlQuery(
        `
        query JPBMyBetsContent($locale: I18NLocaleCode) {
          jackpotBetsPage(locale: $locale) {
            data {
              attributes {
                roundTypeConfig {
                  bubbleText
                  disclaimerText
                  roundType
                }
              }
            }
          }
        }
        `,
        this.languageService.strapiCMSLocale
      )
    );

    return forkJoin(apiCalls);
  }

  getMyBetsCount(couponStatus: MyBetsCouponStatus) {
    // API not available to retrieve the open bets count in JackpotBets, so fetching its list
    this.getMyBetsCall(couponStatus).subscribe(data => {
      const openBets = this.parseGetMyBetsCallResponse(data);
      this.myBetsStore.updateOpenBetsCount(openBets.length);
    });
  }

  getLiveDataCall(): Observable<BetLiveDetailsModel[]> {
    this.loggerService.logEvent(
      'Live events for MyBets JackpotBets not available',
      'JACKPOTBETS',
      SeverityLevel.Warning,
      LogTypeModel.Application
    );
    return null;
  }

  getBetDetails(bet: JackpotBetsRecentBetModel): Observable<any> {
    this.loggerService.logEvent(
      'Bet Details for MyBets JackpotBets not available',
      'JACKPOTBETS',
      SeverityLevel.Warning,
      LogTypeModel.Application
    );
    return null;
  }

  parseGetMyBetsCallResponse = (response: any, isOpen: boolean = true): JackpotBetsRecentBetModel[] =>
    this.mapGetMyBetsCallResponseToRecentBetModel(response, isOpen);

  parseBetDetailsResponse(response: any): BetDetailsModel {
    this.loggerService.logEvent(
      'Cashout for MyBets JackpotBets not available',
      'JACKPOTBETS',
      SeverityLevel.Warning,
      LogTypeModel.Application
    );
    return null;
  }

  retrieveCashoutStatusesForOpenBets(bets: JackpotBetsRecentBetModel[]): void {
    this.loggerService.logEvent(
      'Cashout for MyBets JackpotBets not available',
      'JACKPOTBETS',
      SeverityLevel.Warning,
      LogTypeModel.Application
    );
  }

  private parseRunningStatus(status: MyBetsCouponStatus): boolean {
    switch (status) {
      case MyBetsCouponStatus.Open:
        return true;
      case MyBetsCouponStatus.Settled:
        return false;
      default:
        return true;
    }
  }

  private mapGetMyBetsCallResponseToRecentBetModel(response: any, isOpen: boolean = true): JackpotBetsRecentBetModel[] {
    const retVal = [];

    if (response) {
      const myBetsResponse = response[0];
      const cmsResponse = response[1].data?.jackpotBetsPage.data.attributes.roundTypeConfig;

      myBetsResponse.items.forEach(data => {
        const roundDetails = cmsResponse.filter(round => round.roundType === data.roundType);

        retVal.push(
          new JackpotBetsRecentBetModel({
            couponCode: data.code,
            betFinalState: this.parseBetFinalState(data.status),
            couponDate: format(new Date(data.placedDate), 'd MMM yyyy HH:mm:ss'),
            couponType: roundDetails?.length ? roundDetails[0].bubbleText.toLowerCase() : undefined,
            couponTypeId: data.roundType,
            stakeGross: data.stake,
            won: data.wonAmount,
            maxWinNet: data.potentialWin,
            selectionCount: data.fixtures.length,
            totalCombinations: data.totalCombinations,
            exciseDuty: data.exciseDuty,
            exciseDutyPercentage: data.exciseDutyPercentage,
            incomeTax: data.incomeTax,
            incomeTaxPercentage: data.incomeTaxPercentage,
            incomeTaxDisclaimer: roundDetails?.length
              ? roundDetails[0].disclaimerText.replace('{0}', `${data.incomeTaxPercentage}%`)
              : undefined,
            betDetails: {
              couponType: roundDetails?.length ? roundDetails[0].bubbleText.toLowerCase() : undefined,
              maxWin: data.potentialWin,
              maxWinNet: data.potentialWin,
              won: data.wonAmount,
              events: this.groupOddsWithSameEventAndMarket(data),
            },
          })
        );
      });
    }

    return retVal;
  }

  private groupOddsWithSameEventAndMarket(response: any) {
    const betEvents: EventModel[] = [];

    if (response.fixtures) {
      const eventsMap = new Map<string, { event: any; odds: EventOddsModel[] }>();
      response.fixtures.forEach(odd => {
        odd.picks.forEach(pick => {
          const oddDetails: EventOddsModel = {
            oddStatusId: this.parseOddResultState(odd.won, odd.status),
            marketName: response.market,
            selectionName: pick.description,
          };

          const mapKey = `${odd.externalId}_${response.market}`;

          const foundEventOdds = eventsMap.has(mapKey) ? eventsMap.get(mapKey).odds : [];

          foundEventOdds.push(oddDetails);
          eventsMap.set(mapKey, { event: odd, odds: foundEventOdds });
        });
      });

      eventsMap.forEach(value => {
        const event: EventModel = {
          eventId: value.event.externalId,
          homeTeamName: value.event.homeTeam ? value.event.homeTeam.name.trim() : '',
          awayTeamName: value.event.awayTeam ? value.event.awayTeam.name.trim() : '',
          eventDate: value.event.date,
          eventStatusId: this.parseOddResultState(value.event.won, value.event.status),
          fullTimeScore: value.event.finalScore,
          result: value.event.result ? value.event.result : 'Unset', // set this as 'Unset' instead of null to match other API calls
          odds: value.odds,
        };

        if (value.odds.length > 1 && value.odds.some(odd => odd.oddStatusId === 1)) {
          // if any odd is won, set the main state as won
          event.eventStatusId = RecentBetsStatus.Won;
        }

        betEvents.push(event);
      });
    }
    return betEvents;
  }

  private parseOddResultState(won: boolean, status: number): RecentBetsStatus {
    if (won === null) {
      if (status === 2) {
        return RecentBetsStatus.Cancelled;
      } else {
        return RecentBetsStatus.Running;
      }
    } else {
      if (won) {
        return RecentBetsStatus.Won;
      } else {
        return RecentBetsStatus.Lost;
      }
    }
  }

  private parseBetFinalState(status: number): number {
    switch (status) {
      case 1: // Running
        return BetFinalState.Placed;
      case 2: // Lost
        return BetFinalState.Lost;
      case 3: // Won
        return BetFinalState.Won;
      case 4: // Cancelled/Void
        return BetFinalState.Void;
      default:
        return BetFinalState.Placed;
    }
  }
}
