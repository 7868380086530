import { Injectable } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { isObservable, Observable, of } from 'rxjs';
import { concatMap, finalize, map, tap } from 'rxjs/operators';
import { AccountF2PService } from 'src/app/core/services/account/account-f2p.service';
import { AccountService } from 'src/app/core/services/account/account.service';
import { MobileNumberInputService } from 'src/app/core/services/account/mobile-number-input.service';
import { APIService } from 'src/app/core/services/api.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AccountStore } from 'src/app/core/state/account/account.store';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { NativeUserDetailsService } from 'src/app/modules/native-app/services/native-user-details.service';
import { LinkF2PAccountResponseModel, LoginResponseModel, LoginType } from 'src/app/shared/models/account.model';
import { APIType } from 'src/app/shared/models/api.model';
import { NotificationSettings } from 'src/app/shared/models/notification.model';
import { CookieService } from 'src/app/core/services/cookie.service';

declare global {
  interface Window {
    MobileNative?: {
      setUserId: (userId: string) => void;
    };
    webkit?: {
      messageHandlers?: {
        MobileNative?: {
          postMessage: ({ userId: string }) => void;
        };
      };
    };
  }
}
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  readonly DEFAULT_LOGIN_ERROR_MESSAGE = $localize`There was an issue logging you in. Please try again or contact customer support.`;

  constructor(
    private readonly accountF2PService: AccountF2PService,
    private readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly accountStore: AccountStore,
    private readonly apiService: APIService,
    private readonly appConfig: AppConfigService,
    private readonly applicationQuery: ApplicationQuery,
    private readonly authService: AuthenticationService,
    private readonly dataLayerService: DataLayerService,

    private readonly mobileNumberInputService: MobileNumberInputService,
    private readonly notificationService: NotificationService,
    private readonly nativeUserDetails: NativeUserDetailsService,
    private readonly cookieService: CookieService
  ) {}

  /**
   * Handles login of users
   *
   * @param username username to login with
   * @param password password to login with
   * @param preventLogout Prevent logging out before logging in. This is required during registration.
   * @param linkingF2PAccount Function to be called after token is received, but before data is retrieved.
   * Currently used in F2P Upsell journey
   */
  login(
    username: string,
    password: string,
    preventLogout: boolean = false,
    linkingF2PAccount: boolean = false
  ): Observable<LoginResponseModel> {
    if (!preventLogout && this.accountQuery.isAuthenticated) {
      this.accountService.logout().subscribe();
    }
    this.accountStore.queueLoading();

    const calls = this.authService.getAccessToken(username, password).pipe(
      concatMap((accessToken: string) => {
        if (linkingF2PAccount) {
          return this.accountF2PService.linkToF2PAccount(accessToken).pipe(
            concatMap((linkF2PAccountResponse: LinkF2PAccountResponseModel) => {
              if (linkF2PAccountResponse.success) {
                return this.accountService.getUserData(accessToken);
              } else {
                this.authService.revokeToken(accessToken).subscribe();
                return of(
                  new LoginResponseModel({
                    success: false,
                    linkF2PAccountResponse: linkF2PAccountResponse,
                    loginMessages: undefined,
                    errorMessage: linkF2PAccountResponse.responseCode,
                  })
                );
              }
            })
          );
        } else {
          return this.accountService.getUserData(accessToken);
        }
      })
    );

    return calls.pipe(
      tap((response: LoginResponseModel) => {
        if (response.success) {
          this.dataLayerService.createOptimoveEvent('onLogin', 'userId', this.accountQuery.userData.id.toString());
          window?.MobileNative?.setUserId(this.accountQuery.userData.id.toString() || '');
          window?.webkit?.messageHandlers?.MobileNative?.postMessage({ userId: this.accountQuery.userData.id.toString() || '' });
          this.dataLayerService.createDataLayerEvent({
            event: 'btk.userID',
            userID: this.accountQuery.userData.id,
          });
          this.dataLayerService.createDataLayerEvent({
            event: 'user-login',
            userID: this.accountQuery.userData.id,
            userType: this.accountQuery.dataLayerUserType,
          });
        }

        const { name, isEnableForALL, userIds } = this.appConfig.get('abTesting') || {};
        if (userIds || isEnableForALL) {
          const abKingpayCookieValue = isEnableForALL || userIds.includes(this.accountQuery.userData.id) ? 'new' : 'old';
          this.cookieService.setCookie(name, abKingpayCookieValue);
        }
      }),
      finalize(() => {
        this.nativeUserDetails.saveUser(username, password);
        this.accountStore.dequeueLoading();
      })
    );
  }

  loginWithToken(token: string): Observable<boolean> {
    if (this.accountQuery.isAuthenticated) {
      this.accountService.logout().subscribe();
    }
    this.accountStore.queueLoading();
    return this.authService.validateToken(token).pipe(
      concatMap((response: any) => {
        if (!response?.isValidated) {
          return of(false);
        }
        this.cookieService.setCookie('accessToken', token, 3600 / 86400);
        return this.accountService.getUserData(token).pipe(map((res: LoginResponseModel) => res?.success));
      }),
      finalize(() => {
        this.accountStore.dequeueLoading();
      })
    );
  }

  /**
   * Handles login of F2P users
   *
   * @param username username to login with
   * @param password password to login with
   */
  f2pLogin(username: string, password: string): Observable<LoginResponseModel> {
    return this.authService.getF2PAccessToken(username, password).pipe(
      concatMap((accessToken: string) => this.accountF2PService.getF2PUserData(username, accessToken)),
      tap(() => {
        this.dataLayerService.createDataLayerEvent({
          event: 'user-f2p-login',
        });
      })
    );
  }

  showLoginMessages(messages: any): void {
    const messagesSettings: NotificationSettings[] = [];

    messages.forEach(message => {
      messagesSettings.push(
        new NotificationSettings({
          allowBackdropClose: true,
          showCloseButton: true,
          confirmButtonText: 'OK',
          contentHtml: message.Contents,
          showConfirmButton: true,
          title: message.Title,
          type: 'info',
          confirmButtonCallback: () => {
            this.markMessageAsRead(message.Id).subscribe();
          },
        })
      );
    });

    this.notificationService.showQueueNotifications(messagesSettings);
  }

  userNameValidators(): ValidatorFn[] {
    switch (this.applicationQuery.loginType) {
      case LoginType.Mobile:
        return [Validators.required, Validators.pattern(this.appConfig.get('registration').mobileRegex)];
      case LoginType.UsernameOrMobile:
      case LoginType.Username:
      default:
        return [Validators.required];
    }
  }

  handleLoginErrorResponse(error: any, username: string, prefix?: string): Observable<string> {
    if (error && error.error && error.error.error_description) {
      if (error.error.error_description.toLowerCase() === 'invalid credentials') {
        const message = this.invalidCredentialsErrorMessage(username, prefix);
        return isObservable(message) ? message : of(message);
      } else if (error.error.error_description.toLowerCase() === 'exceeded maximum authentication failures') {
        return of($localize`You have exceeded the maximum number of log in trials. Try again in 10 minutes.`);
      } else if (
        error.error.error_description.toLowerCase() === 'invalid user status' ||
        error.error.error_description.toLowerCase() === 'user deleted'
      ) {
        return of($localize`Sorry, your account is inactive. Please contact customer support.`);
      } else {
        return of(error.error.error_description);
      }
    } else {
      return of($localize`Login Failed`);
    }
  }

  private markMessageAsRead(messageId: number): Observable<boolean> {
    return this.apiService.post<any>(APIType.Platform, `api/User/Messages/${messageId}/MarkRead`, {});
  }

  private invalidCredentialsErrorMessage(username: string, prefix?: string): string | Observable<string> {
    switch (this.applicationQuery.loginType) {
      case LoginType.Username:
        return $localize`Invalid username or password`;
      case LoginType.Mobile:
        return $localize`Invalid phone number or password`;
      case LoginType.UsernameOrMobile:
        return this.mobileNumberInputService.isMobileNumber(username)
          ? this.accountService
              .isExistingUsername(`${prefix}${username}`)
              .pipe(
                map(result =>
                  result
                    ? $localize`Invalid phone number or password`
                    : $localize`This mobile number has not been verified, please use your username instead`
                )
              )
          : $localize`Invalid username or password`;
      default:
        return $localize`Invalid credentials`;
    }
  }
}
