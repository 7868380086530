import { format, addWeeks, getDay, setDay, addDays } from 'date-fns';

const sortOptionName = ['Time', 'Popular Competitions', 'Lowest Odds', 'Highest Odds'];

export enum ActionType {
  Sort,
  Filter,
}

export class SortFilterModel {
  options: string[];
  selectedValue: any;
  actionType: ActionType;
  title: string;
  constructor(init: Partial<SortFilterModel>) {
    Object.assign(this, init);
  }
}

export class SortFilterOptionModel {
  name: any;
  value: any;
  constructor(init: Partial<SortFilterOptionModel>) {
    Object.assign(this, init);
  }
}

export const getDaysInWeek = () => {
  const weekDays = [0, 1, 2, 3, 4, 5, 6];
  const lastDaysOfThisWeek = weekDays.splice(0, weekDays.indexOf(getDay(new Date())));
  const selectableWeekDays = weekDays.concat(lastDaysOfThisWeek);
  const weekDaysObjects = [];
  const positionOfSunday = selectableWeekDays.indexOf(0);

  selectableWeekDays.forEach((day, index) => {
    let newDay: SortFilterOptionModel;
    const isWeekShiftNeeded = index >= positionOfSunday ? 1 : 0;
    if (index === 0) {
      newDay = new SortFilterOptionModel({
        name: 'Today',
        value: format(setDay(new Date(), day), 'yyyy-MM-dd'),
      });
    } else if (index === 1) {
      newDay = new SortFilterOptionModel({
        name: 'Tomorrow',
        value: format(addWeeks(setDay(new Date(), day), isWeekShiftNeeded), 'yyyy-MM-dd'),
      });
    } else {
      newDay = new SortFilterOptionModel({
        name: format(setDay(new Date(), day), 'eeee'),
        value: format(addWeeks(setDay(new Date(), day), isWeekShiftNeeded), 'yyyy-MM-dd'),
      });
    }

    weekDaysObjects.push(newDay);
  });
  return weekDaysObjects;
};

export const getDateFromDayName = (dayName: string): string => {
  const days = getDaysInWeek().map(d => d.name);
  const dayIndex = days.indexOf(dayName);
  if (dayIndex === -1) {
    format(new Date(), 'yyyy-MM-dd');
  }
  const start = new Date();
  return format(addDays(start, dayIndex), 'yyyy-MM-dd');
};

export const getSortOptions = (): any[] => {
  return sortOptionName.map(
    (sortOption, index) =>
      new SortFilterOptionModel({
        name: sortOption,
        value: index,
      })
  );
};

export const sortOptionStorageKey = 'sortOptionPreference';

export const setDefault = (options: any[], defaultValue: any, actionType: ActionType, title: string) => {
  return new SortFilterModel({
    actionType,
    options: options,
    selectedValue: options.find(t => t.name === defaultValue),
    title,
  });
};

export const defaultSortOptions = setDefault(getSortOptions(), sortOptionName[0], ActionType.Sort, $localize`Sort by`);
export const listOfDays = setDefault(getDaysInWeek(), 'Today', ActionType.Filter, $localize`Day`);
export const defaultSortFilterOptions = JSON.stringify([defaultSortOptions, listOfDays]);
