<div class="no-bets-container" *ngIf="myBetsQuery.myBetsContent$ | async as myBetsContent">
  <div class="no-bets-face material-icons">{{ 'sentiment_dissatisfied' }}</div>
  <div class="no-bets-title">
    <span [ngSwitch]="tabName">
      <ng-container *ngSwitchCase="betsTab.Open" i18n>{{ myBetsContent.noOpenBets }}</ng-container>
      <ng-container *ngSwitchCase="betsTab.Settled" i18n>{{ myBetsContent.noSettledBets }}</ng-container>
      <ng-container *ngSwitchCase="betsTab.Booked" i18n>{{ myBetsContent.noBookedBets }}</ng-container>
    </span>
  </div>

  <app-notification-message type="info" *ngIf="tabName !== betsTab.Booked" [message]="myBetsContent.betsMovedToSettled">
  </app-notification-message>

  <button
    *ngIf="(applicationQuery.isVirtuals$ | async) === false || (virtualsQuery.showingMyBetsSlideUp$ | async) === false"
    class="bet-now-button" (click)="navigateHome()" i18n>
    {{ myBetsContent.betNow }}
  </button>
</div>