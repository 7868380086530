import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { EvaluationService } from 'src/app/core/services/evaluation.service';
import { EvaluationQuery } from 'src/app/core/state/evaluation/evaluation.query';
import { ApplicationService } from 'src/app/core/services/application.service';

@Component({
  selector: 'app-coupon-evaluation',
  templateUrl: './coupon-evaluation.component.html',
  styleUrls: ['./coupon-evaluation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CouponEvaluationComponent implements OnInit {
  couponData: any;
  options: any = {
    allowOutsideClick: false,
    backdrop: 'rgba(0, 0, 0, 0.8)',
    buttonsStyling: false,
    cancelButtonText: 'Reject',
    confirmButtonText: 'Accept',
    customClass: {
      container: 'notif-container',
      popup: 'notif-popup',
      header: 'notif-header',
      title: 'notif-title',
      image: 'notif-image',
      content: 'notif-content',
      input: 'notif-input',
      actions: 'notif-actions',
      cancelButton: 'notif-cancel-button',
      closeButton: 'notif-close-button',
      confirmButton: 'notif-confirm-button',
      footer: 'notif-footer',
    },
    heightAuto: false,
    reverseButtons: true,
    showCancelButton: true,
    showCloseButton: false,
    showConfirmButton: true,
    titleText: 'New Offer',
  };

  @ViewChild('evaluation', { static: true }) evaluation: SwalComponent;

  constructor(
    readonly evaluationQuery: EvaluationQuery,
    readonly applicationQuery: ApplicationQuery,
    private readonly evaluationService: EvaluationService,
    private readonly applicationService: ApplicationService
  ) {}

  ngOnInit(): void {
    this.evaluationQuery.awaitingReplyList$.subscribe(data => {
      if (data.length > 0) {
        this.couponData = data[0];

        this.evaluation.fire();
      }
    });
  }

  parseSelectionName(marketTypeId, selectionName, eventName): string {
    const teams = eventName.split(' - ');

    const homeTeam = teams[0];
    const awayTeam = teams.length > 1 ? teams[1] : undefined;

    return this.applicationService.parseSelectionName(selectionName, homeTeam, awayTeam, marketTypeId);
  }

  evaluationReplyClicked(couponCode: string, accepted: boolean, userId: string): void {
    this.evaluationService.evaluationReplyClicked(couponCode, accepted, userId);
  }

  indexTrackBy(index: number): number {
    return index;
  }
}
