import { Injectable } from '@angular/core';
import { ITraceTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AppInsightsService } from 'src/app/core/services/app-insights.service';
import { DeviceInformationService } from 'src/app/core/services/device-information.service';
import { ElasticService } from 'src/app/core/services/elastic.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { LogModel, LogTypeModel } from 'src/app/shared/models/log.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  // TODO: Remove all references of AppInsights from this service
  severityLevel = SeverityLevel;
  logTypeModel = LogTypeModel;

  constructor(
    private readonly appConfigService: AppConfigService,
    private readonly appInsightsService: AppInsightsService,
    private readonly accountQuery: AccountQuery,
    private readonly deviceInformationService: DeviceInformationService,
    private readonly elasticService: ElasticService
  ) {}

  logEvent(logMessage: string, logDetails: string, severity: SeverityLevel, type: LogTypeModel = LogTypeModel.Application): void {
    if (!environment.production) {
      this.debugLog(logMessage, logDetails, severity);
    }

    const loggerConfig = this.appConfigService.get('logging');
    if (!loggerConfig) {
      return;
    }
    // Without parseInt it is already a number, just typescript doesn't trust in it
    const minLogLevel = parseInt(this.severityLevel[loggerConfig.minLogLevel], 10);

    if (!loggerConfig.enabled || minLogLevel > severity) {
      return;
    }
    const userId = this.accountQuery.userData ? this.accountQuery.userData.id.toString() : 'unauthenticated';

    const logEntry = new LogModel({
      user: btoa(userId),
      logMessage,
      logDetails,
      type: this.logTypeModel[type],
      timestamp: new Date().toUTCString(),
      resolution: this.deviceInformationService.getDeviceResolution(),
      userAgent: this.deviceInformationService.getUserAgent(),
    });

    loggerConfig.endpoints.forEach(endpoint => {
      if (endpoint === 'AppInsights') {
        if (this.appInsightsService.isInitialized) {
          this.sendLogToAppInsights(logEntry, severity);
        }
      } else if (endpoint === 'Apm') {
        // on APM we can only log errors
        if ([this.severityLevel.Error, this.severityLevel.Critical].includes(severity)) {
          this.elasticService.logApmError(logMessage, logDetails);
        }
      } else {
        console.warn(`Endpoint not found: ${endpoint}`);
      }
    });
  }

  sendLogToAppInsights(logEntry: LogModel, severity: SeverityLevel): void {
    const newITraceTelemetry: ITraceTelemetry = { message: logEntry.logMessage, properties: logEntry, severityLevel: severity };
    this.appInsightsService.appInsights.trackTrace(newITraceTelemetry);
  }

  private debugLog(logMessage: string, logDetails: string, severity: SeverityLevel): void {
    const message = `${logMessage} - ${logDetails}`;

    switch (severity) {
      case SeverityLevel.Error:
      case SeverityLevel.Critical:
        console.error(message);
        break;
      case SeverityLevel.Warning:
        console.warn(message);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log(message);
    }
  }
}
