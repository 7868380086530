<div class="container" *ngIf="label$ | async as labelText" (click)="handleClick()" data-testid="free-bets-available">
  <div class="container-left">
    <img class="gift-icon" src="assets/images/account/freebet/freebet-gift-icon.svg" />
    <div class="label" *ngIf="hasSelectedFreebet$ | async; else noFreebetsApplied" [innerHTML]="freebetsAppliedLabel$ | async" data-testid="free-bets-available-label"></div>
    <ng-template #noFreebetsApplied>
      <div class="label" data-testid="free-bets-no-free-bets">{{ labelText }}</div>
    </ng-template>
  </div>
  <div class="material-icons">{{ 'chevron_right' }}</div>
</div>

<freebets-list-modal (closeModal)="handleClick()" [show]="showList$ | async" [productType]='productType'></freebets-list-modal>
