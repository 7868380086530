<div
  class="loading-bar-overlay-container"
  *ngIf="show$ | async"
  [@.disabled]="!fade"
  [@fadeInOut]
  [class.see-through]="overlayIsSeeThrough"
  [class.is-fixed]="isFixed"
>
  <app-loading-bar [show]="true"> </app-loading-bar>
  <div class="loading-overlay"></div>
</div>
