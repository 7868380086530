import { Injectable } from '@angular/core';
import { differenceInMinutes } from 'date-fns';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { ResolverService } from 'src/app/core/services/resolver.service';
import { SportService } from 'src/app/core/services/sports/sport.service';
import { SportQuery } from 'src/app/core/state/sport/sport.query';
import { SportStore } from 'src/app/core/state/sport/sport.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { SportModel, FlattenedSportModel, EventSelectionState, CategoryModel, TournamentModel } from 'src/app/shared/models/sport.model';

@Injectable({
  providedIn: 'root',
})
export class SportsListService {
  private readonly getSportsListCallTime$ = new BehaviorSubject<Date>(undefined);
  private readonly getSportsListCallLastResponse$ = new BehaviorSubject<SportModel[]>([]);

  constructor(
    private readonly appConfig: AppConfigService,
    private readonly sportStore: SportStore,
    private readonly apiService: APIService,
    private readonly sportQuery: SportQuery,
    private readonly resolverService: ResolverService,
    private readonly languageService: LanguageService,
    private readonly sportService: SportService
  ) {}

  getCompetitionsList() {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });
    const url = `sportsbook-page?locale=${this.languageService.strapiCMSLocale}&populate[couponsList][populate]=*`;
    return this.apiService.get(APIType.StrapiCms, url, apiSettings).pipe(
      map(res => {
        if (!res) return;
        const competitionsList = res.data.attributes.couponsList?.filter(t => t.enabled);
        const displayCompetitionList = res.data.attributes.displayCouponsList;
        const globalHourLimit = res.data.attributes.hourLimit;
        const globalExpandedListCount = res.data.attributes.expandedListCount;
        this.sportStore.updateCompetitionsList(competitionsList, displayCompetitionList, globalHourLimit, globalExpandedListCount);
        this.sportService.getMostPopularEvents(this.appConfig.get('sports').footballId).subscribe();
      })
    );
  }
  getSportsList(scheduleTimeFrame: number = 4, language?: string): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    let extendUrl: string = '';

    if (this.appConfig.get('sports').enableCustomMenu) {
      extendUrl = 'custommenu/';
    }

    // Check if data retreived last time is stale or not, to avoid unnecessary calls
    const isCallStale = this.getSportsListCallTime$.value
      ? differenceInMinutes(new Date(), this.getSportsListCallTime$.value) >= this.appConfig.get('sports').getSportsListStaleTimeInMinutes
      : true; // First time

    if (isCallStale) {
      // Save call datetime
      this.getSportsListCallTime$.next(new Date());

      return forkJoin([
        this.apiService.get<any>(
          APIType.SportsbookFeed,
          `api/feeds/prematch/matches/${extendUrl}${language || this.languageService.selectedLanguage.language}/${scheduleTimeFrame}`,
          apiSettings
        ),
        this.apiService.get(
          APIType.SportsbookFeed,
          `api/feeds/prematch/outrights/${language || this.languageService.selectedLanguage.language}/${scheduleTimeFrame}`
        ),
        this.getCompetitionsList(),
      ]).pipe(
        map(responseData => {
          if (!responseData) {
            return [];
          }

          const sportsListData: SportModel[] = [];
          const specialSports: SportModel[] = [];
          const playerSpecialsSport: SportModel[] = [];
          const competitionsAZ: FlattenedSportModel[] = [];
          const allCompetitionByCountry: SportModel[] = [];

          responseData[0].Sports.forEach(sport => {
            if (sport.MenuUniqueID.indexOf('_') === -1) {
              sportsListData.push(this.parsePrematchMatch(sport));

              competitionsAZ.push(this.parsePrematchMatchFlattened(sport));
              allCompetitionByCountry.push(this.parsePrematchMatch(sport));
            } else {
              switch (sport.MenuUniqueID) {
                case '3_0':
                  // Previous Odds Boost
                  break;
                case '9_0':
                  playerSpecialsSport.push(this.parsePrematchMatch(sport));
                  break;
                default:
                  // Handle specials and merge all categories based to one sport obj
                  const parsedSport = this.parsePrematchMatch(sport);
                  const sportObjInArray = specialSports.find(special => special.id === parsedSport.id);
                  if (sportObjInArray) {
                    sportObjInArray.categories = sportObjInArray.categories.concat(parsedSport.categories);
                  } else {
                    parsedSport.name = sportsListData.find(data => data.id === sport.SportID).name;
                    specialSports.push(parsedSport);
                  }
                  break;
              }
            }
          });

          const outrights = this.parsePrematchMatches(responseData[1]);

          this.sportStore.updateSportsList(sportsListData);
          this.updateEventSelection({
            specialSports,
            competitionsAZ,
            allCompetitionByCountry,
            outrights,
            playerSpecialsSport,
          });
          this.resolverService.buildCache(
            this.sportQuery.allCompetitionByCountry,
            this.sportQuery.outrights,
            this.sportQuery.specialSports,
            this.sportQuery.playerSpecialsSport,
            this.sportQuery.competitionsList
          );

          const result = sportsListData.concat(specialSports);
          this.getSportsListCallLastResponse$.next(result);

          return result;
        }),
        catchError(err => {
          // If an error happens, clear last call time so that next time the call is re-done
          this.getSportsListCallTime$.next(undefined);
          throw err;
        })
      );
    } else {
      return this.getSportsListCallLastResponse$.pipe(filter(sports => sports.length > 0));
    }
  }

  private parsePrematchMatches(data: any): SportModel[] {
    const retVal: SportModel[] = [];
    data.Sports.forEach(sport => {
      const newSport: SportModel = new SportModel({
        id: sport.SportID,
        menuUniqueId: sport.MenuUniqueID,
        name: sport.SportName,
        groupingType: sport.GroupingType,
        oddCount: sport.NoOfOdds,
        order: sport.Order,
        categories: [],
      });

      sport.Category.forEach(category => {
        const newCategory = new CategoryModel({
          id: category.ItemID,
          name: category.ItemName,
          oddCount: category.NoOfOdds,
          sportId: sport.SportID,
          menuUniqueId: sport.MenuUniqueID,
          menuUniqueName: sport.SportName,
          tournaments: [],
        });

        category.Tournaments.forEach(tournament => {
          const newTournament = new TournamentModel({
            eventCount: tournament.ItemEventCount,
            id: tournament.ItemID,
            name: tournament.ItemName,
            oddCount: tournament.NoOfOdds,
            initRegionId: tournament.InitRegionID,
            initAreaId: tournament.InitAreaID,
          });
          newCategory.tournaments.push(newTournament);
        });
        newSport.categories.push(newCategory);
      });

      retVal.push(newSport);
    });

    return retVal;
  }

  private parsePrematchMatch(data: any): SportModel {
    const retVal: SportModel = new SportModel({
      id: data.SportID,
      menuUniqueId: data.MenuUniqueID,
      name: data.SportName,
      groupingType: data.GroupingType,
      oddCount: data.NoOfOdds,
      order: data.Order,
      categories: [],
    });

    data.Category.forEach(category => {
      const newCategory = new CategoryModel({
        id: category.ItemID,
        name: category.ItemName,
        oddCount: category.NoOfOdds,
        sportId: data.SportID,
        menuUniqueId: data.MenuUniqueID,
        menuUniqueName: data.SportName,
        tournaments: [],
      });

      category.Tournaments.forEach(tournament => {
        const newTournament = new TournamentModel({
          eventCount: tournament.ItemEventCount,
          id: tournament.ItemID,
          name: tournament.ItemName,
          oddCount: tournament.NoOfOdds,
          initRegionId: tournament.InitRegionID,
          initAreaId: tournament.InitAreaID,
        });
        newCategory.tournaments.push(newTournament);
      });
      retVal.categories.push(newCategory);
    });

    return retVal;
  }

  private parsePrematchMatchFlattened(data: any): FlattenedSportModel {
    const retVal: FlattenedSportModel = {
      name: data.SportName,
      id: data.SportID,
      order: data.Order,
      oddCount: data.NoOfOdds,
      tournaments: [],
    };

    data.Category.forEach(category => {
      category.Tournaments.forEach(tournament => {
        const newTournament = new TournamentModel({
          eventCount: tournament.ItemEventCount,
          id: tournament.ItemID,
          name: tournament.ItemName,
          oddCount: tournament.NoOfOdds,
          initRegionId: tournament.InitRegionID,
          initAreaId: tournament.InitAreaID,
        });
        retVal.tournaments.push(newTournament);
      });
    });

    // Sorting tournaments list by oddCount
    const reorderedTournaments = [...retVal.tournaments].sort((a, b) => b.oddCount - a.oddCount);
    retVal.tournaments = reorderedTournaments;
    const newFlattenedSport = new FlattenedSportModel(retVal);

    return newFlattenedSport;
  }

  private updateEventSelection(eventSelection: Partial<EventSelectionState>): void {
    this.sportStore.updateEventSelection(eventSelection);
  }
}
