import { Injectable } from '@angular/core';
import { CityModel, CountryModel, IdTypeModel, StateModel, TelephoneExtensionModel } from 'src/app/shared/models/location.model';

import { Store, StoreConfig } from '@datorama/akita';
import {
  CurrencyModel,
  RegistrationState,
  RegistrationSuccessContentModel,
  RegistrationType,
  SplitRegistrationContentModel,
  UpsellRegistrationSuccessContentModel,
} from 'src/app/shared/models/registration.model';

function createInitialState(): RegistrationState {
  return {
    activationOption: 'none',
    registerOptionTriggered: false,
    verificationInProgress: false,
    currencies: undefined,
    countries: undefined,
    statesOfResidence: undefined,
    cities: undefined,
    telephoneExtensions: undefined,
    newAgentForm: {
      title: undefined,
      formContent: undefined,
    },
    credentials: {
      user: undefined,
      pass: undefined,
    },
    registrationType: RegistrationType.Normal,
    registrationSuccessContent: undefined,
    upsellRegistrationSuccessContent: undefined,
    splitRegistrationContent: undefined,
    isF2PUpsellRegistration: false,
    isSuperPickUser: false,
    idTypes: undefined,
    superPickToken: '',
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'registration' })
export class RegistrationStore extends Store<RegistrationState> {
  constructor() {
    super(createInitialState());
  }

  updateActivationOption(activationOption: string): void {
    this.update({ activationOption });
  }

  updateRegisterOptionTriggered(registerOptionTriggered: boolean): void {
    this.update({ registerOptionTriggered });
  }

  updateVerificationInProgress(verificationInProgress: boolean): void {
    this.update({ verificationInProgress });
  }

  updateCurrencies(currencies: CurrencyModel[]): void {
    this.update({ currencies });
  }

  updateCountries(countries: CountryModel[]): void {
    this.update({ countries });
  }

  updateStates(statesOfResidence: StateModel[]): void {
    this.update({ statesOfResidence });
  }

  updateCities(cities: CityModel[]): void {
    this.update({ cities });
  }

  updateTelephoneExtensions(telephoneExtensions: TelephoneExtensionModel[]): void {
    this.update({ telephoneExtensions });
  }

  updateNewAgentForm(newAgentData: any): void {
    this.update({ newAgentForm: newAgentData });
  }

  updateCredentials(credentialsData: any): void {
    this.update({ credentials: credentialsData });
  }

  clearCredentials(): void {
    this.update({
      credentials: {
        user: undefined,
        pass: undefined,
      },
    });
  }

  updateRegistrationType(registrationType: RegistrationType): void {
    this.update({ registrationType });
  }

  updateRegistrationSuccessContent(registrationSuccessContent: RegistrationSuccessContentModel): void {
    this.update({ registrationSuccessContent });
  }

  updateUpsellRegistrationSuccessContent(upsellRegistrationSuccessContent: UpsellRegistrationSuccessContentModel): void {
    this.update({ upsellRegistrationSuccessContent });
  }

  updateSplitRegistrationContent(splitRegistrationContent: SplitRegistrationContentModel): void {
    this.update({ splitRegistrationContent });
  }

  updateIsF2PUpsellRegistration(isF2PUpsellRegistration: boolean): void {
    this.update({ isF2PUpsellRegistration });
  }

  updateIdTypes(idTypes: IdTypeModel[]): void {
    this.update({ idTypes });
  }
  updateIsSuperPickUser(isSuperPickUser: boolean): void {
    this.update({ isSuperPickUser });
  }
  updateIsSuperToken(superPickToken: string): void {
    this.update({ superPickToken });
  }
}
