<div *ngFor="let odd of odds; let first = first; let last = last; trackBy: idTrackBy">
  <div class="selection-name" *ngIf="showSelectionName">
    {{ odd.selectionName }}
  </div>
  <app-odd
    [odd]="odd"
    [multiple]="odds.length > 0"
    [first]="first"
    [last]="last"
    [eventLocation]="eventLocation"
    [itemIndex]="itemIndex"
    [isLive]="isLive"
    [haveSpreadSelector]="haveSpreadSelector"
    >
  </app-odd>
</div>
