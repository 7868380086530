<div class="promos-container">
  <h2 class="title" i18n>
    Promos
  </h2>

  <div class="promos-card-container" body>
    <div class="promos-available">
      <ng-container *ngFor="let bonus of (accountQuery.bonusInfo$ | async); trackBy: bonusTrackBy">
        <app-card [titleHeaderText]="bonus.name">
          <div body>
            <div class="image">
              <div class="image-content"
                [ngStyle]="{ 'background-image': 'url(' + accountQuery.bonusInfoByCode(bonus.bonusCode).imageURL + ')' }">
              </div>
            </div>
            <div class="bonus-summary" [@expandCollapse]="viewBonusCode !== bonus.bonusCode ? 'expanded' : 'collapsed'">
              {{ bonus.summary }}
            </div>
            <div class="bonus-content" [@expandCollapse]="viewBonusCode === bonus.bonusCode ? 'expanded' : 'collapsed'"
              [innerHTML]="bonus.content">
            </div>
            <div class="buttons">
              <app-button i18n-text text="More Info" *ngIf="viewBonusCode !== bonus.bonusCode"
                (btnClick)="onMoreInfoClicked(bonus.bonusCode)"></app-button>
              <app-button i18n-text text="Less Info" *ngIf="viewBonusCode === bonus.bonusCode"
                (btnClick)="onLessInfoClicked()"></app-button>
            </div>
          </div>
        </app-card>
      </ng-container>
    </div>

    <div class="promos-unavailable" *ngIf="(accountQuery.hasBonusInfo$ | async) === false" i18n>
      There are currently no promotions available.
      Please check back soon later.
    </div>
  </div>
</div>
