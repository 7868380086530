import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductSwitcherItemContent } from 'src/app/modules/header/models/header.model';

@Component({
  selector: 'header-bk-product-switcher-item',
  templateUrl: './bk-product-switcher-item.component.html',
  styleUrls: ['./bk-product-switcher-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BKProductSwitcherItemComponent {
  @Input() isSelected = false;
  @Input() item: ProductSwitcherItemContent;
}
