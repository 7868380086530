import { ChangeDetectionStrategy, Component } from '@angular/core';
import { fadeIn } from 'src/app/shared/animations';

@Component({
  selector: 'app-coupon-page-wrapper',
  templateUrl: './coupon-page-wrapper.component.html',
  styleUrls: ['./coupon-page-wrapper.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn()],
})
export class CouponPageWrapperComponent {}
