import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { ApplicationService } from 'src/app/core/services/application.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { expandCollapseSimpler } from 'src/app/shared/animations';
import { RebetService } from 'src/app/core/services/rebet.service';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
@Component({
  selector: 'app-rebet',
  templateUrl: './rebet.component.html',
  styleUrls: ['./rebet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expandCollapseSimpler()],
})
export class RebetComponent implements OnDestroy {
  @Input() smallButton = false;
  @Input() couponCode: string = undefined;
  @Input() couponCanBeRebet = false;
  @Input() selectionCount: number;

  readonly rebetError$ = new BehaviorSubject(false);
  private readonly destroy$ = new Subject<boolean>();

  constructor(
    readonly accountQuery: AccountQuery,
    readonly swalTargets: SwalPortalTargets,
    private readonly couponQuery: CouponQuery,
    private readonly rebetService: RebetService,
    private readonly applicationService: ApplicationService,
    private readonly notificationService: NotificationService
  ) {}

  navigateHome(): void {
    this.applicationService.navigateHome();
  }

  rebetCoupon(): void {
    if (this.couponQuery.hasCouponData) {
      this.notificationService.showCustomNotification(
        $localize`Rebetting this coupon will clear all current selections you have on the betslip. Would you like to continue?`,
        'question',
        () => {
          this.performRebet();
        },
        $localize`Continue`,
        $localize`Rebet Selection`,
        undefined,
        'Cancel'
      );
    } else {
      this.performRebet();
    }
  }

  private performRebet() {
    this.rebetService
      .performRebet(this.couponCode)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.rebetError$.next(!data);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
