import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { MenuQuery } from 'src/app/core/state/menu/menu.query';
import { SportQuery } from 'src/app/core/state/sport/sport.query';
import { fadeInBy, fadeInByStaggered } from 'src/app/shared/animations';
import { MenuTab } from 'src/app/shared/models/menu.model';
import { SportModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-menu-sports',
  templateUrl: './menu-sports.component.html',
  styleUrls: ['./menu-sports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInByStaggered(), fadeInBy()],
})
export class MenuSportsComponent implements OnInit {
  searchFieldSports = new FormControl('');
  menuTab = MenuTab;
  favourites$: Observable<SportModel[]>;
  allSports$: Observable<SportModel[]>;
  selectedSport$: Observable<SportModel>;

  constructor(public menuQuery: MenuQuery, public sportQuery: SportQuery) {}

  ngOnInit(): void {
    const filterChange$ = this.searchFieldSports.valueChanges.pipe(startWith(''), distinctUntilChanged());

    this.favourites$ = combineLatest([this.sportQuery.favouriteSports$, filterChange$]).pipe(
      map(([favourites, searchTerm]) => favourites.filter(this.sportNameMatches(searchTerm)))
    );

    this.allSports$ = combineLatest([this.sportQuery.sportsList$, this.sportQuery.outrights$, filterChange$]).pipe(
      map(([sports, outrights, searchTerm]) =>
        // Remove duplicates and handle serach terms
        [...sports, ...outrights.filter(outright => sports.findIndex(sport => sport.id === outright.id) < 0)].filter(
          this.sportNameMatches(searchTerm)
        )
      )
    );

    this.selectedSport$ = this.sportQuery.selectedSport$;
  }

  private sportNameMatches(strToMatch: string): (sport: SportModel) => boolean {
    return (sport: SportModel) => sport.name.toLowerCase().indexOf(strToMatch.toLowerCase()) > -1;
  }
}
