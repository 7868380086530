<app-header [headerPinToTop]="headerPinToTop"></app-header>
<div
  *ngIf="showBreadcrumbs && (applicationQuery.embedConfig$ | async)?.hideBreadcrumbs !== true"
  data-testid="breadcrumb-container"
  class="breadcrumb-container"
  [class.no-header]="!showHeader"
  [ngClass]="[
    class,
    (accountQuery.userData$ | async) && (accountQuery.userData$ | async).userStatus === 'PCHANG' ? 'disable-container' : ''
  ]"
>
  <div class="breadcrumb-container-title">
    <i class="fa fa-caret-left" i18n-title title="Back" (click)="goBack()" aria-hidden="true" *ngIf="goBackIcon === 'caret-left'"></i>
    <span class="material-icons-outlined close" title="Close" (click)="goBack()" aria-hidden="true" *ngIf="goBackIcon === 'close'">{{
      'close'
    }}</span>
    <div class="title">
      <div class="text">{{ title }}</div>
    </div>
    <div *ngIf="showTooltip" class="info-icon material-icons" (click)="toggleTooltip()">{{ 'info' }}</div>
    <div *ngIf="activeTooltip | async" class="tooltip-info">
      <span [innerHTML]="tooltipText"></span>
      <div class="close" (click)="closeTooltip()">
        <i class="material-icons icon">{{ 'close' }}</i>
      </div>
    </div>
  </div>
</div>
