import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { CurrencyService } from 'src/app/core/services/currency.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';

@Pipe({
  name: 'currencyFormat',
})
export class CurrencyFormatPipe extends DecimalPipe implements PipeTransform {
  constructor(
    private readonly applicationQuery: ApplicationQuery,
    private readonly languageService: LanguageService,
    private readonly currencyService: CurrencyService
  ) {
    super(languageService.selectedLanguage ? languageService.selectedLanguage.language : 'en');
  }

  transform(value: null | undefined, digitsInfo?: string, locale?: string, approximate?: boolean, excludeSymbol?: boolean): null;

  transform(
    value: number | string | null | undefined,
    digitsInfo?: string,
    locale?: string,
    approximate?: boolean,
    excludeSymbol?: boolean
  ): string | null;

  transform(input: number | string, digitsInfo?: string, locale?: string, approximate?: boolean, excludeSymbol?: boolean): string {
    let value = input;
    if (value === undefined) {
      value = 0;
    }

    // The below is used to truncate the value to 2 decimal places Ex: from 99.9999 to 99.99 (added during task BTK-2655)
    // added a -? to the redex to accept negative values (as per PAY-1448 bug fix)
    const truncatedValue = approximate ? Number(value) : Number(value.toString().match(/^-?\d+(?:\.\d{0,2})?/));
    const isNegative = !isNaN(value as any) ? (value as number) < 0 : false;
    const superFormat = super.transform(
      isNegative ? truncatedValue * -1 : truncatedValue,
      digitsInfo ? digitsInfo : this.currencyService.getCurrency(this.applicationQuery.currency).format,
      this.languageService.selectedLanguage.language
    );
    const negativePart = isNegative ? '-' : '';
    const whitespacePart = excludeSymbol ? '' : this.currencyService.getCurrency(this.applicationQuery.currency).hasSpace ? ' ' : '';
    const currencySymbolPart = excludeSymbol ? '' : this.currencyService.getCurrency(this.applicationQuery.currency).symbol;

    return this.currencyService.getCurrency(this.applicationQuery.currency).inverse
      ? `${negativePart}${superFormat}${whitespacePart}${currencySymbolPart}`
      : `${negativePart}${currencySymbolPart}${whitespacePart}${superFormat}`;
  }
}
