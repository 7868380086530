import { AfterContentInit, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appBadge]',
})
export class BadgeDirective implements OnChanges, AfterContentInit {
  @Input() appBadge: number;
  @Input() badgeClass = 'badge';
  @Input() badgeSize = 20; // Height in pixels
  @Input() rightAdjustment = 0; // If needed, you can adjust the 'right' css property
  @Input() topAdjustment = 0; // If needed, you can adjust the 'right' css property
  fontSizeToWidthRation = 0.8;

  constructor(public elementRef: ElementRef, readonly renderer2: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appBadge && changes.appBadge.previousValue !== changes.appBadge.currentValue) {
      this.handleView();
    }
  }

  ngAfterContentInit(): void {
    this.renderer2.addClass(this.elementRef.nativeElement, this.badgeClass);
    this.renderer2.setStyle(this.elementRef.nativeElement, 'font-size', `${Math.round(this.badgeSize * this.fontSizeToWidthRation)}px`);
    this.renderer2.setStyle(this.elementRef.nativeElement, 'height', `${this.badgeSize}px`);
    this.renderer2.setStyle(this.elementRef.nativeElement, 'min-width', `${this.badgeSize}px`);
    if (this.rightAdjustment !== undefined) {
      this.renderer2.setStyle(this.elementRef.nativeElement, 'right', `${this.rightAdjustment}px`);
    }

    if (this.topAdjustment !== undefined) {
      this.renderer2.setStyle(this.elementRef.nativeElement, 'top', `${this.topAdjustment}px`);
    }
    this.renderer2.setStyle(this.elementRef.nativeElement.parentNode, 'position', 'relative');
  }

  private handleView(): void {
    this.elementRef.nativeElement.innerHTML = `<span ${this.getLongTextStyle()}>${this.appBadge}</span>`;
  }

  /* Reduce fontsize for texts longer than 2 char */
  private getLongTextStyle(): string {
    return this.appBadge.toString().length > 2
      ? `style="font-size: ${Math.floor(this.badgeSize * this.fontSizeToWidthRation * 0.8)}px;"`
      : '';
  }
}
