import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appUnselectAll]',
})
export class UnselectAllDirective {
  @HostListener('click', ['$event.target']) onClick(target: any): void {
    target.blur();
  }
}
