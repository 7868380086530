import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { circleLoaderSuccess } from 'src/assets/json/lottie-configs';

@Component({
  selector: 'mobver-success',
  templateUrl: './verify-success.component.html',
  styleUrls: ['./verify-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileVerificationCampaignSuccessComponent {
  @Input() content = {
    header: '',
    bodyHTML: '',
    ctaText: 'Proceed',
    ctaURL: '/',
  };
  circleLoaderSuccess = circleLoaderSuccess;
  ctaAdditionalStyles = {
    fontSize: '14px',
    minWidth: '110px',
    height: '40px',
  };

  constructor(private readonly router: Router) {}

  proceedClicked(): void {
    this.router.navigateByUrl(this.content ? this.content.ctaURL : '/');
  }
}
