<div class="event-container">
  <div *ngIf="(hasBetBuilderConditions$ | async) === false; else expiredBetBuilderSelection">
    <div class="event-details-container">
      <div class="event-details">
        <span class="live" *ngIf="eventCategory === 'L'">
          <span class="material-icons">{{ 'schedule' }}</span>
          <span class="label" i18n>Live</span>
        </span>
        <div class="selection-name">{{ selectionName }}</div>
      </div>
    </div>
    <div class="market-odds-container">
      <div class="markets" [class.no-edit]="bookedCoupon || eventCategory === 'L'">
        <div class="details-container">
          <div class="market-details">
            <div class="market-name">
              <span>{{ marketName }} -</span>
            </div>
          </div>
          <div class="selection-details">
            <div class="selection-details-content">
              <div class="event-name">{{ eventName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="odd-details-row">
    <div class="odd-details">
      <div class="expired" i18n>Expired</div>
    </div>
    <span class="close-odd material-icons" *ngIf="!bookedCoupon">{{ 'delete' }}</span>
  </div>

  <ng-template #expiredBetBuilderSelection>
    <div class="event-details-container bet-builder-content one-market">
      <div class="event-details-betbuilder">
        <div i18n class="market-name">{{ marketName }}</div>
        <div class="event-name">{{ eventName }}</div>
        <div class="bet-builder-conditions">
          <div *ngFor="let condition of betBuilderConditions" class="bet-builder-condition">
            <div i18n class="bet-builder-condition-icon"><span></span></div>
            <div class="bet-builder-condition-text">{{ condition }}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
