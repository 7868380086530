<button
  (click)="scrollToTopClick()"
  *ngIf="showButton$ | async"
  [@fadeInOut]
  appScrollToTopPositioner
  class="scroll-to-top-button ripple"
  [class.navbar-padding]="applicationQuery.showNavbar$ | async"
  [class.navbar-betslip-selection-padding]="this.applicationQuery.isShowingNavbarBetslipSelections$ | async"
  [class.navbar-accumulator-bonus-progression-padding]="this.applicationQuery.isShowingNavbarBetslipAccumulatorBonusBar$ | async"
  [class.navbar-accumulator-bonus-unacceptable-selections-prompt-padding]="
    this.applicationQuery.isShowingAccumulatorBonusUnacceptableSelectionsPrompt$ | async
  "
>
  <i class="fa fa-arrow-up" aria-hidden="true"></i>
</button>
