import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationService } from 'src/app/core/services/application.service';
import { CookieService } from 'src/app/core/services/cookie.service';
import { GeoAccessControlService } from 'src/app/core/services/geo-access-control.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { GeoAccessControlQuery } from 'src/app/core/state/geo-access-control/geo-access-control.query';
import { CountryListItemDetails } from 'src/app/shared/models/geo-access-control.model';

@Component({
  selector: 'app-country-selection',
  templateUrl: './app-country-selection.component.html',
  styleUrls: ['./app-country-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountrySelectionComponent implements OnInit, OnDestroy {
  readonly siteLocaleData$: BehaviorSubject<CountryListItemDetails>;
  readonly userLocaleData$: BehaviorSubject<CountryListItemDetails>;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    readonly geoAccessQuery: GeoAccessControlQuery,
    private readonly applicationService: ApplicationService,
    private readonly cookieService: CookieService,
    private readonly geoAccessService: GeoAccessControlService,
    private readonly loggingService: LoggerService,
    private readonly router: Router
  ) {
    this.siteLocaleData$ = new BehaviorSubject<CountryListItemDetails>(undefined);
    this.userLocaleData$ = new BehaviorSubject<CountryListItemDetails>(undefined);
  }

  ngOnInit(): void {
    this.applicationService.hideNavbar();

    if (!this.geoAccessService.tldRedirectionEnabled || !this.geoAccessService.siteCountryCode) {
      // the country selection feature is disabled
      this.router.navigateByUrl('/');
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(this.geoAccessService.clearCookieQS) && urlParams.get(this.geoAccessService.clearCookieQS) === 'true') {
      this.cookieService.clearCookie(this.geoAccessService.csCookieName);
    }

    const savedCountryCode = this.cookieService.getCookie(this.geoAccessService.csCookieName);
    if (savedCountryCode && savedCountryCode.toUpperCase() === this.geoAccessService.siteCountryCode.toUpperCase()) {
      // the user is accessing the BetKing site that's saved in their country selection cookie.
      this.router.navigateByUrl('/');
      return;
    }

    forkJoin([this.geoAccessService.getUserCountry(), this.geoAccessService.getCountrySelectionDetails()])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const disableRedirect = urlParams.has(this.geoAccessService.disableRedirectQS)
          ? urlParams.get(this.geoAccessService.disableRedirectQS) === 'true'
          : false;
        if (savedCountryCode && !disableRedirect) {
          const savedCountryData = this.geoAccessQuery.countrySelectionDetails.countryList.find(c => c.countryCode === savedCountryCode);
          if (savedCountryData) {
            this.goToSite(savedCountryData);
            return;
          } else {
            this.loggingService.logEvent(
              'GAC Error',
              `Unable to get country details for saved country code "${savedCountryCode}". Clearing the stored cookie value`,
              SeverityLevel.Error
            );
            this.cookieService.clearCookie(this.geoAccessService.csCookieName);
          }
        }

        const siteLocaleData = this.geoAccessQuery.countrySelectionDetails.countryList.find(
          c => c.countryCode === this.geoAccessService.siteCountryCode
        );
        const userLocaleData = this.geoAccessQuery.countrySelectionDetails.countryList.find(
          c => c.countryCode === this.geoAccessQuery.userCountryCode
        );
        if (!siteLocaleData || !userLocaleData) {
          // redirect to the site's home page if the country selection details doesn't contain
          // an entry for the user's country code.
          this.router.navigateByUrl('/');
          return;
        }

        this.siteLocaleData$.next(siteLocaleData);
        this.userLocaleData$.next(userLocaleData);
      });
  }

  goToSite(countryDetails: CountryListItemDetails): void {
    this.geoAccessService.setCookieAndRedirect(countryDetails.countryCode, countryDetails.siteUrl);
  }

  ngOnDestroy(): void {
    this.applicationService.showNavbar();
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
