import { Pipe, PipeTransform } from '@angular/core';
import { RecentBetModel } from 'src/app/modules/my-bets/models/my-bets.model';

@Pipe({
  name: 'filterSearch',
})
export class FilterSearchPipe implements PipeTransform {
  transform(array: RecentBetModel[], search: string): any {
    if (!array || !search) {
      return array;
    }
    return array.filter(
      item =>
        item.couponDate.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        item.couponCode.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        numberWithCommas(item.stakeGross.toFixed(2)).indexOf(search.toLowerCase()) !== -1
    );
  }
}

function numberWithCommas(value: string): string {
  const parts = value.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (parts[1]) {
    parts[1] = parts[1].substring(0, 2);
  }
  return parts.join('.');
}
