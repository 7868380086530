import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { MatchStatisticsTab } from 'src/app/modules/sport/models/event-view/prematch.model';
import {
  AssistsModel,
  CardsModel,
  GoalscorerModel,
  HeadToHeadModel,
  LastMatchesModel,
  LeagueRankingModel,
  MatchStatisticsModel,
  NextMatchesModel,
  OverUnderModel,
  StatisticsState,
  WinProbabilityModel,
} from 'src/app/shared/models/statistics.model';

function createInitialState(): StatisticsState {
  return {
    matchStatisticsData: undefined,
    headToHeadData: undefined,
    winProbabilityData: undefined,
    lastMatchesData: undefined,
    nextMatchesData: undefined,
    overUnderData: undefined,
    goalscorerData: undefined,
    assistsData: undefined,
    cardsData: undefined,
    leagueRankingData: undefined,
    ui: {
      selectedEventId: -1,
      expandedStatistics: false,
    },
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'statistics' })
export class StatisticsStore extends Store<StatisticsState> {
  constructor() {
    super(createInitialState());
  }

  updateUI(ui: Partial<StatisticsState['ui']>): void {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui,
      },
    }));
  }

  clearStatisticsData(): void {
    this.update(createInitialState());
  }

  updateMatchStatisticsData(matchStatisticsData: MatchStatisticsModel): void {
    if (!matchStatisticsData) {
      this.clearMatchStatisticsData();
      return;
    }

    this.update({
      matchStatisticsData,
    });
  }

  clearMatchStatisticsData(): void {
    this.update({
      matchStatisticsData: undefined,
    });
  }

  updateHeadToHeadData(headToHeadData: HeadToHeadModel): void {
    if (!headToHeadData) {
      this.clearHeadToHeadData();
      return;
    }

    this.update({
      headToHeadData,
    });
  }

  clearHeadToHeadData(): void {
    this.update({
      headToHeadData: undefined,
    });
  }

  updateWinProbabilityData(winProbabilityData: WinProbabilityModel): void {
    if (!winProbabilityData) {
      this.clearWinProbabilityData();
      return;
    }

    this.update({
      winProbabilityData,
    });
  }

  clearWinProbabilityData(): void {
    this.update({
      winProbabilityData: undefined,
    });
  }

  updateLastMatchesData(lastMatchesData: LastMatchesModel): void {
    if (!lastMatchesData) {
      this.clearLastMatchesData();
      return;
    }

    this.update({
      lastMatchesData,
    });
  }

  clearLastMatchesData(): void {
    this.update({
      lastMatchesData: undefined,
    });
  }

  updateNextMatchesData(nextMatchesData: NextMatchesModel): void {
    if (!nextMatchesData) {
      this.clearNextMatchesData();
      return;
    }

    this.update({
      nextMatchesData,
    });
  }

  clearNextMatchesData(): void {
    this.update({
      nextMatchesData: undefined,
    });
  }

  updateOverUnderData(overUnderData: OverUnderModel): void {
    if (!overUnderData) {
      this.clearOverUnderData();
      return;
    }

    this.update({
      overUnderData,
    });
  }

  clearOverUnderData(): void {
    this.update({
      overUnderData: undefined,
    });
  }

  updateGoalscorerData(goalscorerData: GoalscorerModel): void {
    if (!goalscorerData) {
      this.clearGoalscorerData();
      return;
    }

    this.update({
      goalscorerData,
    });
  }

  clearGoalscorerData(): void {
    this.update({
      goalscorerData: undefined,
    });
  }

  updateAssistsData(assistsData: AssistsModel): void {
    if (!assistsData) {
      this.clearAssistsData();
      return;
    }

    this.update({
      assistsData,
    });
  }

  clearAssistsData(): void {
    this.update({
      assistsData: undefined,
    });
  }

  updateCardsData(cardsData: CardsModel): void {
    if (!cardsData) {
      this.clearCardsData();
      return;
    }

    this.update({
      cardsData,
    });
  }

  clearCardsData(): void {
    this.update({
      cardsData: undefined,
    });
  }

  updateLeagueRankingData(leagueRankingData: LeagueRankingModel): void {
    if (!leagueRankingData) {
      this.clearLeagueRankingData();
      return;
    }

    this.update({
      leagueRankingData,
    });
  }

  clearLeagueRankingData(): void {
    this.update({
      leagueRankingData: undefined,
    });
  }

  clearSelectedEventId(): void {
    this.updateUI({ selectedEventId: -1 });
  }

  updateSelectedEventId(selectedEventId: number): void {
    if (!selectedEventId) {
      this.clearSelectedEventId();
      return;
    }

    this.updateUI({ selectedEventId });
  }

  updateStatisticTabs(
    liveListStatisticsTabs: MatchStatisticsTab[],
    liveEventStatisticsTabs: MatchStatisticsTab[],
    preMatchListStatisticsTabs: MatchStatisticsTab[],
    preMatchEventStatisticsTabs: MatchStatisticsTab[],
    enableCPPLiveWidget: boolean,
    enableCPPPreMatchWidget: boolean
  ): void {
    this.update({
      liveListStatisticsTabs,
      liveEventStatisticsTabs,
      preMatchListStatisticsTabs,
      preMatchEventStatisticsTabs,
      enableCPPLiveWidget,
      enableCPPPreMatchWidget,
    });
  }
}
