import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { MobileNumberInputService } from 'src/app/core/services/account/mobile-number-input.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { RegistrationQuery } from 'src/app/core/state/registration/registration.query';
import { MobileVerifactionMobileInputContent } from 'src/app/shared/models/mobile-verification-campaign/mobile-verification-campaign.model';

@Component({
  selector: 'mobver-mobile-number-verification',
  templateUrl: './mobile-number-verification.component.html',
  styleUrls: ['./mobile-number-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileNumberVerificationComponent implements OnInit, OnDestroy {
  @Input() content: MobileVerifactionMobileInputContent = {
    phoneNumberInputHeader: '',
    phoneNumberInputMessage: '',
    phoneVerificationSendCodeCTAText: 'Proceed',
  };
  @Output() readonly proceedClicked = new EventEmitter();

  disableCTAForward$ = new BehaviorSubject<boolean>(false);
  numberChanged$ = new BehaviorSubject<boolean>(false);
  numberVerificationResponseCode$ = new BehaviorSubject<number>(0);
  showTelExtDropdownArrow = true;

  phoneVerificationForm: FormGroup = new FormGroup({
    mobileNumber: new FormControl('', [Validators.required, Validators.pattern(this.appConfig.get('registration').mobileRegex)]),
  });

  ctaAdditionalStyles = {
    fontSize: '14px',
    minWidth: '110px',
    height: '40px',
  };

  private readonly loginPrefix = this.appConfig.get('account').loginPrefix;
  private readonly destroy$ = new Subject<boolean>();

  constructor(
    readonly registrationQuery: RegistrationQuery,
    private readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly appConfig: AppConfigService,
    private readonly mobileNumberInputService: MobileNumberInputService
  ) {}

  ngOnInit(): void {
    this.phoneVerificationForm.controls.mobileNumber.setValue(this.accountQuery.userData.mobile.mobileNumber.replace(this.loginPrefix, ''));
    this.phoneVerificationForm.controls.mobileNumber.markAsTouched();
    this.phoneVerificationForm.controls.mobileNumber.markAsDirty();

    this.phoneVerificationForm.controls.mobileNumber.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(mobileNumber => {
      this.numberChanged$.next(this.accountQuery.userData.mobile.mobileNumber !== `${this.loginPrefix}${mobileNumber}`);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  verifyNumber(): void {
    if (!(this.phoneVerificationForm.pristine || this.phoneVerificationForm.status !== 'VALID' || this.disableCTAForward$.getValue())) {
      // check mobile Number if it has '0' in front
      const mobileNumber = this.mobileNumberInputService.mobileNumberCleanup(this.phoneVerificationForm.controls.mobileNumber.value);

      this.disableCTAForward$.next(true);
      this.numberVerificationResponseCode$.next(0);
      this.accountService.verifyMobileNumber(`${this.loginPrefix}${mobileNumber}`).subscribe(
        response => {
          this.disableCTAForward$.next(false);
          if (response[0]) {
            this.proceedClicked.emit({
              prefix: `+${this.loginPrefix}`,
              number: mobileNumber,
              numberChanged: this.numberChanged$.getValue(),
            });
          } else {
            this.numberVerificationResponseCode$.next(response[1]);
          }
        },
        response => {
          // error
          this.disableCTAForward$.next(false);
          if (response.error.SystemMessage === 'Time period for OTP regenerated still not exceeded') {
            // Although an error was triggered, we still should show OTP screen to user even if a new OTP code was not sent.
            this.proceedClicked.emit({
              prefix: `+${this.loginPrefix}`,
              number: mobileNumber,
              numberChanged: this.numberChanged$.getValue(),
            });
          } else {
            this.numberVerificationResponseCode$.next(response.error ? response.error.ResponseCode : -1);
          }
        }
      );
    }
  }
}
