<div [class.statistics-container]="(cppWidget$ | async) === false" *ngIf="enableStatistics$ | async" [@fadeIn]>
  <app-cpp-widget-statistics *ngIf="cppWidget$ | async; else default" [statisticsType]="statisticsType" [eventLocation]="eventLocation"></app-cpp-widget-statistics>
  <ng-template #default>
    <div class="close-icon" (click)="closeStatistics()">
      <div i18n>Close Stats</div>
      <i class="fa fa-times" aria-hidden="true"></i>
    </div>
    <!-- Win Probability -->
    <div class="header-tab" [class.selected]="headerTabSelected === headerTab.WinProbability"
      *ngIf="(statisticsQuery.winProbabilityData$ | async) && (isFootballGo$ | async)" (click)="headerTabSelected = headerTab.WinProbability">
      <i class="fa fa-percent left-icon" aria-hidden="true"></i>
      <div i18n>Win Probability</div>
      <i class="fa fa-caret-right right-icon" aria-hidden="true"></i>
    </div>
    <div class="head-to-head-content" [class.selected]="headerTabSelected === headerTab.WinProbability"
      *ngIf="statisticsQuery.winProbabilityData$ | async">
      <div class="section-found">
        <div class="team-wins-draws team-probability">
          <div class="team-home-wins home-win" [ngStyle]="{'width': (statisticsQuery.winProbabilityData$ | async).homeTeamProbability + '%'}">
            <span class="team-name">
              <span class="name text-left" i18n>Home</span>
              <span class="results-perc text-left">
                ({{ (statisticsQuery.winProbabilityData$ | async).homeTeamProbability }}%)</span>
            </span>
            <div class="status-bar probability">
              <div></div>
            </div>
          </div>
          <div class="team-draws draw" style="text-align: center;" [ngStyle]="{'width': (statisticsQuery.winProbabilityData$ | async).drawProbability + '%'}">
            <span class="team-name">
              <span class="name">{{ (statisticsQuery.winProbabilityData$ | async).drawProbability }}%</span>
            </span>
            <div class="status-bar probability draw">
              <div></div>
            </div>
            <span class="name" i18n>Draw</span>
          </div>
          <div class="team-away-wins away-win" [ngStyle]="{'width': (statisticsQuery.winProbabilityData$ | async).awayWinProbability + '%'}">
            <span class="team-name">
              <span class="name text-right" i18n>Away</span>
              <span class="results-perc text-right">
                ({{ (statisticsQuery.winProbabilityData$ | async).awayWinProbability }}%)</span>
            </span>
            <div class="status-bar probability">
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Head to Head-->
    <div class="header-tab" [class.selected]="headerTabSelected === headerTab.HeadToHead"
      *ngIf="statisticsQuery.headToHeadData$ | async" (click)="headerTabSelected = headerTab.HeadToHead">
      <i class="fa fa-shield left-icon" aria-hidden="true"></i>
      <div i18n>Head To Head</div>
      <i class="fa fa-caret-right right-icon" aria-hidden="true"></i>
    </div>
    <div class="head-to-head-content" [class.selected]="headerTabSelected === headerTab.HeadToHead"
      *ngIf="statisticsQuery.headToHeadData$ | async">
      <!-- <div class="section-not-found" i18n>Head To Head are not available for this match</div> -->
      <div class="section-found">
        <div class="team-wins-draws">
          <div class="team-home-wins">
            <span class="team-name">
              <span class="name">{{ (statisticsQuery.headToHeadData$ | async).homeTeamName }}</span>
              <span class="results-perc">{{ (statisticsQuery.headToHeadData$ | async).teamHomeWins }}
                ({{ (statisticsQuery.headToHeadData$ | async).teamHomeWinsPerc }}%)</span>
            </span>
            <div class="status-bar">
              <div [ngStyle]="{'width': (statisticsQuery.headToHeadData$ | async).teamHomeWinsPerc + '%'}"></div>
            </div>
          </div>
          <div class="team-draws">
            <span class="team-name">
              <span class="name" i18n>Draw(s)</span>
              <span class="results-perc">{{ (statisticsQuery.headToHeadData$ | async).teamDraws }}
                ({{ (statisticsQuery.headToHeadData$ | async).teamDrawsPerc }}%)</span>
            </span>
            <div class="status-bar">
              <div [ngStyle]="{'width': (statisticsQuery.headToHeadData$ | async).teamDrawsPerc + '%'}"></div>
            </div>
          </div>
          <div class="team-away-wins">
            <span class="team-name">
              <span class="name">{{ (statisticsQuery.headToHeadData$ | async).awayTeamName }}</span>
              <span class="results-perc">{{ (statisticsQuery.headToHeadData$ | async).teamAwayWins }}
                ({{ (statisticsQuery.headToHeadData$ | async).teamAwayWinsPerc }}%)</span>
            </span>
            <div class="status-bar">
              <div [ngStyle]="{'width': (statisticsQuery.headToHeadData$ | async).teamAwayWinsPerc + '%'}"></div>
            </div>
          </div>
        </div>
        <div class="headToHeadHistory">
          <table>
            <tbody>
              <tr
                *ngFor="let match of (statisticsQuery.headToHeadData$ | async).headToHeadHistory; trackBy: indexTrackBy;">
                <td class="team-home">
                  <div class="team-name">
                    <span class="name" [ngClass]="{'won': match.winner === 'home'}">{{ match.teamHomeName }}</span>
                    <span *ngIf="match.teamHomeLogo" class="logo" [ngStyle]="{'background-image': 'url(' + match.teamHomeLogo + ')'}"></span>
                    <span class="score">{{ match.teamHomeScore }}</span>
                  </div>
                  <div class="round">{{ match.round ? match.round : tournamentName }}</div>
                </td>
                <td class="divider"><span>:</span></td>
                <td class="team-away">
                  <div class="team-name">
                    <span class="score">{{ match.teamAwayScore }}</span>
                    <span *ngIf="match.teamAwayLogo" class="logo" [ngStyle]="{'background-image': 'url(' + match.teamAwayLogo + ')'}"></span>
                    <span class="name" [ngClass]="{'won': match.winner === 'away'}">{{ match.teamAwayName }}</span>
                  </div>
                  <div class="date">{{ match.date }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!--Last Matches-->
    <div class="header-tab" [class.selected]="headerTabSelected === headerTab.LastMatches"
      *ngIf="statisticsQuery.lastMatchesData$ | async" (click)="headerTabSelected = headerTab.LastMatches">
      <i class="fa fa-long-arrow-left left-icon" aria-hidden="true"></i>
      <div i18n>Last 5 Matches</div>
      <i class="fa fa-caret-right right-icon" aria-hidden="true"></i>
    </div>
    <div class="last-matches-content" [class.selected]="headerTabSelected === headerTab.LastMatches"
      *ngIf="statisticsQuery.lastMatchesData$ | async">
      <!-- <div class="section-not-found" i18n>Last 5 Matches are not available for this match</div> -->
      <div class="section-found">
        <div class="team-names">
          <div class="team-home" [class.selected]="lastMatchesTeamTabSelected === teamTab.Home"
            (click)="lastMatchesTeamTabSelected = teamTab.Home">
            <div class="name">{{ (statisticsQuery.lastMatchesData$ | async).homeTeamName }}</div>
            <div class="last-results">
              <i class="fa fa-caret-left" aria-hidden="true"></i>
              <span
                *ngFor="let result of (statisticsQuery.lastMatchesData$ | async).homeLastResults; trackBy: indexTrackBy;"
                [class]="result.result"><span>{{ result.result }}</span></span>
            </div>
          </div>
          <div class="team-away" [class.selected]="lastMatchesTeamTabSelected === teamTab.Away"
            (click)="lastMatchesTeamTabSelected = teamTab.Away">
            <div class="name">{{ (statisticsQuery.lastMatchesData$ | async).awayTeamName }}</div>
            <div class="last-results">
              <i class="fa fa-caret-left" aria-hidden="true"></i>
              <span
                *ngFor="let result of (statisticsQuery.lastMatchesData$ | async).awayLastResults; trackBy: indexTrackBy;"
                [class]="result.result"><span>{{ result.result }}</span></span>
            </div>
          </div>
        </div>
        <div class="last-matches-history" [class.selected]="lastMatchesTeamTabSelected === teamTab.Home">
          <table>
            <tbody>
              <tr *ngFor="let match of (statisticsQuery.lastMatchesData$ | async).homeMatches; trackBy: indexTrackBy;">
                <td class="team-home">
                  <div class="team-name">
                    <span class="name" [ngClass]="{'won': match.winner === 'home'}">{{ match.teamHomeName }}</span>
                    <span *ngIf="match.teamHomeLogo" class="logo" [ngStyle]="{'background-image': 'url(' + match.teamHomeLogo + ')'}"></span>
                    <span class="score">{{ match.teamHomeScore }}</span>
                  </div>
                  <div class="round">{{ match.round ? match.round : tournamentName }}</div>
                </td>
                <td class="divider"><span>:</span></td>
                <td class="team-away">
                  <div class="team-name">
                    <span class="score">{{ match.teamAwayScore }}</span>
                    <span *ngIf="match.teamAwayLogo" class="logo" [ngStyle]="{'background-image': 'url(' + match.teamAwayLogo + ')'}"></span>
                    <span class="name" [ngClass]="{'won': match.winner === 'away'}">{{ match.teamAwayName }}</span>
                  </div>
                  <div class="date">{{ match.date }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="last-matches-history" [class.selected]="lastMatchesTeamTabSelected === teamTab.Away">
          <table>
            <tbody>
              <tr *ngFor="let match of (statisticsQuery.lastMatchesData$ | async).awayMatches; trackBy: indexTrackBy;">
                <td class="team-home">
                  <div class="team-name">
                    <span class="name" [ngClass]="{'won': match.winner === 'home'}">{{ match.teamHomeName }}</span>
                    <span *ngIf="match.teamHomeLogo" class="logo" [ngStyle]="{'background-image': 'url(' + match.teamHomeLogo + ')'}"></span>
                    <span class="score">{{ match.teamHomeScore }}</span>
                  </div>
                  <div class="round">{{ match.round ? match.round : tournamentName }}</div>
                </td>
                <td class="divider"><span>:</span></td>
                <td class="team-away">
                  <div class="team-name">
                    <span class="score">{{ match.teamAwayScore }}</span>
                    <span *ngIf="match.teamAwayLogo" class="logo" [ngStyle]="{'background-image': 'url(' + match.teamAwayLogo + ')'}"></span>
                    <span class="name" [ngClass]="{'won': match.winner === 'away'}">{{ match.teamAwayName }}</span>
                  </div>
                  <div class="date">{{ match.date }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!--Next Matches-->
    <div class="header-tab" [class.selected]="headerTabSelected === headerTab.NextMatches"
      *ngIf="statisticsQuery.nextMatchesData$ | async" (click)="headerTabSelected = headerTab.NextMatches">
      <i class="fa fa-long-arrow-right left-icon" aria-hidden="true"></i>
      <div i18n>Next 5 Matches</div>
      <i class="fa fa-caret-right right-icon" aria-hidden="true"></i>
    </div>
    <div class="next-matches-content" [class.selected]="headerTabSelected === headerTab.NextMatches"
      *ngIf="statisticsQuery.nextMatchesData$ | async">
      <!-- <div class="section-not-found" i18n>Next 5 Matches are not available for this match</div> -->
      <div class="section-found">
        <div class="team-names">
          <div class="team-home" [class.selected]="nextMatchesTeamTabSelected === teamTab.Home"
            (click)="nextMatchesTeamTabSelected = teamTab.Home">
            <div class="name">{{ (statisticsQuery.nextMatchesData$ | async).homeTeamName }}</div>
          </div>
          <div class="team-away" [class.selected]="nextMatchesTeamTabSelected === teamTab.Away"
            (click)="nextMatchesTeamTabSelected = teamTab.Away">
            <div class="name">{{ (statisticsQuery.nextMatchesData$ | async).awayTeamName }}</div>
          </div>
        </div>
        <div class="next-matches-history" [class.selected]="nextMatchesTeamTabSelected === teamTab.Home">
          <table>
            <tbody>
              <tr *ngFor="let match of (statisticsQuery.nextMatchesData$ | async).homeMatches; trackBy: indexTrackBy;">
                <td class="team-home">
                  <div class="team-name">
                    <span class="name" [ngClass]="{'won': match.winner === 'home'}">{{ match.teamHomeName }}</span>
                    <span *ngIf="match.teamHomeLogo" class="logo" [ngStyle]="{'background-image': 'url(' + match.teamHomeLogo + ')'}"></span>
                    <span class="score">-</span>
                  </div>
                  <div class="round">{{ match.round ? match.round : tournamentName }}</div>
                </td>
                <td class="divider"><span>:</span></td>
                <td class="team-away">
                  <div class="team-name">
                    <span class="score">-</span>
                    <span *ngIf="match.teamAwayLogo" class="logo" [ngStyle]="{'background-image': 'url(' + match.teamAwayLogo + ')'}"></span>
                    <span class="name" [ngClass]="{'won': match.winner === 'away'}">{{ match.teamAwayName }}</span>
                  </div>
                  <div class="date">{{ match.date }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="next-matches-history" [class.selected]="nextMatchesTeamTabSelected === teamTab.Away">
          <table>
            <tbody>
              <tr *ngFor="let match of (statisticsQuery.nextMatchesData$ | async).awayMatches; trackBy: indexTrackBy;">
                <td class="team-home">
                  <div class="team-name">
                    <span class="name" [ngClass]="{'won': match.winner === 'home'}">{{ match.teamHomeName }}</span>
                    <span *ngIf="match.teamHomeLogo" class="logo" [ngStyle]="{'background-image': 'url(' + match.teamHomeLogo + ')'}"></span>
                    <span class="score">-</span>
                  </div>
                  <div class="round">{{ match.round ? match.round : tournamentName }}</div>
                </td>
                <td class="divider"><span>:</span></td>
                <td class="team-away">
                  <div class="team-name">
                    <span class="score">-</span>
                    <span *ngIf="match.teamAwayLogo" class="logo" [ngStyle]="{'background-image': 'url(' + match.teamAwayLogo + ')'}"></span>
                    <span class="name" [ngClass]="{'won': match.winner === 'away'}">{{ match.teamAwayName }}</span>
                  </div>
                  <div class="date">{{ match.date }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!--Over / Under-->
    <div class="header-tab" [class.selected]="headerTabSelected === headerTab.OverUnder"
      *ngIf="statisticsQuery.overUnderData$ | async" (click)="headerTabSelected = headerTab.OverUnder">
      <i class="fa fa-random left-icon" aria-hidden="true"></i>
      <div i18n>Over / Under</div>
      <i class="fa fa-caret-right right-icon" aria-hidden="true"></i>
    </div>
    <div class="over-under-content" [class.selected]="headerTabSelected === headerTab.OverUnder"
      *ngIf="statisticsQuery.overUnderData$ | async">
      <!-- <div class="section-not-found" i18n>Over / Under are not available for this match</div> -->
      <div class="section-found">
        <div class="top-section">
          <div class="left-selection">
            <div class="top">
              <div class="over-under-tab over-under-matches-tabs selected"
                [class.selected]="overUnderMatchesSelected === matchesTab.All"
                (click)="overUnderMatchesSelected = matchesTab.All" i18n>All Matches
              </div>
              <div class="over-under-tab over-under-matches-tabs"
                [class.selected]="overUnderMatchesSelected === matchesTab.Home"
                (click)="overUnderMatchesSelected = matchesTab.Home" i18n>Home Matches</div>
              <div class="over-under-tab over-under-matches-tabs"
                [class.selected]="overUnderMatchesSelected === matchesTab.Away"
                (click)="overUnderMatchesSelected = matchesTab.Away" i18n>Away Matches</div>
            </div>
            <div class="bottom">
              <div class="over-under-tab over-under-segment-tabs"
                [class.selected]="overUnderSegmentSelected === segmentTab.FullTime"
                (click)="overUnderSegmentSelected = segmentTab.FullTime" i18n>Full Time
              </div>
              <div class="over-under-tab over-under-segment-tabs"
                [class.selected]="overUnderSegmentSelected === segmentTab.FirstHalf"
                (click)="overUnderSegmentSelected = segmentTab.FirstHalf" i18n>1st Half</div>
              <div class="over-under-tab over-under-segment-tabs"
                [class.selected]="overUnderSegmentSelected === segmentTab.SecondHalf"
                (click)="overUnderSegmentSelected = segmentTab.SecondHalf" i18n>2nd Half</div>
            </div>
          </div>
          <div class="right-selection">
            <span i18n>Over / Under:</span>
            <div *ngFor="let item of (statisticsQuery.overUnderData$ | async) | objectKeys; trackBy: indexTrackBy;"
              class="tab-container" [class.selected]="item === overUnderMatchesSelected + overUnderSegmentSelected">
              <span *ngFor="let value of (statisticsQuery.overUnderData$ | async)[item]; trackBy: indexTrackBy;"
                class="over-under-spread-tabs" [class.selected]="overUnderSpreadSelected === value.id"
                (click)="overUnderSpreadSelected = value.id">{{ value.spread }}</span>
            </div>
          </div>
        </div>
        <div *ngFor="let item of (statisticsQuery.overUnderData$ | async) | objectKeys; trackBy: indexTrackBy;"
          class="over-under-container" [class.selected]="item === overUnderMatchesSelected + overUnderSegmentSelected">
          <table
            *ngFor="let value of (statisticsQuery.overUnderData$ | async)[item]; let j = index; trackBy: indexTrackBy;"
            class="spread-table" [class.selected]="overUnderSpreadSelected === value.id">
            <tbody>
              <tr>
                <th i18n>Team</th>
                <th class="over" colspan="3" i18n>Over</th>
                <th class="under" colspan="3" i18n>Under</th>
              </tr>
              <tr *ngFor="let team of value.teams | orderBy: 'order'; trackBy: indexTrackBy;">
                <td class="team">
                  <div class="team-name">
                    <div class="logo" [ngStyle]="{'background-image': 'url(' + team.teamLogo + ')'}">
                    </div>
                    <div class="name">{{ team.teamName }}</div>
                  </div>
                </td>
                <td class="over">{{ team.over }}</td>
                <td class="over-perc">{{ team.overPerc }}%</td>
                <td class="over-perc-bar">
                  <div class="status-bar">
                    <div [ngStyle]="{'width': team.overPerc + '%'}"></div>
                  </div>
                </td>
                <td class="under-perc-bar">
                  <div class="status-bar">
                    <div [ngStyle]="{'width': team.underPerc + '%'}"></div>
                  </div>
                </td>
                <td class="under-perc">{{ team.underPerc }}%</td>
                <td class="under">{{ team.under }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!--Player Stats-->
    <div class="header-tab" [class.selected]="headerTabSelected === headerTab.PlayerStats"
      (click)="headerTabSelected = headerTab.PlayerStats"
      *ngIf="(statisticsQuery.goalscorerData$ | async) || (statisticsQuery.assistsData$ | async) || (statisticsQuery.cardsData$ | async)">
      <i class="fa fa-futbol-o left-icon" aria-hidden="true"></i>
      <div i18n>Player Stats</div>
      <i class="fa fa-caret-right right-icon" aria-hidden="true"></i>
    </div>
    <div class="player-stats-content"
      *ngIf="(statisticsQuery.goalscorerData$ | async) || (statisticsQuery.assistsData$ | async) || (statisticsQuery.cardsData$ | async)"
      [class.selected]="headerTabSelected === headerTab.PlayerStats">
      <div>
        <div class="stats-tabs">
          <div class="player-stats-tab" [class.selected]="playerStatsTabSelected === statsTab.Goals"
            (click)="playerStatsTabSelected = statsTab.Goals" i18n>Goals</div>
          <div class="player-stats-tab" [class.selected]="playerStatsTabSelected === statsTab.Assists"
            (click)="playerStatsTabSelected = statsTab.Assists" i18n>Assists</div>
          <div class="player-stats-tab" [class.selected]="playerStatsTabSelected === statsTab.Cards"
            (click)="playerStatsTabSelected = statsTab.Cards" i18n>Cards</div>
        </div>
        <div class="team-names">
          <div class="team-home player-stats-matches-tabs" [class.selected]="playerStatsTeamTabSelected === teamTab.Home"
            (click)="playerStatsTeamTabSelected = teamTab.Home">
            <div class="name">
              {{ ((statisticsQuery.goalscorerData$ | async) && (statisticsQuery.goalscorerData$ | async).homeTeamName) ||
              ((statisticsQuery.assistsData$ | async) && (statisticsQuery.assistsData$ | async).homeTeamName) ||
              ((statisticsQuery.cardsData$ | async) && (statisticsQuery.cardsData$ | async).homeTeamName) }}
            </div>
          </div>
          <div class="team-away player-stats-matches-tabs" [class.selected]="playerStatsTeamTabSelected === teamTab.Away"
            (click)="playerStatsTeamTabSelected = teamTab.Away">
            <div class="name">
              {{ ((statisticsQuery.goalscorerData$ | async) && (statisticsQuery.goalscorerData$ | async).awayTeamName) ||
              ((statisticsQuery.assistsData$ | async) && (statisticsQuery.assistsData$ | async).awayTeamName) ||
              ((statisticsQuery.cardsData$ | async) && (statisticsQuery.cardsData$ | async).awayTeamName) }}
            </div>
          </div>
        </div>
        <div class="player-stats-container goalscorer-container"
          [class.selected]="playerStatsTabSelected === statsTab.Goals">
          <div class="section-not-found" *ngIf="(statisticsQuery.goalscorerData$ | async) === undefined" i18n>
            Goals are not available for this match
          </div>
          <div class="section-found" *ngIf="statisticsQuery.goalscorerData$ | async">
            <div class="player-stats-history" [class.selected]="playerStatsTeamTabSelected === teamTab.Home">
              <table>
                <thead>
                  <tr>
                    <th i18n>Pos</th>
                    <th i18n>Player</th>
                    <th i18n>Matches</th>
                    <th i18n>Goals</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let player of (statisticsQuery.goalscorerData$ | async).homeGoalscorers; trackBy: indexTrackBy;">
                    <td class="position">{{ player.position }}</td>
                    <td class="team">
                      <div class="team-name">
                        <div class="logo" [ngStyle]="{'background-image': 'url(' + player.nationalityFlag + ')'}"></div>
                        <div class="name">{{ player.name }}</div>
                      </div>
                    </td>
                    <td class="stats">{{ player.matches }}</td>
                    <td class="stats">{{ player.goals }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="player-stats-history" [class.selected]="playerStatsTeamTabSelected === teamTab.Away">
              <table>
                <thead>
                  <tr>
                    <th i18n>Pos</th>
                    <th i18n>Player</th>
                    <th i18n>Matches</th>
                    <th i18n>Goals</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let player of (statisticsQuery.goalscorerData$ | async).awayGoalscorers; trackBy: indexTrackBy;">
                    <td class="position">{{ player.position }}</td>
                    <td class="team">
                      <div class="team-name">
                        <div class="logo" [ngStyle]="{'background-image': 'url(' + player.nationalityFlag + ')'}"></div>
                        <div class="name">{{ player.name }}</div>
                      </div>
                    </td>
                    <td class="stats">{{ player.matches }}</td>
                    <td class="stats">{{ player.goals }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="player-stats-container assists-container"
          [class.selected]="playerStatsTabSelected === statsTab.Assists">
          <div class="section-not-found" *ngIf="(statisticsQuery.assistsData$ | async) === undefined" i18n>
            Assists are not available for this match
          </div>
          <div class="section-found" *ngIf="statisticsQuery.assistsData$ | async">
            <div class="player-stats-history" [class.selected]="playerStatsTeamTabSelected === teamTab.Home">
              <table>
                <thead>
                  <tr>
                    <th i18n>Pos</th>
                    <th i18n>Player</th>
                    <th i18n>Matches</th>
                    <th i18n>Assists</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let player of (statisticsQuery.assistsData$ | async).homeAssists; trackBy: indexTrackBy;">
                    <td class="position">{{ player.position }}</td>
                    <td class="team">
                      <div class="team-name">
                        <div class="logo" [ngStyle]="{'background-image': 'url(' + player.nationalityFlag + ')'}"></div>
                        <div class="name">{{ player.name }}</div>
                      </div>
                    </td>
                    <td class="stats">{{ player.matches }}</td>
                    <td class="stats">{{ player.assists }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="player-stats-history" [class.selected]="playerStatsTeamTabSelected === teamTab.Away">
              <table>
                <thead>
                  <tr>
                    <th i18n>Pos</th>
                    <th i18n>Player</th>
                    <th i18n>Matches</th>
                    <th i18n>Assists</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let player of (statisticsQuery.assistsData$ | async).awayAssists; trackBy: indexTrackBy;">
                    <td class="position">{{ player.position }}</td>
                    <td class="team">
                      <div class="team-name">
                        <div class="logo" [ngStyle]="{'background-image': 'url(' + player.nationalityFlag + ')'}"></div>
                        <div class="name">{{ player.name }}</div>
                      </div>
                    </td>
                    <td class="stats">{{ player.matches }}</td>
                    <td class="stats">{{ player.assists }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="player-stats-container cards-container" [class.selected]="playerStatsTabSelected === statsTab.Cards">
          <div class="section-not-found" *ngIf="(statisticsQuery.cardsData$ | async) === undefined" i18n>
            Cards are not available for this match
          </div>
          <div class="section-found" *ngIf="statisticsQuery.cardsData$ | async">
            <div class="player-stats-history" [class.selected]="playerStatsTeamTabSelected === teamTab.Home">
              <table>
                <thead>
                  <tr>
                    <th i18n>Pos</th>
                    <th i18n>Player</th>
                    <th i18n>Matches</th>
                    <th>
                      <span class="cards-icon">
                        <svg viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
                          <g>
                            <rect width="80" height="120" rx="10" ry="10" x="20" y="0" fill="#ffbc3c"></rect>
                          </g>
                        </svg>
                      </span>
                    </th>
                    <th>
                      <span class="cards-icon">
                        <svg viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
                          <g>
                            <rect width="74.006226" height="111.00934" rx="9.2507782" ry="9.2507782" x="0" y="0"
                              fill="#ffbc3c"></rect>
                            <rect width="74.006226" height="111.00934" rx="9.2507782" ry="9.2507782" x="36.828461"
                              y="-7.268764" transform="matrix(0.98480775,0.17364818,-0.17364818,0.98480775,0,0)"
                              fill="#ff131a"></rect>
                          </g>
                        </svg>
                      </span>
                    </th>
                    <th>
                      <span class="cards-icon">
                        <svg viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
                          <g>
                            <rect width="80" height="120" rx="10" ry="10" x="20" y="0" fill="#ff131a"></rect>
                          </g>
                        </svg>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let player of (statisticsQuery.cardsData$ | async).homeCards; trackBy: indexTrackBy;">
                    <td class="position">{{ player.position }}</td>
                    <td class="team">
                      <div class="team-name">
                        <div class="logo" [ngStyle]="{'background-image': 'url(' + player.nationalityFlag + ')'}"></div>
                        <div class="name">{{ player.name }}</div>
                      </div>
                    </td>
                    <td class="stats">{{ player.matches }}</td>
                    <td class="cards">{{ player.yellowCards }}</td>
                    <td class="cards">{{ player.yellowRedCards }}</td>
                    <td class="cards">{{ player.redCards }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="player-stats-history" [class.selected]="playerStatsTeamTabSelected === teamTab.Away">
              <table>
                <thead>
                  <tr>
                    <th i18n>Pos</th>
                    <th i18n>Player</th>
                    <th i18n>Matches</th>
                    <th>
                      <span class="cards-icon">
                        <svg viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
                          <g>
                            <rect width="80" height="120" rx="10" ry="10" x="20" y="0" fill="#ffbc3c"></rect>
                          </g>
                        </svg>
                      </span>
                    </th>
                    <th>
                      <span class="cards-icon">
                        <svg viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
                          <g>
                            <rect width="74.006226" height="111.00934" rx="9.2507782" ry="9.2507782" x="0" y="0"
                              fill="#ffbc3c"></rect>
                            <rect width="74.006226" height="111.00934" rx="9.2507782" ry="9.2507782" x="36.828461"
                              y="-7.268764" transform="matrix(0.98480775,0.17364818,-0.17364818,0.98480775,0,0)"
                              fill="#ff131a"></rect>
                          </g>
                        </svg>
                      </span>
                    </th>
                    <th>
                      <span class="cards-icon">
                        <svg viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
                          <g>
                            <rect width="80" height="120" rx="10" ry="10" x="20" y="0" fill="#ff131a"></rect>
                          </g>
                        </svg>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let player of (statisticsQuery.cardsData$ | async).awayCards; trackBy: indexTrackBy;">
                    <td class="position">{{ player.position }}</td>
                    <td class="team">
                      <div class="team-name">
                        <div class="logo" [ngStyle]="{'background-image': 'url(' + player.nationalityFlag + ')'}"></div>
                        <div class="name">{{ player.name }}</div>
                      </div>
                    </td>
                    <td class="stats">{{ player.matches }}</td>
                    <td class="cards">{{ player.yellowCards }}</td>
                    <td class="cards">{{ player.yellowRedCards }}</td>
                    <td class="cards">{{ player.redCards }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--League Ranking-->
    <div class="header-tab" [class.selected]="headerTabSelected === headerTab.LeagueRanking"
      *ngIf="statisticsQuery.leagueRankingData$ | async" (click)="headerTabSelected = headerTab.LeagueRanking">
      <i class="fa fa-list-ol left-icon" aria-hidden="true"></i>
      <div i18n>League Ranking</div>
      <i class="fa fa-caret-right right-icon" aria-hidden="true"></i>
    </div>
    <div class="league-ranking-content" [class.selected]="headerTabSelected === headerTab.LeagueRanking"
      *ngIf="statisticsQuery.leagueRankingData$ | async">
      <!-- <div class="section-not-found" i18n>League Ranking are not available for this match</div> -->
      <div class="section-found">
        <table>
          <tr>
            <th i18n>Pos</th>
            <th i18n>Team</th>
            <th i18n>P</th>
            <th i18n>W</th>
            <th i18n>D</th>
            <th i18n>L</th>
            <th i18n>GD</th>
            <th i18n>Pts</th>
          </tr>
          <tr
            *ngFor="let team of (statisticsQuery.leagueRankingData$ | async).teams | orderBy: 'position'; trackBy: indexTrackBy;">
            <td class="position">{{ team.position }}</td>
            <td class="team">
              <div class="team-name">
                <div class="logo" [ngStyle]="{'background-image': 'url(' + team.teamLogo + ')'}">
                </div>
                <div class="name">{{ team.teamName }}</div>
              </div>
            </td>
            <td class="stats">{{ team.played }}</td>
            <td class="stats">{{ team.won }}</td>
            <td class="stats">{{ team.drawn }}</td>
            <td class="stats">{{ team.lost }}</td>
            <td class="stats">{{ team.goalsDifference }}</td>
            <td class="points">{{ team.points }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="header-tab" *ngIf="(isFootballGo$ | async) === false" (click)="statisticsBetradarPopup()">
      <i class="fa fa-area-chart left-icon" aria-hidden="true"></i>
      <div i18n>More Statistics</div>
      <i class="fa fa-caret-right right-icon" aria-hidden="true"></i>
    </div>
  </ng-template>
</div>
