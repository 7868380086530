import { FreeBetProductType } from 'src/app/modules/freebets/models/freebets.model';
import { ProductType, VirtualsCategoryType } from 'src/app/shared/models/product.model';
export function dataLayerFreeBetProduct(productType: string): string {
  switch (productType) {
    case FreeBetProductType.VirtualsScheduled:
    case FreeBetProductType.VirtualsInstant:
      return ProductType.Virtuals;
    default:
      return ProductType.SportsBook;
  }
}
export function dataLayerFreeBetCategory(productType: string): string {
  switch (productType) {
    case FreeBetProductType.VirtualsScheduled:
    case FreeBetProductType.VirtualsInstant:
      return VirtualsCategoryType.FootballLeagues;
    default:
      return ProductType.SportsBook;
  }
}
