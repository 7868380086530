import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { MessagesQuery } from 'src/app/core/state/messages/messages.query';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent {
  @Input() title: string;
  @Input() showHeader: boolean = true;
  @Input() showBreadcrumbs: boolean = true;
  @Input() darkMode: boolean = false;
  @Input() showIcons: boolean = true;
  @Input() showTooltip: boolean = false;
  @Input() tooltipText: string;
  @Input() goBackIcon: 'caret-left' | 'close' = 'caret-left';
  @Input() loggedOutAction: 'login' | 'register' | 'both' = 'both';
  @Input() class: 'short' | 'long' = 'long';
  @Output() readonly backButtonClick = new EventEmitter();
  @Input() readonly headerPinToTop: boolean = true;
  enableBankProfile: boolean = false;
  activeTooltip = new BehaviorSubject<boolean>(false);

  constructor(
    readonly applicationQuery: ApplicationQuery,
    readonly accountQuery: AccountQuery,
    readonly messagesQuery: MessagesQuery,
    private readonly location: Location
  ) {}

  goBack(): void {
    if (this.hasCustomBackFunction()) {
      this.backButtonClick.emit();
    } else {
      this.location.back();
    }
  }
  toggleTooltip(): void {
    this.activeTooltip.next(!this.activeTooltip.getValue());
  }

  closeTooltip(): void {
    this.activeTooltip.next(false);
  }

  private hasCustomBackFunction(): boolean {
    return this.backButtonClick.observers.length > 0;
  }
}
