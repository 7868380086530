<div [class.breadcrumb-pin-to-top]="headerPinToTop === true">
  <app-header [headerPinToTop]="headerPinToTop"></app-header>
  <div class="breadcrumb-navigation" data-testid="breadcrumb-navigation">
    <div class="breadcrumb-navigation-back-icon">
      <span class="material-icons" (click)="goBack()" data-testid="breadcrumb-navigation-go-back">{{ 'arrow_back' }}</span>
    </div>
    <div class="breadcrumb-navigation-scrollable-container" (scroll)="closeDropdown()" #scrollableContainer>
      <div
        class="breadcrumb-main-navigation-title"
        [ngClass]="{ active: isMainParentActive }"
        data-testid="breadcrumb-navigation-element-title"
      >
    <span class="breadcrumb-main-navigation-text" (click)="goToPage(breadcrumbNavigationStructure?.url)"
    >{{ breadcrumbNavigationStructure?.title }}
    </span>
        <span class="breadcrumb-main-navigation-separator" *ngIf="!isMainParentActive"> / </span>
      </div>
      <div class="breadcrumb-main-navigation-submenu" appClickOutside (clickOutside)="closeDropdown()">
        <app-breadcrumb-item
          [breadcrumb]="breadcrumbNavigationStructure"
          [openedDropdownItem]="breadcrumbNavigationService.openedDropdownItem$ | async"
        ></app-breadcrumb-item>
      </div>
    </div>
  </div>
</div>
