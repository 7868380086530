import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginResolver } from 'src/app/core/resolvers/auto-login-resolver';
import { CouponPreviousPageResolver } from 'src/app/core/resolvers/coupon-previous-page-resolver';
import { SportsCreateSIDsResolver } from 'src/app/core/resolvers/sports-create-sids-resolver';
import { CouponSuccessfulBetComponent } from 'src/app/modules/coupon/components/coupon-successful-bet/coupon-successful-bet.component';
import { CouponPageWrapperComponent } from './components/coupon-page-wrapper/coupon-page-wrapper.component';

const routes: Routes = [
  {
    path: '',
    component: CouponPageWrapperComponent,
    resolve: { previousPage: CouponPreviousPageResolver, selections: SportsCreateSIDsResolver, autoLogin: AutoLoginResolver },
    pathMatch: 'full',
  },
  {
    path: 'bet-receipt',
    component: CouponSuccessfulBetComponent,
    pathMatch: 'full',
  },
  {
    path: ':createSIDs',
    component: CouponPageWrapperComponent,
    resolve: { previousPage: CouponPreviousPageResolver, selections: SportsCreateSIDsResolver, autoLogin: AutoLoginResolver },
    pathMatch: 'full',
  },
  {
    path: ':createSIDs/:stake',
    component: CouponPageWrapperComponent,
    resolve: { previousPage: CouponPreviousPageResolver, selections: SportsCreateSIDsResolver, autoLogin: AutoLoginResolver },
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CouponRoutingModule {}
