import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { findIndex } from 'lodash-es';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class GoldenRaceGuard implements CanActivate {
  constructor(private readonly appConfig: AppConfigService, private readonly router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const virtualsConfig = this.appConfig.get('virtuals');
    if (!virtualsConfig || !virtualsConfig.goldenRace || !virtualsConfig.goldenRace.enabled || !virtualsConfig.goldenRace.enabledGames) {
      this.router.navigate(['/', 'virtual']);
      return false;
    }

    const gameSuffix = next.url[0].path.toLowerCase();
    const enabledGames: string[] = virtualsConfig.goldenRace.enabledGames;
    if (findIndex(enabledGames, g => g.toLowerCase() === gameSuffix) === -1) {
      this.router.navigate(['/', 'virtual']);
      return false;
    }

    return true;
  }
}
