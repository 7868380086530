import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbNavigationService {
  readonly openedDropdownItem$ = new BehaviorSubject(undefined);
  openDropdownForActiveItem(parentItem): void {
    this.closeDropdown();
    this.openedDropdownItem$.next(parentItem.uniqueValue);
  }
  closeDropdown(): void {
    this.openedDropdownItem$.next(undefined);
  }
}
