import { Injectable } from '@angular/core';
import { SportModel } from 'src/app/shared/models/sport.model';
import { Store, StoreConfig } from '@datorama/akita';
import { BetCoupon, BetCouponGlobalVariable } from 'clientside-coupon';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { CouponGroupingType, CouponState, DefaultCouponStake } from 'src/app/shared/models/coupon.model';
import { VirtualsCouponSettings, VirtualsCouponState, VirtualsCouponUIState } from 'src/app/shared/models/virtuals-coupon.model';

const createInitialState = (): VirtualsCouponState => ({
  bookedCoupons: undefined,
  couponData: undefined,
  selections: undefined,
  couponInitialized: false,
  correctScoreOddsMatrix: undefined,
  couponSettings: undefined,
  defaultCouponStake: undefined,
  globalVariables: undefined,
  groupingsTabSelected: undefined,
  ui: {
    showCoupon: false,
    showQuickCoupon: false,
    stakeChanged: false,
  },
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'virtuals_scheduled_coupon' })
export class VirtualsCouponStore extends Store<VirtualsCouponState> {
  readonly globalVariablesKey: string = 'virtuals.globalVariables';
  readonly correctScoreOddsMatrixKey: string = 'virtuals.correctScoreOddsMatrix';
  private readonly couponDataKey = 'virtualsCouponData';
  private readonly couponSettingsKey: string = 'virtualsCouponSettings';
  private readonly defaultCouponStakeKey: string = 'virtualsDefaultCouponStake';

  constructor(private readonly localStorage: LocalStorageService, private readonly sessionStorage: SessionStorageService) {
    super(createInitialState());

    this.localStorage.observe(this.couponDataKey).subscribe(couponData => {
      this.update({ couponData });
    });
    this.updateCouponData(this.localStorage.retrieve(this.couponDataKey));

    this.localStorage.observe(this.couponSettingsKey).subscribe(couponSettings => {
      this.update({ couponSettings });
    });
    this.updateCouponSettings(this.localStorage.retrieve(this.couponSettingsKey));

    this.localStorage.observe(this.defaultCouponStakeKey).subscribe(defaultCouponStake => {
      this.update({ defaultCouponStake });
    });
    this.updateDefaultCouponStake(this.localStorage.retrieve(this.defaultCouponStakeKey));
  }

  updateCouponData(couponData: BetCoupon): void {
    if (!couponData) {
      this.clearCouponData();
      return;
    }

    this.localStorage.store(this.couponDataKey, couponData);

    if (this.localStorage.retrieve(this.couponSettingsKey) === null) {
      const couponSettings = new VirtualsCouponSettings({
        allowOddChanges: false,
        allowStakeReduction: false,
        allowTransfer: false,
        transferUserId: undefined,
      });

      this.updateCouponSettings(couponSettings);
    }
  }

  updateCouponInitialized(couponInitialized: boolean): void {
    this.update({ couponInitialized });
  }

  updateCouponSettings(couponSettings: VirtualsCouponSettings): void {
    if (!couponSettings) {
      this.clearCouponSettings();
      return;
    }

    this.localStorage.store(this.couponSettingsKey, couponSettings);
  }

  updateCouponSetting(couponSettings: Partial<CouponState['couponSettings']>): void {
    this.update(state => ({
      couponSettings: {
        ...state.couponSettings,
        ...couponSettings,
      },
    }));

    this.localStorage.store(this.couponSettingsKey, {
      ...this.localStorage.retrieve(this.couponSettingsKey),
      ...couponSettings,
    });
  }

  updateDefaultCouponStake(defaultCouponStake: DefaultCouponStake): void {
    if (!defaultCouponStake) {
      this.clearDefaultCouponStake();
      return;
    }

    this.localStorage.store(this.defaultCouponStakeKey, defaultCouponStake);
  }

  updateGlobalVariables(globalVariables: BetCouponGlobalVariable): void {
    if (!globalVariables) {
      this.clearGlobalVariables();
      return;
    }

    this.update({ globalVariables });
    this.sessionStorage.store(this.globalVariablesKey, globalVariables);
  }

  updateCorrectScoreOddsMatrix(correctScoreOddsMatrix: any): void {
    if (!correctScoreOddsMatrix) {
      this.clearCorrectScoreOddsMatrix();
      return;
    }

    this.update({ correctScoreOddsMatrix });
    this.sessionStorage.store(this.correctScoreOddsMatrixKey, correctScoreOddsMatrix);
  }

  updateGroupingTab(groupingsTabSelected: CouponGroupingType): void {
    if (!groupingsTabSelected) {
      this.clearGroupingTab();
      return;
    }

    this.update({ groupingsTabSelected });
  }

  updateUI(ui: Partial<VirtualsCouponUIState>): void {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui,
      },
    }));
  }

  updateSelections(selections: SportModel[]): void {
    this.update({ selections });
  }

  clearCouponData(): void {
    this.localStorage.clear(this.couponDataKey);
    this.clearCouponSettings();

    this.clearGroupingTab();
  }

  clearCouponSettings(): void {
    this.localStorage.clear(this.couponSettingsKey);
  }

  clearDefaultCouponStake(): void {
    this.localStorage.clear(this.defaultCouponStakeKey);
  }

  clearGlobalVariables(): void {
    this.update({ globalVariables: undefined });
    this.sessionStorage.clear(this.globalVariablesKey);
  }

  clearCorrectScoreOddsMatrix(): void {
    this.update({ correctScoreOddsMatrix: undefined });
    this.sessionStorage.clear(this.correctScoreOddsMatrixKey);
  }

  clearGroupingTab(): void {
    this.update({ groupingsTabSelected: undefined });
  }
}
