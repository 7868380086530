<div class="input-number">
  <div class="icon"></div>
  <h1>
    {{ content ? content.phoneNumberInputHeader : '' }}
  </h1>
  <div class="text" [innerText]="content ? content.phoneNumberInputMessage : ''">
  </div>
  <form [formGroup]="phoneVerificationForm">
    <div
      class="form-item mobile-number"
      [class.verification-error]="(numberVerificationResponseCode$ | async) === -1 || (numberVerificationResponseCode$ | async) > 0"
      [class.error]="phoneVerificationForm.controls.mobileNumber.touched && phoneVerificationForm.controls.mobileNumber.status !== 'VALID'"
      [class.success]="phoneVerificationForm.controls.mobileNumber.touched && phoneVerificationForm.controls.mobileNumber.status === 'VALID'"
    >
      <div class="label" i18n *ngIf="phoneVerificationForm.controls.mobileNumber.touched && phoneVerificationForm.controls.mobileNumber.status !== 'VALID'; else validMobileNumberLabel">Invalid Mobile Number</div>
      <ng-template #validMobileNumberLabel>
        <div class="label" i18n>Mobile Number</div>
      </ng-template>
      <div class="form-item-row">
        <app-mobile-prefix></app-mobile-prefix>
        <input type="tel" i18n-placeholder placeholder="Mobile Number" class="mobile-number-input"
          formControlName="mobileNumber">
      </div>
      <div class="verification-error" *ngIf="(numberVerificationResponseCode$ | async) === -1" i18n>
        There was an issue with verification. Please try again or contact customer support.
      </div>
      <div class="verification-error" *ngIf="(numberVerificationResponseCode$ | async) > 0" i18n>
        There is an issue with this mobile number. Please check that this number is correct and try again, or contact customer support.
      </div>
      <div class="number-change-info" *ngIf="(numberChanged$ | async)" i18n>
        Your profile will be updated with the new mobile number
      </div>
    </div>
  </form>
  <div class="buttons">
    <app-button
      [text]="content ? content.phoneVerificationSendCodeCTAText : 'Proceed'"
      [customButtonStyle]="ctaAdditionalStyles"
      (click)="verifyNumber()"
      [disabled]="phoneVerificationForm.pristine || phoneVerificationForm.status !== 'VALID' || (disableCTAForward$ | async)"
    ></app-button>
  </div>
</div>
