import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Subject } from 'rxjs';
import { PushMessagingService } from 'src/app/modules/native-app/services/push-messaging.service';
import { VersionService } from 'src/app/modules/native-app/services/version.service';
import { AndroidSettingsQuery } from 'src/app/modules/native-app/state/android-settings.query';

@Injectable({
  providedIn: 'root',
})
export class InitializeMobilePluginsService implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly versionService: VersionService,
    private readonly commonLocation: Location,
    private readonly pushMessagingService: PushMessagingService,
    readonly androidSettingsQuery: AndroidSettingsQuery
  ) {}

  initialiseMobileApp(): void {
    if (Capacitor.getPlatform() === 'android') {
      this.handleBackButton(this.androidSettingsQuery);
      FirebaseCrashlytics.setEnabled({ enabled: true });
      this.versionService.checkAppVersionUpdate(this.androidSettingsQuery);

      this.pushMessagingService.initialisePushMessaging();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private handleBackButton(androidSettingsQuery: any): void {
    let count = 0;
    let timestamp = Date.now();

    App.addListener('backButton', data => {
      const timestampDifference = Date.now() - timestamp;
      if (this.commonLocation.isCurrentPathEqualTo('') && androidSettingsQuery.isWebViewMode !== true) {
        App.exitApp();
      } else if (androidSettingsQuery.isWebViewMode === true && timestampDifference < 3000 && count === 2) {
        App.exitApp();
      } else if (androidSettingsQuery.isWebViewMode === true && timestampDifference < 2500 && count < 2) {
        count += 1;
        this.commonLocation.back();
      } else {
        count = 0;
        timestamp = Date.now();
        this.commonLocation.back();
      }
    });
  }
}
