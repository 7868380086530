import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { filter, map, tap, takeUntil, distinct } from 'rxjs/operators';
import { CouponFlexicutService } from 'src/app/core/services/coupon/coupon-flexicut.service';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { CouponStore } from 'src/app/core/state/coupon/coupon.store';
import { expandCollapseSimpler } from 'src/app/shared/animations';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';

@Component({
  selector: 'coupon-flexicut',
  templateUrl: './coupon-flexicut.component.html',
  styleUrls: ['./coupon-flexicut.component.scss'],
  animations: [expandCollapseSimpler()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CouponFlexicutComponent implements OnInit, OnDestroy {
  @Output() readonly scrollToBottom = new EventEmitter();

  readonly isFlexicutCoupon$ = this.couponQuery.isFlexicutCoupon$;
  readonly hasInvalidOdds$ = this.couponQuery.oddsBelowFlexicutMinOdds$.pipe(map(badOdds => badOdds.length > 0));
  readonly hasFlexicutOdds$ = this.couponQuery.flexicutOdds$.pipe(map(odds => odds.length > 0));
  readonly showTotalOddsInfoText$ = new BehaviorSubject(false);

  private readonly cutNumber$ = this.couponQuery.couponData$.pipe(map(couponData => couponData?.BetDetails?.FlexiCutDetails?.Cut));

  readonly maxSelections$ = this.couponQuery.couponData$.pipe(
    filter(couponData => !!couponData),
    map(couponData => couponData.Odds.length)
  );
  readonly winningSelections$ = combineLatest([this.cutNumber$, this.maxSelections$]).pipe(
    map(([cutNumber, maxSelections]) => maxSelections - cutNumber)
  );

  readonly showModal$ = new BehaviorSubject(false);
  readonly showHelpModal$ = new BehaviorSubject(false);
  readonly cannotApplyFlexicut$ = new BehaviorSubject(false);
  readonly flexicutConfig = this.appConfigService.get('sports').flexicut;

  private readonly _destroy$ = new Subject<boolean>();

  constructor(
    readonly couponQuery: CouponQuery,
    private readonly couponStore: CouponStore,
    private readonly couponFlexicutService: CouponFlexicutService,
    private readonly dataLayerService: DataLayerService,
    private readonly appConfigService: AppConfigService
  ) {}

  ngOnInit(): void {
    // On coupon reload, preset FlexicutSelectedOption if flexicut coupon
    const cutNumber = this.couponQuery.couponData?.BetDetails?.FlexiCutDetails?.Cut;
    this.couponFlexicutService.updateFlexicutSelectedOptionByCutNumber(cutNumber);

    this.couponQuery.isFlexicutApplicable$
      .pipe(
        distinct(),
        tap(isFlexicutApplicable => {
          if (isFlexicutApplicable) {
            this.couponFlexicutService.updateFlexicutOdds();
          }
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();

    combineLatest([this.couponQuery.isFlexicutApplicable$, this.hasInvalidOdds$])
      .pipe(
        map(([isFlexicutApplicable, hasInvalidOdds]) => !isFlexicutApplicable || hasInvalidOdds),
        tap((hasError: boolean) => this.cannotApplyFlexicut$.next(hasError))
      )
      .subscribe();
  }

  onRightArrow(): void {
    const cannotApplyFlexicut = this.cannotApplyFlexicut$.getValue();
    if (cannotApplyFlexicut) {
      this.showHelpModal$.next(true);
    } else {
      this.showModal$.next(true);
    }
    this.sendFlexicutEligibilityEvent(cannotApplyFlexicut);
  }

  toggleFlexicutModal(): void {
    this.showModal$.next(!this.showModal$.getValue());
  }

  toggleFlexicutHelpModal(): void {
    this.showHelpModal$.next(!this.showHelpModal$.getValue());
  }

  removeFlexicutCoupon = () => {
    this.sendFlexicutRemovedEvent();
    this.couponStore.updateFlexicutSelectedOption(undefined);
    this.showTotalOddsInfoText$.next(false);
    this.scrollToBottomFn();
  };

  triedToGoBelowOddsThreshold(showTotalOddsInfoText: boolean) {
    this.showTotalOddsInfoText$.next(showTotalOddsInfoText);
  }

  scrollToBottomFn(): void {
    this.scrollToBottom.emit();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  private sendFlexicutEligibilityEvent(cannotApplyFlexicut) {
    this.dataLayerService.createDataLayerEvent({
      event: 'flexicut_toggle',
      value: cannotApplyFlexicut ? 'ineligible' : 'eligible',
    });
  }

  private sendFlexicutRemovedEvent() {
    const couponQuery = this.couponQuery;
    const cutNumber = couponQuery.getFlexicutSelectedOption().cutNumber;
    const selections = couponQuery.couponData?.Odds.length;
    this.dataLayerService.createDataLayerEvent({
      event: 'remove_flexicut',
      cut_selections: cutNumber,
      selections,
    });
  }
}
