<div class="container">
  <div class="brand-logo"></div>

  <div class="location-icon">
    <svg viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="m42.513 3.25 2.987 3-4.48 4.478 4.48 4.478-2.987 3-4.46-4.5-4.46 4.5-2.986-3 4.48-4.478-4.48-4.478 2.987-3 4.46 4.5 4.459-4.5zM35.95 23.402v.422c0 4.943-4.102 11.491-12.622 19.307-8.519-7.816-12.62-14.343-12.62-19.307 0-7.668 5.574-13.096 12.62-13.096.716 0 1.43.063 2.104.169V6.63a17.308 17.308 0 0 0-2.104-.127C14.493 6.503 6.5 13.305 6.5 23.824c0 7.013 5.617 15.315 16.828 24.926 11.213-9.611 16.829-17.913 16.829-24.926v-.422H35.95zm-12.622 4.224c2.324 0 4.207-1.891 4.207-4.224 0-2.334-1.883-4.225-4.207-4.225-2.323 0-4.207 1.892-4.207 4.225 0 2.333 1.884 4.224 4.207 4.224z"
        fill="#fff" />
    </svg>
  </div>

  <span class="title" i18n>
    Sorry, BetKing.com isn't available in this country yet
  </span>

  <span class="message" i18n>
    Unfortunately we're unable to provide BetKing services in your current location. BetKing currently operates in
    Ethiopia, Kenya, Ghana and Nigeria.
  </span>
</div>
