import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { CouponStore } from 'src/app/core/state/coupon/coupon.store';
import { ButtonType } from 'src/app/shared/models/button.model';
import { FlexicutOptionModel } from 'src/app/shared/models/coupon.model';
import { CouponFlexicutService } from 'src/app/core/services/coupon/coupon-flexicut.service';
@Component({
  selector: 'coupon-flexicut-modal',
  templateUrl: './flexicut-modal.component.html',
  styleUrls: ['./flexicut-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlexicutModalComponent implements OnInit, OnDestroy {
  @Input() show = false;
  @Output() readonly closeModal = new EventEmitter();
  @Output() readonly scrollToBottom = new EventEmitter();

  private readonly _destroy$ = new Subject();

  flexicutOptions = [];
  tempOptionClick$ = new BehaviorSubject(undefined);
  buttonType: typeof ButtonType = ButtonType;

  constructor(
    private readonly couponStore: CouponStore,
    private readonly couponFlexicutService: CouponFlexicutService,
    readonly couponQuery: CouponQuery
  ) {}

  ngOnInit(): void {
    // Pre-select option in the modal, if flexicut is already applied
    this.couponQuery.flexicutSelectedOption$
      .pipe(
        tap(selectedFlexicut => {
          this.tempOptionClick$.next(selectedFlexicut);
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();

    this.couponQuery.flexicutOdds$.subscribe(response => {
      if (response) {
        // Limit the number of options to max length of 3 OR whichever is less
        const optionsCount = Math.min(response.length, 3);
        this.flexicutOptions = response.slice(0, optionsCount).map((option, index) => {
          const position = index + 1;
          if (position > 2) {
            return { id: 'option_3', isCustomized: true, decreaseThreshold: 3, cutNumber: 3 };
          } else {
            return { id: `option_${position}`, isCustomized: false, decreaseThreshold: undefined, cutNumber: position };
          }
        });
      }
    });
  }

  handleCloseModal(): void {
    this.tempOptionClick$.next(this.couponQuery.getFlexicutSelectedOption());
    this.closeModal.emit();
  }

  onApplyFlexicut(): void {
    const tempOptionClick = this.tempOptionClick$.getValue();
    this.couponStore.updateFlexicutSelectedOption(tempOptionClick);
    this.couponFlexicutService.sendApplyFlexicutEvent(tempOptionClick.cutNumber);
    this.closeModal.emit();
    this.scrollToBottom.emit();
  }

  optionClick(data: FlexicutOptionModel): void {
    this.tempOptionClick$.next(data);
  }

  ngOnDestroy(): void {
    this.tempOptionClick$.next(undefined);
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
