import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LiveService } from 'src/app/core/services/live.service';

import { SearchQuery } from 'src/app/core/state/search/search.query';
import { fadeIn } from 'src/app/shared/animations';
import { MatchModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-event-details-live',
  templateUrl: './event-details-live.component.html',
  styleUrls: ['./event-details-live.component.scss'],
  animations: [fadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventDetailsLiveComponent {
  @Input() event: MatchModel;
  @Input() showDate = true;

  constructor(readonly searchQuery: SearchQuery, readonly liveService: LiveService) {}
}
