import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { slidePanelSide } from 'src/app/shared/animations';

@Component({
  selector: 'app-slide-panel',
  templateUrl: './slide-panel.component.html',
  styleUrls: ['./slide-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slidePanelSide()],
})
export class SlidePanelComponent {
  @Input() activePanel: 'left' | 'right';
}
