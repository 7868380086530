import { Injectable } from '@angular/core';
import { CouponStore } from 'src/app/core/state/coupon/coupon.store';

import { BetCouponOdd, CouponType } from 'clientside-coupon';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { CouponGroupingType, MarketMatchMap, OddModel } from 'src/app/shared/models/coupon.model';
import {
  AreaModel,
  CategoryModel,
  MarketModel,
  MatchModel,
  RegionModel,
  SportModel,
  TournamentModel,
} from 'src/app/shared/models/sport.model';
import { CouponGroupingsService } from 'src/app/core/services/coupon/coupon-groupings.service';

@Injectable({
  providedIn: 'root',
})
export class CouponSelectionService {
  couponType: typeof CouponType = CouponType;
  constructor(
    private readonly couponQuery: CouponQuery,
    private readonly couponGroupingsService: CouponGroupingsService,
    private readonly couponStore: CouponStore
  ) {}

  parseSelections(selections: BetCouponOdd[]): void {
    // prop marketMatches - When couponQuery.couponSettings.allowCompetitionGrouping is turned ON or OFF, this property
    // is used to render "market-odds-container" element in the betslip.
    const marketMatches: MarketMatchMap[] = [];
    const groupedSports = this.buildGroupedSelections(selections, marketMatches);
    const tournaments = this.buildSelections(selections);
    this.sortMatches(groupedSports);
    this.couponStore.updateGroupedSelections(groupedSports);

    this.couponStore.updateSelections(tournaments);
    this.couponStore.updateMarketMatches(marketMatches);
  }

  sortMatches(sports: SportModel[]): void {
    sports.forEach(s => {
      s.categories[0].tournaments.forEach(t => {
        t.matches = t.matches.sort((m1: MatchModel, m2: MatchModel) => m1.id - m2.id);
        t.matches.sort((m1: MatchModel, m2: MatchModel) => m1.id - m2.id);
      });
    });

    sports.sort((s1: SportModel, s2: SportModel) => s1.id - s2.id);
  }

  isBankerDisabled(allowFixed: boolean, fixed: boolean): boolean {
    if (
      !allowFixed ||
      this.couponQuery.couponData?.CouponType !== this.couponType.System ||
      this.couponQuery.groupingsTabSelected !== CouponGroupingType.Combination
    ) {
      // In these cases banker should be disabled, no further logic is required
      return true;
    }

    if (!fixed) {
      // Check if odd can be set as banker or not
      const combGroupings = [];
      this.couponQuery.couponData?.AllGroupings.forEach((g, idx) => {
        const isLastGrouping = idx === this.couponQuery.couponData.AllGroupings.length - 1;

        if (g.Grouping > 0 && g.Combinations > 0 && this.couponGroupingsService.isCouponGroupVisible(g, isLastGrouping)) {
          combGroupings.push(g);
        }
      });

      // If only 1 grouping is available for selection, stop any further Bankers
      if (combGroupings.length <= 1) {
        return true;
      }
    }
    return false;
  }

  private buildGroupedSelections(selections: BetCouponOdd[], marketMatches: MarketMatchMap[]): SportModel[] {
    const sports: SportModel[] = [];
    selections.forEach(selection => {
      const odd = this.buildOddModel(selection);

      let sport = sports.find(o => o.id === selection.IDSport);
      if (!sport) {
        sport = new SportModel({
          id: selection.IDSport,
          name: selection.SportName,
          categories: [
            new CategoryModel({
              id: selection.EventId,
              name: selection.EventName,
              tournaments: [],
            }),
          ],
        });

        sports.push(sport);
      }

      let category = sport.categories.find(c => c.id === selection.EventId);
      if (!category) {
        category = new CategoryModel({
          id: selection.EventId,
          name: selection.EventName,
          tournaments: [],
        });

        sport.categories.push(category);
      }

      let tournament = category.tournaments.find(t => t.id === selection.TournamentId);
      const extractedSpreadValue = selection.SelectionName.match(/\((.*?)\)/);

      if (!tournament) {
        tournament = this.buildTournamentModel(selection, odd);
        category.tournaments.push(tournament);
        marketMatches.push(
          new MarketMatchMap({
            marketId: selection.MarketId,
            matchIds: [selection.MatchId],
          })
        );
      } else {
        if (selection.IsBetBuilder) {
          // Bet builders are always shown as separate matches
          const match = this.buildMatchModel(selection);
          match.odds.push(odd);
          tournament.matches.push(match);

          const area = tournament.regions[0].areas[0];
          area.markets.push(
            new MarketModel({
              id: selection.MarketId,
              name: selection.MarketName,
              groupingType: undefined,
              marketType: undefined,
              typeId: selection.MarketTypeId,
              description: selection.GamePlayDescription,
              spreadValue: extractedSpreadValue ? parseFloat(extractedSpreadValue[1]) : undefined,
            })
          );
        } else {
          let match = tournament.matches.find(m => m.id === selection.MatchId);
          if (!match) {
            match = this.buildMatchModel(selection);
            match.odds.push(odd);
            tournament.matches.push(match);
          } else {
            match.odds.push(odd);
          }

          const area = tournament.regions[0].areas[0];
          if (!area.markets.some(m => m.id === selection.MarketId)) {
            area.markets.push(
              new MarketModel({
                id: selection.MarketId,
                name: selection.MarketName,
                groupingType: undefined,
                marketType: undefined,
                typeId: selection.MarketTypeId,
                description: selection.GamePlayDescription,
                spreadValue: extractedSpreadValue ? parseFloat(extractedSpreadValue[1]) : undefined,
              })
            );
            marketMatches.push(
              new MarketMatchMap({
                marketId: selection.MarketId,
                matchIds: [selection.MatchId],
              })
            );
          } else {
            const marketMatchesFoundIndex = marketMatches.findIndex(m => m.marketId === selection.MarketId);
            if (!marketMatches[marketMatchesFoundIndex].matchIds.includes(selection.MatchId)) {
              marketMatches[marketMatchesFoundIndex].matchIds.push(selection.MatchId);
            }
          }
        }
      }
    });

    return sports;
  }

  private buildSelections(selections: BetCouponOdd[]): TournamentModel[] {
    const tournaments: TournamentModel[] = [];
    const tournamentsIdWithBBSelected = selections
      .filter(s => s.MarketTypeId === this.couponQuery.preCannedBetBuilderMarketTypeID || s.IsBetBuilder)
      .map(s => s.TournamentId);

    selections.map(selection => {
      const tournament = tournaments.find(t => t.id === selection.TournamentId && t.matches.find(m => m.id === selection.MatchId));
      const extractedSpreadValue = selection.SelectionName.match(/\((.*?)\)/);
      const odd = this.buildOddModel(selection);
      const isBetBuilder = selection.MarketTypeId === this.couponQuery.preCannedBetBuilderMarketTypeID || selection.IsBetBuilder;
      if (isBetBuilder) {
        tournaments.push(this.buildTournamentModel(selection, odd));
      } else {
        if (tournament?.matches[0] && !tournamentsIdWithBBSelected.some(item => item === selection.TournamentId)) {
          tournament.matches[0].odds.push(odd);

          const area = tournament.regions[0].areas[0];
          let market = area.markets.find(m => m.id === selection.MarketId);
          if (!market) {
            market = new MarketModel({
              id: selection.MarketId,
              name: selection.MarketName,
              groupingType: undefined,
              marketType: undefined,
              typeId: selection.MarketTypeId,
              description: selection.GamePlayDescription,
              spreadValue: extractedSpreadValue ? parseFloat(extractedSpreadValue[1]) : undefined,
            });

            area.markets.push(market);
          }
        } else {
          tournaments.push(this.buildTournamentModel(selection, odd));
        }
      }
    });

    return tournaments;
  }

  private buildOddModel(selection: BetCouponOdd): OddModel {
    return new OddModel({
      marketId: selection.MarketId,
      marketTypeId: selection.MarketTypeId,
      marketName: selection.MarketName,
      selectionName: selection.SelectionName,
      selectionTypeId: selection.IDSelectionType,
      id: selection.SelectionId,
      value: selection.OddValue,
      unboostedValue: selection.UnboostedOddValue,
      isLocked: selection.IsLocked,
      isExpired: selection.IsExpired,
      spreadValue: selection.SpecialValue === '' ? undefined : parseInt(selection.SpecialValue, 10),
      isBetBuilder: Boolean(selection.IsBetBuilder),
    });
  }

  private buildTournamentModel(selection: BetCouponOdd, odd: OddModel): TournamentModel {
    const extractedSpreadValue = selection.SelectionName.match(/\((.*?)\)/);
    return new TournamentModel({
      id: selection.TournamentId,
      name: selection.TournamentName,
      isBetBuilder: selection.MarketTypeId === this.couponQuery.preCannedBetBuilderMarketTypeID || selection.IsBetBuilder,
      matches: [
        new MatchModel({
          id: selection.MatchId,
          name: selection.MatchName,
          date: selection.EventDate,
          smartBetCode: selection.SmartCode.toString(),
          eventCategory: selection.EventCategory,
          categoryName: selection.TournamentName,
          combinability: selection.CompatibilityLevel,
          fixed: selection.Fixed,
          allowFixed: selection.AllowFixed,
          groupingType: selection.GroupingType,
          isBankerDisabled: this.isBankerDisabled(selection.AllowFixed, selection.Fixed),
          hasExpiredEvents: selection.IsExpired,
          odds: [odd],
        }),
      ],
      regions: [
        new RegionModel({
          isDefault: true,
          areas: [
            new AreaModel({
              isDefault: true,
              markets: [
                new MarketModel({
                  id: selection.MarketId,
                  name: selection.MarketName,
                  groupingType: undefined,
                  marketType: undefined,
                  typeId: selection.MarketTypeId,
                  description: selection.GamePlayDescription,
                  spreadValue: extractedSpreadValue ? parseFloat(extractedSpreadValue[1]) : undefined,
                }),
              ],
            }),
          ],
        }),
      ],
    });
  }

  private buildMatchModel(selection: BetCouponOdd): MatchModel {
    return new MatchModel({
      id: selection.MatchId,
      name: selection.MatchName,
      date: selection.EventDate,
      smartBetCode: selection.SmartCode.toString(),
      eventCategory: selection.EventCategory,
      categoryName: selection.TournamentName,
      combinability: selection.CompatibilityLevel,
      fixed: selection.Fixed,
      allowFixed: selection.AllowFixed,
      groupingType: selection.GroupingType,
      isBankerDisabled: this.isBankerDisabled(selection.AllowFixed, selection.Fixed),
      hasExpiredEvents: selection.IsExpired,
      odds: [],
      isBetBuilder: selection.IsBetBuilder,
    });
  }
}
