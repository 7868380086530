import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { MobileVerificationCampaignService } from 'src/app/core/services/mobile-verification-campaign/mobile-verification-campaign.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import {
  MobileVerifactionCampaignAlreadyVerifiedContent,
  MobileVerifactionMobileInputContent,
  MobileVerifactionNumberVerifiedContent,
} from 'src/app/shared/models/mobile-verification-campaign/mobile-verification-campaign.model';
import { OTPScreenContentModel } from 'src/app/shared/models/otp.model';

enum MobileVerificationState {
  InputNumber,
  InputOTP,
  NumberVerified,
  AlreadyVerified,
}

@Component({
  selector: 'mobver-campaign',
  templateUrl: './mobile-verification-campaign.component.html',
  styleUrls: ['./mobile-verification-campaign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileVerificationCampaignComponent implements OnInit, OnDestroy {
  readonly inputOTPContent: OTPScreenContentModel = {
    inputOTPLabel: $localize`Please enter the PIN below to verify your mobile number.`,
    phoneVerificationVerifyCTAText: $localize`Complete Verification`,
    sentOTPHeader: $localize`OTP Sent`,
    sentOTPMessage: $localize`We’ve sent a One Time PIN to this phone number: [[User_PhoneNumber]]`,
    contactSupportMessageHTML: `${$localize`Having trouble verifying your phone number?`} <br> ${$localize`Please contact`} <a style="text-decoration: none;" href="help/contact-us">${$localize`Customer Support`}</a>`,
  };
  readonly alreadyVerifiedContent$ = new BehaviorSubject<MobileVerifactionCampaignAlreadyVerifiedContent>(undefined);
  readonly mobileInputContent$ = new BehaviorSubject<MobileVerifactionMobileInputContent>(undefined);
  readonly mobileNumber$ = new BehaviorSubject('');
  readonly mobilePrefix$ = new BehaviorSubject('');
  readonly numberVerifiedContent$ = new BehaviorSubject<MobileVerifactionNumberVerifiedContent>(undefined);
  readonly otpInputGenericError$ = new BehaviorSubject(false);
  readonly otpVerifyStates = MobileVerificationState;
  readonly showLoadingOverlay$ = new BehaviorSubject(false);
  readonly state$ = new BehaviorSubject(undefined);
  private numberChanged = false;

  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly appConfigService: AppConfigService,
    private readonly applicationService: ApplicationService,

    private readonly mobileVerificationCampaignService: MobileVerificationCampaignService,
    private readonly router: Router
  ) {
    this.applicationService.hideNavbar();
  }

  ngOnInit(): void {
    if (this.appConfigService.get('account').mobileVerificationCampaign) {
      if (this.accountQuery.isAuthenticated) {
        this.mobileVerificationCampaignService.getContent().subscribe(content => {
          this.alreadyVerifiedContent$.next(content.alreadyVerified);
          this.mobileInputContent$.next(content.mobileInput);
          this.numberVerifiedContent$.next(content.numberVerified);
        });
        // Might be the case that we need to call Security/Registration/ValidateMobileNumber like in MLITE
        this.state$.next(this.accountQuery.isPhoneVerified ? MobileVerificationState.AlreadyVerified : MobileVerificationState.InputNumber);
      } else {
        this.router.navigateByUrl('/account/login?urlAfterLogin=%2Fmobile-verification');
      }
    } else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnDestroy(): void {
    this.applicationService.showNavbar();
  }

  postOTP(): void {
    if (this.numberChanged) {
      // Refresh user data
      this.accountService.getUserData(this.accountQuery.accessToken).subscribe();
    } else {
      // Just set phone and account statuses
      this.accountService.updatePhoneVerificationStatus('VER');
      this.accountService.updateUserStatus('ACT');
    }
    this.state$.next(MobileVerificationState.NumberVerified);
  }

  proceedFromVerifyNumber(event: { prefix: string; number: string; numberChanged: boolean }): void {
    this.mobilePrefix$.next(event.prefix);
    this.mobileNumber$.next(event.number);
    this.numberChanged = event.numberChanged;

    this.navigateForward();
  }

  navigateForward(): void {
    switch (this.state$.getValue()) {
      case MobileVerificationState.InputNumber: {
        this.state$.next(MobileVerificationState.InputOTP);
        break;
      }
      case MobileVerificationState.InputOTP: {
        this.state$.next(MobileVerificationState.NumberVerified);
        break;
      }
      default: {
        break;
      }
    }
  }

  close(): void {
    this.router.navigateByUrl('/');
  }

  startLoading(): void {
    this.showLoadingOverlay$.next(true);
  }

  stopLoading(): void {
    this.showLoadingOverlay$.next(false);
  }
}
