import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuickBetStore } from 'src/app/core/state/quick-bet/quick-bet.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { QuickBetMarketModel, QuickBetModel } from 'src/app/shared/models/quick-bet.model';
import { APIService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class QuickBetService {
  constructor(private readonly apiService: APIService, private readonly quickBetStore: QuickBetStore) {}

  getQuickBet(eventCode: number, language: string = 'en'): Observable<boolean> {
    if (!eventCode) {
      return of<boolean>(false);
    }

    this.quickBetStore.clearQuickBetData();

    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    return this.apiService.get<any>(APIType.SportsbookFeed, `api/feeds/prematch/QuickBet/${eventCode}/lang/${language}`, apiSettings).pipe(
      map(responseData => {
        if (!responseData || responseData.EventName === '-') {
          return false;
        }

        const quickBetData = new QuickBetModel({
          eventCode: eventCode,
          eventName: responseData.EventName,
          sportId: responseData.IDSport,
          sportName: responseData.SportName,
          categoryId: responseData.IDCategory,
          categoryName: responseData.CategoryName,
          tournamentId: responseData.IDTournament,
          tournamentName: responseData.TournamentName,
          matchId: responseData.IDEvent,
          matchName: responseData.EventName,
          matchDate: responseData.EventDate,
          eventCategory: responseData.EventCategory,
          smartBetCode: responseData.SmartBetCode,
          markets: this.mapQuickBetMarketsToModel(responseData),
          incompatibleEvents: responseData.IncompatibleEvents,
        });
        this.quickBetStore.updateQuickBetData(quickBetData);
        return true;
      })
    );
  }

  mapQuickBetMarketsToModel(responseData: any, spreadValue?: number | undefined): QuickBetMarketModel[] {
    const quickBetMarkets: QuickBetMarketModel[] = [];

    responseData.Markets.forEach(market => {
      market.Markets.forEach(matchOdds => {
        if (spreadValue === undefined || spreadValue === market.SpecialBetValue) {
          quickBetMarkets.push(
            new QuickBetMarketModel({
              matchOddsID: matchOdds.MatchOddsID,
              smartCode: responseData.SmartBetCode,
              marketID: market.OddsID,
              marketName: market.OddsType.OddsTypeName,
              marketTypeID: market.OddsType.OddsTypeID,
              oddValue: matchOdds.OddOutcome,
              unboostedOddValue: matchOdds.UnboostedOddValue,
              selectionID: matchOdds.OddAttribute.OddTypeID,
              selectionName: matchOdds.OddAttribute.OddName,
              spreadValue: market.SpecialBetValue,
              shortcut: matchOdds.Shortcut,
            })
          );
        }
      });
    });

    return quickBetMarkets;
  }

  clearQuickBetData(): void {
    this.quickBetStore.clearQuickBetData();
  }

  getActiveOnlineCodes(): Observable<boolean> {
    this.quickBetStore.clearOnlineCodesData();

    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    return this.apiService.get<any>(APIType.SportsbookFeed, `api/feeds/onlinecodes`, apiSettings).pipe(
      map(responseData => {
        if (!responseData) {
          return false;
        }

        this.quickBetStore.updateOnlineCodesData(responseData);
        return true;
      })
    );
  }
}
