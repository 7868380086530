<div class="placeholder" [ngStyle]="{height: height}">
  <span *ngIf="text">
    {{text}}
  </span>
  <span *ngIf="!text" class="dots">
    <span class="loader-dot">.</span>
    <span class="loader-dot">.</span>
    <span class="loader-dot">.</span>
  </span>
</div>
