import { CityModel, CountryModel, IdTypeModel, StateModel, TelephoneExtensionModel } from './location.model';

export interface RegistrationState {
  activationOption: string;
  registerOptionTriggered: boolean;
  verificationInProgress: boolean;
  currencies: CurrencyModel[];
  countries: CountryModel[];
  statesOfResidence: StateModel[];
  cities: CityModel[];
  telephoneExtensions: TelephoneExtensionModel[];
  newAgentForm: {
    title: string;
    formContent: string;
  };
  credentials: {
    user: string;
    pass: string;
  };
  registrationType: RegistrationType;
  registrationSuccessContent: RegistrationSuccessContentModel;
  upsellRegistrationSuccessContent: UpsellRegistrationSuccessContentModel;
  splitRegistrationContent: SplitRegistrationContentModel;
  isF2PUpsellRegistration: boolean;
  idTypes: IdTypeModel[];
  isSuperPickUser: boolean;
  superPickToken: string;
}

export enum RegisterTab {
  StepOne,
  StepTwo,
}

export enum RegisterState {
  InputNumber,
  NewUserStepOne,
  NewUserStepTwo,
  F2PUserStepOne,
  F2PUserStepTwo,
  PreExistingUser,
  InputOTP,
}

export enum RegistrationType {
  Normal = 'normal',
  Split = 'split',
  Quick = 'quick',
  Redirect = 'redirect',
  UserJourney = 'userjourney',
}

export class CurrencyModel {
  isoCode: string;
  name: string;
  symbol: string;

  constructor(init: CurrencyModel) {
    Object.assign(this, init);
  }
}

export class RegistrationModel {
  username: string;
  password: string;
  winNotificationSetting?: boolean;
  mobilePrefix: string;
  mobileNumber: number;
  currency: string;
  firstName: string;
  lastName: string;
  gender: 'M' | 'F';
  dateOfBirth: Date;
  country: string;
  state: string;
  city: string;
  address: string;
  confirmation: boolean;
  email: string;
  extraDetails: ExtraRegistrationDetails;
  bannerTag: string;

  constructor(init: Partial<RegistrationModel>) {
    Object.assign(this, init);
  }
}

export class KYCRegistrationModel {
  password: string;
  mobilePrefix: string;
  mobileNumber: number;
  confirmation: boolean;
  currency: string;
  country: string;
  extraDetails: ExtraRegistrationDetails;
  idDocumentValue: string;
  idDocumentTypeCode: string;
  firstName: string;
  lastName: string;
  address: string;
  bannerTag: string;

  constructor(init: Partial<KYCRegistrationModel>) {
    Object.assign(this, init);
  }
}

export class QuickRegistrationModel {
  password: string;
  mobilePrefix: string;
  mobileNumber: number;
  confirmation: boolean;
  currency: string;
  country: string;
  extraDetails: ExtraRegistrationDetails;
  bannerTag: string;

  constructor(init: Partial<QuickRegistrationModel>) {
    Object.assign(this, init);
  }
}

export class F2PRegistrationModel {
  firstName: string;
  lastName: string;
  state: string;
  confirmation: boolean;
  extraDetails: ExtraRegistrationDetails;
}

export class F2PRegistrationModelGhana {
  token: string;
  firstName: string;
  lastName: string;
  ReceiveMarketingMessages: boolean;
  IDDocumentValue: string;
  IDDocumentTypeCode: string;
  Addresses: Array<{
    CountryCode: string;
    Region: string;
    AddressTypeCode: string;
    Line1: string;
  }>;

  constructor(init: Partial<F2PRegistrationModelGhana>) {
    Object.assign(this, init);
  }
}

export class F2PRegistrationModelKenya {
  token: string;
  firstName: string;
  lastName: string;
  ReceiveMarketingMessages: boolean;

  constructor(init: Partial<F2PRegistrationModelKenya>) {
    Object.assign(this, init);
  }
}

export interface ExtraRegistrationDetails {
  receiveMarketingMessages: boolean;
  promotionCode?: string | undefined;
}

export interface RegistrationSuccessContentModel {
  registrationSuccessHeader: string;
  registrationSuccessMessage: string;
  registrationSuccessImage: string;
  registrationSuccessImageCaption: string;
  registrationSuccessImageURL: string;
  registrationSuccessCTABack: string;
  registrationSuccessCTAForward: string;
  registrationSuccessCTAForwardURL: string;
}

export interface UpsellRegistrationSuccessContentModel {
  registrationSuccessHeader: string;
  registrationSuccessMessage: string;
  f2PUpsellLoginHeader: string;
  f2PUpsellLoginMessage: string;
}

export interface SplitRegistrationContentModel {
  splitRegistrationHeader: string;
  splitRegistrationInputMessage: string;
  splitRegistrationWithdrawalDisclaimer: string;
  splitRegistrationAgeConfirmation: string;
  splitRegistrationMarketingConsent: string;
  splitRegistrationF2PUserMessage: string;
  splitRegistrationPreExistingUserMessage: string;
}
