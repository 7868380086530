<div class="acca-bonus-progression-bar-container" *ngIf="isVisible$ | async" data-testid="acca-bonus-progression-bar"
  (click)="openAccBonusInfoPopup()">
  <div class="before-acca-bonus" *ngIf="(hasAccumulatorBonus$ | async) === false">
    <div class="info-icon material-icons" *ngIf="showInfoIcon" data-testid="acca-bonus-progression-bar-info-btn">
      {{'info'}}</div>
    <div class="selection-count-text" data-testid="acca-bonus-progression-bar-info-count-text">
      {{ accumulatorBonusSelectionsTillBonusText$ | async }}
    </div>
  </div>
  <div class="has-acca-bonus" *ngIf="hasAccumulatorBonus$ | async">
    <div class="info-icon material-icons" *ngIf="showInfoIcon" data-testid="acca-bonus-progression-bar-info-btn">
      {{'info'}}</div>
    <div class="acca-bonus-step-container" [style.--no-of-steps]="noOfStepsToShow">
      <div class="acca-bonus-step"
        *ngFor="let bonus of accumulatorBonusQuery.bonusList$ | async; let i = index; trackBy: accumulatorBonusListTrackBy"
        #accaBonusStep data-testid="acca-bonus-progression-bar-step">

        <ng-container *ngIf="accumulatorBonusQuery.maxAccumulatorBonus$ | async as accumulatorBonusPercentage">
          <div class="acca-bonus-step-details" [class.previous-step]="bonus.Percentage < accumulatorBonusPercentage"
            [class.upcoming-step]="bonus.Percentage > accumulatorBonusPercentage"
            *ngIf="i < accumulatorBonusPercentageIndex">
            <span class="plus-icon material-icons" *ngIf="bonus.Percentage <= accumulatorBonusPercentage"
              data-testid="acca-bonus-progression-bar-step-add">{{'add'}}</span>
            <span class="lock-icon material-icons" *ngIf="bonus.Percentage > accumulatorBonusPercentage"
              data-testid="acca-bonus-progression-bar-step-lock">{{'lock'}}</span>
            <span data-testid="acca-bonus-progression-bar-step-percent">{{ bonus.Percentage }}%</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<app-info-modal *ngIf="accumulatorBonusQuery.cmsContent$ | async as cmsContent"
  [title]="cmsContent?.accumulatorBonusInfoModalTitle" [subtitle]="cmsContent?.accumulatorBonusInfoModalSubtitle"
  [bodyHTML]="cmsContent?.accumulatorBonusInfoModalBody | replaceAll: MIN_ACCA_ODD_VALUE_KEY : accumulatorBonusQuery.minOddsValue?.toString()"
  [buttonText]="cmsContent?.accumulatorBonusInfoModalCloseCTA" [show]="showInfoModal$ | async" (closeModal)="closeAccBonusInfoPopup()"
  data-testid="acca-bonus-progression-bar-info-modal"></app-info-modal>
