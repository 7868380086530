import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeComma',
})
export class RemoveCommaPipe implements PipeTransform {
  transform(value: string): string {
    if (value !== undefined && value !== null) {
      // here we just remove the commas from value
      return value.replace(/,/g, '');
    } else {
      return value;
    }
  }
}
