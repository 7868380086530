import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { smoothScrollTo } from 'src/app/shared/utils/smooth-scroll-to';

@Directive({
  selector: '[appAdjustContainerOnHorizontalScroll]',
})
export class AdjustContainerOnHorizontalScrollDirective implements AfterViewInit, OnDestroy {
  adjustDivTimeout;

  private readonly el: ElementRef;
  private readonly destroy$ = new Subject();

  constructor(element: ElementRef) {
    this.el = element;
  }

  ngAfterViewInit(): void {
    fromEvent(this.el.nativeElement, 'scroll')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        clearTimeout(this.adjustDivTimeout);

        const children = this.el.nativeElement.children;
        [...children].some(child => {
          const position = child.getBoundingClientRect();

          if (position.left !== 0) {
            if (
              (position.left > 0 && position.left < window.innerWidth / 2) ||
              (position.right > window.innerWidth / 2 && position.right < window.innerWidth)
            ) {
              this.adjustDivTimeout = setTimeout(() => {
                const scrollValue = parseInt(this.el.nativeElement.scrollLeft, 10) + parseInt(position.left, 10);
                smoothScrollTo(this.el.nativeElement, 'left', scrollValue);
              }, 500);

              return true;
            }
          } else {
            return true;
          }
        });
      });
  }

  ngOnDestroy(): void {
    clearTimeout(this.adjustDivTimeout);
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
