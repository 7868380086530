import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { BonusModel } from 'src/app/modules/accounts/modules/auth/models/bonus.model';
import { expandCollapse } from 'src/app/shared/animations';

@Component({
  selector: 'app-menu-promos',
  templateUrl: './menu-promos.component.html',
  styleUrls: ['./menu-promos.component.scss'],
  animations: [expandCollapse()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuPromosComponent {
  viewBonusCode: number = undefined;

  constructor(readonly accountQuery: AccountQuery) {}

  bonusTrackBy(bonus: BonusModel): number {
    return bonus.bonusCode;
  }

  onMoreInfoClicked(id: number): void {
    this.viewBonusCode = id;
  }

  onLessInfoClicked(): void {
    this.viewBonusCode = undefined;
  }
}
