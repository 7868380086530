import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ApplicationService } from 'src/app/core/services/application.service';
import { fadeIn, slideInToggle } from 'src/app/shared/animations';

@Component({
  selector: 'app-slide-up',
  templateUrl: './slide-up.component.html',
  styleUrls: ['./slide-up.component.scss'],
  animations: [fadeIn(), slideInToggle()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideUpComponent {
  @Input() title: string = 'No Title';
  @Input() type: string;

  constructor(private readonly applicationService: ApplicationService) {}

  close(): void {
    this.applicationService.closeSlideUp(this.type);
  }
}
