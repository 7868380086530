import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { BetCoupon, CouponType } from 'clientside-coupon';
import { BehaviorSubject } from 'rxjs';

import { AppConfigService } from 'src/app/core/services/app-config.service';
import { CouponStakeHandlerService } from 'src/app/core/services/coupon/coupon-stake-handler.service';
import { CurrencyService } from 'src/app/core/services/currency.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { expandCollapse } from 'src/app/shared/animations';
import { ButtonType } from 'src/app/shared/models/button.model';
import { DefaultCouponStake } from 'src/app/shared/models/coupon.model';

@Component({
  selector: 'coupon-totals',
  templateUrl: './coupon-totals.component.html',
  styleUrls: ['./coupon-totals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expandCollapse()],
})
export class CouponTotalsComponent implements OnInit {
  @Input() couponData: BetCoupon;
  fixedStakes: number[];
  calculatorNumbers: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '00', '.'];
  stakeGrossDisplay: string;
  saveDefaultStake: boolean = false;
  buttonType: typeof ButtonType = ButtonType;
  couponType: typeof CouponType = CouponType;
  stakeButtonStyle: any = {
    fontSize: '16px',
    height: '41px',
    margin: '2px',
    flex: '1 1 100%',
    padding: '0',
    minWidth: '0',
  };
  resetButtonStyle: any = {
    height: '34px',
    padding: '0px 2px 0px 5px',
    minWidth: '0',
    textAlign: 'center',
  };
  readonly firstStakePress$ = new BehaviorSubject<boolean>(true);
  readonly firstNumberPress$ = new BehaviorSubject<boolean>(true);
  delimitersToggle: boolean = false;
  delimitersOpen: boolean = false;
  currencySymbol: string = '';

  constructor(
    private readonly appConfigService: AppConfigService,
    private readonly applicationQuery: ApplicationQuery,
    private readonly couponQuery: CouponQuery,
    private readonly couponStakeHandlerService: CouponStakeHandlerService,
    private readonly currencyService: CurrencyService
  ) {
    this.fixedStakes = this.appConfigService.get('sports').coupon.fixedStakes;
    this.currencySymbol = this.currencyService.getCurrency(this.applicationQuery.currency).symbol;
  }

  ngOnInit(): void {
    this.stakeGrossDisplay = this.couponData.StakeGross.toString();

    this.couponQuery.defaultCouponStake$.subscribe(defaultCouponStake => {
      if (defaultCouponStake) {
        this.saveDefaultStake = defaultCouponStake.allowSaveDefault;

        if (defaultCouponStake.allowSaveDefault) {
          this.couponStakeHandlerService.updateStakeValue(defaultCouponStake.defaultStake);
          this.stakeGrossDisplay = defaultCouponStake.defaultStake.toString();
        }
      }
    });
  }

  updateStakeValue(stakeValue: number): void {
    this.couponStakeHandlerService.updateStakeValue(stakeValue);
    this.stakeGrossDisplay = stakeValue.toString();

    if (this.couponQuery.defaultCouponStake && this.couponQuery.defaultCouponStake.allowSaveDefault) {
      const defaultCouponStake = new DefaultCouponStake({
        allowSaveDefault: true,
        defaultStake: this.couponQuery.couponData.StakeGross,
      });

      this.couponStakeHandlerService.updateDefaultCouponStake(defaultCouponStake);
    }
  }

  updateDefaultStake(): void {
    this.saveDefaultStake = !this.saveDefaultStake;

    const defaultCouponStake = new DefaultCouponStake({
      allowSaveDefault: this.saveDefaultStake,
      defaultStake: this.saveDefaultStake ? this.couponQuery.couponData.StakeGross : this.couponQuery.globalVariables.MinBetStake,
    });

    this.couponStakeHandlerService.updateDefaultCouponStake(defaultCouponStake);
  }

  resetStakeValue(): void {
    // reset stake to 0
    this.updateStakeValue(0);

    this.firstStakePress$.next(true);
    this.firstNumberPress$.next(true);
  }

  addStakeValue(amount: string): void {
    let stakeAmount;

    if (this.firstStakePress$.getValue() && amount.toString() !== this.couponQuery.globalVariables.MinBetStake.toString()) {
      stakeAmount = parseFloat(amount);
    } else {
      stakeAmount = parseFloat(this.stakeGrossDisplay) + parseFloat(amount);
    }

    this.updateStakeValue(stakeAmount);
    this.firstStakePress$.next(false);
  }

  addNumberValue(num: string): void {
    if (num === '.') {
      if (
        parseFloat(this.stakeGrossDisplay) >= this.couponQuery.globalVariables.MinBetStake &&
        this.stakeGrossDisplay.indexOf('.') === -1
      ) {
        this.stakeGrossDisplay = this.stakeGrossDisplay + num;
      }
    } else if (num === '0' || num === '00') {
      if (
        this.stakeGrossDisplay.indexOf('.') !== -1 &&
        (this.stakeGrossDisplay.substring(this.stakeGrossDisplay.indexOf('.') + 1) === '' ||
          parseFloat(this.stakeGrossDisplay.substring(this.stakeGrossDisplay.indexOf('.') + 1)) === 0)
      ) {
        this.stakeGrossDisplay = this.stakeGrossDisplay + num;
      } else {
        this.updateStakeValue(parseFloat(this.stakeGrossDisplay + num));
      }
    } else {
      this.updateStakeValue(this.firstNumberPress$.getValue() ? parseFloat(num) : parseFloat(this.stakeGrossDisplay + num));
    }
    this.firstNumberPress$.next(false);
  }

  removeNumberValue(): void {
    const removedLastDigit = parseFloat(this.stakeGrossDisplay.substring(0, this.stakeGrossDisplay.length - 1));

    if (removedLastDigit > this.couponQuery.globalVariables.MinBetStake) {
      this.updateStakeValue(removedLastDigit);
      this.firstNumberPress$.next(false);
    } else {
      this.resetStakeValue();
    }
  }

  indexTrackBy(index: number): number {
    return index;
  }
}
