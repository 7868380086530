import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { SearchQuery } from 'src/app/core/state/search/search.query';
import { SearchStore } from 'src/app/core/state/search/search.store';
import { APIType } from 'src/app/shared/models/api.model';
import { OddModel } from 'src/app/shared/models/coupon.model';
import { Market, SearchResult, SearchResultEvent, SearchUIState } from 'src/app/shared/models/search.model';
import { APIService } from './api.service';
import { RecentSearchesService } from './recent-searches.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService implements OnDestroy {
  requestSearchFieldClearance$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
  private readonly destroy$ = new Subject<boolean>();

  constructor(
    private readonly apiService: APIService,
    private readonly recentSearchesService: RecentSearchesService,
    private readonly searchStore: SearchStore,
    private readonly searchQuery: SearchQuery,
    private readonly couponService: CouponService
  ) {}

  search(keyword: string): Observable<SearchResult[]> {
    const uiUpdate = new SearchUIState({ searchIsLoading: true });
    this.searchStore.updateUI(uiUpdate);
    return this.apiService.get(APIType.SportsbookFeed, `/api/feeds/prematch/Search/lang/en?search=${keyword}`).pipe(
      tap((data: any[]) => {
        if (data.length) {
          this.recentSearchesService.saveSearchKeyword(keyword);

          const dataUpdate = [];
          data.forEach(element => {
            const searchResult = new SearchResultEvent({
              categoryName: element.CategoryName,
              eventDate: element.EventDate,
              eventName: element.EventName,
              idCategory: element.IDCategory,
              idEvent: element.IDEvent,
              idEventType: element.IDEventType,
              idSport: element.IDSport,
              idTournament: element.IDTournament,
              markets: [],
              smartBetCode: element.SmartBetCode,
              sportName: element.SportName,
              teamAway: element.TeamAway,
              teamHome: element.TeamHome,
              totalOdds: element.TotalOdds,
              tournamentName: element.TournamentName,
            });

            element.Markets.forEach(market => {
              const markets = new Market({
                gamePlay: market.GamePlay,
                markets: [],
                oddsID: market.OddsID,
                oddsType: market.OddsType,
                specialBetValue: market.SpecialBetValue,
                specialValueDisplay: market.specialValueDisplay,
              });

              market.Markets.forEach(innerMarket => {
                const inner = new OddModel({
                  id: innerMarket.MatchOddsID,
                  value: innerMarket.OddOutcome,
                  unboostedValue: innerMarket.UnboostedOddValue,
                  spreadValue: innerMarket.OddAttribute.SpecialValue,
                  sportId: element.IDSport,
                  sportName: element.SportName,
                  categoryId: element.IDCategory,
                  categoryName: element.CategoryName,
                  eventCategory: 'F', // F = prematch, L = live
                  smartCode: element.SmartBetCode,
                  tournamentId: element.IDTournament,
                  tournamentName: element.TournamentName,
                  matchId: element.IDEvent,
                  matchName: element.EventName,
                  matchDate: element.EventDate,
                  marketId: market.OddsID,
                  marketTypeId: market.OddsType.OddsTypeID,
                  marketName: market.OddsType.OddsTypeName,
                  selectionId: innerMarket.OddAttribute.OddTypeID,
                  selectionName: innerMarket.OddAttribute.OddName,
                  incompatibleEvents: element.IncompatibleEvents,
                  // Combinability and GamePlay are the same thing, just a different name. (has been tested with sports team)
                  combinability: market.GamePlay,
                  selected: this.couponService.isOddInCoupon(innerMarket.MatchOddsID),
                  enabled: true,
                });

                markets.markets.push(inner);
              });

              searchResult.markets.push(markets);
            });

            dataUpdate.push(searchResult);
          });

          const reducedSportNames = dataUpdate.reduce((acc, x) => acc.concat(acc.find(y => y.sportName === x.sportName) ? [] : [x]), []);

          const retVal = [];

          // Getting unique sport names
          reducedSportNames.forEach((dataReduce: any) => {
            retVal.push({ sportName: dataReduce.sportName, data: [] });
          });

          // Collecting data for a given sport name
          dataUpdate.forEach(update => {
            for (const element of retVal) {
              if (update.sportName === element.sportName) {
                element.data.push(update);
              }
            }
          });

          // Reordering sports: Football first, rest alphabetical

          retVal.sort((a, b) => {
            if (a.sportName < b.sportName) {
              return -1;
            }
            if (a.sportName > b.sportName) {
              return 1;
            }
            return 0;
          });

          for (let i = 0; i < retVal.length; i++) {
            if (retVal[i].sportName === 'Football') {
              const football = retVal.splice(i, 1);
              retVal.unshift(...football);
              break;
            }
          }

          this.searchStore.updateSearchResults(retVal, keyword);
          if (this.searchQuery.noSearchResultsFor) {
            const updateUI = new SearchUIState({ noSearchResultsFor: undefined });
            this.searchStore.updateUI(updateUI);
          }
        } else {
          this.searchStore.updateSearchResults(undefined, undefined);
          const updateUI = new SearchUIState({ noSearchResultsFor: keyword });
          this.searchStore.updateUI(updateUI);
        }
        const uiUpdate2 = new SearchUIState({ searchIsLoading: false });
        this.searchStore.updateUI(uiUpdate2);
      })
    );
  }

  clearSearchError(): void {
    const updateUI = new SearchUIState({ noSearchResultsFor: undefined });
    this.searchStore.updateUI(updateUI);
  }

  clearSearch(): void {
    this.searchStore.updateSearchResults(undefined, undefined);
    this.searchStore.updateUI({ lastBadgeClicked: undefined });
  }

  badgeClicked(lastBadgeClicked: string): void {
    this.searchStore.updateUI({ lastBadgeClicked });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
