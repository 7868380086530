<div class="selection-container">
  <ng-container *ngFor="let selection of virtualsCouponQuery.selections$ | async; trackBy: selectionTrackBy">
    <ng-container *ngFor="let category of selection.categories; trackBy: categoryTrackBy">
      <div class="selection-content" [class.expired]="expiredWeeks | includes:tournament.id"
        *ngFor="let tournament of category.tournaments; trackBy: tournamentTrackBy">
        <div class="tournament-container">
          <div class="tournament-name" data-testid="coupon-selections-tournament">
            <span class="virtual-league-icon icon-generic icon-{{category.id}}"
              data-testid="coupon-selections-tournament-icon"></span>
            <span class="name" data-testid="coupon-selections-tournament-name">
              {{ category.name }} - {{ tournament.name}}
            </span>
            <span *ngIf="expiredWeeks | includes:tournament.id" class="expired-label" i18n>EXPIRED</span>
          </div>
          <app-button *ngIf="expiredWeeks | includes:tournament.id" class="remove-expired-button" i18n-text
            text="Remove" [customButtonStyle]="smallButtonStyle" [buttonType]="buttonType.DangerTransparent"
            (btnClick)="removeOddsForWeek.emit(tournament.id)" data-testid="coupon-remove-expired-odds-button">
          </app-button>
        </div>
        <div class="event-content" *ngFor="let match of tournament.matches trackBy: matchTrackBy"
          data-testid="coupon-selections-event">
          <div class="banker-container" *ngIf="selectedGroupingTab === groupingType.Combination">
            <button class="banker banker-icon" *ngIf="match.allowFixed" [disabled]="match.isBankerDisabled"
              data-testid="coupon-selections-events-banker" [class.selected]="match.fixed"
              (click)="updateOddBankerStatus(match.odds[0].id, !match.fixed)">
            </button>

            <span *ngIf="!match.allowFixed" class="banker locked material-icons">{{'lock'}}</span>
          </div>
          <div class="event-container">
            <div class="event-details-container" [class.one-market]="match.odds.length === 1">
              <div class="event-details">
                <div class="match-name" data-testid="coupon-selections-events-match-name">{{ match.name }}</div>
              </div>
            </div>
            <div class="market-odds-container">
              <app-virtuals-coupon-selection-item
                *ngFor="let marketOdds of match.odds | groupOddsByMarket; trackBy: oddsByMarketTrackBy"
                [matchName]="match.name" [marketSelections]="marketOdds" [smartBetCode]="match.smartBetCode"
                [matchOddsLength]="match.odds.length">
              </app-virtuals-coupon-selection-item>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>