<div class="help-list__sections">
  <div *ngFor="let section of helpSections$ | async; trackBy: urlTrackBy" (click)="itemClick(section)"
    class="help-list__section">
    <div class="help-list__section-name">
      {{ section.title }}
    </div>
    <i class="help-list__caret fa fa-angle-right"></i>
  </div>
</div>
<app-loading-bar [show]="loading"></app-loading-bar>
