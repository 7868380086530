import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class VirtualGuard implements CanActivate {
  constructor(private readonly appConfig: AppConfigService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.appConfig.get('virtuals').enabled;
  }
}
