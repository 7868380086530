import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StatisticsQuery } from 'src/app/core/state/statistics/statistics.query';
import { expandCollapse } from 'src/app/shared/animations';
import { MarketModel, MatchModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-single-line-event-live',
  templateUrl: './single-line-event-live.component.html',
  styleUrls: ['./single-line-event-live.component.scss'],
  animations: [expandCollapse()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleLineEventLiveComponent {
  @Input() event: MatchModel;
  @Input() sportId: number;
  @Input() showDate = true;
  @Input() showDetails: boolean = true;
  @Input() first?: boolean;
  @Input() last?: boolean;
  @Input() isAvailable: boolean = true;
  @Input() selectedMarket: MarketModel = undefined;
  @Input() eventLocation: string = undefined; // Used for data layer events
  @Input() matchPosition: string = undefined; // Used for data layer events

  constructor(readonly statisticsQuery: StatisticsQuery) {}
}
