import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appSelectAll]',
})
export class SelectAllDirective {
  @HostListener('focus', ['$event']) onFocus(event: any): void {
    if (event.relatedTarget) {
      event.target.select();
    }
  }
}
