import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ButtonType } from 'src/app/shared/models/button.model';

@Component({
  selector: 'app-booked-bet-input',
  templateUrl: './booked-bet-input.component.html',
  styleUrls: ['./booked-bet-input.component.scss'],
})
export class BookedBetInputComponent implements OnInit {
  @Input() readonly disableSend$ = new BehaviorSubject<boolean>(false);
  @Output() readonly submitCode = new EventEmitter();
  @Output() readonly cancel = new EventEmitter();

  bookBetForm: FormGroup;
  buttonType: typeof ButtonType = ButtonType;

  ngOnInit(): void {
    this.bookBetForm = new FormGroup({
      bookCode: new FormControl('', Validators.required),
    });
  }

  onSubmit(): void {
    if (!this.disableSend$.value) {
      this.submitCode.emit(this.bookBetForm.get('bookCode').value);
    }
  }

  onCancel(): void {
    this.bookBetForm.get('bookCode').setValue('');
    this.cancel.emit();
  }
}
