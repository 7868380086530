import { Component, Input } from '@angular/core';

@Component({
  selector: 'virtuals-bet-success-dialog-jp',
  templateUrl: './bet-success-dialog-jp.component.html',
  styleUrls: ['./bet-success-dialog-jp.component.scss'],
})
export class BetSuccessDialogJpComponent {
  @Input() betslipDialogSuccessContent: string;
}
