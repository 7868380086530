import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { NativeWrapperComponent } from './components/app-native-wrapper/app-native-wrapper.component';
import { UpdateRequiredComponent } from './components/app-update-required/app-update-required.component';
import { WebViewComponent } from './components/app-web-view/app-web-view.component';
import { OfflineModeComponent } from './components/offline-mode/offline-mode.component';

@NgModule({
  imports: [SharedModule],
  exports: [UpdateRequiredComponent, WebViewComponent, OfflineModeComponent, NativeWrapperComponent],
  declarations: [UpdateRequiredComponent, WebViewComponent, OfflineModeComponent, NativeWrapperComponent],
})
export class NativeAppModule {}
