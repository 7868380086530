export interface QuickBetState {
  quickBetData: QuickBetModel;
  onlineCodesData: number[];
}

export class QuickBetModel {
  eventCode: number;
  eventName: string;
  sportId: number;
  sportName: string;
  categoryId: number;
  categoryName: string;
  tournamentId: number;
  tournamentName: string;
  matchId: number;
  matchName: string;
  matchDate: Date;
  eventCategory: string;
  smartBetCode: number;
  markets: QuickBetMarketModel[];
  incompatibleEvents: number[] = [];

  constructor(init: Partial<QuickBetModel>) {
    Object.assign(this, init);
  }
}

export class QuickBetMarketModel {
  matchOddsID: number;
  smartCode: string;
  marketID: number;
  marketName: string;
  marketTypeID: number;
  oddValue: number;
  unboostedOddValue: number | undefined | null;
  selectionID: number;
  selectionName: string;
  spreadValue: number;
  shortcut: string;

  constructor(init: Partial<QuickBetMarketModel>) {
    Object.assign(this, init);
  }
}

export class OnlineCodesModel {
  eventCode: number;

  constructor(init: Partial<OnlineCodesModel>) {
    Object.assign(this, init);
  }
}
