import { ProductType, VirtualsLeagueType } from 'src/app/shared/models/product.model';

export const determineCouponProduct = (couponCode: string): { productType: ProductType; virtualsLeagueType: VirtualsLeagueType } => {
  const couponProduct = {
    productType: undefined,
    virtualsLeagueType: undefined,
  };

  if (couponCode) {
    const splitCoupon = couponCode.trim().split('-');
    if (splitCoupon.length === 1 && !Number.isNaN(couponCode.trim())) {
      // Based on the assumption that a virtuals instant league coupon code will always be numeric
      couponProduct.productType = ProductType.Virtuals;
      couponProduct.virtualsLeagueType = VirtualsLeagueType.Instant;
    } else if (splitCoupon.length === 2) {
      // Based on the assumption that a virtuals scheduled league coupon code will always be in the format '123123-123'
      couponProduct.productType = ProductType.Virtuals;
      couponProduct.virtualsLeagueType = VirtualsLeagueType.Scheduled;
    } else {
      couponProduct.productType = ProductType.SportsBook;
    }
  }

  return couponProduct;
};
