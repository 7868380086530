import { AfterViewChecked, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appPaymentPopulator]',
})
export class PaymentPopulatorDirective implements AfterViewChecked {
  @Input() appPaymentPopulator: any;

  constructor(public el: ElementRef, readonly renderer: Renderer2) {}

  ngAfterViewChecked(): void {
    let iFrameUrl = '';
    let action = 'post';
    const form = this.renderer.createElement('form');

    for (const key in this.appPaymentPopulator) {
      if (this.appPaymentPopulator.hasOwnProperty(key)) {
        const element = this.appPaymentPopulator[key];
        const input = this.renderer.createElement('input');
        this.renderer.setProperty(input, 'type', 'hidden');
        this.renderer.setProperty(input, 'name', key);
        this.renderer.setProperty(input, 'value', element);

        if (key === 'IFrameUrl' || key === 'authorization_url') {
          iFrameUrl = element;
        }

        if (key === 'Method') {
          action = element;
        }

        this.renderer.appendChild(form, input);
      }
    }

    this.renderer.setProperty(form, 'action', iFrameUrl);
    this.renderer.setProperty(form, 'method', action);
    this.renderer.setProperty(form, 'target', '_self');

    this.renderer.appendChild(this.el.nativeElement, form);
    form.submit();
  }
}
