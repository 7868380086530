import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';

import { HelpService } from 'src/app/core/services/help.service';
import { HelpQuery } from 'src/app/core/state/help/help.query';
import { HelpSection } from 'src/app/shared/models/help.model';

interface ISection {
  content: string;
  title: string;
  url: string;
}

@Component({
  selector: 'app-help-items',
  templateUrl: './help-items.component.html',
  styleUrls: ['./help-items.component.scss'],
})
export class HelpItemsComponent implements OnInit {
  helpSections$ = this.helpQuery.sections$;
  loading = true;
  isSalesforce: boolean;

  constructor(
    private readonly helpService: HelpService,
    private readonly helpQuery: HelpQuery,
    private readonly applicationService: ApplicationService,
    private readonly appConfigService: AppConfigService,
    private readonly router: Router
  ) {
    this.isSalesforce = this.appConfigService.get('liveChat').liveChatIntegration === 'salesforce';
  }

  ngOnInit(): void {
    this.helpService.getHelp().subscribe(_ => (this.loading = false));
  }

  urlTrackBy(index: number, item: HelpSection): string {
    return item.url;
  }

  itemClick(section: ISection) {
    const { url } = section;
    if (this.isSalesforce && url === 'contact-us') {
      this.applicationService.menuMethodCalls.loadLiveChat();
    } else {
      this.router.navigateByUrl(`/help/${url}`);
    }
  }
}
