// Custom Domain Keys
export type ActiveUrlDomainKeys = 'latest-jackpots' | ActiveUrlMatcherStoreKey;
// Url Path key to be Matched
export type ActiveUrlMatcherStoreKey = 'virtual' | 'casino' | 'jackpot-bets' | 'promotions' | 'blog';

export const mapActiveUrlByPathKey = (key: ActiveUrlDomainKeys): ActiveUrlMatcherStoreKey => {
  const optionsMap: Partial<Record<ActiveUrlDomainKeys, ActiveUrlMatcherStoreKey>> = {
    'latest-jackpots': 'virtual',
  };
  return optionsMap[key] ?? (key as ActiveUrlMatcherStoreKey);
};
