import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FavouriteSportsService } from 'src/app/core/services/favourite-sports.service';
import { SportQuery } from 'src/app/core/state/sport/sport.query';
import { SportModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-favourite-toggle',
  templateUrl: './favourite-toggle.component.html',
  styleUrls: ['./favourite-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavouriteToggleComponent implements OnInit, OnDestroy {
  @Input() sport: SportModel;
  isFavourite: boolean;
  private readonly destroy$ = new Subject<boolean>();

  constructor(
    private readonly favouriteSportsService: FavouriteSportsService,
    private readonly sportQuery: SportQuery,
    private readonly cdr: ChangeDetectorRef
  ) {}

  get iconClass(): string {
    return this.isFavourite ? 'fa-star' : 'fa-star-o';
  }

  ngOnInit(): void {
    this.sportQuery
      .selectIsFavourite(this.sport.id)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(isFavourite => {
        this.isFavourite = isFavourite;
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleFavourite(): void {
    this.isFavourite
      ? this.favouriteSportsService.removeFromFavourites(this.sport)
      : this.favouriteSportsService.addToFavourites(this.sport);
  }
}
