import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationStore } from 'src/app/core/state/application/application.store';
import { ApplicationUIState } from 'src/app/shared/models/application.model';

declare let window: any;

@Injectable({
  providedIn: 'root',
})
export class DynamicScriptLoaderService implements OnDestroy {
  private scriptStore: { name: string; src: string; id?: string }[] = [];
  private readonly scripts: object = {};
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly applicationStore: ApplicationStore, private readonly appConfigService: AppConfigService) {}

  initialise(): void {
    this.scriptStore = [
      { name: 'zoho', src: 'https://salesiq.zoho.com/widget', id: 'zsiqscript' },
      { name: 'bet-radar-widget', src: this.appConfigService.get('apiBaseUrl').betRadarWidgets },
      { name: 'sfk', src: 'https://c.la1-c2cs-lo2.salesforceliveagent.com/content/g/js/46.0/deployment.js' },
      { name: 'sfk-tracking', src: './assets/js/sfk-chat-status-tracking.js' },
    ];

    this.scriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
        id: script.id,
      };
    });
  }

  loadSFKLiveChat(): void {
    if (window._sfk) {
      this.applicationStore.updateUI({ loadingSFKChat: false });
      this.applicationStore.updateUI({ showSFKChat: true });
      return;
    }
    window._sfk = this.appConfigService.get('liveChat').sfk;

    this.loadScript('sfk')
      .pipe(
        first(),
        map(status => {
          if (status.loaded) {
            const script = window.document.createElement('script');
            script.type = 'text/javascript';
            script.src = this.scripts['sfk-tracking'].src;
            document.getElementsByTagName('head')[0].appendChild(script);
          }
        })
      )
      .subscribe(() => {
        this.applicationStore.updateUI({ loadingSFKChat: false });
        this.applicationStore.updateUI({ showSFKChat: true });
      });
  }

  loadZohoLiveChat(): void {
    if (window.$zoho && window.$zoho.livedesk && window.$zoho.livedesk.floatwindow) {
      window.$zoho.livedesk.floatwindow.visible('show');
      return;
    }
    window.$zoho = {
      salesiq: {
        widgetcode: 'b8338f31343138792845788b3270f9ae489768b09249ac6d8eb22e9a0adf7ec95d5fe629768af6d9a03793700d9418c2',
        values: {},
      },
    };
    const uiUpdate = new ApplicationUIState({ isZohoLiveChatLoading: true });
    this.applicationStore.updateUI(uiUpdate);
    this.loadScript('zoho').pipe(takeUntil(this.destroy$)).subscribe();
  }

  loadScript(name: string): Observable<any> {
    return new Observable(observer => {
      if (!this.scripts[name]) {
        observer.error(`The ${name} script is not existing in the ScriptStore`);
      } else if (!this.scripts[name].loaded) {
        // Loads the script
        const script = window.document.createElement('script');
        script.type = 'text/javascript';
        script.defer = true;
        script.async = true;
        script.src = this.scripts[name].src;
        if (this.scripts[name].id) {
          script.id = this.scripts[name].id;
        }
        if (script.readyState) {
          // IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = undefined;
              this.scripts[name].loaded = true;
              observer.next({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          // Other browsers
          script.onload = () => {
            this.scripts[name].loaded = true;
            observer.next({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = () => {
          observer.next({ script: name, loaded: false, status: 'Loaded' });
        };
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        observer.next({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
