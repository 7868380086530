import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { BetLiveDetailsModel } from 'src/app/modules/my-bets/models/my-bets.model';
import { CouponDetailsModel, CouponDetailsState } from 'src/app/shared/models/coupon-details.model';

function createInitialState(): CouponDetailsState {
  return {
    couponDetails: undefined,
    ui: {
      isLoading: false,
      wrongCouponCode: false,
      checkOutValueLoading: false,
    },
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'couponDetails' })
export class CouponDetailsStore extends Store<CouponDetailsState> {
  constructor() {
    super(createInitialState());
  }

  updateCouponDetailsData(couponDetails: CouponDetailsModel): void {
    this.update({ couponDetails });
  }

  updateCouponDetailsLiveData(liveDetails: BetLiveDetailsModel): void {
    this.update(state => ({
      couponDetails: {
        ...state.couponDetails,
        liveDetails,
        isJackpotWinner: state.couponDetails.isJackpotWinner,
        hasFreebetsVoucher: state.couponDetails.hasFreebetsVoucher,
        isFlexicut: state.couponDetails.isFlexicut,
      },
    }));
  }

  updateUI(ui: Partial<CouponDetailsState['ui']>): void {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui,
      },
    }));
  }
}
