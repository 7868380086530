import { Pipe, PipeTransform } from '@angular/core';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Pipe({
  name: 'titleCase',
})
export class TitleCasePipe implements PipeTransform {
  constructor(private readonly appConfig: AppConfigService) {}

  transform(title: string, sportId?: number): any {
    const isFootballGo = sportId && sportId === this.appConfig.get('sports').footballGoId;
    if (isFootballGo) return title;
    const titleString = title.toLowerCase().split(' ');

    const final = [];

    for (const word of titleString) {
      final.push(word.charAt(0).toUpperCase() + word.slice(1));
    }

    return final.join(' ');
  }
}
