import { BetCoupon, BetCouponGlobalVariable } from 'clientside-coupon';
import { BookedCoupon, CouponGroupingType, DefaultCouponStake } from 'src/app/shared/models/coupon.model';
import { SportModel } from 'src/app/shared/models/sport.model';

export interface VirtualsCouponState {
  bookedCoupons: BookedCoupon[];
  couponData: BetCoupon;
  selections: SportModel[];
  couponInitialized: boolean;
  couponSettings: VirtualsCouponSettings;
  defaultCouponStake: DefaultCouponStake;
  correctScoreOddsMatrix: any;
  globalVariables: BetCouponGlobalVariable;
  groupingsTabSelected: CouponGroupingType;
  ui: VirtualsCouponUIState;
}

export class VirtualsCouponUIState {
  showCoupon: boolean;
  showQuickCoupon: boolean;
  stakeChanged: boolean;

  constructor(init: Partial<VirtualsCouponUIState>) {
    Object.assign(this, init);
  }
}
export class VirtualsCouponSettings {
  allowOddChanges: boolean;
  allowStakeReduction: boolean;
  allowTransfer: boolean;
  transferUserId: string;

  constructor(init?: Partial<VirtualsCouponSettings>) {
    Object.assign(this, init);
  }
}
