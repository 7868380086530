import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FreebetsQuery } from 'src/app/modules/freebets/state/freebets.query';
import { FreeBetProductType } from 'src/app/modules/freebets/models/freebets.model';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { dataLayerFreeBetCategory, dataLayerFreeBetProduct } from 'src/app/shared/utils/data-layer-product-map';

@Component({
  selector: 'coupon-freebets-available',
  templateUrl: './coupon-freebets-available.component.html',
  styleUrls: ['./coupon-freebets-available.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreebetsAvailableComponent implements OnInit {
  @Input() readonly productType: FreeBetProductType;

  freebetsAppliedLabel$: Observable<string>;
  hasSelectedFreebet$: Observable<boolean>;
  readonly label$ = this.freebetQuery.freebetsAvailableLabel$;
  readonly showList$ = new BehaviorSubject(false);

  constructor(
    private readonly freebetQuery: FreebetsQuery,
    private readonly dataLayerService: DataLayerService,
    private readonly accountQuery: AccountQuery
  ) {}

  ngOnInit() {
    this.freebetsAppliedLabel$ = this.freebetQuery.betslipFreeBetsAppliedLabel$(this.productType);
    this.hasSelectedFreebet$ = this.freebetQuery.hasSelectedFreebetVoucher$(this.productType);
  }

  handleClick(): void {
    this.showList$.next(!this.showList$.getValue());

    this.dataLayerService.createDataLayerEvent({
      event: 'free_bet_clicked',
      userId: this.accountQuery.userData?.id,
      product: dataLayerFreeBetProduct(this.productType),
      category: dataLayerFreeBetCategory(this.productType),
      sub_category: this.productType,
    });
  }
}
