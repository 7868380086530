import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { CookieService } from 'src/app/core/services/cookie.service';
import { Router, Scroll } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { Subscription } from 'rxjs';

const BANNER_COOKIE_NAME = 'native-app-banner-clicked';
const BANNER_MARGIN_TOP = '70px';
const BANNER_X_CLICKED = 'smart-banner-x-button-clicked';
const BANNER_INSTALL_CLICKED = 'smart-banner-install-clicked';
const BANNER_SHOWN = 'smart-banner-shown';
@Component({
  selector: 'app-native-app-banner',
  templateUrl: './native-app-banner.component.html',
  styleUrls: ['./native-app-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NativeAppBannerComponent implements OnInit, OnDestroy {
  androidEnabled = this.appConfig.get('appBanner')?.androidEnabled;
  iosEnabled = this.appConfig.get('appBanner')?.iosEnabled;
  isBannerVisible: boolean = false;
  linkToApp: string;
  menu: HTMLDivElement;
  private routerSubscription: Subscription;
  @Output() readonly bannerVisibilityChanged = new EventEmitter<boolean>();

  constructor(
    private readonly cookieService: CookieService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly dataLayerService: DataLayerService,
    private readonly accountQuery: AccountQuery,
    private readonly appConfig: AppConfigService
  ) {
    this.linkToApp = this.appConfig.get('appBanner')?.linkToApp;
  }

  ngOnInit() {
    this.routerSubscription = this.router.events.pipe(filter(event => event instanceof Scroll)).subscribe(async () => {
      this.isBannerVisible = await this.calculateBannerVisibility();
      this.cdr.detectChanges();
      this.menuToggle();
      if (document.querySelector<HTMLDivElement>('.native-app-banner')) {
        document.querySelector<HTMLDivElement>('.native-app-banner').style.width = `${window.innerWidth}px`;
      }
    });

    this.calculateBannerVisibility().then(isVisible => {
      this.bannerVisibilityChanged.emit(isVisible);
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from the router event when the component is destroyed
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  menuToggle() {
    const menu = document.querySelector<HTMLDivElement>('.menu');
    const switcher = document.querySelector<HTMLDivElement>('.header-container-spacer.pin-to-top header-bk-product-switcher');

    if (menu && switcher) {
      if (this.isBannerVisible) {
        menu.style.marginTop = window.innerWidth < 900 ? BANNER_MARGIN_TOP : '110px';
        switcher.style.top = window.innerWidth < 900 ? '127px' : '10px';
        this.dataLayerService.createDataLayerEvent({
          event: BANNER_SHOWN,
          ...(this.accountQuery.userData ? { userId: this.accountQuery.userData.id } : {}),
        });
      }
    }
  }

  closeBanner(eventType: 'x' | 'install'): void {
    const event = eventType === 'x' ? BANNER_X_CLICKED : BANNER_INSTALL_CLICKED;
    this.isBannerVisible = false;
    this.dataLayerService.createDataLayerEvent({
      event,
      ...(this.accountQuery.userData ? { userId: this.accountQuery.userData.id } : {}),
    });
    this.cookieService.setCookie(BANNER_COOKIE_NAME, 'true', 30);
    const menu = document.querySelector<HTMLDivElement>('.menu');
    const switcher = document.querySelector<HTMLDivElement>('.header-container-spacer.pin-to-top header-bk-product-switcher');

    if (menu && switcher) {
      menu.style.marginTop = window.innerWidth < 900 ? '0px' : '50px';
      switcher.style.top = '54px';
    }
  }

  isAndroid(): boolean {
    return /Android/i.test(navigator.userAgent);
  }

  isIOS(): boolean {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  isSupportedVersion(): boolean {
    const androidVersion = this.getAndroidVersion();
    if (androidVersion === null) {
      return true; // Non-detectable version, assuming it's supported
    }
    return androidVersion >= 7.1;
  }

  isClicked(): boolean {
    return this.cookieService.getCookie(BANNER_COOKIE_NAME) === 'true';
  }

  isWebView(): boolean {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get('app_id') === 'KINGMAKERS' || this.cookieService.getCookie('app_id') === 'KINGMAKERS';
  }

  isHomePage(): boolean {
    return window.location.pathname === '/' || window.location.pathname === '';
  }

  async isInstalled(): Promise<boolean> {
    if ('getInstalledRelatedApps' in navigator) {
      const relatedApps = (await (navigator as any).getInstalledRelatedApps()) || [];
      return relatedApps.some(app => app.id === 'com.kingmakers.betking.staging' || app.id === 'com.kingmakers.betking');
    }
    return false;
  }

  private getAndroidVersion(): number | null {
    const userAgent = navigator.userAgent;
    const match = userAgent.match(/Android\s+([0-9\.]+)/);
    if (match && match[1]) {
      return parseFloat(match[1]);
    }
    return null; // Non-detectable version
  }

  private async calculateBannerVisibility(): Promise<boolean> {
    return (
      (this.isAndroid()
        ? this.androidEnabled && this.isSupportedVersion() && !(await this.isInstalled())
        : this.iosEnabled && this.isIOS()) &&
      this.isHomePage() &&
      !this.isClicked() &&
      !this.isWebView()
    );
  }
}
