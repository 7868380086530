<div>
  <div class="event">
    <app-event-tournament-details [event]="event" [sportId]="sportId" [showOddCount]=false *ngIf="showDetails && sportId">
    </app-event-tournament-details>
    <div class="event-info">
      <app-event-details [event]="event" *ngIf="showDetails"
        (click)="matchService.navigateToMatch(event.id, event.name)">
      </app-event-details>
      <app-multi-line-market-odds [odds]="event.odds" [market]="market"></app-multi-line-market-odds>
    </div>
  </div>
  <app-statistics *ngIf="event.id === (statisticsQuery.selectedEventId$ | async)" [eventLocation]="eventLocation"
    [match]="event"></app-statistics>
</div>
