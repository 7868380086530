import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ApplicationService } from 'src/app/core/services/application.service';
import { BookBetService } from 'src/app/core/services/book-bet.service';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { BookBetComponent } from 'src/app/modules/book-bet/book-bet.component';
import { NotificationSettings } from 'src/app/shared/models/notification.model';

@Component({
  selector: 'app-saved-coupons',
  templateUrl: './saved-coupons.component.html',
  styleUrls: ['./saved-coupons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SavedCouponsComponent extends BookBetComponent {
  @Input() withExpiredPrompt: boolean = false;

  constructor(
    readonly couponQuery: CouponQuery,
    protected readonly bookBetService: BookBetService,
    protected readonly notificationService: NotificationService,
    protected readonly applicationService: ApplicationService,
    private readonly couponService: CouponService
  ) {
    super(couponQuery, bookBetService, notificationService, applicationService, undefined);
  }

  getBookBetData(bookCode: string): void {
    this.bookBet(bookCode);
  }

  removeBookedBet(couponCode: string): void {
    const confirmCallback = () => {
      this.couponService.removeBookedCoupon(couponCode);
    };

    this.notificationService.showNotification(
      new NotificationSettings({
        allowBackdropClose: true,
        confirmButtonCallback: confirmCallback,
        contentText: $localize`Are you sure you want to remove this booked coupon?`,
        type: 'question',
        showConfirmButton: true,
        showCancelButton: true,
      })
    );
  }

  indexTrackBy(index: number): number {
    return index;
  }
}
