import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Observable, of } from 'rxjs';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { RefreshTokenService } from 'src/app/core/services/account/refresh-token.service';
import { AccountService } from 'src/app/core/services/account/account.service';
import { catchError, concatMap, finalize, first, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'src/app/core/services/cookie.service';

@Injectable()
export class HttpOAuth2Interceptor implements HttpInterceptor {
  private callHandler = false;
  private refreshTokenApiInProgress = true;
  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly appConfig: AppConfigService,
    private readonly refreshTokenQuery: RefreshTokenService,
    private readonly accountService: AccountService,
    private readonly router: Router,
    private readonly cookieService: CookieService
  ) {}

  executor(initialRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let accessToken: string;
    let sendActivitySource: boolean = false;
    let request: HttpRequest<any> = initialRequest.clone();

    const activitySource: string = this.appConfig.get('activitySource');
    const appActivitySource: string = this.appConfig.get('appActivitySource');
    const apiBaseUrl = this.appConfig.get('apiBaseUrl');

    if (request.headers.has('VND.noAuthToken')) {
      // no auth token should be added in this case
      request = request.clone({ headers: request.headers.delete('VND.noAuthToken') });
    } else if (request.headers.has('VND.forceAuthToken')) {
      accessToken = request.headers.get('VND.forceAuthToken');
      request = request.clone({ headers: request.headers.delete('VND.forceAuthToken') });
    } else if (this.accountQuery.isAuthenticated) {
      accessToken = this.accountQuery.accessToken;
    }

    if (request.headers.has('VND.sendActivitySource')) {
      sendActivitySource = true;
      request = request.clone({ headers: request.headers.delete('VND.sendActivitySource') });
    }

    if (accessToken) {
      // casino requests dont accept Bearer keyword in the Authorization header
      if (initialRequest.url.startsWith(apiBaseUrl.casino)) {
        request = request.clone({ setHeaders: { Authorization: `${accessToken}` } });
      } else request = request.clone({ setHeaders: { Authorization: `Bearer ${accessToken}` } });
    }

    // The ActivitySource header will be added if the sendActivitySource is set to true OR if the
    // Authorization header (accessToken) will be sent as part of the call
    if (Capacitor.isNativePlatform()) {
      if (appActivitySource && (sendActivitySource || accessToken)) {
        request = request.clone({ setHeaders: { ActivitySource: appActivitySource } });
      }
    } else {
      if (activitySource && (sendActivitySource || accessToken)) {
        request = request.clone({ setHeaders: { ActivitySource: activitySource } });
      }
    }

    return next.handle(request);
  }

  intercept(initialRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isAppIdExist = this.cookieService.getCookie('app_id') === 'KINGMAKERS';
    if (isAppIdExist && this.cookieService.getCookie('refreshToken')) {
      if (!this.cookieService.getCookie('accessToken') && !this.callHandler) {
        this.callHandler = true;
        if (!this.refreshTokenApiInProgress) {
          this.refreshTokenApiInProgress = true;
          const calls = this.refreshTokenQuery.getRefreshToken().pipe(
            catchError(() => {
              this.logout();
              return of(null);
            }),
            concatMap(token => {
              return this.accountService.getUserData(token);
            })
          );
          calls
            .pipe(
              tap(data => {
                this.callHandler = false;
                this.refreshTokenApiInProgress = false;
                return this.executor(initialRequest, next);
              }),
              catchError(error => {
                if (error.status === 400 || error.status === 401) {
                  this.logout();
                }
                return of(false);
              })
            )
            .subscribe();
        }
      } else {
        return this.executor(initialRequest, next);
      }
    } else {
      return this.executor(initialRequest, next);
    }
  }

  private logout(): void {
    this.accountService
      .logout()
      .pipe(
        first(),
        finalize(() => {
          this.cookieService.clearCookie('accessToken');
          this.router.navigate(['/login']);
        })
      )
      .subscribe(undefined, () => {
        // if an error occurs while logging out, manually clear the
        // user data
        this.accountService.clearUserData();
      });
  }
}
