import { Pipe, PipeTransform } from '@angular/core';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';

@Pipe({ name: 'oddChanged' })
export class OddChangedPipe implements PipeTransform {
  constructor(private readonly couponQuery: CouponQuery) {}

  transform(hasOddChanges: boolean, oddId: number): any {
    if (hasOddChanges) {
      const oddChange = this.couponQuery.oddChanges.find(o => o.oddId === oddId);

      if (oddChange === undefined || oddChange.initialOddValue === oddChange.latestOddValue) {
        return '';
      } else if (oddChange.latestOddValue > oddChange.initialOddValue) {
        return ['valueChanged', 'valueIncreased', 'flashSuccess'];
      }
      return ['valueChanged', 'valueDecreased', 'flashDanger'];
    } else {
      return '';
    }
  }
}
