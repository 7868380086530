import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ShopOwnerQuery } from 'src/app/core/state/shop-owner/shop-owner.query';
import { ShopOwnerStore } from 'src/app/core/state/shop-owner/shop-owner.store';
import { UserType } from 'src/app/shared/models/account.model';
import { APIType } from 'src/app/shared/models/api.model';
import { SubUserModel } from 'src/app/shared/models/shop-owner.model';
import { APIService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ShopOwnerService {
  constructor(
    private readonly shopOwnerStore: ShopOwnerStore,
    private readonly shopOwnerQuery: ShopOwnerQuery,
    private readonly apiService: APIService,
    private readonly accountQuery: AccountQuery
  ) {}

  initialize(): void {
    // Any time the logged in user data is updated, we must check if the new user is a shop owner and
    // retrieve the sub users list
    this.accountQuery.userData$.subscribe(userData => {
      if (userData && userData.userTypeCode === UserType.ShopOwner) {
        this.updateSubUsersList();
      }
    });
  }

  isSubUser(id: number): boolean {
    return this.shopOwnerQuery.subUsersList ? this.shopOwnerQuery.subUsersList.findIndex(sub => sub.userId === id) > -1 : false;
  }

  isSubUser$(id: number): Observable<boolean> {
    return this.shopOwnerQuery.subUsersList$.pipe(map(subs => (subs ? subs.findIndex(sub => sub.userId === id) > -1 : false)));
  }

  clearSubUsersList(): void {
    this.shopOwnerStore.clearSubUsersList();
  }

  private updateSubUsersList(): void {
    this.apiService
      .get(APIType.Platform, 'api/GamingVendors/3BGSportsbook/GetDelegatedBetUserList')
      .pipe(
        first(),
        map(res =>
          res && res.Result
            ? res.Result.DelegatedBetUsers.map(dbu => ({
                userId: parseInt(dbu.UserId, 10),
                name: dbu.FormattedName,
                isEnabled: dbu.IsEnabled,
              }))
            : undefined
        )
      )
      .subscribe((sub: SubUserModel[]) => {
        this.shopOwnerStore.updateSubUsersList(sub);
      });
  }
}
