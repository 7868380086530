<div class="breadcrumb-navigation-element" data-testid="breadcrumb-navigation-element">
  <div
    class="breadcrumb-navigation-element-title"
    *ngIf="activeItem$ | async as activeItem"
    [ngClass]="{ 'last-active-level': (lastActiveItem$ | async) === activeItem.uniqueValue }"
    [attr.data-testid]="'breadcrumb-navigation-element-' + (activeItemTestId$ | async)"
  >
    <span class="breadcrumb-separator">/</span>
    <div class="breadcrumb-title" (click)="breadcrumbClick(activeItem, this.breadcrumb.uniqueValue)">
      <span>{{ activeItem.title }}</span>
      <span class="breadcrumb-arrow-icon" *ngIf="breadcrumbItems?.length > 1" data-testid="breadcrumb-navigation-dropdown-arrow">
        <i class="fa" [ngClass]="this.breadcrumb.uniqueValue === openedDropdownItem ? 'fa-caret-up' : 'fa-caret-down'"></i>
      </span>
    </div>
    <div
      class="breadcrumb-navigation-element-dropdown"
      data-testid="breadcrumb-navigation-dropdown"
      *ngIf="this.breadcrumb.uniqueValue === openedDropdownItem"
      appPositionDropdownOnScreen
    >
      <div
        class="breadcrumb-navigation-element-dropdown-item"
        data-testid="breadcrumb-navigation-dropdown-item"
        *ngFor="let item of breadcrumbItems"
        [ngClass]="{ 'disabled': isLeagueDisabledForSubAccount(item.url) }"
        (click)="goToPage(item)"
      >
        {{ item.title }}
      </div>
    </div>
  </div>
  <ng-container *ngFor="let breadcrumbItem of breadcrumbItems">
    <div class="breadcrumb-navigation-element-subitems" *ngIf="breadcrumbItem.subMenu?.length">
      <app-breadcrumb-item [breadcrumb]="breadcrumbItem" [openedDropdownItem]="openedDropdownItem"></app-breadcrumb-item>
    </div>
  </ng-container>
</div>
