import { Bonus } from 'clientside-coupon';

export class BonusModel {
  canPause: boolean;
  expirationDate: Date;
  id: number;
  isActivated: boolean;
  isCancelled: boolean;
  isExpired: boolean;
  isPaused: boolean;
  bonusCode: number;
  currentProgress: number;
  nextReleaseAmount: number;
  isBonusTransferPossible: boolean;

  constructor(init: Partial<BonusModel>) {
    Object.assign(this, init);
  }
}

export interface BonusInfoModel {
  name: string;
  bonusCode: number;
  imageURL: string;
  content: string;
  contentTitle: string;
  summary: string;
  type: PromotionType;
  additionalInfoURL: string;
}

export enum PromotionType {
  Welcome = 0,
  Other = 1,
}

export interface AccumulatorBonusState {
  dismissedAccumulatorBonusOddsValuePopup: boolean;
  showInfoModal: boolean;
  cmsContent: AccumulatorBonusContentModel;
  bonusList: Bonus[];
  isSportsBonusListInit: boolean;
}

export interface AccumulatorBonusContentModel {
  accumulatorBonusOddsValuePopupText: string;
  accumulatorBonusInfoModalTitle: string;
  accumulatorBonusInfoModalSubtitle: string;
  accumulatorBonusInfoModalBody: string;
  accumulatorBonusInfoModalCloseCTA: string;
  accumulatorBonusSelectionsTillBonusText: string;
  accumulatorBonusOneSelectionTillBonusText: string;
}

export interface BonusEligibilityModel {
  // status strings are being stored in case additional logic will be required based on the different status values
  bankTransferDetailsStatus: string;
  bankTransferDetailsVerified: boolean;
  userKYCStatus: string;
  userKYCVerified: boolean;
}

export interface BonusBankVerificationModel {
  lastAccNumber: number;
  lastBankId: number;
  BankName: string;
  BankCode: string;
}
