import { NgModule } from '@angular/core';
import { MobileVirtualsSoccerSharedModule } from 'mobile-virtuals-soccer';
import { CouponModule } from 'src/app/modules/coupon/coupon.module';
import { VirtualsCouponGroupingsComponent } from 'src/app/modules/virtuals/modules/virtuals-coupon/components/virtuals-coupon-groupings/virtuals-coupon-groupings.component';
import { VirtualsCouponPageWrapperComponent } from 'src/app/modules/virtuals/modules/virtuals-coupon/components/virtuals-coupon-page-wrapper/virtuals-coupon-page-wrapper.component';
import { VirtualsCouponSelectionsComponent } from 'src/app/modules/virtuals/modules/virtuals-coupon/components/virtuals-coupon-selections/virtuals-coupon-selections.component';
import { VirtualsCouponTotalsComponent } from 'src/app/modules/virtuals/modules/virtuals-coupon/components/virtuals-coupon-totals/virtuals-coupon-totals.component';
import { VirtualsCouponComponent } from 'src/app/modules/virtuals/modules/virtuals-coupon/virtuals-coupon.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FreebetsModule } from 'src/app/modules/freebets/freebets.module';
import { VirtualsWidgetsModule } from 'src/app/modules/virtuals-widgets/virtuals-widgets.module';
import { VirtualsCouponSelectionItemComponent } from './components/virtuals-coupon-selections/virtuals-coupon-selection-item.component';
import { VirtualsCouponRoutingModule } from './virtuals-coupon-routing.module';

const COMPONENTS = [
  VirtualsCouponComponent,
  VirtualsCouponSelectionsComponent,
  VirtualsCouponGroupingsComponent,
  VirtualsCouponTotalsComponent,
  VirtualsCouponPageWrapperComponent,
  VirtualsCouponSelectionItemComponent,
];

@NgModule({
  imports: [
    SharedModule,
    VirtualsCouponRoutingModule,
    VirtualsWidgetsModule,
    MobileVirtualsSoccerSharedModule,
    CouponModule,
    FreebetsModule,
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  // Disabled the MvsOddsService provider because it was causing issues with changes related to VIR-8037 and
  // through testing it didn't seem to be used. Should be re-enabled if issues are encountered with the coupon
  // providers: [{ provide: MvsOddsService, useClass: VirtualsCouponEditService }],
})
export class VirtualsCouponModule {}
