import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SportsListService } from 'src/app/core/services/sports/sports-list.service';
import { MenuQuery } from 'src/app/core/state/menu/menu.query';
import { fadeIn } from 'src/app/shared/animations';
import { MenuTab } from 'src/app/shared/models/menu.model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu-wrapper.component.html',
  styleUrls: ['./menu-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn()],
})
export class MenuWrapperComponent implements OnInit {
  menuTab = MenuTab;

  constructor(private readonly sportsListService: SportsListService, public menuQuery: MenuQuery) {}

  ngOnInit(): void {
    this.sportsListService.getSportsList().subscribe();
  }
}
