import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CouponType } from 'clientside-coupon';
import { ApplicationService } from 'src/app/core/services/application.service';
import { expandCollapse } from 'src/app/shared/animations';
import { CouponGroupingType, OddsByMarket } from 'src/app/shared/models/coupon.model';
import { CategoryModel, MatchModel, SportModel, TournamentModel } from 'src/app/shared/models/sport.model';

import { OddsLayoutResolverService } from 'mobile-virtuals-soccer';
import { ButtonType, SmallButtonType } from 'src/app/shared/models/button.model';
import { InstantCouponQuery } from 'src/app/core/state/instant-coupon/instant-coupon.query';
import { InstantCouponService } from 'src/app/core/services/instant-coupon/instant-coupon.service';
import { kebabCase } from 'lodash-es';
import { Router } from '@angular/router';

@Component({
  selector: 'app-virtuals-instant-coupon-selections',
  templateUrl: './virtuals-instant-coupon-selections.component.html',
  styleUrls: ['./virtuals-instant-coupon-selections.component.scss'],
  animations: [expandCollapse()],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OddsLayoutResolverService],
})
export class VirtualsInstantCouponSelectionsComponent {
  @Input() selectedGroupingTab: CouponGroupingType;

  buttonType: typeof ButtonType = ButtonType;
  couponType: typeof CouponType = CouponType;
  groupingType: typeof CouponGroupingType = CouponGroupingType;

  smallButtonStyle: SmallButtonType = {
    fontSize: '12px',
    padding: '4px 16px',
    borderRadius: '50px',
    minWidth: 'unset',
  };

  constructor(
    readonly instantCouponQuery: InstantCouponQuery,
    private readonly applicationService: ApplicationService,
    private readonly couponService: InstantCouponService,
    private readonly router: Router
  ) {}

  removeOdd(selectionId: number): void {
    this.couponService.removeOdd(selectionId);
  }

  categoryTrackBy(index: number, category: CategoryModel): number {
    return category.id;
  }

  selectionTrackBy(index: number, item: SportModel): number {
    return item.id;
  }

  tournamentTrackBy(index: number, item: TournamentModel): number {
    return item.id;
  }

  matchTrackBy(index: number, item: MatchModel): number {
    return item.id;
  }

  oddsByMarketTrackBy(index: number, oddsByMarket: OddsByMarket): number {
    return oddsByMarket.marketId;
  }

  goToPage(id: number, name: string): void {
    const kebabCaseName = kebabCase(name);
    this.router.navigate([`sports/match/${id}${kebabCaseName ? `/${kebabCaseName}` : ''}`]);

    this.applicationService.closeAnySlideUps();
  }
}
