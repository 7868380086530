import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { asyncScheduler, fromEvent, Subject } from 'rxjs';
import { distinctUntilChanged, map, throttleTime } from 'rxjs/operators';

/** @dynamic */
@Directive({
  selector: '[appPinToTop]',
})
export class PinToTopDirective implements AfterViewInit, OnDestroy {
  private height = 0;
  private readonly destroy$ = new Subject();

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngAfterViewInit(): void {
    const rect = this.elementRef.nativeElement.getBoundingClientRect();
    const body = this.document.body.getBoundingClientRect();
    const initialTop = rect.top - body.top;
    this.height = rect.height;

    const shouldBePinned$ = fromEvent(window, 'scroll').pipe(
      throttleTime(60, asyncScheduler, { leading: true, trailing: true }),
      map(event => window.scrollY > initialTop),
      distinctUntilChanged()
    );

    shouldBePinned$.subscribe(this.changePinned);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private readonly changePinned = (pinned: boolean) => {
    pinned ? this.addPinned() : this.removePinned();
  };

  private addPinned(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'fixed');
    this.renderer.setStyle(this.elementRef.nativeElement, 'top', 0);
    this.renderer.setStyle(this.elementRef.nativeElement, 'box-shadow', '0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 0 rgba(0, 0, 0, 0.2)');
    this.renderer.setStyle(this.elementRef.nativeElement.nextElementSibling, 'margin-top', `${this.height}px`);
    this.renderer.setStyle(this.elementRef.nativeElement.nextElementSibling, 'display', 'block');
  }

  private removePinned(): void {
    //  We explicitly have to set the attributes to null in order to have them removed
    this.renderer.setStyle(this.elementRef.nativeElement, 'position', null);
    this.renderer.setStyle(this.elementRef.nativeElement, 'top', null);
    this.renderer.setStyle(this.elementRef.nativeElement, 'box-shadow', null);
    this.renderer.setStyle(this.elementRef.nativeElement.nextElementSibling, 'margin-top', null);
    this.renderer.setStyle(this.elementRef.nativeElement.nextElementSibling, 'display', null);
  }
}
