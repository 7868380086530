import { NgModule } from '@angular/core';
import { FreebetsAvailableComponent } from 'src/app/modules/freebets/components/coupon-freebets-available/coupon-freebets-available.component';
import { FreebetsListModalComponent } from 'src/app/modules/freebets/components/freebets-list-modal/freebets-list-modal.component';
import { FreebetsVoucherComponent } from 'src/app/modules/freebets/components/freebets-voucher/freebets-voucher.component';
import { SportsbookFreeBetService } from 'src/app/modules/freebets/services/sportsbook-free-bet.service';

import { SharedModule } from 'src/app/shared/shared.module';
import { VirtualsScheduledFreeBetService } from 'src/app/modules/freebets/services/virtuals-scheduled-free-bet.service';

const EXPORTS = [FreebetsAvailableComponent, FreebetsVoucherComponent];

const COMPONENTS = [...EXPORTS, FreebetsListModalComponent];

@NgModule({
  imports: [SharedModule],
  declarations: [...COMPONENTS],
  providers: [SportsbookFreeBetService, VirtualsScheduledFreeBetService],
  exports: [...EXPORTS],
})
export class FreebetsModule {}
