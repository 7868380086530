import { Injectable } from '@angular/core';
import { first, map, tap } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { SportService } from 'src/app/core/services/sports/sport.service';
import { SportQuery } from 'src/app/core/state/sport/sport.query';
import { SportStore } from 'src/app/core/state/sport/sport.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { EventSummaryModel, MarketModel, OddsBoostInfoModalContent } from 'src/app/shared/models/sport.model';

@Injectable({
  providedIn: 'root',
})
export class SportsOddsBoostService {
  constructor(
    private readonly apiService: APIService,
    private readonly languageService: LanguageService,
    private readonly sportQuery: SportQuery,
    private readonly sportStore: SportStore,
    private readonly sportsService: SportService
  ) {
    this.init();
  }

  init = (): void => {
    // Reset odds boost data whenc changing sport
    this.sportQuery.selectedSport$.pipe(tap(() => this.sportStore.updateOddsBoostSportData(undefined))).subscribe();
  };

  retrieveOddsBoostInfoModalCMSContent = (): void => {
    this.apiService
      .get<OddsBoostInfoModalContent>(
        APIType.CMS,
        `/OddsBoost/GetOddsBoostInfoModalContent?language=${this.languageService.selectedLanguage.locale.toLowerCase()}`
      )
      .pipe(
        first(),
        tap(oddsBoostInfoModalCMSContent => {
          this.sportStore.updateOddsBoostInfoModalCMSContent(oddsBoostInfoModalCMSContent);
        })
      )
      .subscribe();
  };

  retrieveOddsBoostData = (sportId: number) =>
    this.apiService
      .get(
        APIType.SportsbookFeed,
        `api/feeds/prematch/oddsboost/${this.languageService.selectedLanguage.language}/${sportId}`,
        new APISettings({
          noAuthToken: true,
        })
      )
      .pipe(map((responseData: any) => this.sportsService.mapMatchSummaryDataToModel(responseData.AreaMatches[0])));

  updateOddsBoostSportData = (oddsBoostSportData: EventSummaryModel) => this.sportStore.updateOddsBoostSportData(oddsBoostSportData);

  updateSelectedBoostedMarket = (marketSelected: MarketModel) =>
    this.sportStore.updateOddsBoostSportData({
      ...this.sportQuery.oddsBoostSportSummary,
      marketSelected,
    });
}
