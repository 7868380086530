import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeviceInformationService {
  getUserAgent(): string {
    if (window && window.navigator && window.navigator.userAgent) {
      return window.navigator.userAgent;
    } else {
      return undefined;
    }
  }

  getDeviceResolution(): string {
    if (window && window.screen) {
      return `${window.screen.width}*${window.screen.height}`;
    } else {
      return undefined;
    }
  }
}
