import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { MessageService } from 'src/app/core/services/message.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { MessagesQuery } from 'src/app/core/state/messages/messages.query';
import { ApplicationService } from 'src/app/core/services/application.service';

@Component({
  selector: 'header-bk-user',
  templateUrl: './bk-user-header.component.html',
  styleUrls: ['./bk-user-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BKUserHeaderComponent implements OnInit {
  readonly messagesCount$ = this.messagesQuery.unreadMessagesCount$;
  readonly showMessageCountBadge$ = this.messagesQuery.unreadMessagesCount$.pipe(map(count => count > 0));

  private readonly ENABLE_WTH_BANK_PROFILE = this.appConfigService.get('account').enableWithdrawalBankProfile;

  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly appConfigService: AppConfigService,
    private readonly messagesQuery: MessagesQuery,
    private readonly messagesService: MessageService,
    private readonly applicationService: ApplicationService
  ) {}

  ngOnInit(): void {
    if (this.applicationService.getIsFirstLoad()) {
      this.messagesService.getUnreadMessagesCount().subscribe();
      this.applicationService.setIsFirstLoad(false);
    }
  }

  menuItemProfileStatus(): boolean {
    if ((this.accountQuery.userProfileState === 'profileEmpty' || this.accountQuery.sbadIndicatorState) && this.ENABLE_WTH_BANK_PROFILE) {
      return true;
    } else {
      return false;
    }
  }
}
