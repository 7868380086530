import { BetFinalState, CouponStatus } from 'src/app/shared/models/coupon-details.model';

export function mapBetFinalState(ticketStatus: string): BetFinalState {
  switch (ticketStatus?.toUpperCase()) {
    case 'OPEN':
    case 'PENDING':
      return BetFinalState.Placed;
    case 'WON':
    case 'PAIDOUT':
      return BetFinalState.Won;
    case 'LOST':
      return BetFinalState.Lost;
    case 'LOCKED':
    case 'REJECTED':
    case 'CANCELLING':
    case 'CANCELLED':
    case 'EXPIRED':
    default:
      return BetFinalState.Void;
  }
}

export function mapCouponStatus(ticketStatus: string): CouponStatus {
  switch (ticketStatus?.toUpperCase()) {
    case 'OPEN':
    case 'PENDING':
      return CouponStatus.Running;
    case 'WON':
    case 'PAIDOUT':
      return CouponStatus.Won;
    case 'LOST':
      return CouponStatus.Lost;
    case 'REJECTED':
    case 'CANCELLING':
    case 'CANCELLED':
      return CouponStatus.Cancelled;
    case 'LOCKED':
    case 'EXPIRED':
    default:
      return CouponStatus.Unknown;
  }
}

export function mapCouponStatusId(ticketStatus: string): number {
  switch (ticketStatus?.toUpperCase()) {
    case 'OPEN':
    case 'PENDING':
      return 1;
    case 'WON':
    case 'PAIDOUT':
      return 3;
    case 'LOST':
      return 2;
    case 'REJECTED':
    case 'CANCELLING':
    case 'CANCELLED':
      return 4;
    case 'LOCKED':
    case 'EXPIRED':
    default:
      return undefined;
  }
}

export function mapCouponType(groupingCount: number, selectionCount: number): string {
  if (groupingCount === 1 && selectionCount === 1) {
    return 'Single';
  } else if (groupingCount === 1 && selectionCount > 1) {
    return 'Multiple';
  } else {
    return 'Combinations';
  }
}
