import { Observable, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { map } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { HttpParams } from '@angular/common/http';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { CookieService } from 'src/app/core/services/cookie.service';

@Injectable({
  providedIn: 'root',
})
export class RefreshTokenService implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly REFRESH_TOKEN_EXPIRY = 14;
  private readonly NUMBER_OF_SECONDS_PER_DAY = 86400;
  constructor(
    private readonly apiService: APIService,
    private readonly accountQuery: AccountQuery,
    private readonly appConfig: AppConfigService,
    private readonly cookieService: CookieService
  ) {}

  getRefreshToken(): Observable<any> {
    const body = new HttpParams()
      .set('refresh_token', this.cookieService.getCookie('refreshToken'))
      .set('grant_type', 'refresh_token')
      .set('brandId', this.appConfig.get('brandId').toString())
      .toString();
    const apiSettings = new APISettings({
      contentType: 'application/x-www-form-urlencoded',
      sendActivitySource: true,
    });

    return this.apiService.post(APIType.Guardian, 'oauth/refresh', body, apiSettings).pipe(
      map(tokenData => {
        if (tokenData && tokenData.expires_in) {
          this.cookieService.setCookie('accessToken', tokenData.access_token, tokenData.expires_in / this.NUMBER_OF_SECONDS_PER_DAY);
          tokenData?.refresh_token && this.cookieService.setCookie('refreshToken', tokenData.refresh_token, this.REFRESH_TOKEN_EXPIRY);
        }
        return tokenData ? tokenData.access_token : undefined;
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
