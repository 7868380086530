import { MyBetsQuery } from 'src/app/modules/my-bets/state/my-bets.query';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ApplicationService } from 'src/app/core/services/application.service';
import { BetsTab } from 'src/app/modules/my-bets/models/my-bets-enums.model';
import { MyBetsService } from 'src/app/modules/my-bets/services/my-bets.service';
import { ProductType } from 'src/app/shared/models/product.model';
import { VirtualsQuery } from 'src/app/core/state/virtuals/virtuals.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';

@Component({
  selector: 'my-bets-no-bets',
  templateUrl: './no-bets.component.html',
  styleUrls: ['./no-bets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoBetsComponent {
  @Input() tabName: BetsTab;
  @Input() productName: ProductType;

  readonly betsTab = BetsTab;
  readonly productType = ProductType;

  constructor(
    readonly myBetsQuery: MyBetsQuery,
    readonly virtualsQuery: VirtualsQuery,
    readonly applicationQuery: ApplicationQuery,
    private readonly applicationService: ApplicationService,
    private readonly myBetsService: MyBetsService
  ) {
    if (!this.myBetsQuery.myBetsContent) {
      this.myBetsService.initialize();
    }
  }

  navigateHome(): void {
    switch (this.productName) {
      case ProductType.Virtuals:
        this.applicationService.navigateTo('/virtual');
        break;
      case ProductType.JackpotBets:
        this.applicationService.navigateTo('/jackpot-bets');
        break;
      case ProductType.SportsBook:
      default:
        this.applicationService.navigateTo('/');
        break;
    }
  }
}
