import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appPositionDropdownOnScreen]',
})
export class PositionDropdownOnScreenDirective implements AfterViewInit {
  constructor(private readonly elementRef: ElementRef<HTMLElement>, private readonly renderer: Renderer2) {}

  ngAfterViewInit() {
    const elementRect = this.elementRef.nativeElement.getBoundingClientRect();
    const parentElement = this.elementRef.nativeElement.parentElement;
    const parentElementRect = parentElement.getBoundingClientRect();
    const spaceAroundParentElement = (elementRect.width - parentElementRect.width) / 2;
    this.renderer.setStyle(this.elementRef.nativeElement, 'top', `${parentElement.offsetTop + parentElementRect.height + 7}px`);

    if (parentElementRect.left <= 0) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'left', '5px');
    } else if (window.innerWidth - 20 < parentElementRect.right) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'right', '5px');
    } else {
      const left = parentElementRect.left - spaceAroundParentElement;
      this.renderer.setStyle(this.elementRef.nativeElement, 'left', `${left < 8 ? 8 : left}px`);
    }
  }
}
