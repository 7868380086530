export class DropdownModel {
  options: DropdownOptionModel[];

  constructor(init: Partial<DropdownModel>) {
    Object.assign(this, init);
  }
}

export class DropdownOptionModel {
  value: string;
  name: string;

  constructor(init: Partial<DropdownOptionModel>) {
    Object.assign(this, init);
  }
}
