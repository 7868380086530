import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProductSwitcherItemContent } from 'src/app/modules/header/models/header.model';
import { HeaderQuery } from 'src/app/modules/header/state/header.query';

@Component({
  selector: 'header-bk-product-switcher',
  templateUrl: './bk-product-switcher.component.html',
  styleUrls: ['./bk-product-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BKProductSwitcherComponent {
  readonly switcherItems$ = this.headerQuery.productSwitcherContent$;
  readonly currentlySelectedIndex$ = this.headerQuery.productSwitcherCurrentSelectedIndex$;
  readonly isTouchScreenDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || (navigator as any).msMaxTouchPoints > 0;

  constructor(private readonly headerQuery: HeaderQuery) {}

  switcherItemTrackBy = (index: number, item: ProductSwitcherItemContent): string => `${index}_${item.link.title}`;
}
