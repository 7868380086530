<div class="unverified-account-tooltip"
  *ngIf="(accountQuery.isAuthenticated$ | async) && (accountQuery.showUnverifiedTooltip$ | async) && verifyData && verifyData.type === verifyAccountType.VerifyInProgress">
  <div>
    <i class="fa fa-warning" aria-hidden="true"></i>
    <div>
      <span i18n>Please verify your account within</span>
      <ng-container *ngIf="verifyData.daysLeft !== 0">
        <span class="bold">{{ verifyData.daysLeft }}</span><span i18n> days</span>
      </ng-container>
      <ng-container *ngIf="verifyData.daysLeft !== 0 && verifyData.hoursLeft !== 0" i18n> and </ng-container>
      <ng-container *ngIf="verifyData.hoursLeft !== 0">
        <span class="bold">{{ verifyData.hoursLeft }}</span><span i18n> hours</span>
      </ng-container>
      <span i18n>so it does not become inactive.</span>
    </div>
  </div>

  <button class="btn" (click)="verifyExistingPhoneNumber()">
    <span class="btn-text" i18n>Verify Existing Phone Number</span>
  </button>
</div>
