import { EntityState } from '@datorama/akita';
export enum EvaluationStatus {
  Pending = 1,
  Accepted = 2,
  Declined = 3,
  AwaitingReply = 4,
}

export interface EvaluationState extends EntityState<EvaluationModel> {}
export class EvaluationModel {
  coupon: any;
  status: EvaluationStatus;

  constructor(init: Partial<EvaluationModel>) {
    Object.assign(this, init);
  }
}
