import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { from, Observable, of, Subject } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { APIService } from 'src/app/core/services/api.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { AndroidSettingsStore } from 'src/app/modules/native-app/state/android-settings.store';
import { APIType } from 'src/app/shared/models/api.model';
import { VersionPlugin } from 'src/app/modules/native-app/plugins/version-plugin';

@Injectable({
  providedIn: 'root',
})
export class VersionService implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly apiService: APIService,
    private readonly appConfig: AppConfigService,
    private readonly languageService: LanguageService,
    private readonly loadingService: LoadingService,
    private readonly router: Router,
    private readonly androidSettingsStore: AndroidSettingsStore
  ) {}

  getVersionDetails(appVersionDetails: { versionCode: number; versionName: string }, latestVersion: any): Observable<void> {
    this.loadingService.enqueueLoader();
    this.loadingService.updateFullscreen(true);
    return this.apiService
      .get(APIType.CMS, `AndroidVersions/GetAndroidVersionContent?language=${this.languageService.selectedLanguage.locale.toLowerCase()}`)
      .pipe(
        first(),
        map(data => {
          let latestVersionObject = latestVersion;
          if (data.latestVersion) {
            latestVersionObject = data.latestVersion;
          } else if (data.androidVersions) {
            latestVersionObject = data.androidVersions[data.androidVersions.length - 1];
          }
          const versionContent = {
            webViewMode: data.webViewMode,
            latestVersion: latestVersionObject,
            appVersionCode: appVersionDetails.versionCode,
            appVersionName: `${appVersionDetails.versionName}.${appVersionDetails.versionCode}`,
            minVersionCode: data.minVersionCode ? data.minVersionCode : 1,
            offlineMode: false,
          };
          this.androidSettingsStore.changeAndroidSettings(versionContent);
        }),
        catchError((err: any, caught: Observable<void>) => {
          this.androidSettingsStore.switchToOfflineMode();
          this.loadingService.dequeueLoader();
          this.loadingService.updateFullscreen(false);
          return of(undefined);
        })
      );
  }

  checkAppVersionUpdate(androidSettingsQuery: any, redirectUrl?: string): void {
    from(VersionPlugin.getVersionDetails()).subscribe((versionDetails: any) => {
      this.getVersionDetails(versionDetails, androidSettingsQuery.latestVersion).subscribe(() => {
        const { appLatestVersionCode, initialVersionCode, isWebViewMode, minVersionCode, offlineMode, optionalUpdate } =
          androidSettingsQuery;
        if (
          versionDetails.versionCode !== initialVersionCode &&
          versionDetails.versionCode >= minVersionCode &&
          versionDetails.versionCode < appLatestVersionCode &&
          optionalUpdate === true &&
          offlineMode !== true &&
          isWebViewMode !== true
        ) {
          this.showVersionUpdateDialog();
        } else if (redirectUrl) {
          this.router.navigate([redirectUrl]);
        }
        this.loadingService.dequeueLoader();
        this.loadingService.updateFullscreen(false);
      });
    });
  }

  switchToOfflineMode(errorUrl: string): void {
    if (Capacitor.getPlatform() === 'android') {
      const cmsUrl = this.appConfig.get('apiBaseUrl').cms.toString();
      if (errorUrl.includes(cmsUrl)) {
        this.androidSettingsStore.switchToOfflineMode();
        if (Swal.isVisible) {
          Swal.clickCancel();
        }
      }
    }
  }

  showVersionUpdateDialog(): void {
    const swalOptions = {
      allowOutsideClick: false,
      backdrop: 'rgba(0, 0, 0, 0.8)',
      buttonsStyling: false,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      customClass: {
        container: 'notif-container dialog-container',
        popup: `notif-popup`,
        header: 'notif-header',
        title: 'notif-title',
        content: 'notif-content',
        actions: `notif-actions`,
        cancelButton: 'notif-cancel-button',
        confirmButton: 'notif-confirm-button',
      },
      heightAuto: false,
      reverseButtons: true,
      showCancelButton: true,
      showCloseButton: false,
      showConfirmButton: true,
      text: $localize`There is a new update available. Do you want to download the new version?`,
      titleText: $localize`Update BetKing`,
    };

    Swal.fire(swalOptions).then(result => {
      if (result.value) {
        // confirm button clicked
        const appDownloadLink = this.appConfig.get('nativeApp').appDownloadUrl.toString();
        window.open(appDownloadLink, '_system', 'location=yes,enableViewportScale=yes,hidden=no');
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
