<div class="sports-list" [@fadeInByStaggered]="{ value: '', params: { duration: 150 } }">
  <div *ngIf="!!title" class="title">{{ title }}</div>
  <app-sport-link
    *ngFor="let sport of sports; trackBy: sportsTrackBy; let last = last"
    [sport]="sport"
    [isLast]="last"
    [staggered]="true"
    [@fadeInOutBy]="{ value: '', params: { duration: 200 } }"
    [isSelected]="isSportSelected(sport)"
  >
  </app-sport-link>
</div>
