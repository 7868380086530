import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormMessageTypes } from 'src/app/shared/models/informational-message.model';

const iconValueMap = {
  success: 'check_circle_outline',
  warning: 'warning_amber',
  info: 'info_outline',
  error: 'error_outline',
};
@Component({
  selector: 'app-message',
  templateUrl: './app-message.component.html',
  styleUrls: ['./app-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppMessageComponent implements OnInit {
  @Input() type: FormMessageTypes = 'info'; // success, warning, error
  @Input() title: string = '';
  @Input() text: string = '';
  iconValue: undefined;

  ngOnInit(): void {
    this.iconValue = iconValueMap[this.type];
  }
}
