import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { CountrySelectionDetails, GeoAccessControlState, GeoAccessModel } from 'src/app/shared/models/geo-access-control.model';

export function createInitialState(): GeoAccessControlState {
  return {
    accessList: [],
    userCountryCode: undefined,
    userCountryName: undefined,
    countrySelectionDetails: undefined,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'accessControl' })
export class GeoAccessControlStore extends Store<GeoAccessControlState> {
  constructor() {
    super(createInitialState());
  }

  updateAccessList(accessList: GeoAccessModel[]): void {
    this.update(state => ({
      ...state,
      accessList,
    }));
  }

  updateUserCountryCode(userCountryCode: string): void {
    this.update(state => ({
      ...state,
      userCountryCode,
    }));
  }

  updateUserCountryName(userCountryName: string): void {
    this.update(state => ({
      ...state,
      userCountryName,
    }));
  }

  updateCountrySelectionDetails(countrySelectionDetails: CountrySelectionDetails): void {
    this.update(state => ({
      ...state,
      countrySelectionDetails,
    }));
  }
}
