import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { MySettledBetsState } from 'src/app/modules/my-bets/models/my-bets-state.model';

const createInitialState = (): MySettledBetsState => ({
  lostBetsLoaded: false,
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'my-settled-bets', resettable: true })
export class MySettledBetsStore extends EntityStore<MySettledBetsState> {
  constructor() {
    super(createInitialState());
  }

  toggleCollapsed(): void {
    this.updateActive(entity => ({
      collapsed: !entity.collapsed,
    }));
  }

  toggleBetInfoCollapsed(): void {
    this.updateActive(entity => ({
      betInfoCollapsed: !entity.betInfoCollapsed,
    }));
  }

  clear(): void {
    this.reset();
  }
}
