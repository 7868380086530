import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { XSellCMSAPIService } from 'src/app/modules/x-sell/services/x-sell-cms-api.service';
import { CasinoXSellQuery } from 'src/app/modules/x-sell/state/casino-x-sell.query';
import { XSellStore } from 'src/app/modules/x-sell/state/x-sell.store';

@Injectable({
  providedIn: 'root',
})
export class CasinoXSellService {
  constructor(
    private readonly xSellCMSAPIService: XSellCMSAPIService,
    private readonly xSellStore: XSellStore,
    private readonly xSellQuery: CasinoXSellQuery
  ) {}

  initialise(): void {
    if (!this.xSellQuery.isInitialised) {
      combineLatest([this.xSellCMSAPIService.getCasinoXSellAccessConfiguration(), this.xSellCMSAPIService.getCasinoXSellContent()])
        .pipe(
          filter(([accessConfigurationCallDone, contentCallDone]) => accessConfigurationCallDone && contentCallDone),
          first()
        )
        .subscribe(() => {
          this.xSellStore.setCasinoXSellInitialised(true);
        });
    }
  }
}
