import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VirtualsAlertType } from 'src/app/shared/models/virtuals.model';

@Component({
  selector: 'virtuals-alert',
  templateUrl: './virtuals-alert.component.html',
  styleUrls: ['./virtuals-alert.component.scss'],
})
export class VirtualsAlertComponent {
  @Input() readonly type: VirtualsAlertType;
  @Input() readonly message: string;
  @Input() readonly ctaText?: string;
  @Output() readonly ctaClick? = new EventEmitter<void>();

  get icon(): string {
    switch (this.type) {
      case VirtualsAlertType.Error:
        return 'error_outline';
      default:
        return '';
    }
  }

  ctaClicked = () => this.ctaClick?.emit();
}
