import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Component({
  selector: 'app-legend-table',
  templateUrl: './legend-table.component.html',
  styleUrls: ['./legend-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegendTableComponent implements OnInit {
  @Input() isVirtuals: boolean = false;
  jackpotNaming: string = 'Jackpot winning coupon';

  constructor(public appConfig: AppConfigService) {}

  ngOnInit(): void {
    this.generateJackpotNaming();
  }

  generateJackpotNaming(): void {
    if (this.appConfig.get('brandId') && this.appConfig.get('brandId') === 1903) {
      this.jackpotNaming = 'MKWANJA VIRTUAL BONUS';
    }
  }
}
