import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { APIType } from 'src/app/shared/models/api.model';
import { APIService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class GeolocationRedirectService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly apiService: APIService,
    private readonly appConfig: AppConfigService
  ) {}

  initialize(): void {
    const redirectRules = this.appConfig.get('geolocationRedirect');
    if (!redirectRules || Object.keys(redirectRules).length <= 0) {
      return;
    }

    this.httpClient.get('https://api.ipify.org/?format=json').subscribe((ipData: any) => {
      if (!ipData || !ipData.ip) {
        return;
      }

      const splitIp = ipData.ip.split('.');
      const iHex =
        Number(splitIp[0]).toString(16) +
        Number(splitIp[1]).toString(16) +
        Number(splitIp[2]).toString(16) +
        Number(splitIp[3]).toString(16);
      const hexIpAddress = `0x${iHex}`;

      this.apiService.get(APIType.Platform, `api/Location/Countries/${hexIpAddress}`).subscribe(response => {
        if (!response || !response.Result || !response.Result.ThreeLetterCode) {
          return;
        }

        const redirectUrl = redirectRules[response.Result.ThreeLetterCode];
        if (redirectUrl && redirectUrl.trim().length > 0) {
          // include route and querystring parameters in the redirect
          window.location.href = `${redirectUrl}${window.location.pathname}${window.location.search}`;
        }
      });
    });
  }
}
