import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { SwalComponent, SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { LocalStorageService } from 'ngx-webstorage';
import { InsufficientBalanceService } from 'src/app/core/services/insufficient-balance.service';

@Component({
  selector: 'app-insufficient-balance',
  templateUrl: './insufficient-balance.component.html',
  styles: [],
})
export class InsufficientBalanceComponent implements AfterViewInit {
  swalOptions = {
    allowOutsideClick: true,
    backdrop: 'rgba(0, 0, 0, 0.8)',
    buttonsStyling: false,
    cancelButtonText: $localize`Cancel`,
    confirmButtonText: $localize`Deposit now`,
    customClass: {
      container: 'notif-container',
      popup: 'notif-popup',
      header: 'notif-header',
      title: 'notif-title',
      image: 'notif-image',
      content: 'notif-content branded',
      input: 'notif-input',
      actions: 'notif-actions',
      cancelButton: 'notif-cancel-button',
      closeButton: 'notif-close-button',
      confirmButton: 'notif-confirm-button',
      footer: 'notif-footer',
    },
    footer: true,
    heightAuto: false,
    reverseButtons: true,
    showCancelButton: true,
    showCloseButton: false,
    showConfirmButton: true,
    text: $localize`Your account balance is low, you can deposit more funds using our deposit options.`,
  };

  @ViewChild('insufficentBalanceSwal') swal: SwalComponent;
  storedValue: boolean;
  doNotShowCtrl: FormControl;
  private readonly doNotShowKey = 'doNotShowLowBalance';

  constructor(
    readonly swalTargets: SwalPortalTargets,

    private readonly insufficientBalanceService: InsufficientBalanceService,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router
  ) {
    this.storedValue = this.localStorageService.retrieve(this.doNotShowKey);
    this.initCheckboxListener();
  }

  ngAfterViewInit(): void {
    this.initshowProgressionBarListener();
  }

  navigateToDeposit(): void {
    this.router.navigate(['/account/deposit']);
  }

  private initshowProgressionBarListener(): void {
    if (!this.storedValue) {
      this.insufficientBalanceService.showProgressionBar$.subscribe(() => {
        this.swal.fire();
      });
    }
  }

  private initCheckboxListener(): void {
    if (!this.storedValue) {
      this.doNotShowCtrl = new FormControl(false);
      this.doNotShowCtrl.valueChanges.subscribe(value => {
        this.localStorageService.store(this.doNotShowKey, value);
      });
    }
  }
}
