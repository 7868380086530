import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Router } from '@angular/router';
import { kebabCase } from 'lodash-es';
import { ApplicationService } from 'src/app/core/services/application.service';
import { SportService } from 'src/app/core/services/sports/sport.service';
import { SportModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-sport-link',
  templateUrl: './sport-link.component.html',
  styleUrls: ['./sport-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SportLinkComponent {
  @Input() sport: SportModel;
  @Input() isSelected = false;
  @Input() isLast = false;

  @HostBinding('class.staggered') @Input() staggered = false;
  @HostBinding('style.display') display = 'block';

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly router: Router,
    private readonly sportService: SportService
  ) {}

  get linkToSport(): string[] {
    return [`/sports/${kebabCase(this.sport.name)}`];
  }

  navigateTo(link: string): void {
    this.router.navigateByUrl(link).then(() => {
      this.sportService.updateEventSelection({ isSportsListOpened: false });
    });
    this.applicationService.closeAnySlideUps();
  }
}
