<div class="sfk-frame-container"
  [@expandCollapse]="{value: (applicationQuery.showSFKChat$ | async) ? 'expanded' : 'collapsed', params: { minHeight: '0px' }}">
  <div class="sfk-frame-header">
    <i class="fa fa-comments-o" aria-hidden="true"></i>
    <span class="header-text" i18n>Live Chat</span>
    <i class="fa fa-times" aria-hidden="true" (click)="closeChat()"></i>
  </div>

  <div class="sfk-frame-body" [hidden]="windowInstance._sfk && windowInstance._sfk.loaded === undefined">
    <iframe src="" class="sfkChatOnline" id="sfkChatOnline" name="sfkFrame" style="display: none;"></iframe>
    <div class="sfkChatOffline" id="sfkChatOffline" style="display: none;">
      <div class="offline-pre-amble-container">
        <div class="offline-pre-amble">
          <div class="logo-container">
            <div class="logo">
            </div>
            <span class="logo-cross" i18n>x</span>
          </div>
          <div class="pre-amble-text" *ngIf="windowInstance._sfk && windowInstance._sfk.loaded">
            <p i18n>Unfortunately, no agents are available right now.</p>
            <p i18n>Please try again later.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
