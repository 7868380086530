<ng-container *ngIf="couponQuery.couponData && couponQuery.couponData.Odds.length > 0">
  <div class="coupon-wrapper">
    <div class="main-content" [ngClass]="{authenticated: (accountQuery.isAuthenticated$ | async)}">
      <coupon-selections></coupon-selections>

      <coupon-totals [couponData]="couponQuery.couponDataFiltered$ | async"></coupon-totals>

      <div class="buttons" [class.disabled]="(canPostCoupon$ | async) === false">
        <ng-container
          *ngIf="(hasOddChanges$ | async) === false || (couponQuery.couponSettings$ | async).allowOddChanges">

          <app-button i18n-text text="Place Bet Now" class="place-bet" [customButtonStyle]="actionButtonStyle" [buttonType]="buttonType.Highlight"
            [disabled]="(canPostCoupon$ | async) === false" (click)="postCoupon()">
          </app-button>

        </ng-container>

        <ng-container
          *ngIf="(hasOddChanges$ | async) && (couponQuery.couponSettings$ | async).allowOddChanges === false">
          <div class="message" i18n>
            Some of the selected odds have changed. Please accept the changes to proceed or remove the items.
          </div>
          <app-button i18n-text text="Accept" class="accept-bet" [customButtonStyle]="actionButtonStyle" [buttonType]="buttonType.Highlight"
            (click)="acceptOddChanges()"></app-button>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #betInProgress>
  <app-loading-bar [show]="true" [transparent]="true"></app-loading-bar>
  <div class="loading-text" i18n>
    Your bet is being processed
  </div>
</ng-template>
