import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationMessageComponent {
  @Input() message: string = 'Something went wrong!';
  @Input() type: 'warning' | 'info' | 'success' | 'error' = 'warning';
}
