import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'teamNameOrder',
})
export class TeamNameOrderPipe implements PipeTransform {
  transform(teams: any, itemOrder: number): any {
    const team: any = teams.find(t => t.itemOrder === itemOrder);
    return team.name;
  }
}
