<div *ngIf="section" class="info-section" data-testid="virtuals-lobby-info-section">
  <span class="section-title" data-testid="virtuals-lobby-info-section-title">{{section.title}}</span>
  <span *ngIf="section.content" class="section-content" data-testid="virtuals-lobby-info-section-content"
    [innerHTML]="section.content | safeHTML"></span>

  <div *ngIf="section.subSections?.length" class="info-sub-sections"
    data-testid="virtuals-lobby-info-sub-section-container">
    <virtuals-info-sub-section *ngFor="let subSection of section.subSections" [subSection]="subSection"
      [isExpanded]="(expandedSubSection$ | async) === subSection"
      (headerClick)="toggleExpanded(subSection)"></virtuals-info-sub-section>
  </div>
</div>