import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AccountQuery } from 'src/app/core/state/account/account.query';

/**
 * This guard ensures the user has a valid auth token before
 * allowing access to a route.
 */
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private readonly accountQuery: AccountQuery, private readonly router: Router) {}

  canActivate(): boolean {
    try {
      if (!this.accountQuery.isAuthenticated) {
        this.router.navigate(['/account/login'], { queryParams: { urlAfterLogin: window.location.pathname } });
        return false;
      }
      return true;
    } catch {
      this.router.navigate(['/account/login'], { queryParams: { urlAfterLogin: window.location.pathname } });
      return false;
    }
  }
}
