<div class="recent-searches-container" *ngIf="searchQuery.recentSearches$ | async">
  <span class="recent-searches-title" i18n="Recent Searches|Recent Searches in landing menu" [ngClass]="theme">
    Your Recent Searches
  </span>
  <div class="recent-searches">
    <div class="recent-search-results">
      <span class="recent-search ripple"
        *ngFor="let recentSearch of searchQuery.recentSearches$ | async; trackBy: recentSearchTrackBy" [@fadeIn]
        (click)="onBadgeClick(recentSearch)">
        {{ recentSearch }}
      </span>
    </div>
    <span class="recent-search clear-button" (click)="clearRecentSearches()" [ngClass]="theme">
      <span class="material-icons-outlined">{{'close'}}</span>
      <span i18n="Clear|Clear button in landing menu">Clear</span>
    </span>
  </div>
</div>
