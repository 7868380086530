import { Component, Input, OnInit } from '@angular/core';
import { VariablesService } from 'src/app/core/services/variables.service';
import { expandCollapse } from 'src/app/shared/animations';

@Component({
  selector: 'app-inner-card',
  templateUrl: './inner-card.component.html',
  styleUrls: ['./inner-card.component.scss'],
  animations: [expandCollapse()],
})
export class InnerCardComponent implements OnInit {
  @Input() titleHeaderText: string;
  @Input() collapsed = true;
  @Input() collapsible = this.collapsed ? true : false;
  @Input() collapsedStart = true;
  @Input() rightIcon: string;
  @Input() loading: boolean = false;
  @Input() expandState: 'cancel-animation' | 'expanded' | 'collapsed';

  constructor(public variablesService: VariablesService) {}

  toggleWidget(): void {
    if (this.collapsible) {
      if (this.expandState === 'cancel-animation' || this.expandState === 'collapsed') {
        this.expandState = 'expanded';
      } else {
        this.expandState = 'collapsed';
      }
    }
  }

  ngOnInit(): void {
    if (this.collapsed) {
      this.expandState = 'cancel-animation';
    }
    if (!this.rightIcon && this.collapsible) {
      this.rightIcon = 'fa fa-caret-right';
    }
  }
}
