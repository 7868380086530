<div class="sport-link" [ngClass]="{'last': isLast, 'selected': isSelected}">
  <div class="sport-link__favourite">
    <app-favourite-toggle [sport]="sport"></app-favourite-toggle>
    <div class="divider"></div>
  </div>
  <div class="sport-link__link" (click)="navigateTo(linkToSport[0])">
    <div class="left-elements">
      <div class="sport-icon icon-{{ sport.id }} text-dark"></div>
      <span class="sport-name">{{ sport.name }}</span>
    </div>
    <div class="right-elements">
      <span class="sport-badge-count">
        {{ sport.oddCount }}
      </span>
      <span class="sport-badge-icon">
        <i class="fa fa-angle-right" aria-hidden="true"></i>
      </span>
    </div>
  </div>
</div>