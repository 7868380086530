<div class="bet-builder-details">
  <div class="selection-details">
    <div class="market-name">
      {{ odd.marketName }}
    </div>
    <div class="event-name">{{ odd.eventName }}</div>
    <div class="bet-builder-selection" *ngFor="let selection of odd.Selections">
      <div class="bet-builder-selection-icon"></div>
      <div class="bet-builder-selection-text">{{ selection }}</div>
    </div>
  </div>
</div>

<div class="odd-details">
  <div class="odd-value">
    <div class="normal-odd-value">
      <i *ngIf="odd.unboostedOddValue" class="odds-boost-badge"></i>
      <span>{{ odd.oddValue | number : '1.2-2' }}</span>
    </div>
    <div *ngIf="odd.unboostedOddValue" class="unboosted-odd-value">
      {{ odd.unboostedOddValue | number : '1.2-2' }}
    </div>
  </div>
</div>
