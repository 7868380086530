<div class="carousel" *ngIf="showBanner$ | async">
  <div #appBannerContainer>
    <ng-container
      *ngFor="let banner of bannerQuery.bannerDetails$ | async; let i = index; trackBy: indexTrackBy;">
      <div class="banner"
        *ngIf="(currentVisibleBannerIndex$ | async) === i"
        [ngStyle]="{ background: banner.bannerBackgroundCss}"
        [ngClass]="{ 'default-bg': banner.eventOnlineCode }"
        [@fadeInOut]>

        <div class="item" *ngIf="banner.imageUrl">
          <a [href]="banner.redirectionUrl" *ngIf="!banner.couponCode"><img [title]="banner.title"
            [src]="banner.imageUrl" /></a>
          <img [title]="banner.title" [src]="banner.imageUrl" (click)="clickBannerCouponCode(banner.couponCode)"
            *ngIf="banner.couponCode" />
        </div>

        <div *ngIf="banner.eventOnlineCode" class="dynamic-banner">
          <div *ngIf="banner.marketsData && banner.marketsData.markets" class="content">
            <div *ngIf="!banner.imageUrl">
              <div class="team-info">
                <div class="team-info-block flex-end">
                  <span class="team-name">{{ banner.marketsData.teamHome }}</span>
                  <div class="team-shirt">
                    <div class="icon"
                      [ngStyle]="{ 'background-image': 'url(' + banner.homeShirtUrl + ')' }">
                    </div>
                  </div>
                </div>
                <div class="hyphen"></div>
                <div class="team-info-block">
                  <div class="team-shirt">
                    <div class="icon"
                      [ngStyle]="{ 'background-image': 'url(' + banner.awayShirtUrl + ')' }">
                    </div>
                  </div>
                  <span class="team-name">{{ banner.marketsData.teamAway }}</span>
                </div>
              </div>
              <div class="separator"></div>
            </div>
            <app-odd-group
              class="app-banner-odds"
              [odds]="banner.marketsData.markets[0].markets"
              eventLocation="Homepage_carousel"
              [showSelectionName]="true">
            </app-odd-group>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="icon-medium nav_arrows left" (click)="onPreviousClick()"
    aria-hidden="true" *ngIf="(showArrows$ | async)">
    <span class="material-icons">{{'keyboard_arrow_left'}}</span>
  </div>

  <div class="icon-medium nav_arrows right" (click)="onNextClick()"
    aria-hidden="true" *ngIf="(showArrows$ | async)">
    <span class="material-icons">{{'keyboard_arrow_right'}}</span>
  </div>
</div>
