import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BKGuestHeaderComponent } from 'src/app/modules/header/components/bk-guest-header/bk-guest-header.component';
import { BKHeaderLogoComponent } from 'src/app/modules/header/components/bk-header-logo/bk-header-logo.component';
import { BKProductSwitcherItemComponent } from 'src/app/modules/header/components/bk-product-switcher/bk-product-switcher-item/bk-product-switcher-item.component';
import { BKProductSwitcherComponent } from 'src/app/modules/header/components/bk-product-switcher/bk-product-switcher.component';
import { BKHeaderUserBalanceComponent } from 'src/app/modules/header/components/bk-user-header/bk-header-user-balance/bk-header-user-balance.component';
import { BKUserHeaderComponent } from 'src/app/modules/header/components/bk-user-header/bk-user-header.component';
import { BreadcrumbItemComponent } from 'src/app/modules/header/components/breadcrumb-navigation/breadcrumb-item/breadcrumb-item.component';
import { BreadcrumbNavigationComponent } from 'src/app/modules/header/components/breadcrumb-navigation/breadcrumb-navigation.component';
import { SportsBreadcrumbComponent } from 'src/app/modules/header/components/sports-breadcrumb/sports-breadcrumb.component';
import { SportsBreadcrumbItemComponent } from 'src/app/modules/header/components/sports-breadcrumb/sports-breadcrumb-item/sports-breadcrumb-item.component';
import { BreadcrumbComponent } from 'src/app/modules/header/components/breadcrumb/breadcrumb.component';
import { HeaderComponent } from 'src/app/modules/header/components/header/header.component';
import { BadgeDirective } from 'src/app/modules/header/directives/badge.directive';
import { PositionDropdownOnScreenDirective } from 'src/app/shared/directives/position-dropdown-on-screen.directive';
import { NativeAppBannerComponent } from 'src/app/modules/header/components/native-app-banner/native-app-banner.component';

import { ClickOutsideDirective } from 'src/app/shared/directives/click-outside.directive';

const EXPORTS = [
  HeaderComponent,
  BreadcrumbNavigationComponent,
  SportsBreadcrumbItemComponent,
  SportsBreadcrumbComponent,
  BreadcrumbItemComponent,
  BreadcrumbComponent,
  PositionDropdownOnScreenDirective,
  ClickOutsideDirective,
];
const EXPORT_DIRECTIVES = [BadgeDirective];
const DIRECTIVES = [PositionDropdownOnScreenDirective, ...EXPORT_DIRECTIVES];
const COMPONENTS = [
  BKGuestHeaderComponent,
  BKHeaderLogoComponent,
  BKHeaderUserBalanceComponent,
  BKProductSwitcherComponent,
  BKProductSwitcherItemComponent,
  BKUserHeaderComponent,
  BreadcrumbItemComponent,
  NativeAppBannerComponent,
];

@NgModule({
  declarations: [...COMPONENTS, ...EXPORTS, ...DIRECTIVES],
  imports: [CommonModule],
  exports: [...EXPORTS, ...EXPORT_DIRECTIVES],
})
export class HeaderModule {}
