export interface BookBetState {
  bookBetData: BookBetModel;
}

export class BookBetModel {
  responseStatus: number;
  bookCode: string;
  availableEventCount: number;
  bookedCoupon: any; // TODO: add CouponModel
  originalEventCount: number;
  removedEvents: string[];
  hasExpiredEvents: boolean;

  constructor(init: Partial<BookBetModel>) {
    Object.assign(this, init);
  }
}
