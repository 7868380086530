<div class="x-sell-swiper">
  <div
    class="x-sell-swiper-icon"
    appXSellSwipeable
    [startingScreenPosition]="startingScreenPosition"
    [class.reverse]="startingScreenPosition === 'right'"
  >
    <div class="material-icons default back-to-spb swiper-icon" *ngIf="!spbIconURL">{{ 'arrow_forward' }}</div>
    <div class="material-icons default to-xsell swiper-icon" *ngIf="!xSellIconURL">{{ 'local_activity' }}</div>
    <div class="custom back-to-spb swiper-icon" *ngIf="spbIconURL" [ngStyle]="customSPBIconStyle"></div>
    <div class="custom to-xsell swiper-icon" *ngIf="xSellIconURL" [ngStyle]="customXSellIconStyle"></div>
  </div>
  <div i18n class="x-sell-swiper-content" [class.default]="!xSellIconURL" [class.swiping]="isSwiping$ | async" #swiperContent>
    <div class="material-icons default loading-icon" *ngIf="!xSellIconURL">{{ 'local_activity' }}</div>
    <div class="custom loading-icon" *ngIf="xSellIconURL" [ngStyle]="customXSellContentLoadingIconStyle"></div>
    <ng-content select="[x-sell-content]"></ng-content>
  </div>
</div>
