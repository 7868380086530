<div class="input-otp">
  <div [class.with-customer-support]="!!content.contactSupportMessageHTML">
    <div class="icon"></div>
    <h1 *ngIf="content">
      {{ content.sentOTPHeader }}
    </h1>
    <div class="text">
      <ng-container *ngIf="content">
        <ng-container *ngIf="inputOTPMessageHasUserPhoneNumber">
          <span [innerText]="inputOTPMessage[0]"></span>
          <span class="user-phone-number">
            <span *ngIf="!mobilePrefixHasPlusSymbol">+</span>{{ mobilePrefix }} {{ mobileNumber.substr(0, 3) }} {{
            mobileNumber.substr(3) }}
          </span>
          <span [innerText]="inputOTPMessage[1]"></span>
        </ng-container>
        <ng-container *ngIf="inputOTPMessageHasUserUsername">
          <span [innerText]="inputOTPMessage[0]"></span>
          <span class="user-username">
            {{ username }}
          </span>
          <span [innerText]="inputOTPMessage[1]"></span>
        </ng-container>
        <ng-container *ngIf="!(inputOTPMessageHasUserPhoneNumber || inputOTPMessageHasUserUsername)">
          <span [innerText]="inputOTPMessage[0]"></span>
        </ng-container>
      </ng-container>
      <ng-container [ngTemplateOutlet]="field"></ng-container>
      <ng-container [ngTemplateOutlet]="customerSupport" *ngIf="!!content.contactSupportMessageHTML"></ng-container>
    </div>
  </div>
</div>

<ng-template #field>
  <div [class.verification-error]="genericError">
    <div class="label" *ngIf="content">{{ content.inputOTPLabel }}</div>
    <app-otp-input-field [tooManyRequests$]="tooManyRequests$" [limitExceeded$]="limitExceeded$"
      [invalidCode$]="invalidCode$" [verifyingCode$]="verifyingCode$" [otpCodeLength]="otpCodeLength"
      [autoComplete]="false" [resendTimer]="resendTimer" [showResendButton]="!otpAllowResendWithoutTimer"
      (resend)="resendCode()" #otpInputField>
    </app-otp-input-field>
    <div class="verification-error" i18n *ngIf="genericError">
      An issue was encountered whilst verifying your PIN. Please try again or contact customer support
    </div>
    <div class="otp-exceed-error" i18n *ngIf="OneTimePasswordVerificationExceeded$.getValue() && isEnableOtpOnCall">
      You’ve exceeded {{noOfAttempts}} atempts to enter the correct one time pin, you can resend the OTP, or request an OTP by call below.
    </div>
    <div class="label" i18n *ngIf="OneTimePasswordVerificationExceeded$.getValue() && isEnableOtpOnCall && noOfAttempts > 6">
      You’ve exceeded the limit of verification attempts. Please contact <a href="help/contact-us">Customer Support</a> to complete your verification process.
    </div>
  </div>
  <div class="buttons">
    <app-button [text]="content ? content.phoneVerificationVerifyCTAText : 'Verify'"
      [buttonType]="buttonType.AccentSecondary" (click)="verifyCode()" [disabled]="(verifyingCode$ | async) || (OneTimePasswordVerificationExceeded$ | async) || (noOfAttempts > 5)"
      [customButtonStyle]="ctaAdditionalStylesConfirmation">
    </app-button>

    <app-button text="Resend PIN" i18n-text [buttonType]="buttonType.TransparentSecondary"
                [customButtonStyle]="ctaAdditionalStylesOld" (click)="resendCode()" *ngIf="!isEnableOtpOnCall"></app-button>

    <ng-container *ngIf="isEnableOtpOnCall">
      <div class="otp-timer-component" i18n *ngIf="!limitExceeded$.getValue()">
        <span>Didn’t recieve a code? </span><span *ngIf="timerValue > 0">00:{{( timerValue) < 10 ? 0:''}}{{timerValue}}</span>
      </div>
      <div class="otp-timer-component" i18n *ngIf="limitExceeded$.getValue()">
        <span>Too many attempts please retry later.</span>
      </div>
      <div class="resend-otp-container" *ngIf="!limitExceeded$.getValue()">
        <app-button text="RESEND PIN VIA SMS" i18n-text [buttonType]="buttonType.TransparentSecondary"
                    [customButtonStyle]="ctaAdditionalStyles" (click)="resendCode()" *ngIf="otpAllowResendWithoutTimer" [disabled]="timerValue > 0" ></app-button>
        <app-button text="CALL ME WITH THE PIN" i18n-text [buttonType]="buttonType.TransparentSecondary"
                    [customButtonStyle]="ctaVoiceAdditionalStyles" (click)="debouncedCallToOTP()" *ngIf="otpAllowResendWithoutTimer" [disabled]="timerValue > 0" ></app-button>
      </div>
    </ng-container>

  </div>
</ng-template>

<ng-template #customerSupport>
  <div class="customer-support" [innerHTML]="content.contactSupportMessageHTML | safeHTML">
  </div>
</ng-template>
