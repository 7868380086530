import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CouponFlexicutService } from 'src/app/core/services/coupon/coupon-flexicut.service';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';

@Component({
  selector: 'coupon-flexicut-selections',
  templateUrl: './coupon-flexicut-selections.component.html',
  styleUrls: ['./coupon-flexicut-selections.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CouponFlexicutSelectionsComponent implements OnInit {
  @Output() readonly triedToGoBelowOddsThreshold = new EventEmitter();

  readonly cutNumber$ = this.couponQuery.couponData$.pipe(map(couponData => couponData?.BetDetails?.FlexiCutDetails?.Cut));

  readonly maxSelections$ = this.couponQuery.couponData$.pipe(
    filter(couponData => !!couponData),
    map(couponData => couponData.Odds.length)
  );
  readonly winningSelections$ = combineLatest([this.cutNumber$, this.maxSelections$]).pipe(
    map(([cutNumber, maxSelections]) => maxSelections - cutNumber)
  );

  readonly cannotDecreaseCutSelections$ = new BehaviorSubject(false);
  readonly cannotIncreaseCutSelections$ = new BehaviorSubject(false);

  constructor(private readonly couponQuery: CouponQuery, private readonly couponFlexicutService: CouponFlexicutService) {}

  ngOnInit() {
    // Decrease cut value check
    combineLatest([this.winningSelections$, this.maxSelections$])
      .pipe(map(([winningSelections, maxSelections]) => winningSelections >= maxSelections - 1))
      .subscribe(state => {
        this.cannotDecreaseCutSelections$.next(state);
      });

    // Increase cut value check
    combineLatest([this.winningSelections$, this.couponQuery.flexicutOdds$, this.maxSelections$])
      .pipe(
        filter(([_winningSelections, fcOdds, maxSelections]) => fcOdds.length > 0 && maxSelections > 0),
        map(([winningSelections, fcOdds, maxSelections]) => winningSelections <= maxSelections - fcOdds.length)
      )
      .subscribe(state => {
        this.cannotIncreaseCutSelections$.next(state);
      });
  }

  increaseCutNumber = () => {
    if (this.cannotIncreaseCutSelections$.value) {
      this.triedToGoBelowOddsThreshold.emit(true);
    } else {
      const updatedCutNumber = this.couponQuery.couponData?.BetDetails?.FlexiCutDetails?.Cut + 1;
      this.couponFlexicutService.setFlexicutCutNumber(updatedCutNumber);
      this.couponFlexicutService.updateFlexicutSelectedOptionByCutNumber(updatedCutNumber);
      this.couponFlexicutService.sendApplyFlexicutEvent(updatedCutNumber);
    }
  };

  reduceCutNumber = () => {
    this.triedToGoBelowOddsThreshold.emit(false);
    if (!this.cannotDecreaseCutSelections$.value) {
      const updatedCutNumber = this.couponQuery.couponData?.BetDetails?.FlexiCutDetails?.Cut - 1;
      this.couponFlexicutService.setFlexicutCutNumber(updatedCutNumber);
      this.couponFlexicutService.updateFlexicutSelectedOptionByCutNumber(updatedCutNumber);
      this.couponFlexicutService.sendApplyFlexicutEvent(updatedCutNumber);
    }
  };
}
