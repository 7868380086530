import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostBinding, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { VirtualsStore } from 'src/app/core/state/virtuals/virtuals.store';
import { slideUp } from 'src/app/shared/animations';

@Component({
  selector: 'virtuals-slide-up',
  templateUrl: './virtuals-slide-up.component.html',
  styleUrls: ['./virtuals-slide-up.component.scss'],
  animations: [slideUp()],
})
export class VirtualsSlideUpComponent implements OnInit, OnDestroy {
  @Input() headerTitle: string;
  @Input() headerBadge: string;

  @ViewChild('content') content: ElementRef;

  constructor(@Inject(DOCUMENT) private readonly document: any, private readonly virtualsStore: VirtualsStore) {}

  @HostBinding('@slideUp') get slideUp() {
    return true;
  }

  ngOnInit(): void {
    // block background scrolling on mobile devices when the slide-up is visible
    this.document.body.classList.add('noscroll-mobile');
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove('noscroll-mobile');
  }

  scrollToBottom(): void {
    this.content?.nativeElement?.scrollTo(0, this.content.nativeElement.scrollHeight);
  }

  closeSlideUps(): void {
    this.virtualsStore.closeSlideUps();
  }
}
