import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { BetCouponGlobalVariable } from 'clientside-coupon';
import { InstantCoupon, InstantCouponState } from 'src/app/shared/models/instant-coupon.model';
import { CouponGroupingType } from 'src/app/shared/models/coupon.model';
import { SportModel } from 'src/app/shared/models/sport.model';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { VirtualsCouponUIState } from 'src/app/shared/models/virtuals-coupon.model';

const createInitialState = (): InstantCouponState => ({
  bookedCoupons: undefined,
  couponData: undefined,
  selections: undefined,
  couponInitialized: false,
  isCouponSend: false,
  correctScoreOddsMatrix: undefined,
  couponSettings: undefined,
  defaultCouponStake: undefined,
  globalVariables: undefined,
  groupingsTabSelected: undefined,
  marketExceptions: undefined,
  ui: {
    showCoupon: false,
    showQuickCoupon: false,
    stakeChanged: false,
  },
  leagueSwitchContent: undefined,
  lastDataCouponStake: undefined,
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'virtuals_instant_coupon' })
export class InstantCouponStore extends Store<InstantCouponState> {
  readonly globalVariablesKey: string = 'virtualsInstant.globalVariables';
  private readonly additionalInstantCouponDataKey = this.appConfig.get('virtuals').instantLeague?.couponExtraDataStorageKey;
  private readonly couponDataKey = this.appConfig.get('virtuals').instantLeague?.couponDataStorageKey;

  constructor(
    private readonly localStorage: LocalStorageService,
    private readonly appConfig: AppConfigService,
    private readonly sessionStorage: SessionStorageService
  ) {
    super(createInitialState());

    this.localStorage.observe(this.couponDataKey).subscribe(couponData => {
      this.update({ couponData });
    });
    this.updateCouponData(this.localStorage.retrieve(this.couponDataKey));
  }

  updateCouponInitialized(couponInitialized: boolean): void {
    this.update({ couponInitialized });
  }

  updateCouponData(couponData: InstantCoupon): void {
    if (!couponData) {
      this.clearCouponData();
      return;
    }
    this.localStorage.store(this.couponDataKey, couponData);
  }

  clearCouponData(): void {
    this.localStorage.clear(this.couponDataKey);
    this.localStorage.clear(this.additionalInstantCouponDataKey);
    this.clearGroupingTab();
  }

  clearLeagueBetsFromCoupon(league): any {
    const additionalCouponData = this.localStorage.retrieve(this.additionalInstantCouponDataKey);
    const couponData = this.localStorage.retrieve(this.couponDataKey);
    const betsToRemove = additionalCouponData.selectedOdds.filter(bet => bet.categoryId === league);
    return {
      additionalCouponData: additionalCouponData,
      couponData: couponData,
      betsToRemove: betsToRemove,
    };
  }

  restoreCoupon(additionalCouponData) {
    this.localStorage.store(this.additionalInstantCouponDataKey, additionalCouponData);
  }

  updateGlobalVariables(globalVariables: BetCouponGlobalVariable): void {
    if (!globalVariables) {
      this.clearGlobalVariables();
      return;
    }

    this.update({ globalVariables });
    this.sessionStorage.store(this.globalVariablesKey, globalVariables);
  }

  clearGlobalVariables(): void {
    this.update({ globalVariables: undefined });
    this.sessionStorage.clear(this.globalVariablesKey);
  }

  updateGroupingTab(groupingsTabSelected: CouponGroupingType): void {
    if (!groupingsTabSelected) {
      this.clearGroupingTab();
      return;
    }

    this.update({ groupingsTabSelected });
  }

  clearGroupingTab(): void {
    this.update({ groupingsTabSelected: undefined });
  }

  updateCouponStatusIsSend(isCouponSend): void {
    this.update({ isCouponSend });
  }

  updateSelections(selections: SportModel[]): void {
    this.update({ selections });
  }

  storeCMSContent(leagueSwitchContent): void {
    this.update({ leagueSwitchContent });
  }

  updateUI(ui: Partial<VirtualsCouponUIState>): void {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui,
      },
    }));
  }

  updateLastDataCouponStakeValue(lastDataCouponStake: number): void {
    this.update(state => ({
      ...state,
      lastDataCouponStake: lastDataCouponStake,
    }));
  }
}
