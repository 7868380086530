import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, first, map, takeUntil } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { RegistrationQuery } from 'src/app/core/state/registration/registration.query';
import { RegistrationStore } from 'src/app/core/state/registration/registration.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { TelephoneExtensionModel } from 'src/app/shared/models/location.model';

@Injectable({
  providedIn: 'root',
})
export class MobileNumberInputService implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly registrationQuery: RegistrationQuery,
    private readonly registrationStore: RegistrationStore,
    private readonly appConfig: AppConfigService,
    private readonly apiService: APIService
  ) {}

  getTelephoneExtensions(preSelected: boolean = false, country?: string): Observable<void> {
    if (!this.registrationQuery.hasTelephoneExtensions) {
      return this.apiService.get(APIType.Platform, `api/Location/TelephoneExtensions/${country && preSelected ? country : ''}`).pipe(
        map(responseData => {
          this.registrationStore.updateTelephoneExtensions(this.mapTelephoneExtensionToModel(responseData, preSelected));
        }),
        catchError((err: HttpErrorResponse) => {
          this.registrationStore.setError(err);
          return throwError(err.message);
        })
      );
    }
  }

  enablePrefixDropdown(): Observable<boolean> {
    return this.registrationQuery.telephoneExtensions$.pipe(
      map(telExt => {
        if (telExt === undefined || telExt.length < 2 || !!this.appConfig.get('registration').disableInternationalNumbers) {
          return false;
        } else {
          return true;
        }
      }),
      takeUntil(this.destroy$)
    );
  }

  mobileNumberCleanup(value: string): string {
    return value[0] === '0' ? value.substr(1) : value;
  }

  isMobileNumber(value: string): boolean {
    return value && !isNaN(value as any) && !!value.match(this.appConfig.get('registration').mobileRegex);
  }

  checkPhoneNumber(mobileExtension: string, mobileNumber: string): Observable<boolean> {
    const apiSettings = new APISettings({ sendActivitySource: true });

    return this.apiService
      .post(
        APIType.Platform,
        'api/Security/Registration/ValidateMobileNumber',
        {
          PhoneContactTypeCode: 'MB',
          Prefix: mobileExtension,
          Number: mobileNumber,
        },
        apiSettings
      )
      .pipe(
        first(),
        map(responseData => responseData.Result === null)
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private mapTelephoneExtensionToModel(responseData: any, preSelected: boolean): TelephoneExtensionModel[] {
    const telephoneExtensions: TelephoneExtensionModel[] = [];
    if (preSelected) {
      telephoneExtensions.push(
        new TelephoneExtensionModel({
          countryCode: responseData.Result.CountryCode,
          prefixNumber: responseData.Result.PrefixNumber,
        })
      );
    } else {
      responseData.Result.forEach(telExt => {
        telephoneExtensions.push(
          new TelephoneExtensionModel({
            countryCode: telExt.CountryCode,
            prefixNumber: telExt.PrefixNumber,
          })
        );
      });
    }

    return telephoneExtensions;
  }
}
