import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';

import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { LoginService } from 'src/app/core/services/account/login.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { RegistrationService } from 'src/app/core/services/registration.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { RegistrationQuery } from 'src/app/core/state/registration/registration.query';
import { NotificationSettings } from 'src/app/shared/models/notification.model';

@Component({
  selector: 'app-phone-verification',
  templateUrl: './phone-verification.component.html',
  styleUrls: ['./phone-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneVerificationComponent implements OnInit, OnDestroy {
  @Input() mobileNumber: string;
  @Input() username: string;
  @Input() userId: string;
  @Input() OTPOption: number;
  @Input() OTPOptionAfterResend: number = undefined;
  @Input() limitExceeded: boolean;
  @Input() resendCodeVerification: boolean;

  verificationForm: FormGroup;
  avoidCallsWithSameCode: string;

  invalidCode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  limitExceeded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  verifyingCode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  intervalSub: Subscription;
  resendCodeNumOfSeconds: number = 60;
  showDNDNotice = this.appConfigService.get('registration').showDNDNotice;
  otpCodeLength = this.appConfigService.get('otp').otpCodeLength;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly hasResentCode$ = new BehaviorSubject(false);
  private readonly showLoader$ = new BehaviorSubject(false);

  constructor(
    readonly applicationQuery: ApplicationQuery,
    private readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly appConfigService: AppConfigService,
    private readonly applicationService: ApplicationService,
    private readonly dataLayerService: DataLayerService,
    private readonly loadingService: LoadingService,
    private readonly loginService: LoginService,
    private readonly notificationService: NotificationService,
    private readonly registrationQuery: RegistrationQuery,
    private readonly registrationService: RegistrationService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    if (this.limitExceeded) {
      this.limitExceeded$.next(true);
    }

    this.showLoader$
      .pipe(
        tap(showLoader => {
          showLoader ? this.loadingService.enqueueLoader() : this.loadingService.dequeueLoader();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onCancelClick(): void {
    this.accountQuery.isAuthenticated && this.accountQuery.userData.userStatus !== 'ACT' ? this.logout() : this.cancel();
  }

  logout(redirectPage: string = ''): void {
    this.accountService.logout().subscribe(
      () => {
        // Success
        if (redirectPage !== '') {
          this.applicationService.navigateTo(redirectPage);
        } else {
          this.router.navigate(['/account/login']);
        }

        this.notificationService.showSuccessMessage($localize`Logout successful`);
      },
      () => {
        // Error
        // if the revokeToken call fails, clear the userData anyway
        this.accountService.clearUserData();
      }
    );
  }

  cancel(): void {
    this.applicationService.navigateTo('/');
  }

  resendCode(): void {
    const body: any = {
      RequestTypeOption: this.OTPOption,
      Data: {},
    };

    switch (this.OTPOption) {
      case 2: // Reset Password
      case 3: // Registration
        body.Data = {
          Username: this.username,
        };
        break;
      case 4: // Change Phone Number
      case 5: // Verify Phone Number
        body.Data = {
          UserId: this.accountQuery.userData.id,
          Mobile: this.mobileNumber,
        };
        break;
      default:
        break;
    }

    this.accountService
      .regenerateTokenForService(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.limitExceeded$.next(false);
          this.hasResentCode$.next(true);
        },
        error => {
          if (error.error.ResponseCode === 20805) {
            this.limitExceeded$.next(true);
          }
        }
      );
  }

  verifyCode(code: string): void {
    this.showLoader$.next(true);
    this.verifyingCode$.next(true);

    this.accountService
      .validateOneTimePassword(
        this.username,
        code,
        this.hasResentCode$.value && this.OTPOptionAfterResend !== undefined ? this.OTPOptionAfterResend : this.OTPOption
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          // Success
          this.showLoader$.next(false);
          this.invalidCode$.next(false);
          if (this.OTPOption === 2) {
            // Reset Password
            window.location.href = `account/password-reset/${this.username}/${code}`;
          } else if (this.OTPOption === 3) {
            // Registration
            if (this.accountQuery.isAuthenticated) {
              this.accountService.getUserData(this.accountQuery.accessToken).subscribe(() => {
                this.notificationService.showNotification(
                  new NotificationSettings({
                    allowBackdropClose: true,
                    contentText: $localize`Phone verified successful`,
                    type: 'success',
                    showConfirmButton: true,
                  })
                );

                window.location.href = '/';
              });
            } else {
              // This code was added as a hotfix for UP-484 because registration-step-3
              // was not being logged during quick registration
              if (this.userId) {
                this.dataLayerService.createDataLayerEvent({
                  event: 'registration-step-3',
                  userID: this.userId,
                });
              }

              this.loginService
                .login(this.registrationQuery.credentials.user, this.registrationQuery.credentials.pass, true)
                .subscribe(() => {
                  this.registrationService.clearCredentials();
                  window.location.href = 'register-success';
                });
            }
          } else if (this.OTPOption === 4) {
            // Change Phone Number
            this.accountService.getUserData(this.accountQuery.accessToken).subscribe(() => {
              this.notificationService.showNotification(
                new NotificationSettings({
                  allowBackdropClose: true,
                  contentText: $localize`Phone number changed successful`,
                  type: 'success',
                  showConfirmButton: true,
                })
              );

              window.location.href = `account/change-phone-number`;
            });
          } else if (this.OTPOption === 5) {
            // Verify Phone Number
            this.accountService.getUserData(this.accountQuery.accessToken).subscribe(() => {
              this.notificationService.showNotification(
                new NotificationSettings({
                  allowBackdropClose: true,
                  contentText: $localize`Phone verified successful`,
                  type: 'success',
                  showConfirmButton: true,
                })
              );

              window.location.href = '/';
            });
          }
          this.verifyingCode$.next(false);
        },
        () => {
          // Error
          this.showLoader$.next(false);
          this.invalidCode$.next(true);
          this.verifyingCode$.next(false);
        }
      );
  }
}
